import * as React from 'react';
import { styled } from 'linaria/react';
import SevOneFooter from './sevone-footer.svg';
import { gitCommit, gitTag } from '../../../global';
import { VERTICAL_RHYTHM } from '../../../utils/spacing';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--sev1-primary-4-contrast);
  & > span {
    font-size: calc(var(--sev1-size) * 0.65);
  }

  & > * {
    margin: 0px ${VERTICAL_RHYTHM / 2}px;
  }
`;
const LogoWrapper = styled.div`
  width: 70px;
`;


function Footer() {
  return (
    <Container>
      <span>{`SevOne Data Insight \u00A9 2016-${new Date().getFullYear()}`}</span>
      <span>{`SevOne Data Insight Software (${gitTag} sha1:${gitCommit})`}</span>
      <LogoWrapper>
        <SevOneFooter />
      </LogoWrapper>
    </Container>
  );
}

export { Footer };
