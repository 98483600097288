import * as React from 'react';
import { styled } from 'linaria/react';
import { Page, PageTitle } from '../../components/page';

const NotFound = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

function ReportNotFound() {
  return (
    <Page title={'Report not Found'} >
      <NotFound>
        <PageTitle>{'Report not Found'}</PageTitle>
      </NotFound>
    </Page>
  );
}

export { ReportNotFound };
