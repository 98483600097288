import * as React from 'react';
import { hot } from 'react-hot-loader/root';
import { Route, Redirect, Switch } from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import Backend from 'react-dnd-html5-backend';
import { InsightProvider, useNotification } from '@sevone/insight-connect';
import { configure } from '@sevone/insight-wdk';
import { CoreStore } from '../store';
import { serverHost } from '../global';
import { SidebarProvider } from '../hooks/use-sidebar';
import { useGql } from '../hooks/use-gql';
import { baseUrl, basePath } from '../utils/base-location';
import { getWdkServer } from '../utils/wdk';
import { AuthenticatedRoute } from '../components/authenticated-route';
import { PermissionGate } from '../components/permission-gate';
import { LoginPage } from '../pages/login';
import { OpenIDCallbackPage } from '../pages/openid';
import { NotFound } from '../pages/not-found';
import { PdfLoading } from '../pages/pdf-loading';
import { WidgetPage } from '../pages/widget-viewer';
import { Bootstrap } from './bootstrap';
import { Shell } from './shell';
import { defaultTheme } from './default-theme';
import { flatNavigation } from './navigation';
import { TENANTS_QUERY, TenantsResponseType } from './current-tenant-datasources.query';

function AppContent() {
  const { state } = CoreStore.useContainer();
  const { showNotification } = useNotification();
  const { runGql: fetchCurrentTenantDatasources } = useGql<TenantsResponseType>(TENANTS_QUERY);
  // if defined, redirect to user's or tenant's home-report (user precedence)
  let redirectTo = '/reports';
  if (state.user && state.activeTenant) {
    const homeReportId = state.user.homeReportId || state.activeTenant.homeReportId;
    if (homeReportId) {
      redirectTo = `/reports/${homeReportId}`;
    }
  }

  React.useEffect(() => {
    if (state.activeTenant) {
      fetchCurrentTenantDatasources({ id: state.activeTenant.id }).then((res) => {
        if (res.tenants[0].datasources.length === 0) {
          showNotification({
            type: 'error',
            message: 'Tenant has no assigned datasources'
          });
        }
      });
    }
  }, [ showNotification, state.activeTenant ]);

  return (
    <Bootstrap>
      <Switch>
        <AuthenticatedRoute path="/widget/:sessionId/:section?">
          <WidgetPage />
        </AuthenticatedRoute>

        <Route path="/callback">
          <OpenIDCallbackPage />
        </Route>

        <Shell isAuthenticated={state.isAuthenticated}>
          <Switch>
            <Route path="/login">
              <LoginPage />
            </Route>

            <AuthenticatedRoute exact path="/">
              <Redirect to={redirectTo} />
            </AuthenticatedRoute>

            {flatNavigation.map((item) => {
              if (!item.pages?.length) {
                return null;
              }

              return item.pages.map((page) => (
                <AuthenticatedRoute exact key={item.id} path={page.match}>
                  <PermissionGate
                    requiredPermissions={page.permissions || []}
                    fallback={<NotFound />}
                  >
                    <page.page />
                  </PermissionGate>
                </AuthenticatedRoute>
              ));
            })}

            <Route path="/pdf-loading">
              <PdfLoading />
            </Route>

            <Route path="*">
              <NotFound />
            </Route>
          </Switch>
        </Shell>
      </Switch>
    </Bootstrap>
  );
}

function InsightConfigure() {
  const { state } = CoreStore.useContainer();
  const { activeTenant, activeThemeId } = state;
  const serverUrl = serverHost || `${baseUrl}/retinaserver`;
  const wdkServerUrl = getWdkServer();
  let currentTheme = defaultTheme;

  if (activeTenant && activeThemeId) {
    const currentTenantTheme = activeTenant.themes.find((theme) => {
      return theme.id === activeThemeId;
    });

    if (currentTenantTheme) {
      currentTheme = {
        ...defaultTheme,
        ...JSON.parse(currentTenantTheme.colors)
      };
    }
  }

  React.useEffect(() => {
    configure({
      api: {
        sevone: {
          server: serverUrl,
          authToken: state.authToken || undefined
        }
      },
      assets: {
        server: `${wdkServerUrl}/widget/`
      }
    });
  }, [ state.authToken, serverUrl, wdkServerUrl ]);

  return (
    <InsightProvider
      basePath={basePath}
      authToken={state.authToken}
      serverUrl={serverUrl}
      theme={currentTheme}
    >
      <AppContent />
    </InsightProvider>
  );
}

function App() {
  return (
    <DndProvider backend={Backend}>
      <CoreStore.Provider>
        <SidebarProvider>
          <InsightConfigure />
        </SidebarProvider>
      </CoreStore.Provider>
    </DndProvider>
  );
}

export default hot(App);
