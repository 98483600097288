import { metadataSchema } from '@sevone/insight-connect';
import { Variable } from './variable';
import { VariableType } from './types';
import { Configure } from './configure';

const objectMetadata: VariableType = {
  label: 'Object Metadata',
  schema: metadataSchema({ allowedEntityTypes: [ 'OBJECT' ] }),
  defaultConfiguration: {
    datasources: [],
    isClearable: true,
    namespaceName: null,
    attributeName: null
  },
  configure: Configure,
  render: Variable
};

export { objectMetadata };
