import * as React from 'react';
import { styled } from 'linaria/react';
import { useNotification } from '@sevone/insight-connect';
import {
  Dropdown,
  DropdownMenu,
  DropdownItem,
  FolderIcon,
  OpenedFolderIcon,
  VerticalEllipsisIcon
} from '@sevone/scratch';
import { useGql } from '../../../hooks/use-gql';
import { HORIZONTAL_RHYTHM } from '../../../utils/spacing';
import { ReportDropTarget } from '../draggable-report';
import { ReportType } from '../get-reports.query';
import { isImmutableFolder, isPersonalFolder } from './immutable-folders';
import {
  DELETE_FOLDER,
  DeleteFolderResponseType
} from './delete-folder.mutation';
import {
  UPDATE_REPORT_FOLDER,
  UpdateReportFolderResponseType
} from './update-report-folder.mutation';
import { FolderTreeType } from './types';
import { PermissionGate } from '../../../components/permission-gate';

const DropdownWrapper = styled.div<{ isOpen: boolean, onClick: React.MouseEventHandler }>`
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  margin-left: auto;
  padding-left: ${HORIZONTAL_RHYTHM}px;
`;

const FolderIconWrapper = styled.div<{ selected: boolean }>`
  width: 1.2em;
  padding-right: .5em;
  color: ${(props) => (props.selected ? 'inherit' : 'var(--sev1-primary-5-color)')};
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: .1em;

  // @ts-ignore: linaria TS is missing typing for component interpolations
  &:hover ${DropdownWrapper} {
    visibility: visible;
  }
`;

const calculateReportCount = (folder: FolderTreeType): number => {
  if (!folder.children || !folder.children.length) {
    return folder.reportCount;
  }

  // An item's count is the sum of its count, all its children's counts,
  // and their children's children. So on and so forth.
  return folder.children.reduce((prev, childId) => {
    return prev + calculateReportCount(childId);
  }, folder.reportCount);
};

type Props = {
  data: FolderTreeType,
  selected: boolean,
  expanded: boolean,
  onExpandNode: (folderId: number) => void,
  onEditNode: (folderId: number) => void,
  onCreateNode: (parentId: number | null) => void,
  onDeleteNode: () => void,
  onMoveReports: () => void
};

function FolderNode(props: Props) {
  const {
    data,
    selected,
    expanded,
    onExpandNode,
    onEditNode,
    onCreateNode,
    onDeleteNode,
    onMoveReports
  } = props;
  const { showNotification } = useNotification();
  const {
    runGql: deleteFolder
  } = useGql<DeleteFolderResponseType>(DELETE_FOLDER);
  const {
    runGql: updateReportFolder
  } = useGql<UpdateReportFolderResponseType>(UPDATE_REPORT_FOLDER);
  const [ menuVisibility, setMenuVisibility ] = React.useState(false);
  const [ isDraggedOver, setIsDraggedOver ] = React.useState(false);
  const Icon = expanded ? OpenedFolderIcon : FolderIcon;
  const reportCount = calculateReportCount(data);

  const handleMenuClick = (event: React.MouseEvent) => {
    // We want to prevent clicking the dropdown menu from also selecting the
    // folder.
    event.stopPropagation();
    event.preventDefault();
  };

  const handleMoveReports = async (
    fId: FolderTreeType['id'],
    reportIds: Array<ReportType['id']>
  ) => {
    const folderId = isPersonalFolder(fId) ? null : fId;

    try {
      await Promise.all(reportIds.map((id) => {
        return updateReportFolder({ id, report: { folderId } });
      }));
      onMoveReports();
    } catch (e) {
      showNotification({ type: 'error', message: e.message });
    }
  };

  const handleEditFolder = () => {
    onEditNode(data.id);
  };

  const handleCreateFolder = () => {
    onCreateNode(data.id);
  };

  const handleDeleteFolder = () => {
    deleteFolder({ id: data.id }).then(() => {
      onDeleteNode();
    }).catch((e: { message: string }) => {
      showNotification({ type: 'error', message: e.message });
    });
  };

  React.useEffect(() => {
    if (isDraggedOver) {
      const timeout = setTimeout(() => { onExpandNode(data.id); }, 300);
      return () => { clearTimeout(timeout); };
    }

    return () => {};
  }, [ isDraggedOver ]);

  return (
    <ReportDropTarget
      folderId={data.id}
      disabled={isImmutableFolder(data.id) && !isPersonalFolder(data.id)}
      onDrop={handleMoveReports}
      onHover={() => { setIsDraggedOver(true); }}
      onLeave={() => { setIsDraggedOver(false); }}
    >
      <Wrapper selected={selected}>
        <FolderIconWrapper selected={selected}>
          <Icon />
        </FolderIconWrapper>
        {data.label} {`(${reportCount})`}
        {(!isImmutableFolder(data.id) || isPersonalFolder(data.id)) &&
          <PermissionGate requiredPermissions={[ 'createReport' ]} >
            <DropdownWrapper isOpen={menuVisibility} onClick={handleMenuClick}>
              <Dropdown
                trigger={[ 'click' ]}
                visible={menuVisibility}
                menu={
                  <DropdownMenu>
                    <DropdownItem
                      onClick={handleCreateFolder}
                    >
                      {'Add'}
                    </DropdownItem>
                    <DropdownItem
                      disabled={isPersonalFolder(data.id)}
                      onClick={handleEditFolder}
                    >
                      {'Edit'}
                    </DropdownItem>
                    <DropdownItem
                      disabled={reportCount > 0 || isPersonalFolder(data.id)}
                      onClick={handleDeleteFolder}
                    >
                      {'Delete'}
                    </DropdownItem>
                  </DropdownMenu>
                }
                onVisiblityChange={setMenuVisibility}
              >
                <VerticalEllipsisIcon />
              </Dropdown>
            </DropdownWrapper>
          </PermissionGate>
        }
      </Wrapper>
    </ReportDropTarget>
  );
}

export { FolderNode };
