import * as React from 'react';
import { styled } from 'linaria/react';
import {
  Card,
  Input,
  Switch,
  Select,
  Tooltip
} from '@sevone/scratch';
import { LocalLogo } from '..';
import { Logos } from './logos';
import { AuthSourceType } from '../queries/get-tenants.query';
import { ExtendedDatasourceType } from '../queries/get-tenant-datasource.query';
import { LogoType } from '../queries/get-logos.query';
import { VERTICAL_RHYTHM, HORIZONTAL_RHYTHM } from '../../../utils/spacing';
import { PermissionGate } from '../../../components/permission-gate';

const CardWrapper = styled.div`
  border: none;
  border-radius: 4px;
  flex: none;
  margin: ${HORIZONTAL_RHYTHM / 2}px 0;
  min-width: 300px;
`;
const ContentWrapper = styled.div`
  padding: ${VERTICAL_RHYTHM / 5}px ${HORIZONTAL_RHYTHM}px;
  & > * {
    margin: ${VERTICAL_RHYTHM}px 0px;
  }
`;

type Props = {
  tenantId?: number,
  name: string
  onNameChange: (value: string) => void,
  logoutURL: string,
  onLogoutURLChange: (value: string) => void,
  nmsRoleId: string,
  onNmsRoleIdChange: (value: string) => void,
  useAlternateName: boolean,
  onAlternateNameChange: (value: boolean) => void,
  authSource?: AuthSourceType,
  onAuthSourceChange: (authSource: AuthSourceType) => void,
  availableDatasources: Array<ExtendedDatasourceType>,
  isFetchingLogos: boolean,
  logos: Array<LogoType>,
  isEditing: boolean,
  defaultLogoId?: number,
  smallLogoId?: number,
  setDefaultLogoId: (id: number) => void,
  setSmallLogoId: (id: number) => void,
  localTenantLogo?: LocalLogo
  setLocalTenantLogo: (logo: LocalLogo) => void,
  onUploadLogo: (tenantId: number) => void,
  onDeleteLogo: (ids: Array<number | string>) => void,
  onHasChanged: (hasChanged: boolean) => void
}

function TenantDetails(props: Props) {
  const {
    tenantId,
    name,
    onNameChange,
    logoutURL,
    onLogoutURLChange,
    nmsRoleId,
    onNmsRoleIdChange,
    useAlternateName,
    onAlternateNameChange,
    authSource,
    onAuthSourceChange,
    availableDatasources,
    isFetchingLogos,
    logos,
    isEditing,
    defaultLogoId,
    smallLogoId,
    setDefaultLogoId,
    setSmallLogoId,
    localTenantLogo,
    setLocalTenantLogo,
    onUploadLogo,
    onDeleteLogo,
    onHasChanged
  } = props;

  const authSourceValue = authSource ? {
    label: authSource.name,
    value: authSource.id
  } : null;

  const authSourceOptions = availableDatasources.map((ds) => {
    return {
      label: ds.name,
      value: ds.id
    };
  });

  const handleNameChange = (value: string) => {
    onNameChange(value);
    onHasChanged(true);
  };

  const handleLogoutURLChange = (value: string) => {
    onLogoutURLChange(value);
    onHasChanged(true);
  };

  const handleNmsRoleChange = (value: string) => {
    onNmsRoleIdChange(value);
    onHasChanged(true);
  };

  const handleUseAlternateNameChange = (value: boolean) => {
    onAlternateNameChange(value);
    onHasChanged(true);
  };

  const handleAuthSourceChange = (option: { label: string, value: number }) => {
    const newAuthSource = {
      id: option.value,
      name: option.label
    };
    onHasChanged(true);
    onAuthSourceChange(newAuthSource);
  };

  return (
    <CardWrapper>
      <Card title={'Tenant Details'}>
        <ContentWrapper>
          <PermissionGate requiredPermissions={[ 'updateAllTenants', 'viewAllTenants' ]}>
            <Input
              value={name}
              label={'Tenant Name'}
              status={!name ? 'error' : undefined}
              placeholder={'Enter new tenant\'s name'}
              onChange={handleNameChange}
            />
            <Input
              value={logoutURL}
              label={'Logout URL'}
              placeholder={'(Optional) URL to be redirected to on logout'}
              onChange={handleLogoutURLChange}
            />
            <Input
              value={nmsRoleId}
              label={'Tenant NMS Role ID'}
              placeholder={'NMS Role ID'}
              onChange={handleNmsRoleChange}
              type={'number'}
            />
            <Tooltip
              position={[ 'top', 'left' ]}
              tooltip={
                <div style={{ width: '200px' }}>
                  {`
                    Users will see the Alternate Device and Object Names instead
                    of the Primary Device and Object Names. Alternate Names are
                    defined in the SevOne NMS.
                  `}
                </div>
              }
            >
              <Switch
                checked={useAlternateName}
                onChange={handleUseAlternateNameChange}
              >
                {'Use Alternate Names'}
              </Switch>
            </Tooltip>
            <Select
              label={'Authentication Source'}
              placeholder={'Please select...'}
              value={authSourceValue}
              options={authSourceOptions}
              onChange={handleAuthSourceChange}
            />
          </PermissionGate>
          <Logos
            tenantId={tenantId}
            isFetchingLogos={isFetchingLogos}
            logos={logos}
            isEditing={isEditing}
            defaultLogoId={defaultLogoId}
            smallLogoId={smallLogoId}
            setDefaultLogoId={setDefaultLogoId}
            setSmallLogoId={setSmallLogoId}
            localTenantLogo={localTenantLogo}
            setLocalTenantLogo={setLocalTenantLogo}
            onUploadLogo={onUploadLogo}
            onDeleteLogo={onDeleteLogo}
            onHasChanged={onHasChanged}
          />
        </ContentWrapper>
      </Card>
    </CardWrapper>
  );
}

export { TenantDetails };
