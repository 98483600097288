import { ThemeType } from './get-themes.query';

export const CREATE_THEME_MUTATION = `
  mutation CreateTheme (
    $name: String!,
    $colors: String!,
    $tenantId: Int,
    $isGlobal: Boolean!
  ) {
    createTheme (
      name: $name,
      colors: $colors,
      tenantId: $tenantId,
      isGlobal: $isGlobal
    ) {
      id,
      name,
      colors,
      isGlobal,
      isBuiltIn
    }
  }
`;

export type CreateThemeResponseType = {
  createTheme: ThemeType
};
