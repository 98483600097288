import * as React from 'react';
import { pathToRegexp } from 'path-to-regexp';
import { useNavigation } from '@sevone/insight-connect';
import { NavigationMenu } from './navigation-menu';
import { navigation, flatNavigation } from '../../navigation';

type Props = {
  collapsed: boolean,
  collapsedWidth: string
};

function Navigation(props: Props) {
  const { collapsed, collapsedWidth } = props;
  const { location } = useNavigation();
  const selectedItem = flatNavigation.find((item) => {
    if (!item.pages) {
      return false;
    }

    const regs = item.pages.map((page) => pathToRegexp(page.match));
    return regs.some((re) => !!re.exec(location.pathname));
  });
  const [
    expandedItems,
    setExpandedItems
  ] = React.useState<Array<string>>(
    // If on a secondary item, expand the parent item on initial page load so
    // the user knows where they are.
    selectedItem?.parentId ? [ selectedItem.parentId ] : []
  );

  return (
    <NavigationMenu
      compact={collapsed}
      compactWidth={collapsedWidth}
      selectedItem={selectedItem?.id || null}
      menuItems={navigation}
      expandedItems={expandedItems}
      onExpansionChange={setExpandedItems}
    />
  );
}

export { Navigation };
