export const ADD_TENANT_DATASOURCE_MUTATION = `
  mutation ($datasourceId: Int!, $tenantId: Int!, $useAsDatasource: Boolean, $useAsAuthsource: Boolean) {
    addTenantDatasource
      (
        datasourceId: $datasourceId,
        tenantId: $tenantId,
        useAsDatasource: $useAsDatasource,
        useAsAuthsource: $useAsAuthsource
      )
      {
        id
      }
  }
`;

export type AddTenantDatasourceResponseType = {
  addTenantDatasource: { id: number }
}
