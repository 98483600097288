import * as React from 'react';
import { styled } from 'linaria/react';
import { LoadingCircle } from '@sevone/scratch';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 200;
  background: var(--sev1-primary-4-color);
`;

function LoadingIndicator() {
  return (
    <Wrapper>
      <LoadingCircle />
    </Wrapper>
  );
}

export { LoadingIndicator };
