import * as React from 'react';
import { styled } from 'linaria/react';
import { Link, useRequest } from '@sevone/insight-connect';
import {
  Dialog,
  Button,
  FileSelector,
  Table,
  CheckIcon,
  RemoveIcon,
  Tooltip
} from '@sevone/scratch';
import { HORIZONTAL_RHYTHM, VERTICAL_RHYTHM } from '../../../../utils/spacing';
import { ReportType } from '../../get-reports.query';
import {
  IMPORT_REPORTS,
  ImportReportsResponseType
} from './import-reports.mutation';

const GenericWrapper = styled.div`
  margin-bottom: ${VERTICAL_RHYTHM}px;
`;

const FileDropzone = styled.div`
  background: var(--sev1-primary-3-color);
  color: var(--sev1-primary-3-contrast);
  border: 1px solid var(--sev1-primary-1-color);
  padding: ${VERTICAL_RHYTHM}px ${HORIZONTAL_RHYTHM}px;
  cursor: pointer;
`;

const TableWrapper = styled(GenericWrapper)`
  height: 250px;
  width: 400px;
`;

const ImportStatusWrapper = styled.div<{ error: boolean }>`
  text-align: center;
  color: ${({ error }) => (error ?
    'var(--sev1-error-color)' : 'var(--sev1-confirmation-color'
  )};
`;

const ButtonsWrapper = styled.div`
  text-align: right;

  & > * {
    margin-left: ${HORIZONTAL_RHYTHM}px;
  }
`;

const CellLink = styled.span`
  color: var(--sev1-primary-5-color);
`;

const COLUMNS = [
  {
    id: 'name',
    title: 'Report Name',
    render: (row: ImportedReport) => (
      !row.error && row.id ? (
        <CellLink>
          <Link to={`/reports/${row.id}`}>
            {row.name}
          </Link>
        </CellLink>
      ) : (
        row.name
      )
    ),
    sort: (row: ImportedReport, otherRow: ImportedReport) => {
      const { name } = row;
      const { name: otherName } = otherRow;
      if (name < otherName) {
        return -1;
      }

      if (name > otherName) {
        return 1;
      }

      return 0;
    }
  },
  {
    id: 'imported',
    title: 'Imported',
    render: (row: ImportedReport) => (
      <ImportStatusWrapper error={!!row.error}>
        {row.error ?
          <Tooltip position={[ 'top', 'center' ]} tooltip={row.error}>
            <RemoveIcon />
          </Tooltip> :
          <CheckIcon />
        }
      </ImportStatusWrapper>
    )
  }
];

type ImportedReport = {
  id: ReportType['id'],
  name: string,
  error: string | null
};

type Props = {
  hideModal: () => void,
  onSuccess: () => void,
  onError: (error: { message: string }) => void
};

function ImportDialog(props: Props) {
  const { hideModal, onSuccess, onError } = props;
  const { raw } = useRequest();
  const [ files, setFiles ] = React.useState<Array<File>>([]);
  const [ imported, setImported ] = React.useState<Array<ImportedReport>>([]);

  const handleFileUpload = async () => {
    const data = new FormData();
    data.append('file', files[0]);
    data.append('query', IMPORT_REPORTS);
    data.append('variables', '{}');

    try {
      const res: ImportReportsResponseType = await raw({
        url: '/',
        method: 'POST',
        data
      });

      setFiles([]);
      setImported(res.data.data.importReports.map((r) => ({
        error: r.error,
        id: r.report.id,
        name: r.report.name
      })));
      onSuccess();
    } catch {
      onError({ message: 'Unknown error uploading.' });
      throw new Error();
    }
  };

  return (
    <Dialog title={'Upload Import File'} onClose={hideModal}>
      {imported.length === 0 &&
        <GenericWrapper>
          <FileSelector
            accept={[ 'application/x-tar' ]}
            files={files}
            onChange={setFiles}
          >
            <FileDropzone>
              {files.length ? files[0].name : 'Click or Drag file to upload.'}
            </FileDropzone>
          </FileSelector>
        </GenericWrapper>
      }
      {imported.length !== 0 &&
        <TableWrapper>
          <Table
            rows={imported}
            columns={COLUMNS}
          />
        </TableWrapper>
      }
      {imported.length === 0 &&
        <ButtonsWrapper>
          <Button secondary onClick={hideModal}>
            {'Cancel'}
          </Button>
          <Button disabled={files.length === 0} onClick={handleFileUpload}>
            {'Upload'}
          </Button>
        </ButtonsWrapper>
      }
      {imported.length !== 0 &&
        <ButtonsWrapper>
          <Button secondary onClick={hideModal}>
            {'Close'}
          </Button>
        </ButtonsWrapper>
      }
    </Dialog>
  );
}

export { ImportDialog };
