import { useState } from 'react';
import { facetManager, FacetType } from '@sevone/insight-wdk';
import { uuid } from '../../utils/uuid';
import { createContainer } from '../../utils/create-container';
import { WidgetLinkType } from '../../pages/report/types';
import {
  TYPE_PRIORITIES,
  DEPENDENCY_LIST,
  registerFacetContainer,
  addDependencies,
  removeDependencies,
  addFacet,
  CONTAINER_TYPES
} from '../facet-manager';
import { WidgetManager } from '../widget-manager';

type UnregisterContainerType = () => void;

type UnregisterersType = Record<string, UnregisterContainerType>;

function useWidgetLinkManager() {
  const { getWidgetBroadcastName } = WidgetManager.useContainer();
  const [ links, setLinks ] = useState<Record<string, WidgetLinkType>>({});
  const [ unregisterers, setUnregisterers ] = useState<UnregisterersType>({});

  const getWidgetLink = (id: string | null) => {
    if (id === null) {
      return null;
    }

    return links[id] || null;
  };

  const getWidgetLinkByParent = (parentId: string) => {
    return Object
      .values(links)
      .find((link) => link.parentId === parentId) || null;
  };

  const getWidgetLinksByChild = (childId: string) => {
    return Object
      .values(links)
      .filter((link) => link.children.map(({ id }) => id).includes(childId));
  };

  const getCompatibleChildWidgetTypes = (widgetType: string) => {
    return facetManager.queryCompatibleWidgetConsumers(widgetType);
  };

  const createWidgetLink = (link: {
    id?: WidgetLinkType['id'],
    parentId: WidgetLinkType['parentId'],
    children: WidgetLinkType['children']
  }) => {
    const { id = uuid(), parentId, children } = link;
    const createdLink = { id, parentId, children };
    const { unregister } = registerFacetContainer({
      id,
      priority: TYPE_PRIORITIES[CONTAINER_TYPES.widgetLink],
      dependencies: [
        ...DEPENDENCY_LIST[CONTAINER_TYPES.widgetLink],
        getWidgetBroadcastName(parentId)
      ]
    });

    setLinks((cur) => ({ ...cur, [id]: createdLink }));
    setUnregisterers((cur) => ({ ...cur, [id]: unregister }));
    children.forEach((child) => { addDependencies(child.id, [ id ]); });

    return createdLink;
  };

  const deleteWidgetLink = (id: string) => {
    const { [id]: link, ...keptLinks } = links;
    const { [id]: unregister, ...keptUnregisterers } = unregisterers;

    link.children.forEach((child) => {
      removeDependencies(child.id, [ link.id ]);
    });
    setLinks(keptLinks);

    if (unregister) {
      unregister();
      setUnregisterers(keptUnregisterers);
    }
  };

  const clearWidgetLinks = () => {
    Object.values(links).forEach((link) => { deleteWidgetLink(link.id); });
  };

  const addChildToWidgetLink = (
    linkId: WidgetLinkType['id'],
    childId: string,
    chain: boolean
  ) => {
    const link = getWidgetLink(linkId);

    if (!link) {
      return;
    }

    addDependencies(childId, [ linkId ]);
    setLinks((cur) => ({
      ...cur,
      [linkId]: {
        ...link,
        children: [ ...link.children, { id: childId, chain } ]
      }
    }));
  };

  const removeChildFromWidgetLink = (linkId: string, childId: string) => {
    const link = getWidgetLink(linkId);

    if (!link) {
      return;
    }

    const nextChildIds = link.children.filter(({ id }) => id !== childId);

    if (nextChildIds.length > 0) {
      removeDependencies(childId, [ linkId ]);
      setLinks((cur) => (cur[linkId] ? {
        ...cur,
        [linkId]: {
          ...cur[linkId],
          children: cur[linkId].children.filter(({ id }) => id !== childId)
        }
      } : cur));
    } else {
      deleteWidgetLink(linkId);
    }
  };

  const addDataToWidgetLink = (id: string, facet: FacetType) => {
    const link = getWidgetLink(id);

    if (!link) {
      return;
    }

    addFacet(link.id, facet);
  };

  return {
    links,
    createWidgetLink,
    deleteWidgetLink,
    clearWidgetLinks,
    addChildToWidgetLink,
    removeChildFromWidgetLink,
    getWidgetLink,
    getWidgetLinkByParent,
    getWidgetLinksByChild,
    getCompatibleChildWidgetTypes,
    addDataToWidgetLink
  };
}

const WidgetLinkManager = createContainer(useWidgetLinkManager);

export { WidgetLinkManager };
