export const SET_TENANT_FTP_SETTINGS_MUTATION = `
  mutation (
    $protocol: FileTransferProtocol!,
    $server: String,
    $port: Int,
    $username: String,
    $path: String,
    $password: String
  ){
    setTenantFtpSettings(
      protocol: $protocol,
      server: $server,
      port: $port,
      username: $username,
      password: $password,
      path: $path
    ) {
      server
      port
      username
      protocol
      path
    }
  }
`;

export type SetTenantFTPSettingsResponseType= {
  setTenantFtpSettings: {
    server: string,
    port: number,
    username: string,
    protocol: string,
    path: string
  }
}
