export const FTP_VERIFY_CREDS_MUTATION = `
  mutation (
    $protocol: FileTransferProtocol!,
    $server: String,
    $port: Int,
    $username: String,
    $path: String,
    $password: String
  ){
    tenantFtpVerifyCredentials(
      protocol: $protocol,
      server: $server,
      port: $port,
      username: $username,
      password: $password,
      path: $path
    )
  }
`;


export type FTPVerifyCredsResponseType = {
  tenantFtpVerifyCredentials: boolean
}
