import * as React from 'react';
import { styled } from 'linaria/react';
import { css } from 'linaria';
import {
  startOfDay,
  getHours,
  getMinutes,
  addMinutes,
  format,
  parse
} from 'date-fns';
import { zonedTimeToUtc, utcToZonedTime } from 'date-fns-tz';
import {
  Calendar,
  Input,
  Button,
  Tooltip,
  CheckIcon,
  CircleInfoIcon
} from '@sevone/scratch';
import {
  VERTICAL_RHYTHM,
  HORIZONTAL_RHYTHM
} from '../../../../../utils/spacing';
import {
  TimespanType,
  SpecificTimespanType,
  RelativeTimespanType
} from '../../../types';
import { TimezoneSelector } from './timezone-selector';
import { GqlTimezoneType } from './get-timezones.query';

const Wrapper = styled.div`
  display: flex;
  width: 400px;
  background: var(--sev1-primary-4-color);
  color: var(--sev1-primary-4-contrast);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25);
`;

const RelativeOptionsWrapper = styled.div`
  font-size: .8em;
  overflow-y: auto;
  border-right: 1px solid var(--sev1-primary-2-color);
`;

const RelativeOptionsList = styled.div`
  /*
   * CSS hack to make the calendar the dominant height and for this list to
   * fill up 100% of that height.
   */
  height: 0;
`;

const OptionIconWrapper = styled.div`
  display: inline-block;
  margin-left: auto;
  padding-left: ${HORIZONTAL_RHYTHM}px;
`;

const OptionWrapper = styled.div<{ selected: boolean, onClick: () => void }>`
  display: flex;
  align-items: center;
  color: ${({ selected }) => (selected ?
    'var(--sev1-primary-5-color)' :
    'inherit'
  )};
  padding: ${VERTICAL_RHYTHM / 2}px ${HORIZONTAL_RHYTHM}px;
  cursor: pointer;

  &:hover {
    color: var(--sev1-secondary-1-color);
  }

  // @ts-ignore: linaria TS is missing typing for component interpolations
  ${OptionIconWrapper} {
    // @ts-ignore For some reason TS keeps complaining here about having an
    // any type, while still displaying its type.
    visibility: ${(p) => (p.selected ? 'visible' : 'hidden')};
  }
`;

const TooltipWrapper = styled.div`
  max-width: 200px;
`;

const tooltipTargetStyles = css`
  padding-left: ${HORIZONTAL_RHYTHM}px;
`;

const CustomTimeWrapper = styled.div`
  flex: 1;
`;

const TimeWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 ${HORIZONTAL_RHYTHM}px ${VERTICAL_RHYTHM}px ${HORIZONTAL_RHYTHM}px;

  & > * {
    margin-right: ${HORIZONTAL_RHYTHM}px;

    &:last-of-type {
      margin-right: 0;
    }
  }
`;

const TimezoneWrapper = styled.div`
  margin: 0 ${HORIZONTAL_RHYTHM}px ${VERTICAL_RHYTHM}px ${HORIZONTAL_RHYTHM}px;
`;

const ButtonsWrapper = styled.div`
  text-align: right;
  margin: 0 ${HORIZONTAL_RHYTHM}px ${VERTICAL_RHYTHM}px ${HORIZONTAL_RHYTHM}px;

  & > * {
    margin-left: ${HORIZONTAL_RHYTHM}px;
  }
`;

const DEFAULT_MINUTES = 720;

function isSpecificTimespan(
  timespan: TimespanType | null
): timespan is SpecificTimespanType {
  return !!timespan && ('startTime' in timespan);
}

function isRelativeTimespan(
  timespan: TimespanType | null
): timespan is RelativeTimespanType {
  return !!timespan && ('timespan' in timespan);
}

function convertTimeToMinutes(time: number, timezone: string | null) {
  if (!timezone) {
    return DEFAULT_MINUTES;
  }

  const date = utcToZonedTime(new Date(time), timezone);

  return (getHours(date) * 60) + getMinutes(date);
}

function convertMinutesToReadableTime(
  minutes: number,
  timezone: string | null
) {
  if (Number.isNaN(minutes) || !timezone) {
    return '';
  }

  const utcTime = zonedTimeToUtc(new Date(), timezone);
  return format(addMinutes(startOfDay(utcTime), minutes), 'HH:mm');
}

function convertReadableTimeToMinutes(time: string, timezone: string | null) {
  if (!timezone) {
    return DEFAULT_MINUTES;
  }

  const date = parse(time, 'HH:mm', zonedTimeToUtc(new Date(), timezone));

  return (getHours(date) * 60) + getMinutes(date);
}

function generateCustomRange(
  dates: Array<number>,
  times: { startTime: number, endTime: number },
  timezone: string | null
): SpecificTimespanType | null {
  if (
    !timezone ||
    !dates[0] ||
    !dates[1] ||
    Number.isNaN(times.startTime) ||
    Number.isNaN(times.endTime)
  ) {
    return null;
  }

  const start = addMinutes(startOfDay(new Date(dates[0])), times.startTime);
  const end = addMinutes(startOfDay(new Date(dates[1])), times.endTime);

  return {
    startTime: zonedTimeToUtc(start, timezone).getTime(),
    endTime: zonedTimeToUtc(end, timezone).getTime()
  };
}

type Props = {
  timespan: TimespanType | null,
  relativeTimespans: Array<{
    label: string,
    timespan: string
  }>,
  timezones: Array<GqlTimezoneType>,
  onHide: () => void,
  onChange: (timespan: TimespanType | null) => void
};

function TimespanPicker(props: Props) {
  const {
    timespan,
    relativeTimespans,
    timezones,
    onHide,
    onChange
  } = props;
  const [
    relativeTimespan,
    setRelativeTimespan
  ] = React.useState(isRelativeTimespan(timespan) ? timespan : null);
  const [
    timezone,
    setTimezone
  ] = React.useState(timespan?.timezone || null);
  const [ page, setPage ] = React.useState(isSpecificTimespan(timespan) ? {
    month: new Date(timespan.startTime).getMonth() + 1,
    year: new Date(timespan.startTime).getFullYear()
  } : {
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear()
  });
  const [
    selectedDates,
    setSelectedDates
  ] = React.useState<Array<number>>(isSpecificTimespan(timespan) ? [
    timespan.startTime, timespan.endTime
  ] : []);
  const [
    startTime,
    setStartTime
  ] = React.useState(isSpecificTimespan(timespan) ?
    convertTimeToMinutes(timespan.startTime, timezone) : DEFAULT_MINUTES);
  const [
    endTime,
    setEndTime
  ] = React.useState(isSpecificTimespan(timespan) ?
    convertTimeToMinutes(timespan.endTime, timezone) : DEFAULT_MINUTES);
  const startTimeLabel = selectedDates.length > 0 ?
    format(selectedDates[0], 'MM/dd/yy') : 'From';
  const endTimeLabel = selectedDates.length > 1 ?
    format(selectedDates[1], 'MM/dd/yy') : 'To';

  const generateTimespan = (): TimespanType | null => {
    const nextTimespan = generateCustomRange(selectedDates, {
      startTime,
      endTime
    }, timezone) || relativeTimespan;

    if (!nextTimespan || !timezone) {
      return null;
    }

    return { ...nextTimespan, timezone };
  };

  const today = utcToZonedTime(new Date(), timezone || '').getTime();

  const handleCommit = () => {
    onChange(generateTimespan());
    onHide();
  };

  const handleRelativeSelection = (
    relative: { label: string, timespan: string } | null
  ) => {
    setSelectedDates([]);
    setStartTime(DEFAULT_MINUTES);
    setEndTime(DEFAULT_MINUTES);
    setRelativeTimespan(relative);
  };

  const handleDateSelection = (dates: Array<number>) => {
    setSelectedDates(dates.sort());
    setRelativeTimespan(null);
  };

  const handleStartTimeChange = (time: string) => {
    const minutes = convertReadableTimeToMinutes(time, timezone);

    setStartTime(minutes);
    setRelativeTimespan(null);
  };

  const handleEndTimeChange = (time: string) => {
    const minutes = convertReadableTimeToMinutes(time, timezone);

    setEndTime(minutes);
    setRelativeTimespan(null);
  };

  return (
    <Wrapper>
      <RelativeOptionsWrapper>
        <RelativeOptionsList>
          <OptionWrapper
            selected={false}
            onClick={() => {
              onChange(null);
              onHide();
            }}
          >
            {'None'}
            <Tooltip
              triggerDelay={0}
              position={[ 'right', 'center' ]}
              className={tooltipTargetStyles}
              tooltip={(
                <TooltipWrapper>
                  {`
                    Selecting none will clear your current timespan selection
                    and revert widgets to their widget timespan settings.
                  `}
                </TooltipWrapper>
              )}
            >
              <CircleInfoIcon />
            </Tooltip>
          </OptionWrapper>
          {relativeTimespans.map((ts) => (
            <OptionWrapper
              key={ts.timespan}
              selected={
                !!relativeTimespan &&
                relativeTimespan.timespan === ts.timespan
              }
              onClick={() => handleRelativeSelection(ts)}
            >
              {ts.label}
              <OptionIconWrapper><CheckIcon /></OptionIconWrapper>
            </OptionWrapper>
          ))}
        </RelativeOptionsList>
      </RelativeOptionsWrapper>
      <CustomTimeWrapper>
        <Calendar
          selectedDates={selectedDates}
          markedDates={[ today ]}
          page={page}
          onPageChange={setPage}
          onDateSelection={handleDateSelection}
        />
        <TimeWrapper>
          <Input
            label={startTimeLabel}
            type="time"
            disabled={!selectedDates[0]}
            value={convertMinutesToReadableTime(startTime, timezone)}
            onChange={handleStartTimeChange}
          />
          <Input
            label={endTimeLabel}
            type="time"
            disabled={!selectedDates[1]}
            value={convertMinutesToReadableTime(endTime, timezone)}
            onChange={handleEndTimeChange}
          />
        </TimeWrapper>
        <TimezoneWrapper>
          <TimezoneSelector
            timezone={timezone}
            timezones={timezones}
            onChange={setTimezone}
          />
        </TimezoneWrapper>
        <ButtonsWrapper>
          <Button type="outlined" onClick={onHide}>
            {'Cancel'}
          </Button>
          <Button
            disabled={!generateTimespan()}
            onClick={handleCommit}
          >
            {'Select'}
          </Button>
        </ButtonsWrapper>
      </CustomTimeWrapper>
    </Wrapper>
  );
}

export { TimespanPicker };
