import * as React from 'react';
import { styled } from 'linaria/react';
import { LoadingCircle, Message } from '@sevone/scratch';
import { WidgetInterfaceType, WidgetMetaType } from '../../../utils/wdk';
import { useWidgetFetcher } from '../../../hooks/wdk-server';

const Cover = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

type Props = {
  type: string,
  children: (
    widgetInterface: WidgetInterfaceType | null,
    widgetMeta: WidgetMetaType | null
  ) => React.ReactNode
}

function WidgetLoader(props: Props) {
  const { type, children } = props;
  const {
    isFetching,
    widgetInterface,
    widgetMeta
  } = useWidgetFetcher(type);

  if (isFetching) {
    return (
      <Cover><LoadingCircle size="large" /></Cover>
    );
  }

  if (!widgetInterface || !widgetMeta) {
    return (
      <Cover>
        <Message type="error">
          {'There was an error fetching this widget.'}
        </Message>
      </Cover>
    );
  }

  // ts-bug: ReactNode and JSX.Element are not compatible.
  // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/20544
  // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/18912
  // https://github.com/microsoft/TypeScript/issues/21699
  // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/20544
  return <>{children(widgetInterface, widgetMeta)}</>;
}

export { WidgetLoader };
