export const CREATE_TENANT_MUTATION = `
  mutation ($tenant: TenantDetailsArgs!) {
    createTenant(tenant: $tenant) {
      id
    }
  }
`;

export type CreateTenantResponse = {
  createTenant: { id: number }
}
