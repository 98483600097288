import * as React from 'react';
import { styled } from 'linaria/react';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { Select, Input, DatePicker, Checkbox, Tooltip } from '@sevone/scratch';
import { HORIZONTAL_RHYTHM, VERTICAL_RHYTHM } from '../../../utils/spacing';
import { ReportType } from '../get-reports.query';
import { useGql } from '../../../hooks/use-gql';
import {
  TenantEmailSettingsResponseType,
  TENANT_EMAIL_SETTINGS_QUERY
} from '../../delivery-manager/queries/get-email-settings.query';
import {
  TenantFTPSettingsResponseType,
  TENANT_FTP_SETTINGS_QUERY
} from '../../delivery-manager/queries/get-ftp-settings.query';

const PERIOD_TYPES = {
  HOURLY: { value: 'HOURLY', label: 'Hour(s)' },
  DAILY: { value: 'DAILY', label: 'Day(s)' },
  WEEKLY: { value: 'WEEKLY', label: 'Week(s)' },
  MONTHLY: { value: 'MONTHLY', label: 'Month(s)' },
  QUARTERLY: { value: 'QUARTERLY', label: 'Quarter(s)' },
  YEARLY: { value: 'YEARLY', label: 'Year(s)' }
};

const DEFAULT_SCHEDULE: NonNullable<ReportType['schedule']> = {
  startDate: new Date().getTime(),
  timezone: 'America/New_York',
  periodFrequency: 1,
  periodType: 'HOURLY',
  emailDelivery: false,
  emailRecipients: [],
  ftpDelivery: false,
  lastPrintStatus: 'NOT_RUN',
  lastPrintTime: null
};

const ControlWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  width: 100%;
  margin-bottom: ${VERTICAL_RHYTHM}px;

  & > * {
    flex: 1;
    margin-right: ${HORIZONTAL_RHYTHM}px;

    &:last-child {
      margin-right: 0;
    }
  }
`;

type ConditionalTooltipProps = {
  render: boolean,
  tooltip: string,
  children: React.ReactElement
}

const ConditionalTooltip: React.FC<ConditionalTooltipProps> = ({ render, tooltip, children }) => {
  if (render) {
    return <Tooltip position={[ 'top', 'left' ]} tooltip={tooltip}>
      {children}
    </Tooltip>;
  }
  return children;
};

type ScheduleType = NonNullable<ReportType['schedule']>;

type Props = {
  report: ReportType,
  timezones: Array<{ label: string, value: string }>,
  onChange: (update: Partial<ReportType>) => void
}

function Schedule(props: Props) {
  const { report, timezones, onChange } = props;
  const { schedule } = report;
  const timezoneValue = timezones.find((timezone) => {
    return schedule && timezone.value === schedule.timezone;
  });

  const {
    runGql: requestEmailSettings
  } = useGql<TenantEmailSettingsResponseType>(TENANT_EMAIL_SETTINGS_QUERY);
  const [ hasEmailSettings, setHasEmailSettings ] = React.useState<boolean>(false);
  const {
    runGql: requestFTPSettings
  } = useGql<TenantFTPSettingsResponseType>(TENANT_FTP_SETTINGS_QUERY);
  const [ hasFTPSettings, setHasFTPSettings ] = React.useState<boolean>(false);

  const getField = <K extends keyof ScheduleType>(field: K): ScheduleType[K] => {
    if (schedule === null || schedule[field] === null) {
      return DEFAULT_SCHEDULE[field];
    }

    return schedule[field];
  };

  const getZonedStartDate = () => {
    return utcToZonedTime(getField('startDate'), getField('timezone')).getTime();
  };

  const handleChange = (update: {
    [K in keyof ScheduleType]?: ScheduleType[K]
  }) => {
    onChange({
      schedule: {
        ...DEFAULT_SCHEDULE,
        ...schedule,
        ...update
      }
    });
  };

  const handleIsScheduledChange = (checked: boolean) => {
    onChange({ schedule: checked ? DEFAULT_SCHEDULE : null });
  };

  const handleTimezoneChange = (value: { label: string, value: string }) => {
    const prevZoned = getZonedStartDate();

    handleChange({
      startDate: zonedTimeToUtc(new Date(prevZoned), value.value).getTime(),
      timezone: value.value
    });
  };

  const handlePeriodFrequencyChange = (value: string) => {
    handleChange({ periodFrequency: parseInt(value) || 1 });
  };

  const handlePeriodTypeChange = (
    value: { label: string, value: ScheduleType['periodType'] }
  ) => {
    handleChange({ periodType: value.value });
  };

  const handleStartDateChange = (value: number) => {
    handleChange({
      startDate: zonedTimeToUtc(new Date(value), getField('timezone')).getTime()
    });
  };

  const handleEmailDeliveryChange = (checked: boolean) => {
    handleChange({ emailDelivery: checked });
  };

  const handleEmailRecipientsChange = (value: string) => {
    const emailRecipients = value.replace(/\s/g, '').split(',');

    handleChange({ emailRecipients });
  };

  const handleFtpChange = (checked: boolean) => {
    handleChange({ ftpDelivery: checked });
  };

  React.useEffect(() => {
    requestFTPSettings().then((res) => {
      setHasFTPSettings(res.tenantFtpSettings != null);
    });
    requestEmailSettings().then((res) => {
      setHasEmailSettings(res.tenantEmailSettings != null);
    });
  }, []);

  return (
    <div>
      <ControlWrapper>
        <Checkbox
          checked={!!report.schedule}
          onChange={handleIsScheduledChange}
        >
          {'Run the report on a schedule'}
        </Checkbox>
      </ControlWrapper>
      <ControlWrapper>
        <Select
          disabled={!schedule}
          label={'Time zone'}
          value={timezoneValue}
          options={timezones}
          onChange={handleTimezoneChange}
        />
      </ControlWrapper>
      <ControlWrapper>
        <Input
          disabled={!schedule}
          label={'Run every'}
          type="number"
          value={getField('periodFrequency').toString()}
          onChange={handlePeriodFrequencyChange}
        />
        <Select
          disabled={!schedule}
          value={PERIOD_TYPES[getField('periodType')]}
          options={Object.values(PERIOD_TYPES)}
          onChange={handlePeriodTypeChange}
        />
      </ControlWrapper>
      <ControlWrapper>
        <DatePicker
          showTime
          disabled={!schedule}
          label={'Starting at'}
          value={getZonedStartDate()}
          onChange={handleStartDateChange}
        />
      </ControlWrapper>
      <ControlWrapper>
        <ConditionalTooltip
          tooltip={'Configure Email settings in the Delivery Manager first'}
          render={!!schedule && !hasEmailSettings}
        >
          <Checkbox
            disabled={!schedule || !hasEmailSettings}
            checked={getField('emailDelivery')}
            onChange={handleEmailDeliveryChange}
          >
            {'Email delivery'}
          </Checkbox>
        </ConditionalTooltip>
      </ControlWrapper>
      <ControlWrapper>
        <Input
          disabled={!schedule || !getField('emailDelivery')}
          placeholder="user@domain.com, user2@domain.com"
          value={getField('emailRecipients').join(', ')}
          onChange={handleEmailRecipientsChange}
        />
      </ControlWrapper>
      <ControlWrapper>
        <ConditionalTooltip
          tooltip={'Configure FTP settings in the Delivery Manager first'}
          render={!!schedule && !hasFTPSettings}
        >
          <Checkbox
            disabled={!schedule || !hasFTPSettings}
            checked={getField('ftpDelivery')}
            onChange={handleFtpChange}
          >
            {'FTP/SFTP delivery'}
          </Checkbox>
        </ConditionalTooltip>
      </ControlWrapper>
    </div>
  );
}

export { Schedule };
