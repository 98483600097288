import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { useNavigation } from '@sevone/insight-connect';
import { ReportRuntime } from '../../report-runtime';
import { debug } from '../../utils/logger';
import { useSessionFetcher } from './use-session-fetcher';
import { Section } from './section';
import {
  EVENTS,
  SectionChangeType,
  WidgetChangeType,
  generateReportLoadEvent
} from './events';

function WidgetPage() {
  const { location, match, navigateTo } = useNavigation();
  const { isPrintMode } = location.params;
  const { sessionId, section } = match.params;
  const {
    session,
    fetchingSession
  } = useSessionFetcher(sessionId);
  const [ activeWidget, setActiveWidget ] = React.useState<string | null>(null);

  // Notify when report has loaded and is ready to be rendered
  React.useEffect(() => {
    if (session) {
      document.dispatchEvent(generateReportLoadEvent(session.report));
    }
  }, [ !!session ]);

  // Update the URL every time we receive notice to change sections
  React.useEffect(() => {
    const redirectToSection = (e: SectionChangeType) => {
      debug('pdf', EVENTS.sectionChange, e.detail.sectionId);
      navigateTo({
        ...location,
        pathname: `/widget/${sessionId}/${e.detail.sectionId}`
      });
    };

    document.addEventListener(EVENTS.sectionChange, redirectToSection);

    return () => {
      document.removeEventListener(EVENTS.sectionChange, redirectToSection);
    };
  }, []);

  // Keep track of which widget is being requested to render
  React.useEffect(() => {
    const updateActiveWidget = (e: WidgetChangeType) => {
      debug('pdf', EVENTS.widgetChange, e.detail.widgetId);
      setActiveWidget(e.detail.widgetId);
    };

    document.addEventListener(EVENTS.widgetChange, updateActiveWidget);

    return () => {
      document.removeEventListener(EVENTS.widgetChange, updateActiveWidget);
    };
  }, [ setActiveWidget ]);

  // Add the class to the root containers so widgets grow freely
  React.useLayoutEffect(() => {
    document.querySelector('html')?.classList.add('pdf');
    document.querySelector('body')?.classList.add('pdf');
    document.querySelector('#root')?.classList.add('pdf');

    return () => {
      document.querySelector('html')?.classList.remove('pdf');
      document.querySelector('body')?.classList.remove('pdf');
      document.querySelector('#root')?.classList.remove('pdf');
    };
  }, []);

  // Provide the necessary flag for `usePrintMode()` in insight-connect
  if (!isPrintMode) {
    return (
      <Redirect
        to={{
          pathname: location.pathname,
          hash: location.hash,
          search: new URLSearchParams({
            ...location.params,
            isPrintMode: 'true'
          }).toString(),
          state: location.state
        }}
      />
    );
  }

  if (!session || fetchingSession || !section) {
    return null;
  }

  return (
    <ReportRuntime report={session.report}>
      <Section
        key={section}
        sectionId={parseInt(section)}
        activeWidgetId={activeWidget}
        sessionPayload={session}
      />
    </ReportRuntime>
  );
}

export { WidgetPage };
