import * as React from 'react';
import { ReportVariableType } from '../../types';
import { VariableBlueprintType } from './types';

type Props = {
  type: VariableBlueprintType<Record<string, any>, any, any>,
  variable: ReportVariableType | null,
  onConfigChange: (config: Record<string, any>) => void,
  onValueChange: (value: any) => void
};

function RenderVariable(props: Props) {
  const { type, variable, onConfigChange, onValueChange } = props;

  const generateConfig = () => {
    return {
      ...type.defaultConfiguration,
      ...(variable ? variable.config : {})
    };
  };

  const getOptions = () => {
    return variable ? variable.options : [];
  };

  const getValue = () => {
    return variable ? variable.value : null;
  };

  const handleConfigChange = (config: Record<string, any>) => {
    if (!variable) {
      return;
    }

    onConfigChange({ ...variable.config, ...config });
  };

  const handleValueChange = (value: any) => {
    onValueChange(value);
  };

  return (
    <type.render
      config={generateConfig()}
      options={getOptions()}
      value={getValue()}
      onConfigChange={handleConfigChange}
      onValueChange={handleValueChange}
    />
  );
}

export { RenderVariable };
