import * as React from 'react';
import { styled } from 'linaria/react';
import BaseFullscreen from 'react-full-screen';
import { Button } from '@sevone/scratch';
import AnimatedContainer from './AnimatedContainer';

const FullscreenWrapper = styled.div<{ isFullscreen?: boolean }>`
  &, > .fullscreen {
    height: 100%;
    width: 100%;
    padding: ${(props) => (props.isFullscreen ? '3rem' : 'none')};
    background-color: var(--sev1-primary-4-color);
  }
`;

type Props = {
  enabled: boolean,
  onChange?: (fullscreen: boolean) => void,
  children: React.ReactNode
};

type State = {
  /** Whether to show the cursor and the exit button. */
  showControls: boolean;
};

export default class Fullscreen extends React.Component<Props, State> {
  state = {
    showControls: false
  };

  /**
   * Store the timeoutId from the last time the mouse was moved.
   */
  mouseTimeout: any;

  handleMouseMove = () => {
    if (!this.props.enabled) return;

    if (!this.state.showControls) this.setState({ showControls: true });

    clearTimeout(this.mouseTimeout);
    this.mouseTimeout = setTimeout(() => {
      this.setState({ showControls: false });
    }, 3000);
  };

  handleExit = () => {
    if (this.props.onChange) this.props.onChange(false);
  };

  render() {
    return (
      <FullscreenWrapper
        isFullscreen={this.props.enabled}
        // @ts-ignore
        onMouseMove={this.handleMouseMove}
      >
        <BaseFullscreen enabled={this.props.enabled} onChange={this.props.onChange}>
          {this.props.children}

          <AnimatedContainer isVisible={this.props.enabled && this.state.showControls}>
            <Button
              onClick={this.handleExit}
              data-test-id="exit-button"
            >
              {'Exit Fullscreen'}
            </Button>
          </AnimatedContainer>

        </BaseFullscreen>
      </FullscreenWrapper>
    );
  }
}
