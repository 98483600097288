export type Cancelable<T> = {
  promise: Promise<T>,
  cancel: () => void
};

function cancelable<T>(promise: Promise<T>): Cancelable<T> {
  let forceCancel: (reason: any) => void;
  const dummyPromise: Promise<T> = new Promise((resolve, reject) => {
    forceCancel = reject;
  });

  const race = Promise.race([ dummyPromise, promise ])
    .then((data) => Promise.resolve(data))
    .catch((err) => Promise.reject(err));

  const cancel = () => {
    forceCancel({ canceled: true });
  };

  return {
    promise: race,
    cancel
  };
}

export { cancelable };
