import { generateNotEqualsFilter } from '../../filter-reports';
import { ReportType } from '../../get-reports.query';
import { FilterArgsType } from '../types';

const filters = (props: FilterArgsType = { id: null, userId: 0 }) => {
  return [
    generateNotEqualsFilter('isTemplate', true),
    generateNotEqualsFilter('owner.id', props.userId)
  ];
};

const sharedReports = {
  id: -3,
  parentId: null,
  name: 'Reports Shared with Me',
  reportCount: ({ reports, userId }: {
    reports: Array<ReportType>,
    userId: number | null
  }): number => {
    return reports.filter((report) => {
      return report.owner.id !== userId && !report.isTemplate;
    }).length;
  },
  children: () => [],
  filters
};

export { sharedReports };
