import * as React from 'react';

type SidebarProps = {
  hideSidebar: () => void
};

export type SidebarContextType = {
  sidebar: React.ReactNode,
  showSidebar: (
    title: string,
    component: React.ComponentType<SidebarProps>
  ) => void,
  hideSidebar: () => void
};

const SidebarContext = React.createContext<SidebarContextType>({
  sidebar: null,
  showSidebar: () => {},
  hideSidebar: () => {}
});

export { SidebarContext };
