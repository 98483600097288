import { useEffect } from 'react';
import {
  useNavigation,
  reportLinking
} from '@sevone/insight-connect';
import { facetManager, FacetType } from '@sevone/insight-wdk';
import { createContainer } from '../../utils/create-container';
import { debug } from '../../utils/logger';
import {
  CONTAINER_TYPES,
  TYPE_PRIORITIES,
  DEPENDENCY_LIST,
  registerFacetContainer,
  addFacet
} from '../facet-manager';

const LINK_FIELD = 'variables';

function useReportLinkController() {
  const { location, navigateInPlace } = useNavigation();
  const encodedReportVariables: string = location.params[LINK_FIELD];

  const updateUrl = (facets: Array<FacetType>) => {
    const { [LINK_FIELD]: _, ...otherParams } = location.params;
    const links = facets.length > 0 ?
      reportLinking.encodeReportLink(facets) : '';

    navigateInPlace({
      pathname: location.pathname,
      hash: location.hash,
      params: {
        ...otherParams,
        ...(links ? { [LINK_FIELD]: links } : {})
      },
      state: location.state
    });
  };

  const addLink = (facet: FacetType) => {
    debug('add link', facet);
    const facets = reportLinking.decodeReportLink(encodedReportVariables);

    updateUrl([ ...facets, facet ]);
  };

  const removeLinks = (schemas: Array<FacetType['schema']>) => {
    const facets = reportLinking.decodeReportLink(encodedReportVariables);
    const stack = facetManager.createFacetStack(facets);

    schemas.forEach((schema) => {
      const nullFacet = facetManager.createFacet(schema, null);
      // Adding this null value to a temporary stack for us to immediately
      // use when generating the facet list for the next url
      stack.add(nullFacet);
      // Adding this null value to the facet container permenantly so we remove
      // the previous value corresponding to this schema.
      addFacet(CONTAINER_TYPES.reportLinks, nullFacet);
    });

    updateUrl(stack.list().filter((facet) => facet.data !== null));
  };

  useEffect(() => {
    const { unregister } = registerFacetContainer({
      id: CONTAINER_TYPES.reportLinks,
      priority: TYPE_PRIORITIES[CONTAINER_TYPES.reportLinks],
      dependencies: DEPENDENCY_LIST[CONTAINER_TYPES.reportLinks]
    });

    return unregister;
  }, []);

  useEffect(() => {
    const decoded = reportLinking.decodeReportLink(encodedReportVariables);
    debug('report link', decoded);

    decoded.forEach((facet) => {
      addFacet(CONTAINER_TYPES.reportLinks, facet);
    });
  }, [ encodedReportVariables ]);

  return {
    addLink,
    removeLinks
  };
}

const ReportLinkController = createContainer(useReportLinkController);

export { ReportLinkController };
