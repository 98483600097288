import * as React from 'react';
import { styled } from 'linaria/react';

const Div = styled.div<{ show?: boolean }>`
  position: absolute;
  width: 100%;
  opacity: ${(prop) => (prop.show ? '1' : '0')};
  top: ${(prop) => (prop.show ? '1rem' : '-2rem')};
  transition: all 500ms;
  text-align: center;
  z-index: 100;
`;

type ContainerProps = {
  isVisible: boolean,
  children: React.ReactNode
}

type ContainerState = {
  isMounted: boolean,
  isVisible: boolean,
}

class AnimatedContainer extends React.Component<ContainerProps, ContainerState> {
  state = {
    isMounted: false,
    isVisible: false
  };

  componentWillReceiveProps = (nextProps: ContainerProps) => {
    if (nextProps.isVisible) {
      this.showButton();
    } else {
      this.hideButton();
    }
  }

  showButton = () => {
    this.setState({
      isMounted: true
    }, () => {
      // Wait untill current execution is finished before applying transition effect to button
      global.setTimeout(() => {
        this.setState({ isVisible: true });
      }, 0);
    });
  }

  hideButton = () => {
    this.setState({
      isVisible: false
    });
  }

  handleTransitionEnd = () => {
    if (!this.props.isVisible) {
      this.setState({
        isMounted: false
      });
    }
  }

  render() {
    // Remove component from DOM Tree, so that DIV do not interfere with other component, since its not hidden.
    if (!this.state.isMounted) {
      return null;
    }
    return (
    // @ts-ignore
      <Div show={this.state.isVisible} onTransitionEnd={this.handleTransitionEnd}>
        {this.props.children}
      </Div>
    );
  }
}

export default AnimatedContainer;
