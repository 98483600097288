import * as React from 'react';
import { styled } from 'linaria/react';
import { Select, Checkbox } from '@sevone/scratch';
import { HORIZONTAL_RHYTHM, VERTICAL_RHYTHM } from '../../../utils/spacing';
import { ReportType } from '../get-reports.query';

const USER_ACCESS = {
  OWNER_ONLY: {
    label: 'Private',
    value: 1
  },
  PUBLIC: {
    label: 'Public',
    value: 2
  },
  RESTRICTED: {
    label: 'Restricted',
    value: 3
  }
};

const ControlWrapper = styled.div`
  margin-bottom: ${VERTICAL_RHYTHM}px;
`;

const SubControl = styled(ControlWrapper)`
  margin-left: ${HORIZONTAL_RHYTHM}px;
`;

type Props = {
  report: ReportType,
  users: Array<{ label: string, value: number }>,
  onChange: (update: Partial<ReportType>) => void
}

function UserAccess(props: Props) {
  const { report, users, onChange } = props;
  const [
    isRestricted,
    setIsRestricted
  ] = React.useState(!report.acl.isPublic && report.acl.entries.length > 0);
  const ownerValue = users.find((user) => user.value === report.acl.ownerId);
  const userAccessValue = isRestricted ? USER_ACCESS.RESTRICTED : (
    report.acl.isPublic ? USER_ACCESS.PUBLIC : USER_ACCESS.OWNER_ONLY
  );
  const usersValue = users.filter((user) => report.acl.entries.find((entry) => {
    return entry.sid.id === user.value;
  }));

  const handleOwnerChange = (value: { label: string, value: number }) => {
    onChange({
      acl: {
        ...report.acl,
        ownerId: value.value
      }
    });
  };

  const handleUserAccessChange = (value: { label: string, value: number }) => {
    const nextIsRestricted = value.value === USER_ACCESS.RESTRICTED.value;
    const nextAcl = {
      ...report.acl,
      isPublic: value.value === USER_ACCESS.PUBLIC.value,
      entries: nextIsRestricted ? report.acl.entries : []
    };

    setIsRestricted(nextIsRestricted);
    onChange({ acl: nextAcl });
  };

  const handleUsersChange = (
    values: Array<{ label: string, value: number }>
  ) => {
    onChange({
      acl: {
        ...report.acl,
        entries: values.map((value) => ({
          sid: { id: value.value },
          read: true,
          write: true
        }))
      }
    });
  };

  const handleReadOnlyChange = (checked: boolean) => {
    onChange({
      acl: {
        ...report.acl,
        isReadOnly: checked
      }
    });
  };

  return (
    <div>
      <ControlWrapper>
        <Select
          label={'Owner'}
          value={ownerValue}
          options={users}
          onChange={handleOwnerChange}
        />
      </ControlWrapper>
      <ControlWrapper>
        <Select
          label={'User Access'}
          value={userAccessValue}
          options={Object.values(USER_ACCESS)}
          onChange={handleUserAccessChange}
        />
      </ControlWrapper>
      <SubControl>
        <Select
          isMulti
          maxDisplayed={3}
          label={'Users'}
          disabled={userAccessValue.value !== USER_ACCESS.RESTRICTED.value}
          value={usersValue}
          options={users}
          onChange={handleUsersChange}
        />
      </SubControl>
      <ControlWrapper>
        <Checkbox
          checked={report.acl.isReadOnly}
          onChange={handleReadOnlyChange}
        >
          {'Read Only'}
        </Checkbox>
      </ControlWrapper>
    </div>
  );
}

export { UserAccess };
