const CREATE_REPORT = `
  mutation createReport($input: ReportInput!) {
    createReport(report: $input) {
      id
    }
  }
`;

export type CreateReportResponseType = {
  createReport: {
    id: string
  }
};

export { CREATE_REPORT };
