import * as React from 'react';
import { Select } from '@sevone/scratch';
import { RenderVariableProps } from '../../types';
import {
  FlowDirectionsType,
  ConfigurationType,
  OptionType,
  ValueType
} from './types';

const DIRECTION_MAP = {
  ALL: {
    label: 'All Directions',
    value: 'ALL'
  },
  INCOMING: {
    label: 'Incoming',
    value: 'INCOMING'
  },
  OUTGOING: {
    label: 'Outgoing',
    value: 'OUTGOING'
  }
};

type Props = RenderVariableProps<ConfigurationType, OptionType, ValueType>;

function Variable(props: Props) {
  const { value, onValueChange } = props;
  const selectedDirection = value ?
    DIRECTION_MAP[value.flowDirection.value] : null;

  const handleChange = (
    selection: { label: string, value: FlowDirectionsType }
  ) => {
    const nextValue = selection ? {
      flowDirection: { value: selection.value }
    } as const : null;

    onValueChange(nextValue);
  };

  return (
    <Select
      clearable
      value={selectedDirection}
      options={Object.values(DIRECTION_MAP)}
      onChange={handleChange}
    />
  );
}

export { Variable };
