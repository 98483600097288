/**
 * More or less a copy of unstated-next to fix a typescript error with how it
 * imports react (default vs named).
 * https://github.com/jamiebuilds/unstated-next
 */
import * as React from 'react';

type ContainerProviderProps<State = void> = {
  initialState?: State
  children: React.ReactNode
}

type Container<Value, State = void> = {
  Provider: React.ComponentType<ContainerProviderProps<State>>
  useContainer: () => Value
}

function createContainer<Value, State = void>(
  useHook: (initialState?: State) => Value
): Container<Value, State> {
  const Context = React.createContext<Value | null>(null);

  function Provider(props: ContainerProviderProps<State>) {
    const value = useHook(props.initialState);
    return <Context.Provider value={value}>{props.children}</Context.Provider>;
  }

  function useContainer(): Value {
    const value = React.useContext(Context);
    if (value === null) {
      throw new Error('Component must be wrapped with <Container.Provider>');
    }
    return value;
  }

  return { Provider, useContainer };
}

export { createContainer };
