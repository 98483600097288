import { DatasourceType } from './get-datasource.query';

export const CREATE_DATASOURCE_MUTATION = `
  mutation($datasource: DatasourceArgs!) {
    createDatasource(datasource: $datasource) {
      id
      name
      dstype
      address
    }
  }
`;

export type CreateDatasourceResponseType = {
  createDatasource: DatasourceType
}
