import {
  generateEqualsFilter,
  generateNotEqualsFilter
} from '../../filter-reports';
import { ReportType } from '../../get-reports.query';

const scheduledReports = {
  id: -5,
  parentId: null,
  name: 'Scheduled Reports',
  reportCount: ({ reports }: { reports: Array<ReportType> }) => {
    return reports.filter((report) => !!report.schedule).length;
  },
  children: () => [],
  filters: () => {
    return [
      generateNotEqualsFilter('schedule', null),
      generateEqualsFilter('isTemplate', false)
    ];
  }
};

export { scheduledReports };
