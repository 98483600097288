import { RuleType } from '../rule-builder/types';

const gql = String.raw;

export const GET_REPORT_LINKS_QUERY = gql`
  query reportLinks {
    reportLinks {
      id enabled column name
      reports { id }
      filters { field operator value }
    }
  }
`;

type GqlReportLink = {
  id: number,
  enabled: boolean,
  column: string,
  name: string | null,
  reports: Array<{ id: number }>,
  filters: Array<RuleType>
};

export type GetReportLinksResponseType = {
  reportLinks: Array<GqlReportLink>
};
