import * as React from 'react';
import { styled } from 'linaria/react';
import isEqual from 'lodash-es/isEqual';
import { useModal } from '@sevone/insight-connect';
import { Button, TrashIcon, EditIcon } from '@sevone/scratch';
import { VERTICAL_RHYTHM, HORIZONTAL_RHYTHM } from '../../../../utils/spacing';
import { useReportRuntime } from '../../../../report-runtime';
import { ReportVariableType } from '../../types';
import { RenderVariable, variableTypes } from '../report-variables';

const IconWrapper = styled.div`
  opacity: 0;
  font-size: .8em;
  cursor: pointer;
  padding-left: ${HORIZONTAL_RHYTHM}px;
  transition: all .1s ease-in;

  &:hover {
    color: var(--sev1-primary-5-color);
  }
`;

const Wrapper = styled.div`
  padding: ${VERTICAL_RHYTHM / 2}px ${HORIZONTAL_RHYTHM / 2}px;
  border-radius: 3px;
  transition: all .1s ease-in;
  background: var(--sev1-primary-2-color);
  color: var(--sev1-primary-2-contrast);

  &:hover {
    background: var(--sev1-primary-3-color);
    color: var(--sev1-primary-3-contrast);

    // @ts-ignore: Bug with linaria and its typing
    ${IconWrapper} {
      opacity: 1;
    }
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: .8rem;
  line-height: 1;
  padding-bottom: ${VERTICAL_RHYTHM / 2}px;
`;

const Label = styled.div`
  margin-right: auto;
`;

type Props = {
  isWritable: boolean,
  variable: ReportVariableType,
  onEdit: (id: string) => void
};

function ReportVariable(props: Props) {
  const { isWritable, variable, onEdit } = props;
  const { showModal } = useModal();
  const {
    deleteReportVariable,
    updateReportVariable,
    updateReportVariableValue
  } = useReportRuntime();
  const variableType = variableTypes.find((v) => {
    return isEqual(v.schema, variable.schema);
  });

  if (!variableType) {
    return null;
  }

  const handleConfigChange = (config: Record<string, any>) => {
    updateReportVariable({ ...variable, config });
  };

  const handleValueChange = (value: any) => {
    updateReportVariableValue(variable.id, value);
  };

  const handleDeleteVariable = () => {
    showModal({
      type: 'warning',
      header: 'Delete Variable',
      message: () => (`
        Are you sure you want to delete variable ${variable.label}?
        This cannot be undone.
      `),
      actions: ({ hideModal }) => [
        <Button key="cancel" type="outlined" onClick={hideModal}>
          {'Cancel'}
        </Button>,
        <Button
          key="delete"
          prefixIcon={<TrashIcon />}
          onClick={() => {
            deleteReportVariable(variable.id);
            hideModal();
          }}
        >
          {'Yes, delete my variable'}
        </Button>
      ]
    });
  };

  return (
    <Wrapper>
      <HeaderWrapper>
        <Label>{variable.label}</Label>
        {isWritable &&
          <IconWrapper onClick={() => { onEdit(variable.id); }}>
            <EditIcon />
          </IconWrapper>
        }
        {isWritable &&
          <IconWrapper onClick={handleDeleteVariable}>
            <TrashIcon />
          </IconWrapper>
        }
      </HeaderWrapper>
      <RenderVariable
        // @ts-ignore: Need to find a better way to type generics
        type={variableType}
        variable={variable}
        onConfigChange={handleConfigChange}
        onValueChange={handleValueChange}
      />
    </Wrapper>
  );
}

export { ReportVariable };
