export const UPADATE_TENANT_DATASOURCE_FILTER_MUTATION = `
  mutation(
    $attributeName: String!
    $attributeValue: String!
    $namespaceName: String!
    $id: Int!
  ) {
    updateTenantDatasourceFilter(
      attributeName: $attributeName
      attributeValue: $attributeValue
      namespaceName: $namespaceName
      id: $id
    ) {
      id
    }
  }
`;


export type UpdateTenantDatasourceFilterResponseType = {
  updateTenantDatasourceFilter: { id: number }
}
