import * as React from 'react';
import { styled } from 'linaria/react';
import { Badge, Input, SearchIcon } from '@sevone/scratch';
import { VERTICAL_RHYTHM, HORIZONTAL_RHYTHM } from '../../../../utils/spacing';
import { useWidgetListFetcher } from '../../../../hooks/wdk-server';
import { WidgetType } from '../../types';
import { WidgetOption } from './widget-option';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const InputWrapper = styled.div`
  padding: 0 ${HORIZONTAL_RHYTHM}px ${VERTICAL_RHYTHM}px ${HORIZONTAL_RHYTHM}px;
`;

const Categories = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: ${HORIZONTAL_RHYTHM}px;
`;

const Category = styled.div`
  cursor: pointer;
  margin-right: ${VERTICAL_RHYTHM}px;
  margin-bottom: ${HORIZONTAL_RHYTHM}px;
`;

const WidgetList = styled.div`
  flex: 1;
  overflow: auto;
  padding: ${VERTICAL_RHYTHM}px ${HORIZONTAL_RHYTHM}px;
`;

type Props = {
  onDragWidget: (widget: WidgetType) => void,
  onAddWidget: (widget: WidgetType) => void
};

function WidgetPalette(props: Props) {
  const { onDragWidget, onAddWidget } = props;
  const { widgetList } = useWidgetListFetcher();
  const [ searchTerm, setSearchTerm ] = React.useState('');
  const [ selectedCategory, setSelectedCategory ] = React.useState<string | null>(null);
  const categories = [
    {
      label: 'All',
      value: null
    },
    ...[ ...new Set(widgetList.map((meta) => meta.tags || [])
      .reduce((acc, curr) => {
        return acc.concat(curr);
      }, [])) ].map((category) => ({ label: category, value: category }))
  ];
  const filteredList = widgetList.filter((meta) => {
    return (
      meta.title.toLowerCase().includes(searchTerm.toLowerCase()) &&
      (selectedCategory === null || (meta.tags || []).includes(selectedCategory))
    );
  });

  return (
    <Wrapper>
      <InputWrapper>
        <Input
          placeholder={'Search'}
          prefixIcon={<SearchIcon />}
          value={searchTerm}
          onChange={setSearchTerm}
        />
      </InputWrapper>
      <Categories>
        {categories.map((category) => (
          <Category
            key={category.label}
            onClick={() => setSelectedCategory(category.value)}
          >
            <Badge
              label={category.label}
              type={category.value === selectedCategory ? 'info' : 'default'}
            />
          </Category>
        ))}
      </Categories>
      <WidgetList>
        {filteredList.map((meta) => (
          <WidgetOption
            key={meta.name}
            meta={meta}
            onDrag={onDragWidget}
            onSelect={onAddWidget}
          />
        ))}
      </WidgetList>
    </Wrapper>
  );
}

export { WidgetPalette };
