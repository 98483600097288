import * as React from 'react';
import { styled } from 'linaria/react';
import {
  Card,
  Input,
  Checkbox,
  Select,
  LoadingCircle
} from '@sevone/scratch';
import clamp from 'lodash-es/clamp';
import { HORIZONTAL_RHYTHM, VERTICAL_RHYTHM } from '../../../utils/spacing';

const CardContainer = styled.div`
  margin-right: ${HORIZONTAL_RHYTHM}px
  height: 100%;
`;
const ContentContainer = styled.div`
  & > * {
    padding: 0 ${HORIZONTAL_RHYTHM * 2}px ${VERTICAL_RHYTHM / 2}px ${HORIZONTAL_RHYTHM * 2}px;
  }
`;
const LoadingContainer = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

type Props = {
  server: string,
  setServer: (value: string) => void,
  port: string | null,
  setPort: (value: string) => void,
  username: string,
  setUsername: (value: string) => void,
  password: string,
  setPassword: (value: string) => void,
  connectionSecurity: string,
  setConnectionSecurity: (value: string) => void,
  senderMail: string,
  setSenderMail: (value: string) => void,
  senderName: string,
  setSenderName: (value: string) => void,
  subject: string,
  setSubject: (value: string) => void,
  enableAuthentication: boolean,
  setEnableAuthentication: (value: boolean) => void,
  isLoading: boolean
}

type OptionType = {
  value: string,
  label: string
}

const CONNECTION_SECURITY_OPTIONS = [
  { value: 'NONE', label: 'None' },
  { value: 'SSL_TLS', label: 'SSL/TLS' },
  { value: 'STARTTLS', label: 'STARTTLS' }
];
const DEFAULT_PORT = '25';

function Email(props: Props) {
  const { server, setServer, port, setPort, username, setUsername, password,
    setPassword, connectionSecurity, setConnectionSecurity, senderMail, setSenderMail,
    senderName, setSenderName, subject, setSubject, enableAuthentication,
    setEnableAuthentication, isLoading } = props;

  const generateConnectionSecurityValue = () => {
    return CONNECTION_SECURITY_OPTIONS.find((opt) => opt.value === connectionSecurity);
  };

  const handleConnectionSecurityChange = (opt: OptionType) => {
    setConnectionSecurity(opt.value);
  };

  const handlePortBlur = () => {
    const clampedVal = clamp(Number(port), 1, 65535);
    setPort(clampedVal.toString());
  };

  return (
    <CardContainer>
      <Card title={'Email'}>
        {!isLoading ?
          <ContentContainer>
            <Input
              label={'Server'}
              value={server}
              onChange={setServer}
            />
            <Input
              type={'number'}
              label={'Port'}
              value={port === null ? DEFAULT_PORT : port}
              onChange={setPort}
              onBlur={handlePortBlur}
            />
            <Checkbox checked={enableAuthentication} onChange={setEnableAuthentication}>
              {'Enable Authentication'}
            </Checkbox>
            <Input
              label={'Username'}
              value={username}
              onChange={setUsername}
              disabled={!enableAuthentication}
            />
            <Input
              label={'Password'}
              value={password}
              onChange={setPassword}
              disabled={!enableAuthentication}
              type={'password'}
            />
            <Select
              label={'Connection Security'}
              value={generateConnectionSecurityValue()}
              options={CONNECTION_SECURITY_OPTIONS}
              onChange={handleConnectionSecurityChange}
            />
            <Input
              label={'Sender Email Address'}
              value={senderMail}
              onChange={setSenderMail}
            />
            <Input
              label={'Sender Name'}
              value={senderName}
              onChange={setSenderName}
            />
            <Input
              label={'Subject'}
              value={subject}
              onChange={setSubject}
            />
          </ContentContainer> :
          <LoadingContainer><LoadingCircle size={'large'} /></LoadingContainer>
        }
      </Card>
    </CardContainer>
  );
}

export { Email };
