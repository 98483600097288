import * as React from 'react';
import { styled } from 'linaria/react';
import {
  Card,
  Table,
  LoadingCircle,
  Input,
  Button
} from '@sevone/scratch';
import { useNavigation } from '@sevone/insight-connect';
import { TenantType } from '../../queries/get-datasources-with-tenant.query';
import { HORIZONTAL_RHYTHM } from '../../../../utils/spacing';
import { useDebounce } from '../../../../hooks/use-debounce';

const CardWrapper = styled.div`
  border: none;
  border-radius: 4px;
  flex: 1;
  margin: ${HORIZONTAL_RHYTHM / 2}px 0;
`;
const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  overflow: hidden;
`;
const TableWrapper = styled.div`
  flex: 1;
  overflow: auto;
`;
const LoadingWrapper = styled.div`
  align-self: center;
`;
const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  & > * {
    flex: 1;
  }
`;

type Props = {
  isLoading: boolean,
  tenants: Array<TenantType>
}

const PAGE_SIZE = 50;
const COLUMNS = [
  {
    id: 'name',
    title: 'Name',
    render: (data: TenantType) => data.name
  }
];

function Tenants(props: Props) {
  const { isLoading, tenants } = props;
  const [ page, setPage ] = React.useState(1);
  const [ search, setSearch ] = React.useState('');
  const [ filteredRows, setFilteredRows ] = React.useState<Array<TenantType>>([]);
  const debouncedSearchTerm = useDebounce(search, 200);
  const navigation = useNavigation();

  React.useEffect(() => {
    setFilteredRows(tenants.filter((row) => row.name.toLowerCase().includes(debouncedSearchTerm.toLowerCase())));
  }, [ debouncedSearchTerm, tenants ]);

  const handleSearch = (value: string) => {
    setSearch(value);
    setPage(1);
  };

  const renderHeader = () => {
    return (
      <HeaderWrapper>
        <div>
          {'Associated Tenants'}
        </div>
        <div>
          <Input
            value={search}
            onChange={handleSearch}
            placeholder={'Search tenants...'}
          />
        </div>
      </HeaderWrapper>
    );
  };

  return (
    <CardWrapper>
      <Card
        title={renderHeader()}
        actions={[
          <Button
            key={'Associate tenants'}
            onClick={() => navigation.navigateTo('/admin/tenants')}
          >
            {'Associate tenants'}
          </Button>
        ]}
      >
        <ContentWrapper>
          {!isLoading ?
            <TableWrapper>
              <Table
                rows={filteredRows}
                columns={COLUMNS}
                page={page}
                onPageChange={setPage}
                pageSize={PAGE_SIZE}
              />
            </TableWrapper> : <LoadingWrapper><LoadingCircle size={'large'} /></LoadingWrapper>
          }
        </ContentWrapper>
      </Card>
    </CardWrapper>
  );
}

export { Tenants };
