import * as React from 'react';
import { styled } from 'linaria/react';
import {
  AddIcon,
  BarChartIcon,
  CogsIcon,
  UsersIcon
} from '@sevone/scratch';
import { RequiredPermissionsType } from '../components/permission-gate';
import { ReportManager } from '../pages/report-manager';
import { Report } from '../pages/report';
import { UserManager } from '../pages/user-manager';
import { DatasourceManager } from '../pages/datasource-manager';
import { TenantManager } from '../pages/tenant-manager';
import { DeliveryManager } from '../pages/delivery-manager';
import { ThemeManager } from '../pages/theme-manager';
import { ReportLinkingManager } from '../pages/report-linking-manager';

// Icons are wrapped because they each need to be 24px wide
const IconWrapper = styled.div`
  font-size: 1.2em;
`;

// Items that generate routing logic
type RouteType = {
  pages: Array<{
    match: string,
    page: React.ComponentType,
    permissions: RequiredPermissionsType
  }>,
  permissions: RequiredPermissionsType,
};

// Items that can navigate to a route
type LinkType = {
  permissions: RequiredPermissionsType,
  goTo: () => string
};

export type SecondaryLevelType = {
  id: string,
  title: string
} & LinkType & (RouteType | { match?: undefined, page?: undefined });

export type TopLevelType = {
  id: string,
  title: string,
  icon: React.ReactNode
} & ({
  children: Array<SecondaryLevelType>
} | LinkType) & (RouteType | { match?: undefined, page?: undefined });

type FlatType = {
  id: string,
  title: string,
  pages?: Array<{
    match: string,
    page: React.ComponentType,
    permissions: RequiredPermissionsType
  }>,
  icon?: React.ReactNode,
  parentId: string | null,
  permissions?: RequiredPermissionsType,
  goTo?: () => string
}

function flattenTree(tree: Array<TopLevelType>): Array<FlatType> {
  return tree.map((node) => {
    if (!('children' in node)) {
      return [ node ];
    }

    const { children, ...rest } = node;
    return [ { ...rest, parentId: null }, ...children.map((child) => ({
      ...child,
      parentId: node.id
    })) ];
  }).reduce((accum, curr) => {
    // @ts-ignore: TS being unable to handle union array types again.
    return accum.concat(curr);
  }, []);
}

const navigation: Array<TopLevelType> = [
  {
    id: 'report',
    title: 'Create',
    icon: <IconWrapper><AddIcon fixedWidth /></IconWrapper>,
    permissions: [ 'createReport' ],
    goTo: () => '/reports/new-report'
  },
  {
    id: 'reports',
    title: 'Reports',
    icon: <IconWrapper><BarChartIcon fixedWidth /></IconWrapper>,
    permissions: [ 'viewReportManager' ],
    goTo: () => '/reports',
    pages: [
      {
        match: '/reports',
        page: ReportManager,
        permissions: [ 'viewReportManager' ]
      },
      {
        match: '/reports/:id/:section?/:widget?',
        page: Report,
        permissions: [ [
          'viewPersonalReports',
          'viewTenantReports',
          'createReport'
        ] ]
      }
    ]
  },
  {
    id: 'config',
    title: 'Configure',
    icon: <IconWrapper><CogsIcon fixedWidth /></IconWrapper>,
    children: [
      {
        id: 'themes',
        title: 'Themes',
        permissions: [ 'manageTenantThemes' ],
        goTo: () => '/config/themes',
        pages: [
          {
            match: '/config/themes',
            page: ThemeManager,
            permissions: [ 'manageTenantThemes' ]
          }
        ]
      },
      {
        id: 'delivery',
        title: 'Delivery',
        permissions: [ 'manageFTPSettings', 'manageEmailSettings' ],
        goTo: () => '/config/delivery',
        pages: [
          {
            match: '/config/delivery',
            page: DeliveryManager,
            permissions: [ 'manageFTPSettings', 'manageEmailSettings' ]
          }
        ]
      },
      {
        id: 'datasources',
        title: 'Data Sources',
        permissions: [ 'viewAllDatasources', 'modifyAllDatasources' ],
        goTo: () => '/config/datasources',
        pages: [
          {
            match: '/config/datasources',
            page: DatasourceManager,
            permissions: [ 'viewAllDatasources', 'modifyAllDatasources' ]
          }
        ]
      },
      {
        id: 'report-linking',
        title: 'Report Linking',
        permissions: [ 'manageReportLinks' ],
        goTo: () => '/config/report-linking',
        pages: [
          {
            match: '/config/report-linking',
            page: ReportLinkingManager,
            permissions: [ 'manageReportLinks' ]
          }
        ]
      }
    ]
  },
  {
    id: 'admin',
    title: 'Administer',
    icon: <IconWrapper><UsersIcon fixedWidth /></IconWrapper>,
    children: [
      {
        id: 'roles',
        title: 'User Roles',
        permissions: [ 'viewTenantUsers', 'modifyTenantUsers', 'assignRoles' ],
        goTo: () => '/admin/roles',
        pages: [
          {
            match: '/admin/roles',
            page: UserManager,
            permissions: [
              'viewTenantUsers',
              'modifyTenantUsers',
              'assignRoles'
            ]
          }
        ]
      },
      {
        id: 'tenants',
        title: 'Tenants',
        permissions: [ 'updateOwnTenant' ],
        goTo: () => '/admin/tenants',
        pages: [
          {
            match: '/admin/tenants',
            page: TenantManager,
            permissions: [ 'updateOwnTenant' ]
          }
        ]
      }
    ]
  }
];

const flatNavigation = flattenTree(navigation);

export { navigation, flatNavigation };
