import * as React from 'react';
import { format, utcToZonedTime } from 'date-fns-tz';
import { css } from 'linaria';
import { facetManager } from '@sevone/insight-wdk';
import { timespanSchema } from '@sevone/insight-connect';
import {
  PopoutTarget,
  Button,
  ClockIcon
} from '@sevone/scratch';
import { useGql } from '../../../../../hooks/use-gql';
import { facetSystem, useReportRuntime } from '../../../../../report-runtime';
import { TimespanType } from '../../../types';
import { TimespanPicker } from './timespan-picker';
import {
  GET_RELATIVE_TIMESPANS,
  GetRelativeTimespansResponseType
} from './get-relative-timespans.query';
import {
  GET_TIMEZONES,
  GqlTimezoneType,
  GetTimezonesResponseType
} from './get-timezones.query';

const triggerStyles = css`
  display: flex;
  align-items: center;
`;

const popoutStyles = css`
  margin-top: 3px;
  z-index: 4000;
`;

function ReportTimespan() {
  const { getStack, updateTimespan } = useReportRuntime();
  const {
    runGql: fetchRelativeTimespans
  } = useGql<GetRelativeTimespansResponseType>(GET_RELATIVE_TIMESPANS);
  const {
    runGql: fetchTimezones
  } = useGql<GetTimezonesResponseType>(GET_TIMEZONES);
  const [ menuVisible, setMenuVisible ] = React.useState(false);
  const [
    relativeTimespans,
    setRelativeTimespans
  ] = React.useState<Array<{ label: string, timespan: string }>>([]);
  const [
    timezones,
    setTimezones
  ] = React.useState<Array<GqlTimezoneType>>([]);
  const stack = getStack(facetSystem.CONTAINER_TYPES.reportTimespan);
  const timespanFacet = stack ? stack.list().find((facet) => {
    return facetManager.createFacet(timespanSchema, facet.data);
  }) || null : null;
  const timespan = timespanFacet?.data || null;

  const generateReadableTimespan = (ts: TimespanType | null) => {
    if (!ts) {
      return null;
    }

    if ('timespan' in ts) {
      const found = relativeTimespans.find((r) => r.timespan === ts.timespan);
      return found?.label || null;
    }

    if ('startTime' in ts) {
      const startDate = new Date(ts.startTime);
      const endDate = new Date(ts.endTime);
      const startTime = ts.timezone ?
        utcToZonedTime(startDate, ts.timezone) : startDate;
      const endTime = ts.timezone ?
        utcToZonedTime(endDate, ts.timezone) : endDate;
      const start = format(startTime, 'MMM.dd HH:mm', {
        timeZone: ts.timezone
      });
      const end = format(endTime, 'MMM.dd HH:mm (\'UTC\' x)', {
        timeZone: ts.timezone
      });

      return `${start} - ${end}`;
    }

    return null;
  };

  const handleChange = (value: TimespanType | null) => {
    // Only necessary until we update the timespan facet's schema to remove
    // the 'label' field.
    const modified = value && 'timespan' in value ? {
      ...value,
      label: ''
    } : value;
    setMenuVisible(false);
    updateTimespan(modified);
  };

  React.useEffect(() => {
    fetchRelativeTimespans().then((res) => {
      setRelativeTimespans(res.timespans.options);
    });

    fetchTimezones().then((res) => {
      setTimezones(res.timezones);
    });
  }, []);

  return (
    <PopoutTarget
      position={[ 'bottom', 'right' ]}
      trigger={[]}
      visible={menuVisible}
      className={triggerStyles}
      popoutClassName={popoutStyles}
      popout={(p) => (
        <div {...p}>
          <TimespanPicker
            timespan={timespan}
            relativeTimespans={relativeTimespans}
            timezones={timezones}
            onChange={handleChange}
            onHide={() => { setMenuVisible(false); }}
          />
        </div>
      )}
      onVisibilityChange={() => {}}
    >
      <Button
        type="outlined"
        suffixIcon={<ClockIcon />}
        onClick={() => { setMenuVisible(!menuVisible); }}
      >
        {generateReadableTimespan(timespan) || 'Select a timespan'}
      </Button>
    </PopoutTarget>
  );
}

export { ReportTimespan };
