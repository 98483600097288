import { RoleType, PermissionType } from './roles-query';

export const USERS_QUERY = `
  query ($id: Int) {
    users(id: $id) {
      id
      username
      roles {
        id
        name
        permissions {
          id
          name
        }
      }
      permissions {
        id
        name
      }
    }
  }
`;

export const INITIAL_USERS_QUERY = `
  query {
    users {
      id
      username
    }
  }
`;

export type UserType = {
  id: string,
  username: string,
  roles: Array<RoleType>,
  permissions: Array<PermissionType>
};

export type InitialUserType = {
  id: string,
  username: string
}

export type UsersResponseType = {
  data: {
    data: {
      users: Array<UserType>
    }
    errors?: Array<{
      message: string
    }>
  }
};

export type InitialUsersResponseType = {
  data: {
    data: {
      users: Array<InitialUserType>
    }
    errors?: Array<{
      message: string
    }>
  }
}
