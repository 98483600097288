import * as React from 'react';
import { styled } from 'linaria/react';
import { Input, SearchIcon } from '@sevone/scratch';
import { useDebounce } from '../../../../hooks/use-debounce';
import { generateMatchesFilter, FilterType } from '../../filter-reports';

const Wrapper = styled.div`
  width: 250px;
`;

type Props = {
  onChange: (filters: Array<FilterType>) => void
};

function Search(props: Props) {
  const { onChange } = props;
  const [ searchTerm, setSearchTerm ] = React.useState('');
  const debouncedTerm = useDebounce(searchTerm, 100);

  React.useEffect(() => {
    onChange([
      generateMatchesFilter('name', searchTerm)
    ]);
  }, [ debouncedTerm ]);

  return (
    <Wrapper>
      <Input
        autofocus
        placeholder={'Search for reports...'}
        prefixIcon={<SearchIcon />}
        value={searchTerm}
        onChange={setSearchTerm}
      />
    </Wrapper>
  );
}

export { Search };
