import { useReducer, useCallback, useEffect } from 'react';
import castArray from 'lodash-es/castArray';
import isEqual from 'lodash-es/isEqual';

type State<Resource> = {
  isSingleOption: boolean | null,
  options: Array<Resource> | null,
  value: Resource | Array<Resource> | null
}

type Actions<Resource> =
  { type: 'reset' } |
  { type: 'determine', payload: {
    options: Array<Resource>,
    value: Resource | Array<Resource> | null
  } }

function reducer<Resource>(
  state: State<Resource>,
  action: Actions<Resource>
): State<Resource> {
  switch (action.type) {
    case 'determine': {
      if (state.isSingleOption !== null) {
        return state;
      }

      if (action.payload.options.length === 1) {
        return {
          isSingleOption: true,
          options: action.payload.options,
          value: action.payload.value
        };
      }

      return {
        isSingleOption: false,
        options: action.payload.options,
        value: action.payload.value
      };
    }
    case 'reset': {
      return {
        isSingleOption: null,
        options: null,
        value: null
      };
    }
    default:
      return state;
  }
}

function useSingleSelection<ResourceType>(
  value: ResourceType | Array<ResourceType> | null = null,
  onChange: (selection: Array<ResourceType>) => void,
  deps: Array<any>
) {
  const [ state, dispatch ] = useReducer(reducer, {
    isSingleOption: null,
    options: null,
    value: null
  });

  const handleOptionsChange = useCallback((options: Array<ResourceType>) => {
    dispatch({ type: 'determine', payload: { options, value } });
  }, [ onChange ]);

  useEffect(() => {
    dispatch({ type: 'reset' });
  }, deps);

  useEffect(() => {
    if (value || !state.options || state.options.length !== 1) {
      return;
    }

    onChange(state.options as Array<ResourceType>);
  }, [ value, state.options ]);

  return {
    isSingleOption: !!state.isSingleOption && isEqual(
      castArray(state.options),
      castArray(value)
    ),
    options: state.options as Array<ResourceType>,
    handleOptionsChange
  };
}

export { useSingleSelection };
