import * as React from 'react';
import { styled } from 'linaria/react';
import {
  Table,
  Button,
  Input,
  NewEntityIcon,
  TrashIcon,
  EyeIcon,
  SaveIcon
} from '@sevone/scratch';
import { useModal, useNotification } from '@sevone/insight-connect';
import { COLUMNS } from './columns';
import {
  DATASOURCE_QUERY,
  DatasourceType,
  DatasourceResponseType
} from './queries/get-datasource.query';
import {
  DATASOURCE_WITH_TENANTS_QUERY,
  TenantType,
  DatasourceWithTenantsResponse
} from './queries/get-datasources-with-tenant.query';
import {
  CREATE_DATASOURCE_MUTATION,
  CreateDatasourceResponseType
} from './queries/create-datasource.mutation';
import {
  UPDATE_DATASOURCE_MUTATION,
  UpdateDatasourceResponseType
} from './queries/update-datasource.mutation';
import {
  DELETE_DATASOURCE_MUTATION,
  DeleteDatasourceResponseType
} from './queries/delete-datasource.query';
import { Editor } from './editor';
import {
  Page,
  PageHeader,
  PageTitle,
  PageActions,
  PageSection
} from '../../components/page';
import { HORIZONTAL_RHYTHM, VERTICAL_RHYTHM } from '../../utils/spacing';
import { useDebounce } from '../../hooks/use-debounce';
import { useGql } from '../../hooks/use-gql';
import { ApiManagementModal } from './api-management-modal';

const PageContainer = styled(Page)`
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;
const PageHeaderContainer = styled(PageHeader)`
  display: flex;
  justify-content: space-between;
`;
const PageBody = styled.div`
  display: flex;
  justify-content: center;
  flex: 1;
  overflow: hidden;
`;
const TableSection = styled(PageSection)`
  display: flex;
  flex-direction: column;
  width: 275px;
  align-items: center;
`;
const TableHeaderContainer = styled.div`
  width: 100%;
`;
const TableSearchWrapper = styled.div`
  margin: ${VERTICAL_RHYTHM / 2}px ${HORIZONTAL_RHYTHM}px;
`;
const TableWrapper = styled.div`
  height: 100%;
  width: 100%;
  overflow: auto;
`;

const PAGE_SIZE = 50;
const INIT_DATASOURCE_CONFIG = {
  id: -1,
  name: '',
  dstype: '',
  address: ''
};

function DatasourceManager() {
  const { showModal } = useModal();
  const { showNotification } = useNotification();
  const {
    isFetching: isFetchingDatasources,
    runGql: requestDatasources
  } = useGql<DatasourceResponseType>(DATASOURCE_QUERY);
  const {
    isFetching: isFetchingTenants,
    runGql: requestTenants
  } = useGql<DatasourceWithTenantsResponse>(DATASOURCE_WITH_TENANTS_QUERY);
  const {
    runGql: requestUpdateDatasource
  } = useGql<UpdateDatasourceResponseType>(UPDATE_DATASOURCE_MUTATION);
  const {
    runGql: requestCreateDatasource
  } = useGql<CreateDatasourceResponseType>(CREATE_DATASOURCE_MUTATION);
  const {
    runGql: requestDeleteDatasources
  } = useGql<DeleteDatasourceResponseType>(DELETE_DATASOURCE_MUTATION);
  const [ selectedDatasources, setSelectedDatasources ] = React.useState<Array<number | string>>([]);
  const [ datasources, setDatasources ] = React.useState<Array<DatasourceType>>([]);
  const [ filteredRows, setFilteredRows ] = React.useState<Array<DatasourceType>>([]);
  const [ datasourceSearch, setDatasourceSearch ] = React.useState('');
  const debouncedSearchTerm = useDebounce(datasourceSearch, 200);
  const [ page, setPage ] = React.useState(1);
  // datasource config state
  const [ name, setName ] = React.useState('');
  const [ dsType, setDsType ] = React.useState('');
  const [ address, setAddress ] = React.useState('');
  const [ apiKey, setApiKey ] = React.useState('');
  const [ tenants, setTenants ] = React.useState<Array<TenantType>>([]);
  const [ hasChanged, setHasChanged ] = React.useState(false);

  const handleError = (error: string) => {
    showNotification({
      type: 'error',
      message: error,
      lifespan: null
    });
  };

  const fetchDatasources = () => {
    return requestDatasources().then((res) => {
      return res.datasources;
    }).catch((e) => {
      handleError(e.message);
      return [];
    });
  };

  const fetchTenants = (datasourceId: number) => {
    const vars = { ids: [ datasourceId ] };
    return requestTenants(vars).then((res) => {
      return res.datasources[0].tenants;
    }).catch((e) => {
      handleError(e.message);
      return [];
    });
  };

  const initDatasourceConfigState = (datasource: DatasourceType) => {
    setName(datasource.name);
    setDsType(datasource.dstype);
    setAddress(datasource.address);
    setApiKey('');
    setTenants([]);
  };

  React.useEffect(() => {
    if (selectedDatasources.length === 1) {
      const datasourceId = Number(selectedDatasources[0]);
      const selectedDatasource = datasources.find((ds) => ds.id === datasourceId);
      if (selectedDatasource) {
        initDatasourceConfigState(selectedDatasource);
        fetchTenants(datasourceId).then(setTenants);
      }
    }
    if (selectedDatasources.length === 0) {
      initDatasourceConfigState(INIT_DATASOURCE_CONFIG);
    }
    setHasChanged(false);
  }, [ selectedDatasources ]);


  React.useEffect(() => {
    fetchDatasources().then((newDatasources) => {
      setDatasources(newDatasources);
      if (newDatasources.length > 0) {
        setSelectedDatasources([ newDatasources[0].id ]);
      }
    });
  }, []);

  React.useEffect(() => {
    setFilteredRows(datasources.filter((row) => row.name.toLowerCase().includes(debouncedSearchTerm.toLowerCase())));
  }, [ debouncedSearchTerm, datasources ]);

  const handleDatasourceSearch = (value: string) => {
    setDatasourceSearch(value);
    setPage(1);
  };

  const handleCreate = () => {
    initDatasourceConfigState(INIT_DATASOURCE_CONFIG);
    setSelectedDatasources([]);
  };

  const handleConfirmCreate = () => {
    if (hasChanged) {
      showModal({
        type: 'confirmation',
        header: 'Unsaved Changes',
        message: () => 'You have unsaved changes. Are you sure you want to continue?',
        actions: (modalProps: { hideModal: () => void }) => [
          (<Button key={'cancel'} onClick={() => modalProps.hideModal()}>{'Cancel'}</Button>),
          (
            <Button
              key={'OK'}
              onClick={() => {
                handleCreate();
                modalProps.hideModal();
              }}
            >
              {'OK'}
            </Button>
          )
        ]
      });
    } else {
      handleCreate();
    }
  };

  const handleConfirmSelectDatasources = (ids: Array<number | string>) => {
    if (hasChanged) {
      showModal({
        type: 'confirmation',
        header: 'Unsaved Changes',
        message: () => 'You have unsaved changes. Are you sure you want to continue?',
        actions: (modalProps: { hideModal: () => void }) => [
          (<Button key={'cancel'} onClick={() => modalProps.hideModal()}>{'Cancel'}</Button>),
          (
            <Button
              key={'OK'}
              onClick={() => {
                setSelectedDatasources(ids);
                modalProps.hideModal();
              }}
            >
              {'OK'}
            </Button>
          )
        ]
      });
    } else {
      setSelectedDatasources(ids);
    }
  };

  const handleSave = () => {
    if (selectedDatasources[0]) {
      const vars = {
        id: selectedDatasources[0],
        datasource: {
          name,
          dstype: dsType,
          address
        }
      };
      return requestUpdateDatasource(vars).then((res) => {
        const updatedDatasource = res.updateDatasource;
        const newDatasources = datasources.map((ds) => {
          if (ds.id === updatedDatasource.id) {
            return updatedDatasource;
          }

          return ds;
        });
        setDatasources(newDatasources);
        setHasChanged(false);
        return Promise.resolve();
      }).catch((e) => {
        handleError(e.message);
        return Promise.reject();
      });
    }
    return Promise.resolve();
  };

  const handleSaveAs = () => {
    const vars = {
      datasource: {
        name,
        dstype: dsType,
        address,
        apiKey
      }
    };

    return requestCreateDatasource(vars).then((res) => {
      const newDatasource = res.createDatasource;
      const newDatasources = [ ...datasources, newDatasource ];
      setDatasources(newDatasources);
      setSelectedDatasources([ newDatasource.id ]);
      setHasChanged(false);
      return Promise.resolve();
    }).catch((e) => {
      handleError(e.message);
      return Promise.reject();
    });
  };

  const handleDelete = () => {
    const vars = {
      ids: selectedDatasources
    };

    return requestDeleteDatasources(vars).then((res) => {
      const deletedIds = new Set(res.deleteDatasource);
      const remainingDatasources = datasources.filter((ds) => !deletedIds.has(ds.id));
      setDatasources(remainingDatasources);
      setSelectedDatasources([]);
      return Promise.resolve();
    }).catch((e) => {
      handleError(e.message);
      return Promise.reject();
    });
  };

  const handleConfirmDelete = () => {
    showModal({
      type: 'confirmation',
      header: 'Delete Data Source(s)',
      message: () => 'Are you sure you want to delete the selected data sources? This cannot be undone.',
      actions: (modalProps: { hideModal: () => void }) => [
        (<Button key={'cancel'} onClick={() => modalProps.hideModal()}>{'Cancel'}</Button>),
        (
          <Button
            key={'OK'}
            onClick={() => handleDelete().then(() => modalProps.hideModal())}
          >
            {'OK'}
          </Button>
        )
      ]
    });
  };

  const handleShowAll = () => {
    showModal(ApiManagementModal);
  };

  const isFormValid = () => {
    if (selectedDatasources.length > 0) {
      return name && dsType && address;
    }
    return name && dsType && address && apiKey;
  };

  const renderSaveButton = () => {
    if (selectedDatasources.length === 0) {
      return (
        <Button
          onClick={handleSaveAs}
          disabled={!isFormValid}
          prefixIcon={<SaveIcon />}
          type={'outlined'}
        >
          {'Save as'}
        </Button>
      );
    }

    return (
      <Button
        onClick={handleSave}
        disabled={!isFormValid}
        prefixIcon={<SaveIcon />}
        type={'outlined'}
      >
        {'Save'}
      </Button>
    );
  };

  return (
    <PageContainer title={'Data Sources'}>
      <PageHeaderContainer>
        <PageTitle>{'Configure Data Sources'}</PageTitle>
        <PageActions>
          <Button
            onClick={handleConfirmCreate}
            disabled={selectedDatasources.length < 1}
            prefixIcon={<NewEntityIcon />}
            type={'outlined'}
          >
            {'Create Data Source'}
          </Button>
          <Button
            onClick={handleConfirmDelete}
            disabled={selectedDatasources.length === 0}
            prefixIcon={<TrashIcon />}
            type={'outlined'}
          >
            {'Delete'}
          </Button>
          {renderSaveButton()}
          <Button
            type="outlined"
            prefixIcon={<EyeIcon />}
            onClick={handleShowAll}
          >
            {'Show all'}
          </Button>
        </PageActions>
      </PageHeaderContainer>
      <PageBody>
        <TableSection>
          <TableHeaderContainer>
            <TableSearchWrapper>
              <Input
                placeholder={'Search for a datasource...'}
                onChange={handleDatasourceSearch}
                value={datasourceSearch}
              />
            </TableSearchWrapper>
          </TableHeaderContainer>
          <TableWrapper>
            <Table
              rows={filteredRows}
              columns={COLUMNS}
              selectedRows={selectedDatasources}
              onRowSelection={handleConfirmSelectDatasources}
              page={page}
              onPageChange={setPage}
              pageSize={PAGE_SIZE}
            />
          </TableWrapper>
        </TableSection>
        <Editor
          isLoading={isFetchingDatasources}
          isCreating={selectedDatasources.length === 0}
          name={name}
          setName={setName}
          dsType={dsType}
          setDsType={setDsType}
          address={address}
          setAddress={setAddress}
          apiKey={apiKey}
          setApiKey={setApiKey}
          isFetchingTenants={isFetchingTenants}
          tenants={tenants}
          setHasChanged={setHasChanged}
        />
      </PageBody>
    </PageContainer>
  );
}

export { DatasourceManager };
