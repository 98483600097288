const DEVICE = {
  allowedValue: true
};

const DEVICE_GROUP = {
  next: { DEVICE }
};

const resourcePaths = {
  DEVICE_GROUP,
  DEVICE
};

export { resourcePaths };
