import * as React from 'react';
import { styled } from 'linaria/react';
import { Tabs, Tab } from '@sevone/scratch';
import { Datasources } from './datasources';
import { Themes } from './themes';
import { ExtendedDatasourceType } from '../queries/get-tenant-datasource.query';
import { ThemeType } from '../queries/get-themes.query';
import { PageSection } from '../../../components/page';
import { VERTICAL_RHYTHM } from '../../../utils/spacing';

const Section = styled(PageSection)`
  height: 100%;
  margin: ${VERTICAL_RHYTHM / 2}px 0;
`;
const TabsContainer = styled.div`
  height: 100%;
  margin: ${VERTICAL_RHYTHM / 2}px 0;
`;

type Props = {
  tenantId?: number,
  availableDatasources: Array<ExtendedDatasourceType>,
  availableThemes: Array<ThemeType>,
  datasources: Array<ExtendedDatasourceType>,
  themes: Array<ThemeType>,
  isFetchingTenantDatasources: boolean,
  isFetchingTenantThemes: boolean,
  setDatasources: (datasources: Array<ExtendedDatasourceType>) => void,
  setThemes: (themes: Array<ThemeType>) => void,
  defaultThemeId?: number,
  setDefaultThemeId: (id?: number) => void,
  onHasChanged: (hasChanged: boolean) => void
}

function ColumnSelectors(props: Props) {
  const { tenantId, availableDatasources, datasources,
    availableThemes, themes, isFetchingTenantDatasources, setDatasources,
    setThemes, defaultThemeId, setDefaultThemeId, onHasChanged, isFetchingTenantThemes } = props;
  const [ selectedTab, setSelectedTab ] = React.useState<string | number>('1');

  React.useEffect(() => {
    setSelectedTab('1');
  }, [ tenantId ]);

  const handleAddDatasources = (ids: Array<number | string>) => {
    const newDsIds = new Set<number | string>(ids);
    const newListOfDatasources = availableDatasources.filter((ds) => {
      return newDsIds.has(ds.id);
    });

    onHasChanged(true);
    setDatasources([ ...datasources, ...newListOfDatasources ]);
  };

  const handleRemoveDatasources = (ids: Array<number | string>) => {
    const removedDsIds = new Set<number | string>(ids);
    const newListOfDatasources = datasources.filter((ds) => {
      return !removedDsIds.has(ds.id);
    });

    onHasChanged(true);
    setDatasources(newListOfDatasources);
  };

  const handleAddThemes = (ids: Array<number | string>) => {
    const newThemeIds = new Set<number | string>(ids);
    const newListOfThemes = availableThemes.filter((theme) => {
      return newThemeIds.has(theme.id);
    });

    onHasChanged(true);
    setThemes([ ...themes, ...newListOfThemes ]);
  };

  const handleRemoveThemes = (ids: Array<number | string>) => {
    const removedThemeIds = new Set<number | string>(ids);
    const newListOfThemes = themes.filter((theme) => {
      return !removedThemeIds.has(theme.id);
    });

    if (defaultThemeId && removedThemeIds.has(defaultThemeId)) {
      setDefaultThemeId(undefined);
    }

    onHasChanged(true);
    setThemes(newListOfThemes);
  };

  const handleFilterChange = (datasource: ExtendedDatasourceType) => {
    const newDatasources = datasources.map((ds) => {
      if (ds.id === datasource.id) {
        return datasource;
      }

      return ds;
    });

    onHasChanged(true);
    setDatasources(newDatasources);
  };

  return (
    <Section>
      <TabsContainer>
        <Tabs
          selectedTab={selectedTab}
          onSelect={setSelectedTab}
        >
          <Tab id={'1'} title={'Datasources'}>
            <Datasources
              tenantId={tenantId}
              availableDatasources={availableDatasources}
              datasources={datasources}
              selectedDatasources={datasources.map((ds) => ds.id)}
              isLoading={isFetchingTenantDatasources}
              onAddDatasources={handleAddDatasources}
              onRemoveDatasources={handleRemoveDatasources}
              onFiltersChange={handleFilterChange}
            />
          </Tab>
          <Tab id={'2'} title={'Themes'}>
            <Themes
              tenantId={tenantId}
              availableThemes={availableThemes}
              selectedThemes={themes.map((theme) => theme.id)}
              isLoading={isFetchingTenantThemes}
              onAddThemes={handleAddThemes}
              onRemoveThemes={handleRemoveThemes}
              defaultThemeId={defaultThemeId}
              setDefaultThemeId={setDefaultThemeId}
            />
          </Tab>
        </Tabs>
      </TabsContainer>
    </Section>
  );
}

export { ColumnSelectors };
