import * as React from 'react';
import { styled } from 'linaria/react';
import {
  Input,
  Table,
  NewEntityIcon,
  TrashIcon,
  DropdownButton,
  DropdownMenu,
  DropdownItem,
  Button,
  SaveIcon
} from '@sevone/scratch';
import { useModal, useNotification } from '@sevone/insight-connect';
import set from 'lodash-es/set';
import get from 'lodash-es/get';
import { DEAFULT_BUILT_IN_COLUMNS, DEAFULT_CUSTOM_COLUMNS } from './columns';
import { Preview } from './preview';
import { SidePanel } from './side-panel';
import {
  GET_THEMES_QUERY,
  ThemeType,
  GetThemeResponseType
} from './queries/get-themes.query';
import {
  CREATE_THEME_MUTATION,
  CreateThemeResponseType
} from './queries/create-theme.mutation';
import {
  UPDATE_THEME_MUTATION,
  UpdateThemeResponseType
} from './queries/update-theme.mutation';
import {
  REMOVE_TENANT_MUTATION,
  RemoveTenantResponseType
} from './queries/remove-tenants.mutation';
import {
  ADD_TENANT_MUTATION,
  AddTenantResponseType
} from './queries/add-tenant.mutation';
import {
  GET_ASSOCIATED_TENANTS,
  GetAssociatedTenantsResponseType
} from './queries/get-associated-tenants.query';
import {
  DELETE_THEMES_MUTATION,
  DeleteThemesResponseType
} from './queries/delete-theme.mutation';
import { CoreStore } from '../../store';
import {
  Page,
  PageHeader,
  PageTitle,
  PageActions,
  PageSection
} from '../../components/page';
import { hasPermission } from '../../components/permission-gate';
import { HORIZONTAL_RHYTHM } from '../../utils/spacing';
import { useDebounce } from '../../hooks/use-debounce';
import { useGql } from '../../hooks/use-gql';

const PageContainer = styled(Page)`
  overflow: hidden;
  display: flex;
`;
const PageContentContainer = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex: 1;
`;
const PageHeaderContainer = styled(PageHeader)`
  display: flex;
  justify-content: space-between;
`;
const PageBody = styled.div`
  display: flex;
  justify-content: center;
  flex: 1;
  overflow: hidden;
`;
const TableSection = styled(PageSection)`
  display: flex;
  flex-direction: column;
  width: 200px;
  align-items: center;
  margin-right: ${HORIZONTAL_RHYTHM}px;
`;
const BuiltInTableWrapper = styled.div`
  flex: none;
  width: 100%;
`;
const CustomTableWrapper = styled.div`
  flex: 1;
  width: 100%;
  overflow: auto;
`;
const SearchWrapper = styled.div`
  width: 225px;
`;

const PAGE_SIZE = 50;
const VIEW_OPTIONS = [
  { label: 'Report Manager', value: 'report manager' },
  { label: 'Hover Styles', value: 'hover styles' }
];

type OptionType = { label: string, value: string | number };

function ThemeManager() {
  const {
    runGql: requestTheme,
    isFetching: isFetchingThemes
  } = useGql<GetThemeResponseType>(GET_THEMES_QUERY);
  const {
    runGql: requestCreateTheme
  } = useGql<CreateThemeResponseType>(CREATE_THEME_MUTATION);
  const {
    runGql: requestUpdateTheme
  } = useGql<UpdateThemeResponseType>(UPDATE_THEME_MUTATION);
  const {
    runGql: requestRemoveTenants
  } = useGql<RemoveTenantResponseType>(REMOVE_TENANT_MUTATION);
  const {
    runGql: requestAddTenants
  } = useGql<AddTenantResponseType>(ADD_TENANT_MUTATION);
  const {
    runGql: requestGetAssociatedTenants
  } = useGql<GetAssociatedTenantsResponseType>(GET_ASSOCIATED_TENANTS);
  const {
    runGql: requestDeleteThemes
  } = useGql<DeleteThemesResponseType>(DELETE_THEMES_MUTATION);
  const { state: coreState, updateActiveTenant } = CoreStore.useContainer();
  const { activeTenant, permissions } = coreState;
  const { showNotification } = useNotification();
  const { showModal } = useModal();
  const [ customThemes, setCustomThemes ] = React.useState<Array<ThemeType>>([]);
  const [ builtInThemes, setBuiltInThemes ] = React.useState<Array<ThemeType>>([]);
  const [ selectedThemes, setSelectedThemes ] = React.useState<Array<number | string>>([]);
  const [ isExpanded, setIsExpanded ] = React.useState(true);
  const [ themeSearch, setThemeSearch ] = React.useState('');
  const debouncedSearchTerm = useDebounce(themeSearch, 200);
  const [ builtInFilteredRows, setBuiltInFilteredRows ] = React.useState<Array<ThemeType>>([]);
  const [ customFilteredRows, setCustomFilteredRows ] = React.useState<Array<ThemeType>>([]);
  const [ page, setPage ] = React.useState(1);
  const [ isSaveButtonMenuVisible, setIsSaveButtonMenuVisible ] = React.useState(false);
  const [ currentView, setCurrentView ] = React.useState<OptionType | Array<OptionType>>(VIEW_OPTIONS[0]);
  // theme config
  const [ name, setName ] = React.useState('');
  const [ isGlobal, setIsGlobal ] = React.useState(false);
  const [ colors, setColors ] = React.useState<{ [key: string]: any }>();
  const [ initColors, setInitColors ] = React.useState();
  const [ isBuiltIn, setIsBuiltIn ] = React.useState(false);
  const [ hasChanged, setHasChanged ] = React.useState(false);
  const [ baseThemeType, setBaseThemeType ] = React.useState('light');

  const handleError = (errors: Array<{ message: string }>) => {
    showNotification({
      type: 'error',
      message: `${errors[0].message}`,
      lifespan: null
    });
  };
  const initTheme = (baseThemes: Array<ThemeType>) => {
    const baseTheme = baseThemes.find((theme) => theme.name === 'SevOne Light');
    if (baseTheme) {
      setName('');
      setIsGlobal(false);
      setColors(JSON.parse(baseTheme.colors));
      setInitColors(JSON.parse(baseTheme.colors));
      setIsBuiltIn(false);
      setIsExpanded(true);
      setHasChanged(false);
      setBaseThemeType('light');
    }
  };

  React.useEffect(() => {
    requestTheme().then((res) => {
      const bThemes: Array<ThemeType> = [];
      const cThemes: Array<ThemeType> = [];
      res.themes.forEach((theme) => {
        if (theme.isBuiltIn) {
          bThemes.push(theme);
        } else {
          cThemes.push(theme);
        }
      });

      setCustomThemes(cThemes);
      setBuiltInThemes(bThemes);
      initTheme(bThemes);
    });
  }, []);

  React.useEffect(() => {
    setHasChanged(false);
    if (selectedThemes.length === 1) {
      const allThemes = [ ...customThemes, ...builtInThemes ];
      const selectedTheme = allThemes.find((theme) => theme.id === selectedThemes[0]);
      if (selectedTheme) {
        setName(selectedTheme.name);
        setIsGlobal(selectedTheme.isGlobal);
        setColors(JSON.parse(selectedTheme.colors));
        setInitColors(JSON.parse(selectedTheme.colors));
        setIsBuiltIn(selectedTheme.isBuiltIn);
        setIsExpanded(true);
        setBaseThemeType('light');
      }
    } else if (selectedThemes.length === 0) {
      initTheme(builtInThemes);
    }
  }, [ selectedThemes ]);

  React.useEffect(() => {
    setBuiltInFilteredRows(
      builtInThemes.filter((row) => row.name.toLowerCase().includes(debouncedSearchTerm.toLowerCase()))
    );
    setCustomFilteredRows(
      customThemes.filter((row) => row.name.toLowerCase().includes(debouncedSearchTerm.toLowerCase()))
    );
  }, [ debouncedSearchTerm, builtInThemes, customThemes ]);

  const handleRevertAll = () => {
    if (selectedThemes.length > 0) {
      const allThemes = [ ...customThemes, ...builtInThemes ];
      const selectedTheme = allThemes.find((theme) => theme.id === selectedThemes[0]);
      if (selectedTheme) {
        setName(selectedTheme.name);
        setIsGlobal(selectedTheme.isGlobal);
        setColors(JSON.parse(selectedTheme.colors));
        setInitColors(JSON.parse(selectedTheme.colors));
        setIsBuiltIn(selectedTheme.isBuiltIn);
        setHasChanged(false);
        setBaseThemeType('light');
      }
    } else {
      initTheme(builtInThemes);
    }
  };

  const handleConfirmRevertAll = () => {
    showModal({
      type: 'confirmation',
      header: 'Revert All',
      message: () => 'Are you sure you want to revert all changes? This action cannot be undone.',
      actions: (modalProps: { hideModal: () => void }) => [
        (
          <Button
            key={'OK'}
            onClick={() => {
              handleRevertAll();
              modalProps.hideModal();
            }}
          >
            {'Revert all'}
          </Button>
        ),
        (<Button key={'Cancel'} onClick={() => modalProps.hideModal()}>{'Cancel'}</Button>)
      ]
    });
  };

  const handleColorChange = (value: string, path: string) => {
    const newColors = { ...colors };
    set(newColors, path, value);
    setColors(newColors);
    setHasChanged(true);
  };

  const handleRevertColor = (path: string) => {
    const newColors = { ...colors };
    set(newColors, path, get(initColors, path));
    setColors(newColors);
  };

  const handleBaseThemeChange = (value: string) => {
    setHasChanged(true);
    if (value === 'light') {
      const baseTheme = builtInThemes.find((theme) => theme.name === 'SevOne Light');
      if (baseTheme) {
        setColors(JSON.parse(baseTheme.colors));
        setInitColors(JSON.parse(baseTheme.colors));
        setBaseThemeType('light');
      }
    } else {
      const baseTheme = builtInThemes.find((theme) => theme.name === 'SevOne Dark');
      if (baseTheme) {
        setColors(JSON.parse(baseTheme.colors));
        setInitColors(JSON.parse(baseTheme.colors));
        setBaseThemeType('dark');
      }
    }
  };

  const isDeletable = () => {
    const ids = new Set(selectedThemes);
    let deletable = true;
    builtInThemes.forEach((theme) => {
      if (ids.has(theme.id)) {
        deletable = false;
      }
    });

    return deletable;
  };

  const handleSearch = (value: string) => {
    setThemeSearch(value);
    setPage(1);
  };

  const handleNameChange = (value: string) => {
    setHasChanged(true);
    setName(value);
  };

  const handleGlobalChange = (value: boolean) => {
    setHasChanged(true);
    setIsGlobal(value);
  };

  const handleConfirmSwitchSelectedTheme = (callback: () => void) => {
    if (hasChanged) {
      showModal({
        type: 'confirmation',
        header: 'Unsaved Changes',
        message: () => 'You have unsaved changes. Are you sure you want to continue?',
        actions: (modalProps: { hideModal: () => void }) => [
          (<Button key={'cancel'} onClick={() => modalProps.hideModal()}>{'Cancel'}</Button>),
          (
            <Button
              key={'OK'}
              onClick={() => {
                callback();
                modalProps.hideModal();
              }}
            >
              {'OK'}
            </Button>
          )
        ]
      });
    } else {
      callback();
    }
  };

  const handleSelectThemes = (ids: Array<number | string>) => {
    handleConfirmSwitchSelectedTheme(() => {
      setSelectedThemes(ids);
    });
  };

  const handleCreateTheme = () => {
    handleConfirmSwitchSelectedTheme(() => {
      setSelectedThemes([]);
    });
  };

  const handleSaveAs = (): Promise<any> => {
    const userPermissionsIds = permissions.map((perm) => perm.id);
    let addToTenant = false;
    const vars = {
      name,
      colors: JSON.stringify(colors),
      isGlobal
    };
    // If the user does not have the permissions to manage all themes,
    // or if the user has manageAllTheme but is global is false
    // then we need to add a tenant id.
    // This is because a theme needs to belong to a tenant or be global.
    if ((!hasPermission([ 'manageAllThemes' ], userPermissionsIds) || !isGlobal) && activeTenant) {
      set(vars, 'tenantId', activeTenant.id);
      addToTenant = true;
    }

    return requestCreateTheme(vars).then((res) => {
      const newTheme = res.createTheme;
      setCustomThemes([ ...customThemes, newTheme ]);
      setSelectedThemes([ newTheme.id ]);
      setHasChanged(false);
      if (addToTenant && activeTenant) {
        updateActiveTenant({
          ...activeTenant,
          ...{ themes: [ ...activeTenant.themes, newTheme ] }
        });
      }
      return Promise.resolve();
    }).catch((e) => {
      handleError(e);
      return Promise.reject();
    });
  };

  const handleDeleteThemes = (resolve: () => void, reject: () => void) => {
    return requestDeleteThemes({ ids: selectedThemes }).then(() => {
      const ids = new Set(selectedThemes);
      const newCustomThemes = customThemes.filter((theme) => !ids.has(theme.id));
      setCustomThemes(newCustomThemes);
      setSelectedThemes([]);
      return resolve();
    }).catch((e) => {
      handleError(e);
      return reject();
    });
  };

  const handleConfirmDelete = () => {
    return new Promise((resolve, reject) => {
      showModal({
        type: 'confirmation',
        header: 'Delete Theme(s)',
        message: () => 'Are you sure you want to delete these themes? This cannot be undone.',
        actions: (modalProps: { hideModal: () => void }) => [
          (
            <Button
              key={'cancel'}
              onClick={() => {
                modalProps.hideModal();
                resolve();
              }}
            >
              {'Cancel'}
            </Button>
          ),
          (
            <Button
              key={'OK'}
              onClick={() => {
                handleDeleteThemes(resolve, reject);
                modalProps.hideModal();
              }}
            >
              {'OK'}
            </Button>
          )
        ]
      });
    });
  };

  const handleSave = async (): Promise<any> => {
    const userPermissionsIds = permissions.map((perm) => perm.id);
    const selectedTheme = customThemes.find((theme) => theme.id === selectedThemes[0]);

    if (selectedTheme && activeTenant) {
      try {
        // If a global theme is being downgraded to a non-global theme
        // then we need to remove all assoicated tenants except the current
        // tenant the super admin is on.
        if (hasPermission([ 'manageAllThemes' ], userPermissionsIds) && selectedTheme.isGlobal && !isGlobal) {
          const getAssoicatedTenantsVars = { filter: { id: selectedTheme.id } };
          const associatedTenantIds = await requestGetAssociatedTenants(getAssoicatedTenantsVars)
            .then((res) => new Set(res.tenants));
          const removeTenantsPromises: Array<Promise<RemoveTenantResponseType>> = [];
          if (!associatedTenantIds.has(activeTenant.id)) {
            await requestAddTenants({ tenantId: activeTenant.id, themeId: selectedTheme.id });
          }
          associatedTenantIds.forEach((associatedTenantId) => {
            if (associatedTenantId !== activeTenant.id) {
              const removeTenantVars = { tenantId: associatedTenantId, themeId: selectedTheme.id };
              removeTenantsPromises.push(requestRemoveTenants(removeTenantVars));
            }
          });
          await Promise.all(removeTenantsPromises);
        }

        const newTheme = {
          id: selectedTheme.id,
          theme: {
            name,
            colors: JSON.stringify(colors),
            isGlobal
          }
        };

        return requestUpdateTheme(newTheme).then((res) => {
          const updatedTheme = res.updateTheme;
          const newCustomThemes = customThemes.map((theme) => {
            if (theme.id === updatedTheme.id) {
              return updatedTheme;
            }

            return theme;
          });

          const tenantThemes = new Set(activeTenant.themes.map((theme) => theme.id));
          if (tenantThemes.has(updatedTheme.id) && activeTenant) {
            const newTenantThemes = activeTenant.themes.map((theme) => {
              if (theme.id === updatedTheme.id) {
                return updatedTheme;
              }

              return theme;
            });
            updateActiveTenant({
              ...activeTenant,
              ...{ themes: newTenantThemes }
            });
          }

          setCustomThemes(newCustomThemes);
          setSelectedThemes([ updatedTheme.id ]);
          setHasChanged(false);
          return Promise.resolve();
        }).catch((e) => {
          handleError(e);
          return Promise.reject();
        });
      } catch (e) {
        handleError(e);
        return Promise.reject();
      }
    }

    return Promise.resolve();
  };

  const renderSaveButtons = () => {
    if (selectedThemes.length > 0) {
      return (
        <div style={{ display: 'flex' }}>
          <DropdownButton
            prefixIcon={<SaveIcon />}
            visible={isSaveButtonMenuVisible}
            onVisiblityChange={setIsSaveButtonMenuVisible}
            trigger={[ 'click' ]}
            type={'outlined'}
            menu={
              <DropdownMenu>
                <DropdownItem onClick={handleSaveAs}>{'Save As'}</DropdownItem>
                <DropdownItem onClick={handleSave}>{'Save'}</DropdownItem>
              </DropdownMenu>
            }
            onClick={handleSave}
            position={'bottomRight'}
            disabled={isBuiltIn || !name}
          >
            {'Save'}
          </DropdownButton>
        </div>
      );
    }

    return (
      <Button
        onClick={handleSaveAs}
        disabled={!name}
        prefixIcon={<SaveIcon />}
        type={'outlined'}
      >
        {'Save as'}
      </Button>
    );
  };

  return (
    <PageContainer title={'Custom Themes'}>
      <PageContentContainer>
        <PageHeaderContainer>
          <PageTitle>{'Custom Themes'}</PageTitle>
          <SearchWrapper>
            <Input
              value={themeSearch}
              placeholder={'Search themes...'}
              onChange={handleSearch}
            />
          </SearchWrapper>
          <PageActions>
            <Button
              onClick={handleCreateTheme}
              disabled={selectedThemes.length === 0}
              prefixIcon={<NewEntityIcon />}
              type={'outlined'}
            >
              {'Create Theme'}
            </Button>
            {renderSaveButtons()}
            <Button
              onClick={handleConfirmDelete}
              disabled={selectedThemes.length === 0 || !isDeletable()}
              prefixIcon={<TrashIcon />}
              type={'outlined'}
            >
              {'Delete'}
            </Button>
          </PageActions>
        </PageHeaderContainer>
        <PageBody>
          <TableSection>
            <BuiltInTableWrapper>
              <Table
                rows={builtInFilteredRows}
                columns={DEAFULT_BUILT_IN_COLUMNS}
                selectedRows={selectedThemes}
                onRowSelection={handleSelectThemes}
              />
            </BuiltInTableWrapper>
            <CustomTableWrapper>
              <Table
                rows={customFilteredRows}
                columns={DEAFULT_CUSTOM_COLUMNS}
                selectedRows={selectedThemes}
                onRowSelection={handleSelectThemes}
                pageSize={PAGE_SIZE}
                onPageChange={setPage}
                page={page}
              />
            </CustomTableWrapper>
          </TableSection>
          <Preview
            isLoading={isFetchingThemes}
            colors={colors}
            view={Array.isArray(currentView) ? String(currentView[0].value) : String(currentView.value)}
          />
        </PageBody>
      </PageContentContainer>
      <SidePanel
        isExpanded={isExpanded}
        name={name}
        isGlobal={isGlobal}
        colors={colors}
        isBuiltIn={isBuiltIn}
        onRevertAll={handleConfirmRevertAll}
        onNameChange={handleNameChange}
        onGlobalChange={handleGlobalChange}
        onColorChange={handleColorChange}
        onRevertColor={handleRevertColor}
        onBaseThemeChange={handleBaseThemeChange}
        baseThemeType={baseThemeType}
        isCreating={selectedThemes.length === 0}
        onExpand={setIsExpanded}
        onViewChange={setCurrentView}
        viewOptions={VIEW_OPTIONS}
        currentView={currentView}
      />
    </PageContainer>
  );
}

export { ThemeManager };
