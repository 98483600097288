import { useState, useEffect, useRef } from 'react';
import isEqual from 'lodash-es/isEqual';
import keyBy from 'lodash-es/keyBy';
import {
  facetManager,
  FacetType,
  FacetSchema,
  FacetData
} from '@sevone/insight-wdk';
import { uuid } from '../../utils/uuid';
import { isTruthy } from '../../utils/is-truthy';
import { createContainer } from '../../utils/create-container';
import { ReportVariableType } from '../../pages/report/types';
import {
  CONTAINER_TYPES,
  TYPE_PRIORITIES,
  DEPENDENCY_LIST,
  registerFacetContainer,
  addFacet,
  addSchemaToBlacklist,
  removeSchemaFromBlacklist,
  buildStack
} from '../facet-manager';
import { ReportLinkController } from '../report-link-controller';
import { variableTypePresets } from './variable-types';

const CONTAINER_ID = CONTAINER_TYPES.reportVariables;

function useReportVariableManager() {
  const { addLink, removeLinks } = ReportLinkController.useContainer();
  const [
    variables,
    setVariables
  ] = useState<Record<string, ReportVariableType>>({});
  const [ order, setOrder ] = useState<Array<string>>([]);
  const unregisterContainer = useRef<(() => void) | null>(null);

  const isRegistered = () => {
    return !!unregisterContainer.current;
  };

  const registerContainer = () => {
    if (isRegistered()) {
      return;
    }

    const { unregister } = registerFacetContainer({
      id: CONTAINER_ID,
      priority: TYPE_PRIORITIES.reportVariables,
      dependencies: DEPENDENCY_LIST.reportVariables
    }, [], {
      schemaBlacklist: variableTypePresets.map((type) => type.schema)
    });

    unregisterContainer.current = unregister;
  };

  const getFacetForSchema = (schema: FacetSchema): FacetType | null => {
    const stack = buildStack(CONTAINER_ID);
    const facets = (stack?.list() || []).filter((facet) => {
      return facetManager.isSchemaCompatible(facet.schema, schema);
    });

    if (facets.length > 0) {
      return facets[facets.length - 1];
    }

    return null;
  };

  const getFacetForVariable = (id: string): FacetType | null => {
    const variable = variables[id] || null;

    if (!variable) {
      return null;
    }

    return getFacetForSchema(variable.schema);
  };

  const getVariable = (id: string): ReportVariableType | null => {
    const variable = variables[id] || null;

    if (!variable) {
      return null;
    }

    const facet = getFacetForVariable(id);
    return {
      ...variable,
      value: facet ? facet.data : null
    };
  };

  const getVariables = () => {
    return order.map((id) => getVariable(id) || null).filter(isTruthy);
  };

  const getVariablesBySchema = (schema: FacetSchema) => {
    return Object.values(variables).filter((variable) => {
      return isEqual(variable.schema, schema);
    });
  };

  const addValue = (value: FacetType) => {
    addLink(value);
    addFacet(CONTAINER_ID, value);
  };

  const create = (variable: {
    id?: string,
    label: string,
    schema: FacetSchema,
    value: FacetData,
    options: Array<any>,
    config: {
      [key: string]: any
    }
  }): ReportVariableType => {
    const { id = uuid(), ...rest } = variable;
    const createdVariable = { id, ...rest };

    // Make sure we register the facet container before we go adding any
    // variables to it. It's possible this will get called before its initial
    // `useEffect` has had a chance to run and register itself.
    registerContainer();
    addFacet(CONTAINER_ID, facetManager.createFacet(
      createdVariable.schema,
      createdVariable.value
    ));
    removeSchemaFromBlacklist(CONTAINER_ID, createdVariable.schema);
    setVariables((prev) => ({ ...prev, [id]: createdVariable }));
    setOrder((prev) => [ ...prev, createdVariable.id ]);

    return createdVariable;
  };

  const updateVariables = (vars: Array<ReportVariableType>) => {
    const update = keyBy(vars, 'id');

    setVariables((prev) => ({ ...prev, ...update }));
  };

  const deleteVariables = (ids: Array<string>) => {
    const schemas = ids.map((id) => {
      if (!variables[id]) {
        return null;
      }

      return variables[id].schema;
    }).filter(isTruthy);

    removeLinks(schemas);
    // We need to clear any values currently in the stack related to these
    // deleted schemas.
    schemas.forEach((schema) => {
      addFacet(
        CONTAINER_ID,
        facetManager.createFacet(schema, null)
      );
      addSchemaToBlacklist(CONTAINER_ID, schema);
    });
    setOrder((curr) => curr.filter((id) => !ids.includes(id)));
    setVariables((curr) => {
      const next = { ...curr };
      ids.forEach((id) => { delete next[id]; });

      return next;
    });
  };

  const updateOrder = (nextOrder: Array<string>) => {
    setOrder(nextOrder);
  };

  useEffect(() => {
    registerContainer();

    return () => {
      unregisterContainer.current?.();
    };
  }, []);

  return {
    variables,
    getVariables,
    getVariablesBySchema,
    getVariable,
    create,
    updateVariables,
    deleteVariables,
    updateOrder,
    addValue
  };
}

const ReportVariableManager = createContainer(useReportVariableManager);

export { ReportVariableManager };
