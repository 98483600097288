import 'react-grid-layout/css/styles.css';
/**
 * We don't use `react-resizable` as a direct dep, but react-grid-layout does
 * and needs us to include its styling.
 * https://github.com/STRML/react-grid-layout#installation
 */
import 'react-resizable/css/styles.css'; // eslint-disable-line
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as reactDnd from 'react-dnd';
import * as wdk from '@sevone/insight-wdk';
import * as insightConnect from '@sevone/insight-connect';
import App from './app';
import './global.css';
import './fonts.css';
import './overrides.css';

window.React = React;
window.ReactDOM = ReactDOM;
// @ts-ignore
window.reactDnd = reactDnd;
// @ts-ignore
window.wdk = wdk;
// @ts-ignore
window.insightConnect = insightConnect;

ReactDOM.render(<App />, document.getElementById('root'));
