export const NAMESPACE_QUERY = `
  query($filter: MetadataNamespacesFilterArgs, $datasourceId: Int, $size: Int) {
    metadataNamespaces (filter: $filter, datasourceId: $datasourceId, size: $size) {
      id,
      name
    }
  }
`;

export type NamespaceType = {
  id: number,
  name: string
}

export type NamespaceResponseType = {
  metadataNamespaces: Array<NamespaceType>
}
