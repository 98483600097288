import * as React from 'react';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import cx from 'classnames';
import { PopoutTarget, AngleDownIcon, AngleUpIcon } from '@sevone/scratch';
import { SecondaryLevelItem } from './secondary-level-item';
import { TopLevelType } from '../../../navigation';
import * as styles from './styles';
import { PermissionGate } from '../../../../components/permission-gate';

type MaybeLinkProps = {
  item: TopLevelType,
  className: string,
  children: React.ReactNode,
  onClick: () => void
};

function MaybeLink(props: MaybeLinkProps) {
  const { item, className, children, onClick } = props;
  const to = ('goTo' in item) ? item.goTo() : null;

  if (to) {
    return (
      <Link to={to} className={className} onClick={onClick}>{children}</Link>
    );
  }

  return (
    <div className={className} onClick={onClick}>{children}</div>
  );
}

type Props = {
  compact: boolean,
  item: TopLevelType,
  selectedItem: string | null,
  expanded: boolean,
  onExpand: (id: string) => void,
  onCollapse: (id: string) => void
};

function TopLevelItem(props: Props) {
  const {
    compact,
    item,
    selectedItem,
    expanded,
    onExpand,
    onCollapse
  } = props;
  const [ showChildrenPopout, setShowChildrenPopout ] = React.useState(false);
  const selected = selectedItem === item.id ||
    (compact && 'children' in item && item.children.find((i) => i.id === selectedItem));

  const handleClick = () => {
    if (!expanded && 'children' in item) {
      onExpand(item.id);
    } else {
      onCollapse(item.id);
    }
  };

  return (
    <>
      <PopoutTarget
        visible={compact && showChildrenPopout}
        position={[ 'right', 'top' ]}
        hoverSensitivity={50}
        trigger={[ 'hover', 'click' ]}
        className={cx(styles.itemWrapper, {
          [styles.isHovered]: compact && showChildrenPopout
        })}
        popoutClassName={styles.popoutWrapper}
        popout={(p) => 'children' in item && (
          <AnimatePresence>
            <motion.div
              {...p}
              className={styles.secondaryItemsPopoutWrapper}
              initial="hidden"
              animate="visible"
              variants={{
                visible: { x: 0, opacity: 1 },
                hidden: { x: -10, opacity: 0.5 }
              }}
              transition={{ duration: 0.2 }}
            >
              {item.children.map((secondaryItem) => (
                <PermissionGate
                  key={secondaryItem.id}
                  requiredPermissions={secondaryItem.permissions}
                >
                  <SecondaryLevelItem
                    item={secondaryItem}
                    selectedItem={selectedItem}
                  />
                </PermissionGate>
              ))}
            </motion.div>
          </AnimatePresence>
        )}
        onVisibilityChange={setShowChildrenPopout}
      >
        <MaybeLink
          item={item}
          className={cx(styles.topLevelItem, {
            [styles.selected]: selected,
            [styles.compact]: compact
          })}
          onClick={handleClick}
        >
          <div className={styles.icon}>
            {item.icon}
          </div>

          {!compact && item.title}

          {'children' in item && !compact &&
            <div className={styles.expandToggle}>
              {expanded ? <AngleUpIcon /> : <AngleDownIcon />}
            </div>
          }
        </MaybeLink>

        {selected &&
          <motion.div
            layoutId="selectionIndicator"
            className={styles.selectionIndicator}
          />
        }
      </PopoutTarget>

      <AnimatePresence initial={false}>
        {expanded && !compact && 'children' in item &&
          <motion.div
            className={styles.secondaryItemsNestedWrapper}
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={{
              visible: { height: 'auto', overflow: 'visible' },
              hidden: { height: 0, overflow: 'hidden' }
            }}
          >
            {item.children.map((secondaryItem) => (
              <PermissionGate
                key={secondaryItem.id}
                requiredPermissions={secondaryItem.permissions}
              >
                <SecondaryLevelItem
                  item={secondaryItem}
                  selectedItem={selectedItem}
                />
              </PermissionGate>
            ))}
          </motion.div>
        }
      </AnimatePresence>
    </>
  );
}

export { TopLevelItem };
