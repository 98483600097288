const FOLDER_QUERY = `
  query {
    folders {
      id,
      name,
      parentId,
      reportCount
    }
  }
`;

export type FolderType = {
  id: number,
  name: string,
  parentId: number | null,
  reportCount: number
};

export type FolderResponseType = {
  folders: Array<FolderType>
};

export { FOLDER_QUERY };
