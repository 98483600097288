import * as React from 'react';
import { Select } from '@sevone/scratch';
import {
  ATTRIBUTE_QUERY,
  AttributeResponseType
} from '../queries/get-attributes.query';
import { useGql } from '../../../../../../../hooks/use-gql';

type Props = {
  datasourceIds: Array<number>,
  namespaceName?: string | null,
  value: string | null,
  onChange: (value: string | null) => void
}
type OptionType = {
  value: string,
  label: string
}

const SIZE = 200;

function AttributeSelector(props: Props) {
  const {
    runGql: requestAttributes
  } = useGql<AttributeResponseType>(ATTRIBUTE_QUERY);
  const { datasourceIds, namespaceName, onChange, value } = props;

  const fetchAttributeOptions = async (name: string = '') => {
    if (!namespaceName) {
      return [];
    }

    const attributeLists = await Promise.all(datasourceIds.map((datasourceId) => {
      const vars = {
        datasourceId,
        filter: {
          name,
          namespaceName
        },
        size: SIZE
      };
      return requestAttributes(vars).then((res) => {
        return res.metadataAttributes.map((attr) => {
          return attr.name;
        });
      });
    }));

    const attributeSet: Set<string> = new Set();
    attributeLists.forEach((attributeList) => {
      attributeList.forEach((attribute) => {
        attributeSet.add(attribute);
      });
    });
    const mergedAttributes = [ ...attributeSet ];

    return mergedAttributes
      .sort((a, b) => a.localeCompare(b, undefined, { numeric: true, ignorePunctuation: true }))
      .map((attr) => ({ label: attr, value: attr }));
  };

  const handleChange = (option: OptionType) => {
    onChange(option?.value || null);
  };

  return (
    <Select
      clearable
      key={(namespaceName || '') + datasourceIds.join('-')}
      disabled={!namespaceName}
      label={'Attribute'}
      placeholder={'Attribute'}
      options={fetchAttributeOptions}
      onChange={handleChange}
      value={value ? { value, label: value } : null}
    />
  );
}

export { AttributeSelector };
