/**
 * Essentially just a copy of
 * https://github.com/facebook/react/blob/master/packages/shared/lowPriorityWarning.js
 */

import { isEnvironment } from '../is-environment';

function printWarning(format: string, ...args: Array<any>) {
  let argIndex = 0;
  // eslint-disable-next-line
  const message = 'Warning: ' + format.replace(/%s/g, () => args[argIndex++]);
  if (typeof console !== 'undefined') {
    // eslint-disable-next-line
    console.warn(message);
  }

  try {
    // This error was thrown as a convenience so that you can use this stack
    // to find the callsite that caused this warning to fire.
    throw new Error(message);
  } catch (x) {} // eslint-disable-line
}

function warn(condition: boolean, format: string, ...args: Array<string>) {
  if (format === undefined) {
    throw new Error(
      '`warn(condition, format, ...args)` requires a warning message argument'
    );
  }

  if (!condition) {
    printWarning(format, ...args);
  }
}

function debug(label: string, ...args: Array<any>) {
  if (!isEnvironment('production')) {
    // eslint-disable-next-line
    console.log(`[${label}]`, ...args);
  }
}

// Disable warnings in production
function lowPriorityWarning(
  condition: boolean,
  format: string,
  ...args: Array<string>
) {
  if (!isEnvironment('production')) {
    warn(condition, format, ...args);
  }
}

export { lowPriorityWarning, debug };
