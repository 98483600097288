import * as React from 'react';
import { styled } from 'linaria/react';
import {
  LoadingCircle,
  Button,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownMenuDivider,
  AngleDownIcon,
  Input,
  Select,
  RadioGroup,
  Radio
} from '@sevone/scratch';
import { ReportManager } from '../../report-manager';

const Container = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--sev1-primary-2-color);
  border-radius: 4px;
`;
const CssVarWrapper = styled.div<{
  currentTheme: { [key: string]: { color: string, contrast: string } },
  isAllowedInteraction: boolean
}>`
  height: 100%;
  width: 100%;
  cursor: ${({ isAllowedInteraction }) => (isAllowedInteraction ? '' : 'not-allowed')};
  --sev1-primary-1-color: ${(props) => props.currentTheme.primary1.color};
  --sev1-primary-1-contrast: ${(props) => props.currentTheme.primary1.contrast};

  --sev1-primary-2-color: ${(props) => props.currentTheme.primary2.color};
  --sev1-primary-2-contrast: ${(props) => props.currentTheme.primary2.contrast};

  --sev1-primary-3-color: ${(props) => props.currentTheme.primary3.color};
  --sev1-primary-3-contrast: ${(props) => props.currentTheme.primary3.contrast};

  --sev1-primary-4-color: ${(props) => props.currentTheme.primary4.color};
  --sev1-primary-4-contrast: ${(props) => props.currentTheme.primary4.contrast};

  --sev1-primary-5-color: ${(props) => props.currentTheme.primary5.color};
  --sev1-primary-5-contrast: ${(props) => props.currentTheme.primary5.contrast};

  --sev1-primary-6-color: ${(props) => props.currentTheme.primary6.color};
  --sev1-primary-6-contrast: ${(props) => props.currentTheme.primary6.contrast};

  --sev1-secondary-1-color: ${(props) => props.currentTheme.secondary1.color};
  --sev1-secondary-1-contrast: ${(props) => props.currentTheme.secondary1.contrast};

  --sev1-secondary-2-color: ${(props) => props.currentTheme.secondary2.color};
  --sev1-secondary-2-contrast: ${(props) => props.currentTheme.secondary2.contrast};

  --sev1-secondary-3-color: ${(props) => props.currentTheme.secondary3.color};
  --sev1-secondary-3-contrast: ${(props) => props.currentTheme.secondary3.contrast};

  --sev1-secondary-4-color: ${(props) => props.currentTheme.secondary4.color};
  --sev1-secondary-4-contrast: ${(props) => props.currentTheme.secondary4.contrast};

  --sev1-error-color: #eb452a;
  --sev1-error-contrast: #ffffff;

  --sev1-warning-color: #fbba17;
  --sev1-warning-contrast: #333333;

  --sev1-info-color: #3e98d3;
  --sev1-info-contrast: #ffffff;

  --sev1-confirmation-color: #1bbb9c;
  --sev1-confirmation-contrast: #ffffff;

  --sev1-size: 16px;
`;
const PreviewContainer = styled.div<{ isAllowedInteraction: boolean }>`
  height: 100%;
  width: 100%;
  pointer-events: ${({ isAllowedInteraction }) => (isAllowedInteraction ? '' : 'none')};;
`;
const HoverStylesContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  & > * {
    margin: 10px 20px;
  }
`;
const HoverComponentWrapper = styled.div`
  width: 200px;
  max-width: 200px;
`;

type Props = {
  isLoading: boolean,
  colors?: { [key: string]: { color: string, contrast: string } }
  view: string
}
type OptionType = {
  label: string,
  value: string | number
}

function Preview(props: Props) {
  const { isLoading, colors, view } = props;
  const [ dropdownVisibility, setDropdownVisibility ] = React.useState(false);
  const [ inputValue, setInputValue ] = React.useState('');
  const [
    selectsValue, setSelectsValue
  ] = React.useState<OptionType | Array<OptionType>>({ label: 'option 1', value: 'option 1' });
  const [ radioValue, setRadioValue ] = React.useState<string | number>('value 1');

  const isAllowedInteraction = view === 'hover styles';

  const renderHoverView = () => {
    return (
      <HoverStylesContainer>
        <Button onClick={() => {}}>{'Click me'}</Button>
        <Dropdown
          position={'bottomRight'}
          visible={dropdownVisibility}
          trigger={[ 'click' ]}
          menu={
            <DropdownMenu>
              <DropdownItem>{'This is Menu Item 1'}</DropdownItem>
              <DropdownItem
                disabled
                onClick={() => {}}
              >
                {'Menu Item 2'}
              </DropdownItem>
              <DropdownMenuDivider />
              <DropdownItem>{'Menu Item 3'}</DropdownItem>
            </DropdownMenu>
          }
          onVisiblityChange={setDropdownVisibility}
        >
          <Button suffixIcon={<AngleDownIcon />} onClick={() => {}}>{'Click me'}</Button>
        </Dropdown>
        <Input
          label={'Text input'}
          placeholder={'Text...'}
          value={inputValue}
          onChange={setInputValue}
        />
        <HoverComponentWrapper>
          <div style={{ width: '100%' }}>
            <Select
              label={'Selector'}
              options={[
                { label: 'option 1', value: 'option 1' },
                { label: 'option 2', value: 'option 2' },
                { label: 'option 3', value: 'option 3' }
              ]}
              onChange={setSelectsValue}
              value={selectsValue}
            />
          </div>
        </HoverComponentWrapper>
        <RadioGroup
          onChange={setRadioValue}
          value={radioValue}
        >
          <Radio value={'value 1'}>{'Value 1'}</Radio>
          <Radio value={'value 2'}>{'Value 2'}</Radio>
          <Radio value={'value 3'}>{'Value 3'}</Radio>
        </RadioGroup>
      </HoverStylesContainer>
    );
  };

  if (isLoading || !colors) {
    return (
      <Container>
        <div>
          <LoadingCircle size={'large'} />
        </div>
      </Container>
    );
  }

  return (
    <Container>
      <CssVarWrapper currentTheme={colors} isAllowedInteraction={isAllowedInteraction}>
        <PreviewContainer isAllowedInteraction={isAllowedInteraction}>
          {view === 'hover styles' ? renderHoverView() : (<ReportManager />)}
        </PreviewContainer>
      </CssVarWrapper>
    </Container>
  );
}

export { Preview };
