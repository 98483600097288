import * as React from 'react';
// import { styled } from 'linaria/react';
import { css } from 'linaria';
import cx from 'classnames';
import { useModal } from '@sevone/insight-connect';
import {
  Button,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  EditIcon,
  SyncIcon,
  TrashIcon,
  ExpandIcon,
  VerticalEllipsisIcon,
  ExpandArrowsIcon,
  CompressArrowsIcon
} from '@sevone/scratch';
import { WidgetType } from '../../../types';
import { ChainMenu } from './chain-menu';
import { CopyMenu } from './copy-menu';
import { ExportDataMenu } from './export-data-menu';

// We're using classnames for these styles because some of these icons we
// want to hide based on criteria, and if we wrap all of them here, you'll end
// up with an empty wrapper with a margin. This extra margin throws off the
// spacing, so instead we pass classnames down to each of these icons and if
// they hide themselves they also hide their corresponding margin.
const linariaControlWrapperStyles = css`
  cursor: pointer;
  margin-right: 15px;
  transition: color .1s ease-in;

  &:hover {
    color: var(--sev1-primary-5-color);
  }

  &:last-of-type {
    margin-right: 0;
  }
`;

const controlWrapperStyles = `widget-grid-no-drag ${linariaControlWrapperStyles}`;

const activeControlStyles = css`
  color: var(--sev1-primary-5-color);
`;

type Props = {
  widget: WidgetType,
  isLinked: boolean,
  isCompact: boolean,
  isWritable: boolean,
  isEditing: boolean,
  isMaximized: boolean,
  refreshWidget: (widgetId: string) => void,
  exportDataFn: ((mime: string) => Blob | Promise<Blob | null> | null) | null,
  onEditWidget: () => void,
  onDeleteWidget: (id: string) => void
  onMaximizeWidget: (id: string | null) => void
  onFullscreenWidget: (fullscreen: boolean) => void
};

function ActionBar(props: Props) {
  const {
    widget,
    isLinked,
    isCompact,
    isWritable,
    isEditing,
    isMaximized,
    refreshWidget,
    exportDataFn,
    onEditWidget,
    onDeleteWidget,
    onMaximizeWidget,
    onFullscreenWidget
  } = props;
  const { showModal } = useModal();
  const [ menuIsVisible, setMenuIsVisible ] = React.useState(false);
  const Tag = isCompact ? DropdownItem : 'div';

  const handleMaximizeWidget = () => {
    return isMaximized ? onMaximizeWidget(null) : onMaximizeWidget(widget.id);
  };

  const handleFullscreenWidget = () => {
    onFullscreenWidget(true);
  };

  const handleDeleteWidget = () => {
    showModal({
      type: 'warning',
      header: 'Delete Widget',
      message: () => (
        'Are you sure you want to delete this widget? ' +
        'This cannot be undone.'
      ),
      actions: (actionProps) => [
        <Button
          key="cancel"
          type="outlined"
          onClick={() => { actionProps.hideModal(); }}
        >{'Cancel'}</Button>,
        <Button
          key="delete"
          onClick={() => {
            onDeleteWidget(widget.id);
            actionProps.hideModal();
          }}
        >{'Yes, delete widget'}</Button>
      ]
    });
  };

  const handleRefreshWidget = () => {
    refreshWidget(widget.id);
  };

  const actions = (
    <>
      {!isMaximized &&
        <ChainMenu
          className={controlWrapperStyles}
          widget={widget}
          isCompact={isCompact}
        />
      }
      {!isMaximized && isWritable && !isLinked &&
        <CopyMenu
          className={controlWrapperStyles}
          widget={widget}
          isCompact={isCompact}
        />
      }
      <Tag
        title={isMaximized ? 'Restore' : 'Maximize'}
        className={cx(controlWrapperStyles, {
          [activeControlStyles]: isMaximized
        })}
        onClick={handleMaximizeWidget}
      >
        {isCompact ? 'Maximize' : (isMaximized ?
          <CompressArrowsIcon /> : <ExpandArrowsIcon />
        )}
      </Tag>
      {isMaximized && (
        <Tag
          title="Fullscreen"
          className={controlWrapperStyles}
          onClick={handleFullscreenWidget}
        >
          {isCompact ? 'Fullscreen' : <ExpandIcon />}
        </Tag>)}
      <Tag
        title="Refresh"
        className={controlWrapperStyles}
        onClick={handleRefreshWidget}
      >
        {isCompact ? 'Refresh' : <SyncIcon />}
      </Tag>
      <ExportDataMenu
        widget={widget}
        isCompact={isCompact}
        className={controlWrapperStyles}
        exportDataFn={exportDataFn}
      />
      {isWritable &&
        <Tag
          title="Edit"
          className={cx(controlWrapperStyles, {
            [activeControlStyles]: isEditing
          })}
          onClick={onEditWidget}
        >
          {isCompact ? 'Edit' : <EditIcon />}
        </Tag>
      }
      {isWritable &&
        <Tag
          title="Delete"
          className={controlWrapperStyles}
          onClick={handleDeleteWidget}
        >
          {isCompact ? 'Delete' : <TrashIcon />}
        </Tag>
      }
    </>
  );

  if (isCompact) {
    return (
      <Dropdown
        trigger={[ 'click' ]}
        position="bottomRight"
        visible={menuIsVisible}
        menu={<DropdownMenu>{actions}</DropdownMenu>}
        onVisiblityChange={setMenuIsVisible}
      >
        {<VerticalEllipsisIcon />}
      </Dropdown>
    );
  }

  return actions;
}

export { ActionBar };
