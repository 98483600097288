import * as React from 'react';
import { XYCoord, useDragLayer } from 'react-dnd';
import { styled } from 'linaria/react';
import { FolderIcon } from '@sevone/scratch';
import { HORIZONTAL_RHYTHM, VERTICAL_RHYTHM } from '../../../utils/spacing';
import { itemTypes } from './item-types';

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
  pointer-events: none;
`;

const DraggedReport = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  background: var(--sev1-primary-5-color);
  color: var(--sev1-primary-5-contrast);
  padding: ${VERTICAL_RHYTHM}px ${HORIZONTAL_RHYTHM * 2}px;
  border-radius: .2em;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25);
`;

const LabelWrapper = styled.div`
  font-size: .9em;
  margin-left: ${HORIZONTAL_RHYTHM}px;
`;

function getItemStyles(
  initialOffset: XYCoord | null,
  currentOffset: XYCoord | null
) {
  if (!initialOffset || !currentOffset) {
    return {
      display: 'none'
    };
  }

  const { x, y } = currentOffset;

  const transform = `translate(${x - 5}px, ${y - 5}px)`;
  return {
    transform,
    WebkitTransform: transform
  };
}

function ReportDragLayer() {
  const {
    itemType,
    isDragging,
    item,
    initialOffset,
    currentOffset
  } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getClientOffset(),
    isDragging: monitor.isDragging()
  }));

  if (!isDragging || itemType !== itemTypes.REPORT) {
    return null;
  }

  const reportCount = item.selectedReports.length;

  return (
    <Wrapper>
      <div style={getItemStyles(initialOffset, currentOffset)}>
        <DraggedReport>
          <FolderIcon />
          <LabelWrapper>
            {`Move ${reportCount} report${reportCount > 1 ? 's' : ''}`}
          </LabelWrapper>
        </DraggedReport>
      </div>
    </Wrapper>
  );
}

export { ReportDragLayer };
