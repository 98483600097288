import * as React from 'react';
import { styled } from 'linaria/react';
import { WidgetChainProvider } from '@sevone/insight-connect';
import Fullscreen from '../../../../components/fullscreen';
import {
  useReportRuntime,
  useWidgetLinks,
  useWidgets
} from '../../../../report-runtime';
import { WidgetType } from '../../types';
import { WidgetHeader } from './widget-header';
import { WidgetVisualization } from './widget-visualization';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
  border-radius: 5px;
  overflow: hidden;
`;

// To indicate that we are editing a widget, we put a colored border + shadow around it.
// If we put that border directly on the Wrapper, the browser would need to re-calculate
// the style for the widget whenever we click to edit the widget, leading to lag for
// heavy widget renders. This is unavoidable even with React.memo/shouldComponentUpdate
// since that is only for React, but the browser would still recalculate style for
// the whole child DOM tree. Using sibling elements for the border is a bit of a hack
// but it gives a considerable performance improvement since it's a very light DOM change.
// The reason for splitting up the Shadow and Border components is that animating the
// opacity tends to perform better than using a transition on the box-shadow itself.
const ShadowSibling = styled.div<{active?: boolean}>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 1px solid;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  transition: opacity 100ms;
  opacity: ${(props) => (props.active ? '1' : '0')};
  box-shadow: 0px 0px 3px 1px var(--sev1-primary-5-color);
  border-color: ${(props) => (props.active ?
    'var(--sev1-primary-5-color)' : 'var(--sev1-primary-1-color)'
  )};
`;

const BorderSibling = styled.div<{active?: boolean}>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 1px solid;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  transition: border-color 100ms;
  border-color: ${(props) => (props.active ?
    'var(--sev1-primary-5-color)' : 'var(--sev1-primary-1-color)'
  )};
`;

const HeaderWrapper = styled.div`
  flex: none;
  background: var(--sev1-primary-3-color);
  color: var(--sev1-primary-3-contrast);
`;

const VisualizationWrapper = styled.div`
  box-sizing: border-box;
  flex: 1;
  display: flex;
  padding: 5px;
  overflow: auto;
  background: var(--sev1-primary-4-color);
  color: var(--sev1-primary-4-contrast);
`;

type ExportFnType = (mime: string) => Blob | Promise<Blob | null> | null;

export type Props = {
  isWritable: boolean,
  isEditing?: boolean,
  isMaximized?: boolean,
  widget: WidgetType,
  onEditWidget: (id: string) => void,
  onDeleteWidget: (id: string) => void
  onMaximizeWidget: (id: string | null) => void
  onFullscreenWidget: (id: string | null) => void
};

function Widget(props: Props) {
  const {
    isWritable,
    isEditing = false,
    isMaximized = false,
    widget,
    onEditWidget,
    onDeleteWidget,
    onMaximizeWidget,
    onFullscreenWidget
  } = props;
  const {
    getStack,
    setWidgetData,
    registerWidgetRefreshFunction,
    updateWidgetConfiguration
  } = useReportRuntime();
  const { getWidgetLinksByChild, getWidgetLinkByParent } = useWidgetLinks();
  const { broadcastFromWidget } = useWidgets();
  const [
    exportDataFn,
    setExportDataFn
  ] = React.useState<ExportFnType | null>(null);
  const [ isFullscreen, setIsFullscreen ] = React.useState<boolean>(false);
  const parentLinks = getWidgetLinkByParent(widget.id)?.children.map((c) => ({
    parentId: widget.id,
    childId: c.id
  })) || [];
  const childLinks = getWidgetLinksByChild(widget.id).map((link) => ({
    parentId: link.parentId,
    childId: widget.id
  }));
  const facetStack = getStack(widget.id);
  const isActive = isEditing && isWritable;

  // Memoizing this with JSON.stringify. Otherwise we would get a new array every
  // time and the memoization to prevent widgets from re-rendering unnecessarily
  // will always think that something has changed with the WidgetChainProvider.
  const chains = React.useMemo(() => {
    return parentLinks.concat(childLinks);
  }, [ JSON.stringify(parentLinks), JSON.stringify(childLinks) ]);

  const handleFullscreenWidget = (fullscreen: boolean) => {
    setIsFullscreen(fullscreen);
    if (fullscreen) {
      onFullscreenWidget(widget.id);
    } else {
      onFullscreenWidget(null);
    }
  };

  const handleRegisterExportDataFn = React.useCallback((fn: ExportFnType) => {
    // We're setting state here using the function notation because if we don't,
    // the state setter thinks the function we're registering is its function
    // notation, aka a `(state) => nextState` setter.
    setExportDataFn(() => fn);
  }, []);

  // Every widget should have a facet stack, so if there isn't one yet the
  // report runtime is still processing the widget. Wait until it finishes
  // before we do anything with the widget.
  if (!facetStack) {
    return null;
  }

  return (
    <WidgetChainProvider
      widgetId={widget.id}
      chains={chains}
    >
      <Wrapper>
        <ShadowSibling active={isActive} />
        <BorderSibling active={isActive} />
        <HeaderWrapper>
          <WidgetHeader
            isWritable={isWritable}
            isEditing={isEditing}
            isMaximized={isMaximized}
            widget={widget}
            facetStack={facetStack}
            exportDataFn={exportDataFn}
            onEditWidget={onEditWidget}
            onDeleteWidget={onDeleteWidget}
            onMaximizeWidget={onMaximizeWidget}
            onFullscreenWidget={handleFullscreenWidget}
          />
        </HeaderWrapper>
        <VisualizationWrapper>
          <Fullscreen enabled={isFullscreen} onChange={handleFullscreenWidget}>
            <WidgetVisualization
              type={widget.type.name}
              widget={widget}
              facetStack={facetStack}
              setConfig={updateWidgetConfiguration}
              setData={setWidgetData}
              broadcastFacet={broadcastFromWidget}
              registerExportDataFn={handleRegisterExportDataFn}
              registerWidgetRefreshFunction={registerWidgetRefreshFunction}
            />
          </Fullscreen>
        </VisualizationWrapper>
      </Wrapper>
    </WidgetChainProvider>
  );
}

export { Widget };
