import * as React from 'react';
import { ObjectGroupSelector, ObjectGroupType } from '@sevone/insight-connect';
import { RenderVariableProps } from '../../types';
import { MAX_RESOURCES_DISPLAYED } from '../utils';
import { useSingleSelection } from '../use-single-selection';
import { ConfigurationType, OptionType, ValueType } from './types';

type Props = RenderVariableProps<ConfigurationType, OptionType, ValueType>;

function Variable(props: Props) {
  const { value, options, config, onValueChange } = props;

  const getOptions = () => {
    if (config.selectAll) {
      return undefined;
    }

    return options.length > 0 ? options : undefined;
  };

  const handleChange = (values: Array<ObjectGroupType>) => {
    const nextValue = values.length ? {
      type: 'OBJECT_GROUP',
      resources: values
    } : null;

    onValueChange(nextValue);
  };

  const handleFetchValueError = () => {
    onValueChange(null);
  };

  const {
    isSingleOption,
    handleOptionsChange
  } = useSingleSelection(value?.resources, handleChange, [
    options,
    config.selectAll
  ]);

  return (
    <ObjectGroupSelector
      clearable={config.isClearable}
      disabled={isSingleOption}
      isMulti={config.isMulti && !isSingleOption}
      maxDisplayed={MAX_RESOURCES_DISPLAYED}
      datasources={config.datasources}
      value={value ? value.resources : []}
      objectGroupOptions={getOptions()}
      onChange={handleChange}
      onOptionsChange={handleOptionsChange}
      onFetchValueError={handleFetchValueError}
    />
  );
}

export { Variable };
