import * as React from 'react';
import { styled } from 'linaria/react';
import { LoadingCircle } from '@sevone/scratch';
import { Roles } from './roles';
import { Permissions } from './permissions';
import { UserType } from '../user-query';
import { RoleType, PermissionType } from '../roles-query';
import { HORIZONTAL_RHYTHM } from '../../../utils/spacing';

const Container = styled.div`
  display: flex;
  flex: 1;
`;
const CenteredMessage = styled.div`
  align-self: center;
`;
const Section = styled.div`
  display: flex;
  flex: 1;
  margin-left: ${HORIZONTAL_RHYTHM}px;
  justify-content: center;
  align-content: center;
`;

type Props = {
  selectedUser?: UserType,
  isLoading: boolean,
  currentRoles: Array<RoleType>,
  currentPermissions: Array<PermissionType>
  availableRoles: Array<RoleType>,
  onRoleSelect: (selectedRoleIds: Array<string>) => void
}

function Editor(props: Props) {
  const {
    selectedUser,
    isLoading,
    currentRoles,
    availableRoles,
    currentPermissions,
    onRoleSelect
  } = props;

  if (isLoading) {
    return (
      <Section>
        <CenteredMessage>
          <LoadingCircle size={'large'} />
        </CenteredMessage>
      </Section>
    );
  }

  if (!selectedUser) {
    return (
      <Section>
        <CenteredMessage>{'Please select a user.'}</CenteredMessage>
      </Section>
    );
  }

  return (
    <Container>
      <Section>
        <Roles
          currentRoles={currentRoles}
          availableRoles={availableRoles}
          onRoleSelect={onRoleSelect}
        />
      </Section>
      <Section>
        <Permissions
          currentPermissions={currentPermissions}
        />
      </Section>
    </Container>
  );
}

export { Editor };
