import { useState, useEffect } from 'react';
import { WidgetInterfaceType, WidgetMetaType } from '../../utils/wdk';
import { getWidgetInterface, getWidgetMeta } from './store';

function useWidgetFetcher(widgetType: string | null) {
  const [
    widgetInterface,
    setWidgetInterface
  ] = useState<WidgetInterfaceType | null>(null);
  const [
    widgetMeta,
    setWidgetMeta
  ] = useState<WidgetMetaType | null>(null);
  const [ isFetching, setIsFetching ] = useState(true);

  useEffect(() => {
    const fetchData = () => {
      if (widgetType === null) {
        setWidgetInterface(null);
        setWidgetMeta(null);
        setIsFetching(false);

        return;
      }

      setIsFetching(true);

      Promise.all([
        getWidgetInterface(widgetType),
        getWidgetMeta(widgetType)
      ]).then(([ wInterface, meta ]) => {
        setWidgetInterface(wInterface);
        setWidgetMeta(meta);
        setIsFetching(false);
      }).catch(() => {
        setWidgetInterface(null);
        setWidgetMeta(null);
        setIsFetching(false);
      });
    };

    fetchData();
  }, [ widgetType ]);

  return {
    isFetching,
    widgetInterface,
    widgetMeta
  };
}

export { useWidgetFetcher };
