import * as React from 'react';
import { styled } from 'linaria/react';
import castArray from 'lodash-es/castArray';
import { Switch } from '@sevone/scratch';
import { DatasourceSelector, ResourceSelection } from '@sevone/insight-connect';
import { VERTICAL_RHYTHM } from '../../../../../../utils/spacing';
import { ConfigureVariableProps } from '../../types';
import { ConfigurationType, OptionType, ValueType } from './types';
import { resourcePaths } from './resource-paths';

const Wrapper = styled.div``;

const SwitchWrapper = styled.div`
  display: flex;
  margin-bottom: ${VERTICAL_RHYTHM}px;
`;

const DatasourceWrapper = styled.div`
  margin-bottom: ${VERTICAL_RHYTHM}px;
`;

type Props = ConfigureVariableProps<ConfigurationType, OptionType, ValueType>;

function Configure(props: Props) {
  const { config, onConfigChange, onOptionsChange } = props;

  const handleMultiChange = (checked: boolean) => {
    onConfigChange({ isMulti: checked });
  };

  const handleClearableChange = (checked: boolean) => {
    onConfigChange({ isClearable: checked });
  };

  const handleSelectAllChange = (checked: boolean) => {
    onConfigChange({ selectAll: checked });
  };

  const handleDatasourceSelection = (datasources: Array<number>) => {
    onConfigChange({ datasources });
  };

  const handleResourceSelection = (
    datasources: Array<number>,
    value: ConfigurationType['value'],
    hierarchicalData: ConfigurationType['hierarchicalData']
  ) => {
    onConfigChange({
      datasources: castArray(datasources),
      hierarchicalData,
      value
    });

    if (value && value.type === 'DEVICE') {
      onOptionsChange(value.resources);
    } else {
      onOptionsChange([]);
    }
  };

  return (
    <Wrapper>
      <SwitchWrapper>
        <Switch checked={config.isMulti} onChange={handleMultiChange}>
          {'Multiple selection'}
        </Switch>
      </SwitchWrapper>
      <SwitchWrapper>
        <Switch checked={config.isClearable} onChange={handleClearableChange}>
          {'Clear selection'}
        </Switch>
      </SwitchWrapper>
      <SwitchWrapper>
        <Switch checked={config.selectAll} onChange={handleSelectAllChange}>
          {'Select all'}
        </Switch>
      </SwitchWrapper>
      {config.selectAll &&
        <DatasourceWrapper>
          <DatasourceSelector
            isMulti
            label="Datasources"
            value={config.datasources}
            onChange={handleDatasourceSelection}
          />
        </DatasourceWrapper>
      }
      {!config.selectAll &&
        <ResourceSelection
          isMultiDatasource
          allowedPaths={resourcePaths}
          datasource={config.datasources}
          value={config.value}
          hierarchicalData={config.hierarchicalData}
          onChange={handleResourceSelection}
        />
      }
    </Wrapper>
  );
}

export { Configure };
