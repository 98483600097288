import * as React from 'react';
import { styled } from 'linaria/react';
import { useNotification } from '@sevone/insight-connect';
import { Input, Select, Button, Message } from '@sevone/scratch';
import { PageSidebar } from '../../../components/page/page-sidebar';
import { useGql } from '../../../hooks/use-gql';
import { VERTICAL_RHYTHM, HORIZONTAL_RHYTHM } from '../../../utils/spacing';
import { FolderType } from '../get-folders.query';
import {
  UPDATE_FOLDER,
  UpdateFolderResponseType
} from './update-folder.mutation';
import {
  CREATE_FOLDER,
  CreateFolderResponseType
} from './create-folder.mutation';

const MAX_NAME_LENGTH = 50;

const PERSONAL_FOLDER = {
  label: 'Personal',
  value: -1
};

const Wrapper = styled.div`
  margin: 0 ${HORIZONTAL_RHYTHM}px;
`;

const ControlWrapper = styled.div`
  margin-bottom: ${VERTICAL_RHYTHM}px;
`;

const ButtonsWrapper = styled(ControlWrapper)`
  text-align: right;

  & > * {
    margin-left: ${HORIZONTAL_RHYTHM}px;
  }
`;

type Props = {
  folder: FolderType | null,
  folders: Array<FolderType>,
  parentId: number | null,
  onSaveFolder: () => void,
  onClose: () => void
};

function FolderPanel(props: Props) {
  const { folder, folders, parentId, onSaveFolder, onClose } = props;
  const parentFolder = folders.find((f) => f.id === parentId) || null;
  const { showNotification } = useNotification();
  const {
    runGql: updateFolder
  } = useGql<UpdateFolderResponseType>(UPDATE_FOLDER);
  const {
    runGql: createFolder
  } = useGql<CreateFolderResponseType>(CREATE_FOLDER);
  const [ name, setName ] = React.useState(folder ? folder.name : '');
  const [ parent, setParent ] = React.useState<{
    label: string,
    value: number
  }>(!parentFolder ? PERSONAL_FOLDER : {
    label: parentFolder.name,
    value: parentFolder.id
  });
  const folderOptions = [
    PERSONAL_FOLDER,
    ...folders.map((f) => ({
      label: f.name,
      value: f.id
    }))
  ];
  const nameLengthError = name.length > MAX_NAME_LENGTH;

  const handleSave = () => {
    const saveFn = folder ? updateFolder : createFolder;
    const sameName = folders.filter((f) => {
      return f.name.toLowerCase() === name.toLowerCase();
    }).filter((f) => {
      return !folder || f.id !== folder.id;
    });
    const input = {
      id: folder ? folder.id : null,
      folder: {
        name,
        parentId: parent.value === PERSONAL_FOLDER.value ? null : parent.value
      }
    };

    if (sameName.length > 0) {
      showNotification({
        type: 'error',
        message: 'A folder with this name already exists.'
      });
      return Promise.reject();
    }

    // @ts-ignore: Looks like there's a bug with the `useGql` promise type
    // and handling multiple possible resolve values in `then()`.
    return saveFn(input).then(() => {
      onSaveFolder();
      onClose();
    }).catch((e: { message: string }) => {
      showNotification({ type: 'error', message: e.message });
      return Promise.reject();
    });
  };

  return (
    <PageSidebar
      title={`${folder ? 'Edit' : 'Create'} Folder`}
      onClose={onClose}
    >
      <Wrapper>
        <ControlWrapper>
          <Input
            status={nameLengthError ? 'error' : undefined}
            label={'Name'}
            value={name}
            onChange={setName}
          />
        </ControlWrapper>
        <ControlWrapper>
          <Select
            label={'Nest under'}
            value={parent}
            options={folderOptions}
            // @ts-ignore: The `Select` typing is overly vague and assumes it's
            // always possible to return an `Array<OptionType>` even when
            // `isMulti` is disabled.
            onChange={setParent}
          />
        </ControlWrapper>
        <ButtonsWrapper>
          <Button type="outlined" onClick={onClose}>{'Cancel'}</Button>
          <Button
            disabled={!name || nameLengthError}
            onClick={handleSave}
          >
            {folder ? 'Save' : 'Create'}
          </Button>
        </ButtonsWrapper>
        {nameLengthError &&
          <ControlWrapper>
            <Message type="error">
              {`
                Folder names can be no more than ${MAX_NAME_LENGTH} characters.
              `}
            </Message>
          </ControlWrapper>
        }
      </Wrapper>
    </PageSidebar>
  );
}

export { FolderPanel };
