
import { Log } from 'oidc-client';
import * as queryString from 'querystring';
import {
  oidcRedirectEnabled
} from '../../global';

/* custom RedirectNavigator used to inject a state-key into the uri
 * to workaround IdP-initiated flows not including one */
class CallbackNavigator {
  prepare() {
    return Promise.resolve(this);
  }

  navigate(params:any) {
    if (!params || !params.url) {
      Log.error('CallbackNavigator.navigate: No url provided');
      return Promise.reject(new Error('No url provided'));
    }
    if (params.useReplaceToNavigate) {
      window.location.replace(params.url);
    } else {
      window.location = params.url;
    }
    return Promise.resolve();
  }

  get url() {
    const { search } = window.location;
    const query = search ? queryString.parse(search.substring(1)) : {};
    if (!oidcRedirectEnabled && !query.state) {
      query.state = '0'; // missing state key
      const newSearch = queryString.stringify(query);
      const { origin, pathname, hash } = window.location;
      return `${origin}${pathname}?${newSearch}${hash}`;
    }
    return window.location.href;
  }
}

export { CallbackNavigator };
