import * as React from 'react';
import { styled } from 'linaria/react';
import { Redirect } from 'react-router-dom';
import { useNavigation } from '@sevone/insight-connect';
import { LoadingCircle } from '@sevone/scratch';
import { useReportFetcher } from '../../hooks/use-report-fetcher';
import { ReportRuntime } from '../../report-runtime';
import { ReportNotFound } from './report-not-found';
import { ReportViewer } from './report-viewer';

const LoadingWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

function Report() {
  const { location, match } = useNavigation();
  const {
    report,
    fetchingReport
  } = useReportFetcher(match.params.id, {
    useDefault: true,
    defaultOverrides: {
      folderId: parseInt(location.params.folder)
    }
  });

  if (fetchingReport) {
    return (
      <LoadingWrapper><LoadingCircle size="large" /></LoadingWrapper>
    );
  }

  if (!report) {
    return <ReportNotFound />;
  }

  const { section: sectionId } = match.params;
  const defaultSectionId = report.content.sections[0].id;
  const redirectLocation = {
    pathname: `/reports/${match.params.id}/${defaultSectionId}`,
    search: `?${new URLSearchParams(location.params).toString()}`,
    hash: location.hash,
    state: location.state
  };

  // If there is no requested section, redirect to the first one
  if (!sectionId) {
    return <Redirect to={redirectLocation} />;
  }

  return (
    <ReportRuntime key={report.id} report={report}>
      <ReportViewer />
    </ReportRuntime>
  );
}

export { Report };
