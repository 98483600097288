import * as React from 'react';
import { SidebarContext } from './context';

function useSidebar() {
  const sidebarContext = React.useContext(SidebarContext);

  return sidebarContext;
}

export { useSidebar };
