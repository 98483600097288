import * as React from 'react';
import { styled } from 'linaria/react';
import {
  IconButton,
  Table,
  AngleRightIcon,
  AngleLeftIcon
} from '@sevone/scratch';
import { VERTICAL_RHYTHM } from '../../../../utils/spacing';

const ColmunSelectorContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  height: inherit;
  flex: 1;
  overflow: hidden;
`;
const ColumnSelectorArrowsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5px;
`;
const ColumnSelectorArrows = styled.div`
  font-size: calc(var(--sev1-size) * 1.5);
`;
const TableWrapper = styled.div`
  padding: 10px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  & > label {
    font-size: calc(var(--sev1-size) * .75);
  }
  & > div {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 150px;
    max-height: inherit;
    overflow-y: auto;
  }
`;
const VerticalDivider = styled.div`
  border: 0.5px solid;
  border-color: var(--sev1-primary-1-color);
  width: 0;
  flex: 1;
  margin: ${VERTICAL_RHYTHM}px 0px;
`;

type TableProps = React.ComponentProps<typeof Table>;
type ColumnsType = TableProps['columns'];
type RowsType = TableProps['rows'];
type Props = {
  availableRows: RowsType,
  selectedRows: Array<string | number>,
  availableColumns: ColumnsType,
  selectedColumns: ColumnsType,
  leftLabel: string,
  rightLabel: string,
  onAdd: (id: Array<number | string>) => void,
  onRemove: (id: Array<number | string>) => void,
  searchTerm?: string,
  pagination?: boolean
  pageSize?: number
}
type Column = 'leftSelectedIds' | 'rightSelectedIds';

function ColumnSelector(props: Props) {
  const { availableRows, selectedRows, searchTerm, onAdd, onRemove,
    availableColumns, leftLabel, rightLabel, selectedColumns, pagination,
    pageSize = 50 } = props;
  const [ leftSelectedIds, setLeftSelectedIds ] = React.useState<Array<number | string>>([]);
  const [ rightSelectedIds, setRightSelectedIds ] = React.useState<Array<number | string>>([]);
  const [ leftPage, setLeftPage ] = React.useState(1);
  const [ rightPage, setRightPage ] = React.useState(1);

  const handleCalcAvaibleRows = () => {
    const selectedIds = new Set(selectedRows);
    let filteredRows = availableRows.filter((row) => !selectedIds.has(row.id));
    if (searchTerm) {
      filteredRows = filteredRows.filter((row) => row.name.toLowerCase().includes(searchTerm.toLowerCase()));
    }
    return filteredRows;
  };

  const handleCalcSelectedRows = () => {
    const selectedIds = new Set(selectedRows);
    let filteredRows = availableRows.filter((row) => selectedIds.has(row.id));
    if (searchTerm) {
      filteredRows = filteredRows.filter((row) => row.name.toLowerCase().includes(searchTerm.toLowerCase()));
    }
    return filteredRows;
  };

  const handleOnRowSelect = (selectedIds: Array<number | string>, column: Column) => {
    if (column === 'leftSelectedIds') {
      setLeftSelectedIds(selectedIds);
    } else {
      setRightSelectedIds(selectedIds);
    }
  };

  const handleMoveRight = () => {
    onAdd(leftSelectedIds);
    setLeftSelectedIds([]);
  };

  const handleMoveLeft = () => {
    onRemove(rightSelectedIds);
    setRightSelectedIds([]);
  };

  return (
    <ColmunSelectorContainer data-test-id={'column-selector'}>
      <TableWrapper data-test-id={'column-selector-table-left'}>
        <span>{leftLabel}</span>
        <div data-test-id={'column-selector-left-table'}>
          <Table
            columns={availableColumns}
            rows={handleCalcAvaibleRows()}
            selectedRows={leftSelectedIds}
            onRowSelection={(selectedIds) => handleOnRowSelect(selectedIds, 'leftSelectedIds')}
            page={pagination ? leftPage : undefined}
            onPageChange={pagination ? setLeftPage : undefined}
            pageSize={pageSize}
          />
        </div>
      </TableWrapper>
      <ColumnSelectorArrowsWrapper>
        <VerticalDivider />
        <ColumnSelectorArrows data-test-id={'column-selector-move-right'}>
          <IconButton
            disabled={leftSelectedIds.length < 1}
            onClick={handleMoveRight}
            data-test-id={'col-move-right'}
          >
            <AngleRightIcon />
          </IconButton>
        </ColumnSelectorArrows>
        <ColumnSelectorArrows data-test-id={'column-selector-move-left'}>
          <IconButton
            disabled={rightSelectedIds.length < 1}
            onClick={handleMoveLeft}
            data-test-id={'col-move-left'}
          >
            <AngleLeftIcon />
          </IconButton>
        </ColumnSelectorArrows>
        <VerticalDivider />
      </ColumnSelectorArrowsWrapper>
      <TableWrapper data-test-id={'column-selector-table-right'}>
        <span >{rightLabel}</span>
        <div data-test-id={'column-selector-right-table'}>
          <Table
            columns={selectedColumns}
            rows={handleCalcSelectedRows()}
            selectedRows={rightSelectedIds}
            onRowSelection={(selectedIds) => handleOnRowSelect(selectedIds, 'rightSelectedIds')}
            page={pagination ? rightPage : undefined}
            onPageChange={pagination ? setRightPage : undefined}
            pageSize={pageSize}
          />
        </div>
      </TableWrapper>
    </ColmunSelectorContainer>
  );
}

export { ColumnSelector };
