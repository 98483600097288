const GET_TIMEZONES = `
  query {
    timezones {
      displayName
      timezone
      offset
    }
  }
`;

export type GqlTimezoneType = {
  displayName: string,
  timezone: string,
  offset: number
};

export type GetTimezonesResponseType = {
  timezones: Array<GqlTimezoneType>
};

export { GET_TIMEZONES };
