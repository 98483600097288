import * as React from 'react';
import {
  Badge,
  Button,
  ButtonGroup,
  ShapesIcon,
  AddIcon
} from '@sevone/scratch';
import { useReportVariables } from '../../../../report-runtime';

type Props = {
  isWritable: boolean,
  isPanelVisible: boolean,
  onShowReportVariablesPalette: () => void,
  onToggleReportVariablesPanel: (visible: boolean) => void
};

function VariablesMenu(props: Props) {
  const {
    isWritable,
    isPanelVisible,
    onShowReportVariablesPalette,
    onToggleReportVariablesPanel
  } = props;
  const { variables } = useReportVariables();
  const numOfVariables = Object.keys(variables).length;

  const togglePanel = () => {
    onToggleReportVariablesPanel(!isPanelVisible);
  };

  if (!isWritable && !numOfVariables) {
    return null;
  }

  const btn = (
    <span
      title={`${isPanelVisible ? 'Hide' : 'Show'} report variables panel`}
    >
      <ButtonGroup>
        <Button
          type="outlined"
          prefixIcon={<ShapesIcon />}
          onClick={togglePanel}
        >
          {'Variables'}
        </Button>
        {isWritable &&
          <Button type="outlined" onClick={onShowReportVariablesPalette}>
            <AddIcon />
          </Button>
        }
      </ButtonGroup>
    </span>
  );

  if (!isPanelVisible && numOfVariables) {
    return <Badge position="topLeft" type="info">{btn}</Badge>;
  }

  return btn;
}

export { VariablesMenu };
