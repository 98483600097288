const defaultTheme = {
  size: '16px',
  primary1: {
    color: '#666666',
    contrast: '#FCFCFC'
  },
  primary2: {
    color: '#171717',
    contrast: '#FCFCFC'
  },
  primary3: {
    color: '#333333',
    contrast: '#FCFCFC'
  },
  primary4: {
    color: '#2b2b2b',
    contrast: '#FCFCFC'
  },
  primary5: {
    color: '#3498dc',
    contrast: '#FCFCFC'
  },
  primary6: {
    color: '#c1c1c1',
    contrast: '#333333'
  },
  primary7: {
    color: '#cccccc',
    contrast: '#666666'
  },
  primary8: {
    color: '#000000',
    contrast: '#cccccc'
  },
  primary9: {
    color: 'rgba(89, 128, 199, .4)',
    contrast: '#FCFCFC'
  },
  secondary1: {
    color: '#086eb3',
    contrast: '#ffffff'
  },
  secondary2: {
    color: '#4368aa',
    contrast: '#ffffff'
  },
  secondary3: {
    color: 'rgba(60, 60, 60, 0.85)',
    contrast: '#cccccc'
  },
  secondary4: {
    color: '#606060',
    contrast: '#ccc'
  },
  secondary5: {
    color: '#cccccc',
    contrast: '#000000'
  },
  error: {
    color: '#eb422b',
    contrast: '#ffffff'
  },
  warning: {
    color: '#fede5b',
    contrast: '#333333'
  },
  info: {
    color: '#3498dc',
    contrast: '#ffffff'
  },
  confirmation: {
    color: '#1bbb9c',
    contrast: '#ffffff'
  }
};

export { defaultTheme };
