import * as React from 'react';
import { styled } from 'linaria/react';
import keyBy from 'lodash-es/keyBy';
import { request } from '@sevone/insight-connect';
import { Button, AddIcon, AccordionSection, Accordion, InlineEdit, EditIcon } from '@sevone/scratch';
import { Link } from './link';
import { ReportLinkingConfigType, ReportLinkType } from './types';
import { HORIZONTAL_RHYTHM } from '../../../utils/spacing';
import { LinkColumn } from '../report-linking-manager';


const ToolbarWrapper = styled.div`
  margin: ${HORIZONTAL_RHYTHM}px 0px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const EditNameIconWrapper = styled.div`
  margin-left: ${HORIZONTAL_RHYTHM}px;
  opacity: 0;
  transition: opacity .1s ease-in;
`;

const LinkTitle = styled.div`
  display: flex;
  align-items: center;
  overflow-x: hidden;
`;

const NameWrapper = styled.div`
  display: flex;
  min-width: 10ch;
  position: relative;
  z-index: 1;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: -.2em;
    bottom: 0;
    left: -.2em;
    z-index: -1;
    border-radius: 2px;
    transition: background .1s ease-in;
  }

  &:hover {
    &:before {
      background: var(--sev1-primary-1-color);
    }
  }
  // @ts-ignore: linaria TS is missing typing for component interpolations
  &:hover ${EditNameIconWrapper} {
    // @ts-ignore For some reason TS keeps complaining here about having an
    // any type, while still displaying its type.
    opacity: 1;
  }
`;

type Props = {
  reportLinking: ReportLinkingConfigType,
  onDelete: (id: number | undefined) => void,
  onChange: (value: ReportLinkingConfigType) => void,
};

type State = {
  reportMap: {
    [key: string]: {
      id: number,
      name: string,
      isTemplate: boolean
    }
  },
  fetchingReports: boolean,
  expandedSections: string[],
};

class ReportLinkEditor extends React.Component<Props, State> {
  state = {
    reportMap: {},
    fetchingReports: false,
    expandedSections: []
  };

  componentDidMount() {
    this.fetchReports();
  }

  fetchReports() {
    const query = `{
      reports {
        id
        name
        isTemplate
      }
    }`;

    this.setState({ fetchingReports: true });

    return request.query(query, {}).then((response) => {
      const reports = (response.data.data.reports || []);

      this.setState({
        reportMap: keyBy(reports, 'id'),
        fetchingReports: false
      });
    });
  }

  getColumnMap(columnOptions: Array<{ value: string, label: string }>) {
    const columnMap: { [value: string]: string } = {};

    columnOptions.forEach(({ value, label }) => {
      columnMap[value] = label;
    });

    return columnMap;
  }

  handleLinkChange = (link: ReportLinkType, idx: number) => {
    const { reportLinking, onChange } = this.props;
    const { reportLinks } = reportLinking;
    const nextLinks = [ ...reportLinks ];
    nextLinks[idx] = link;

    onChange({
      ...reportLinking,
      reportLinks: nextLinks
    });
  }

  handleAddLink = () => {
    const { reportLinking, onChange } = this.props;
    const { reportLinks } = reportLinking;
    const nextLinks = [ ...reportLinks, {
      id: undefined,
      name: 'Untitled Global Report Link',
      column: null,
      reportIds: [],
      filters: [],
      enabled: true
    } ];

    onChange({
      ...reportLinking,
      reportLinks: nextLinks
    });
  }

  handleDeleteLink = (idx: number) => {
    const { reportLinking, onChange, onDelete } = this.props;
    const { reportLinks } = reportLinking;
    const nextLinks = [ ...reportLinks ];
    nextLinks.splice(idx, 1);

    onDelete(reportLinks[idx].id);

    onChange({
      ...reportLinking,
      reportLinks: nextLinks
    });
  }

  handleExpand = (id: string) => {
    this.setState({ expandedSections: [ ...this.state.expandedSections, id ] });
  }

  handleCollapse = (id: string) => {
    this.setState({ expandedSections: this.state.expandedSections.filter((i) => i !== id) });
  }

  render() {
    const { reportLinking } = this.props;
    const { reportMap, fetchingReports, expandedSections } = this.state;

    return (
      <div style={{ width: '100%' }}>
        <div style={{ backgroundColor: 'var(--sev1-primary-4-color)' }}>
          <Accordion
            expanded={expandedSections}
            onExpand={this.handleExpand}
            onCollapse={this.handleCollapse}
          >
            {reportLinking.reportLinks.map((link, idx) => (
              <AccordionSection
                key={link.id ?? `Untitled${idx}`}
                id={link.id ? link.id.toString() : `Untitled${idx}`}
                title={(
                  <LinkTitle>
                    <NameWrapper>
                      <span style={{ overflowX: 'hidden' }} onClick={(e) => e.stopPropagation()}>
                        <InlineEdit
                          value={link.name ?? `Global Report Link ${idx}`}
                          disabled={false}
                          multiLine={false}
                          onBlur={(value) => this.handleLinkChange({ ...link, name: value }, idx)}
                        />
                      </span>
                      <EditNameIconWrapper>
                        <EditIcon />
                      </EditNameIconWrapper>
                    </NameWrapper>
                  </LinkTitle>
                )}
              >
                <Link
                  key={idx}
                  id={link.id}
                  name={link.name}
                  fetchingReports={fetchingReports}
                  availableReports={reportMap}
                  column={link.column || LinkColumn.DEVICE}
                  filters={link.filters || []}
                  selectedReports={link.reportIds}
                  enabled={link.enabled}
                  onChange={(reportLink) => this.handleLinkChange(reportLink, idx)}
                  onDelete={() => this.handleDeleteLink(idx)}
                />
              </AccordionSection>
            ))}
          </Accordion>
        </div>
        <ToolbarWrapper>
          <Button onClick={this.handleAddLink} prefixIcon={<AddIcon />}>
            {'Add Link'}
          </Button>
        </ToolbarWrapper>
      </div>
    );
  }
}

export default ReportLinkEditor;
