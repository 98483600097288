/* eslint-disable camelcase */
import { UserManager, WebStorageStateStore } from 'oidc-client';
import { CallbackNavigator } from './callback-navigator';
import { LocalStorageStateStore } from './local-storage-state-store';
import {
  oidcEnabled,
  oidcAuthority,
  oidcClientId,
  oidcClientSecret,
  oidcResponseType,
  oidcScope
} from '../../global';

// OpenID settings
const openidConfig = {
  authority: oidcAuthority,
  client_id: oidcClientId,
  client_secret: oidcClientSecret,
  response_type: oidcResponseType,
  scope: oidcScope,
  redirect_uri: `${window.location.origin}/callback`,
  loadUserInfo: false,
  redirectNavigator: new CallbackNavigator(),
  userStore: new WebStorageStateStore({}),
  stateStore: new LocalStorageStateStore({})
};

// OpenID client singleton (use getOidcClient)
let oidcClient:UserManager;
function getOidcClient() {
  if (!oidcEnabled) {
    throw new Error('OpenID Connect support is disabled.');
  }
  if (!oidcClient) {
    oidcClient = new UserManager(openidConfig);
  }
  return oidcClient;
}

// redirect to OpenID signin page, preserving redirectUrl
function openidSigninRedirect(redirectUrl:string) {
  return getOidcClient()
    .signinRedirect({
      state: { redirectUrl }
    });
}

// trigger redirect to OpenID logout endpoint, providing logoutUrl
function openidSignoutRedirect(logoutUrl?:string) {
  return getOidcClient()
    .signoutRedirect({
      post_logout_redirect_uri: logoutUrl
    });
}

// handle redirect callback from OpenID signin page
function openidCallback(
  onSuccess:(openid:{ id_token:string, state:any }) => any | Promise<any>
) {
  return getOidcClient()
    .signinRedirectCallback()
    .then(onSuccess, (error:any) => {
      throw new Error(error);
    });
}

export { openidSigninRedirect, openidSignoutRedirect, openidCallback };
