import { RawReportType } from './types';

const GET_REPORT_QUERY = `
  query($id: Int) {
    reports(id: $id) {
      id
      name
      description
      updatedAt
      refreshInterval
      rotateInterval
      owner {
        id
        username
      }
      folder {
        id
        name
      }
      acl {
        id
        ownerId
        isPublic
        isReadOnly
        entries {
          sid {
            id
          }
          read
          write
        }
      }
      content
      isWritable
      isTemplate
    }
  }
`;

export type GetReportResponseType = {
  data: {
    data: {
      reports: Array<RawReportType<string>>
    }
  }
};

export { GET_REPORT_QUERY };
