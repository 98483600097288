const PRINT_SESSION_QUERY = `
  query($sessionId: String!) {
    printSession(sessionId: $sessionId) {
      content
      sessionPayload
    }
  }
`;

export type PrintSessionResponseType = {
  printSession: {
    content: string,
    sessionPayload: string | null
  } | null
};

export { PRINT_SESSION_QUERY };
