import * as React from 'react';
import { messages } from '@sevone/insight-wdk';
import { VisualizationRuntime } from '@sevone/insight-widget-runtime';
import { debug } from '../../../../utils/logger';
import { WidgetType, FacetStackType } from '../../types';
import { WidgetLoader } from '../widget-loader';

type ExportFnType = (mime: string) => Blob | Promise<Blob | null> | null;

type Props = {
  type: string,
  widget: WidgetType,
  facetStack: FacetStackType,
  setConfig: (widgetId: string, config: Record<string, any>) => void,
  setData: (widgetId: string, data: any) => void,
  broadcastFacet: (widgetId: string, payload: any) => void,
  registerExportDataFn: (fn: ExportFnType) => void,
  registerWidgetRefreshFunction: (widgetId: string, fn: () => void) => void
};

function RawWidgetVisualization(props: Props) {
  const {
    type,
    widget,
    facetStack,
    setConfig,
    setData,
    broadcastFacet,
    registerExportDataFn,
    registerWidgetRefreshFunction
  } = props;
  // We want to strip out any null facets, essentially removing those schemas
  // from the widget's list of known facets to process.
  const facets = facetStack.list().filter((facet) => {
    return facet.data !== null;
  });

  const handleMessage = (message: string, payload: any) => {
    if (message === messages.broadcastFacet) {
      debug('widget facet', payload);
      broadcastFacet(widget.id, payload);
    } else if (message === messages.updateConfiguration) {
      setConfig(widget.id, payload);
    } else if (message === messages.setData) {
      setData(widget.id, payload);
    }
  };

  const handleRegisteringRefreshFunction = (refresh: () => void) => {
    registerWidgetRefreshFunction(widget.id, refresh);
  };

  return (
    <WidgetLoader type={type}>
      {(widgetInterface, meta) => {
        if (!widgetInterface || !meta) {
          return null;
        }

        return (
          <VisualizationRuntime
            meta={meta}
            widget={widgetInterface}
            configuration={widget.configuration}
            facets={facets}
            registerRefreshFunction={handleRegisteringRefreshFunction}
            registerDataExportFunction={(registerExportDataFn)}
            onConfigurationChange={(payload) => {
              setConfig(widget.id, payload);
            }}
            onMessage={handleMessage}
          />
        );
      }}
    </WidgetLoader>
  );
}

const WidgetVisualization = React.memo(RawWidgetVisualization);

export { WidgetVisualization };
