import * as React from 'react';
import { Page, PageHeader, PageTitle } from '../../components/page';


function NotFound() {
  return (
    <Page title={'Not Found'}>
      <PageHeader>
        <PageTitle>{'404: Page Not Found'}</PageTitle>
      </PageHeader>
    </Page>
  );
}

export { NotFound };
