import * as React from 'react';
import { styled } from 'linaria/react';
import trimEnd from 'lodash-es/trimEnd';
import { ArrowRightIcon } from '@sevone/scratch';
import { VERTICAL_RHYTHM, HORIZONTAL_RHYTHM } from '../../../../utils/spacing';
import { baseUrl, basePath } from '../../../../utils/base-location';

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed var(--sev1-primary-1-color);
`;

const Content = styled.div`
  max-width: 400px;
  text-align: center;
  padding: ${VERTICAL_RHYTHM}px ${HORIZONTAL_RHYTHM}px;
`;

const IconWrapper = styled.div`
  font-size: 6em;
`;

const Header = styled.div`
  font-size: 2em;
  font-style: italic;
  margin-bottom: ${VERTICAL_RHYTHM}px;
`;

const SubHeader = styled.div`
  font-size: 1.2em;
`;

const GuideLink = styled.a`
  color: var(--sev1-primary-5-color);
  text-decoration: underline;
`;

function EmptyPlaceholder() {
  const base = `${baseUrl}${trimEnd(basePath, '/')}`;
  const guideUrl = `${base}/assets/user-guide.pdf#page=21`;

  return (
    <Wrapper>
      <Content>
        <IconWrapper><ArrowRightIcon /></IconWrapper>
        <Header>{'Select a widget on your right to begin your report'}</Header>
        <SubHeader>
          {'Need help creating a widget? '}
          <GuideLink href={guideUrl} target="_blank">{'Click here'}</GuideLink>
          {`
            to learn more or read the User Guide located within the user-menu
            whenever you need help.
          `}
        </SubHeader>
      </Content>
    </Wrapper>
  );
}

export { EmptyPlaceholder };
