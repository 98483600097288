import debounce from 'lodash-es/debounce';
import zipObject from 'lodash-es/zipObject';
import {
  facetManager,
  FacetType,
  FacetSchema,
  FacetStack
} from '@sevone/insight-wdk';
import { debug } from '../../utils/logger';

type FacetContainerType = {
  id: string,
  priority: number,
  dependencies?: Array<string>,
  stack: FacetStack
};

type ContainerStoreType = Record<string, Omit<FacetContainerType, 'dependencies'>>;

type DependencyListType = Record<string, Array<string>>;

type ContainerPrioritiesType = Array<string>;

type ListenerType = (containers: Array<FacetContainerType>) => void;

export type FacetManagerExportType = {
  containerStore: Array<Omit<FacetContainerType, 'stack'> & {
    facets: ReturnType<FacetStack['list']>
  }>
};

const containerStore: ContainerStoreType = {};
const dependencyList: DependencyListType = {};
const stackCache: Record<string, FacetStack | undefined> = {};
let containerPriorities: ContainerPrioritiesType = [];
let listeners: Array<ListenerType> = [];

function getContainers() {
  return Object.values(containerStore);
}

function getContainer(id: string) {
  return containerStore[id] || null;
}

function getDependencies(id: string) {
  return dependencyList[id] || [];
}

const triggerListeners = debounce(() => {
  const containers = getContainers();

  listeners.forEach((listener) => {
    listener(containers);
  });
}, 300);

function addFacet(id: string, facet: FacetType) {
  debug('add facet', id, facet);

  if (!containerStore[id]) {
    // Allow facets to be added to a container before its been registered.
    // We'll ignore its values for now by giving it a low priority. Once the
    // container is officially registered it'll retain these initial facets.
    containerStore[id] = {
      id,
      priority: -1,
      stack: facetManager.createFacetStack()
    };
  }

  containerStore[id].stack.add(facet);

  triggerListeners();
}

function buildStack(id: string) {
  const container = getContainer(id);
  const dependencies = getDependencies(id);
  const cachedStack = stackCache[id];
  debug('buildStack', id);

  dependencies.forEach(buildStack);

  if (
    cachedStack &&
    // Check for any dependencies that are newer than this. If there are, we
    // need to rebuild.
    Math.max(...dependencies.map((dep) => {
      // `stackCache[dep]` should never be undefined because we just built all
      // the dependencies right before this. But TS doesn't know that, so
      // supply a fallback value anyways.
      return stackCache[dep]?.lastUpdated || 0;
    // Because we're using `Date` under the hood to track timing and we don't
    // get exact numbers from it, it's possible a stack will have a
    // `lastUpdated` equal to its most recent dependency if the builds both
    // happened very close together. This will cause an unnecessary cache miss
    // and rebuild of the stack. We could check for equality, but for the sake
    // of rounding errors we play it safe here and accept the extra rebuild.
    })) < cachedStack.lastUpdated &&
    (container?.stack?.lastUpdated || 0) < cachedStack.lastUpdated
  ) {
    debug('buildStack cache', id);
    return cachedStack;
  }

  // Use for easy lookup when going through the priority list so we know
  // which of the containers are a dependency we need to include
  const dependencyMap: Record<string, boolean> =
    zipObject(dependencies, Array(dependencies.length).fill(true));
  const stack = containerPriorities.reduce((acc, curr) => {
    let facets: Array<FacetType> = [];

    if (dependencyMap[curr]) {
      facets = stackCache[curr]?.list() || [];
    } else if (id === curr) {
      facets = container?.stack?.list() || [];
    }

    return [ ...acc, ...facets ];
  }, []);

  debug('buildStack build', id);
  stackCache[id] = facetManager.createFacetStack(stack, {
    schemaBlacklist: container?.stack?.getBlacklist(),
    schemaWhitelist: container?.stack?.getWhitelist()
  });

  return stackCache[id];
}

function buildAllStacks() {
  debug('build stacks', { containerStore, dependencyList });

  Object.keys(containerStore).forEach(buildStack);

  // Make sure we return a new object for comparison's sake.
  return { ...stackCache };
}

function addDependencies(id: string, dependencies: Array<string>) {
  // By checking if this id is already registered or not we can prebuild
  // dependencies for any given container, regardless if it's registered yet.
  // While in practice this isn't likely to be necessary, it gives us safety
  // when it comes to the order we register containers.
  if (dependencyList[id]) {
    dependencyList[id] = [ ...new Set([
      ...dependencyList[id],
      ...dependencies
    ]) ];
  } else {
    dependencyList[id] = [ ...dependencies ];
  }

  triggerListeners();
}

function removeDependencies(id: string, dependencies: Array<string>) {
  if (dependencyList[id]) {
    dependencyList[id] = dependencyList[id].filter((item) => {
      return !dependencies.includes(item);
    });
  } else {
    dependencyList[id] = [];
  }

  triggerListeners();
}

function addSchemaToBlacklist(id: string, schema: FacetSchema) {
  if (!containerStore[id]) {
    return;
  }

  containerStore[id].stack.addToBlacklist(schema);

  triggerListeners();
}

function removeSchemaFromBlacklist(id: string, schema: FacetSchema) {
  if (!containerStore[id]) {
    return;
  }

  containerStore[id].stack.removeFromBlacklist(schema);

  triggerListeners();
}

function addSchemaToWhitelist(id: string, schema: FacetSchema) {
  if (!containerStore[id]) {
    return;
  }

  containerStore[id].stack.addToWhitelist(schema);

  triggerListeners();
}

function removeSchemaFromWhitelist(id: string, schema: FacetSchema) {
  if (!containerStore[id]) {
    return;
  }

  containerStore[id].stack.removeFromWhitelist(schema);

  triggerListeners();
}

function unregisterFacetContainer(id: string) {
  delete containerStore[id];
  delete dependencyList[id];
  containerPriorities = containerPriorities.filter((cId) => cId !== id);
}

function registerFacetContainer(
  container: Omit<FacetContainerType, 'stack'>,
  initialFacets: Array<FacetType> = [],
  opts: {
    schemaBlacklist?: Array<FacetSchema>,
    schemaWhitelist?: Array<FacetSchema>
  } = {}
) {
  if (containerStore[container.id]) {
    debug('register container', 'id collision', container.id, {
      ...containerStore
    });

    // We need to keep any pre-existing facets that were added for this
    // container before it was registered.
    containerStore[container.id] = {
      id: container.id,
      priority: container.priority,
      stack: facetManager.createFacetStack([
        ...initialFacets,
        ...containerStore[container.id].stack.list()
      ], opts)
    };
  } else {
    containerStore[container.id] = {
      id: container.id,
      priority: container.priority,
      stack: facetManager.createFacetStack(initialFacets, opts)
    };
  }

  // Make sure every container has a dependency list. But, it's possible it was
  // already created if one of its dependencies were already registered. In that
  // case we don't want to overwrite it.
  if (!dependencyList[container.id]) {
    dependencyList[container.id] = [];
  }

  if (container.dependencies) {
    addDependencies(container.id, container.dependencies);
  }

  // Keep a sorted order of priorities so we don't need to do this every read
  const nextPriorities = Object.values(containerStore).sort((a, b) => {
    if (a.priority < b.priority) {
      return -1;
    }

    if (a.priority > b.priority) {
      return 1;
    }

    return 0;
  }).map(({ id }) => id).reverse();

  containerPriorities = nextPriorities;

  triggerListeners();

  return {
    unregister: () => {
      unregisterFacetContainer(container.id);
      triggerListeners();
    }
  };
}

function subscribe(callback: ListenerType) {
  listeners.push(callback);

  const unsubscribe = () => {
    listeners = listeners.filter((listener) => listener !== callback);
  };

  return { unsubscribe };
}

function exportData(): FacetManagerExportType {
  const containerExport = getContainers().map((container) => ({
    id: container.id,
    priority: container.priority,
    dependencies: dependencyList[container.id],
    facets: container.stack.list()
  }));

  return {
    containerStore: containerExport
  };
}

function importData(data: FacetManagerExportType) {
  data.containerStore.forEach(({ facets, ...container }) => {
    if (!containerStore[container.id]) {
      return;
    }

    containerStore[container.id].stack = facetManager.createFacetStack(facets);
  });

  triggerListeners();
}

export {
  subscribe,
  addFacet,
  addSchemaToBlacklist,
  removeSchemaFromBlacklist,
  addSchemaToWhitelist,
  removeSchemaFromWhitelist,
  addDependencies,
  removeDependencies,
  buildStack,
  buildAllStacks,
  registerFacetContainer,
  exportData,
  importData
};
