import * as React from 'react';
import { styled } from 'linaria/react';
import { Sidebar } from './sidebar';
import { Footer } from './footer';

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  z-index: 1;
  background: var(--sev1-primary-3-color);
  color: var(--sev1-primary-3-contrast);
`;

const ContentWrapper = styled.div`
  flex: 1;
  display: flex;
  overflow: hidden;
`;
const FooterWrapper = styled.div`
  flex: none;
`;

const NavWrapper = styled.div`
  flex: none;
`;

const PageWrapper = styled.div`
  flex: 1;
  overflow: auto;
`;

type Props = {
  isAuthenticated: boolean,
  // Apparently there's a TS bug in regards to functional components
  // and { children: React.ReactNode }
  // https://stackoverflow.com/a/54908762
  children: any
};

function Shell(props: Props) {
  const { isAuthenticated, children } = props;

  if (!isAuthenticated) {
    return (
      <Container>
        <ContentWrapper>
          {children}
        </ContentWrapper>
        <FooterWrapper>
          <Footer />
        </FooterWrapper>
      </Container>
    );
  }

  return (
    <Container>
      <ContentWrapper>
        <NavWrapper>
          <Sidebar />
        </NavWrapper>
        <PageWrapper>
          {children}
        </PageWrapper>
      </ContentWrapper>
      <FooterWrapper>
        <Footer />
      </FooterWrapper>
    </Container>

  );
}

export { Shell };
