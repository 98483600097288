const GET_TIMEZONES = `
  query timezones {
    timezones {
      displayName
      timezone
      offset
    }
  }
`;

export type GetTimezonesResponseType = {
  timezones: Array<{
    displayName: string,
    timezone: string,
    offset: number
  }>
};

export { GET_TIMEZONES };
