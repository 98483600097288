import * as React from 'react';
import { Input } from '@sevone/scratch';
import { DeviceTypeSelector } from './device-type-selector';
import { ObjectTypeSelector } from './object-type-selector';
import { ResourceValueType, ValueSelectorOnChange, SimpleValueOnChange } from './types';

type ValueType = string | ResourceValueType;

type Props = {
  onChange: ValueSelectorOnChange,
  value: ValueType,
  column: string,
  field: string,
  operator: string
};

type SelectorProps = {
  label?: string,
  onChange: ValueSelectorOnChange,
  value: ValueType,
  [other: string]: any
};

type OperatorMapType = { [operator: string]: React.ComponentType<SelectorProps> };
type FieldMapType = { [field: string]: OperatorMapType };
type ColumnMapType = { [column: string]: FieldMapType };

const selectorPath: ColumnMapType = {
  DEVICE: {
    inDeviceTypePaths: {
      includes: DeviceTypeSelector
    }
  },
  OBJECT: {
    objectTypePaths: {
      includes: ObjectTypeSelector
    }
  }
};

export function ValueSelector(props: Props) {
  const { column, field, operator, value } = props;
  const Selector = selectorPath?.[column]?.[field]?.[operator];

  if (Selector) {
    return (
      <Selector
        label={'Value'}
        value={value}
        onChange={props.onChange}
      />
    );
  }

  // Default to plain text input
  return (
    <Input
      label={'Value'}
      value={typeof props.value === 'string' ? props.value : ''}
      onChange={props.onChange as SimpleValueOnChange}
    />
  );
}
