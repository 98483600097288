const CREATE_FOLDER = `
  mutation ($folder: FolderInput!) {
    createFolder(folder: $folder) {
      id
    }
  }
`;

export type CreateFolderResponseType = {
  createFolder: {
    id: number
  }
};

export { CREATE_FOLDER };
