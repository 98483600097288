export const TENANTS_QUERY = `
  query($id: Int) {
    tenants(id: $id) {
      datasources {
        id
        name
        address
        dstype
      }
    }
  }
`;

export type TenantType = {
  datasources: Array<{
    id: number,
    name: string,
    address: string,
    dstype: string
  }>
}

export type TenantsResponseType = { tenants: Array<TenantType> }
