export const ADD_USER_ROLE = `
  mutation M($userId: Int!, $roleId: Int!) {
    addUserRole(userId: $userId, roleId: $roleId) {
      id
    }
  }
`;

export const REMOVE_USER_ROLE = `
  mutation M($userId: Int!, $roleId: Int!) {
    removeUserRole(userId: $userId, roleId: $roleId) {
      id
    }
  }
`;
