import * as React from 'react';
import { styled } from 'linaria/react';
import { Card, Table } from '@sevone/scratch';
import { PermissionType } from '../../roles-query';
import { VERTICAL_RHYTHM } from '../../../../utils/spacing';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin ${VERTICAL_RHYTHM}px 0px;
`;
const TableWrapper = styled.div`
  height: 100%;
`;

type Props = {
  currentPermissions: Array<PermissionType>
}

const COLUMNS = [
  {
    id: 'name',
    title: 'Name',
    render: (data: PermissionType) => data.name,
    sort: (row: PermissionType, otherRow: PermissionType): number => {
      const { name } = row;
      const { name: otherName } = otherRow;
      if (name < otherName) {
        return -1;
      }
      if (name > otherName) {
        return 1;
      }
      return 0;
    }
  }
];

function Permissions(props: Props) {
  const { currentPermissions } = props;
  return (
    <Container>
      <Card title={'Permissons'}>
        <TableWrapper>
          <Table
            rows={currentPermissions}
            columns={COLUMNS}
          />
        </TableWrapper>
      </Card>
    </Container>
  );
}

export { Permissions };
