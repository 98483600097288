import * as React from 'react';
import { styled } from 'linaria/react';
import { useNavigation } from '@sevone/insight-connect';
import {
  Checkbox,
  Input,
  Button,
  LoadingCircle
} from '@sevone/scratch';
import { ColumnSelector } from '../columns-selector';
import { ThemeType } from '../../queries/get-themes.query';
import { HORIZONTAL_RHYTHM } from '../../../../utils/spacing';
import { useDebounce } from '../../../../hooks/use-debounce';

const ContentContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  min-width: 590px;
`;
const ContentWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const LoadingWrapper = styled.div`
  align-self: center;
`;
const ActionBarWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
const SearchWrapper = styled.div`
  flex: 1;
  padding-right: ${HORIZONTAL_RHYTHM}px;
`;
const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

type Props = {
  tenantId?: number,
  availableThemes: Array<ThemeType>,
  selectedThemes: Array<number | string>,
  isLoading: boolean,
  onAddThemes: (ids: Array<number | string>) => void,
  onRemoveThemes: (ids: Array<number | string>) => void,
  defaultThemeId?: number,
  setDefaultThemeId: (id: number) => void
}

function Themes(props: Props) {
  const navigation = useNavigation();
  const { tenantId, availableThemes, selectedThemes, onAddThemes, onRemoveThemes,
    defaultThemeId, setDefaultThemeId, isLoading } = props;
  const [ searchTerm, setSearchTerm ] = React.useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 200);

  const Loading = (
    <LoadingWrapper>
      <LoadingCircle size={'large'} />
    </LoadingWrapper>
  );

  return (
    <ContentContainer>
      {isLoading ? Loading :
        <ContentWrapper>
          <ActionBarWrapper>
            <SearchWrapper>
              <Input
                onChange={setSearchTerm}
                value={searchTerm}
                placeholder={'Search themes...'}
              />
            </SearchWrapper>
            <Button onClick={() => navigation.navigateTo('/config/themes')}>
              {'Create New Theme'}
            </Button>
          </ActionBarWrapper>
          <ColumnSelector
            key={tenantId}
            leftLabel={'Available Themes'}
            rightLabel={'Active Themes'}
            onAdd={onAddThemes}
            onRemove={onRemoveThemes}
            availableRows={availableThemes}
            selectedRows={selectedThemes}
            availableColumns={[
              {
                id: 'name',
                title: 'Name',
                width: '150px',
                render: (data: ThemeType) => data.name
              }
            ]}
            selectedColumns={[
              {
                id: 'name',
                title: 'Name',
                width: '150px',
                render: (data: ThemeType) => data.name
              },
              {
                id: 'default',
                title: 'Default',
                render: (data: ThemeType) => {
                  return (
                    <CheckboxWrapper onClick={(event) => event.stopPropagation()}>
                      <Checkbox
                        onChange={() => setDefaultThemeId(data.id)}
                        checked={data.id === defaultThemeId}
                      />
                    </CheckboxWrapper>
                  );
                }
              }
            ]}
            searchTerm={debouncedSearchTerm}
            pagination
          />
        </ContentWrapper>
      }
    </ContentContainer>
  );
}

export { Themes };
