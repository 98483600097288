import * as React from 'react';
import {
  DropdownButton,
  DropdownMenu,
  DropdownItem,
  PresentationIcon
} from '@sevone/scratch';
import { useReportRuntime } from '../../../../report-runtime';

const ROTATE_INTERVALS = [
  {
    label: 'Off',
    condensedLabel: 'Off',
    value: null
  },
  {
    label: '30 seconds',
    condensedLabel: '30s',
    value: 30000
  },
  {
    label: '1 minute',
    condensedLabel: '1m',
    value: 60000
  },
  {
    label: '2 minutes',
    condensedLabel: '2m',
    value: 120000
  },
  {
    label: '5 minutes',
    condensedLabel: '5m',
    value: 300000
  },
  {
    label: '10 minutes',
    condensedLabel: '10m',
    value: 600000
  }
];

function RotateMenu() {
  const { report, updateRotateInterval, rotateTabs } = useReportRuntime();
  const [ menuVisibililty, setMenuVisibility ] = React.useState(false);
  const selectedInterval = ROTATE_INTERVALS.find((interval) => {
    return report && interval.value === report.rotateInterval;
  }) || ROTATE_INTERVALS[0];

  return (
    <DropdownButton
      type="outlined"
      prefixIcon={<PresentationIcon />}
      trigger={[ 'click' ]}
      position="bottomRight"
      disabled={!!report.refreshInterval}
      visible={menuVisibililty}
      menu={(
        <DropdownMenu>
          {ROTATE_INTERVALS.map((interval) => (
            <DropdownItem
              key={interval.label}
              disabled={interval.value === selectedInterval.value}
              onClick={() => { updateRotateInterval(interval.value); }}
            >
              {interval.label}
            </DropdownItem>
          ))}
        </DropdownMenu>
      )}
      onVisiblityChange={setMenuVisibility}
      onClick={rotateTabs}
    >
      {selectedInterval.condensedLabel}
    </DropdownButton>
  );
}

export { RotateMenu };
