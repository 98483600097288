import { useState, useEffect, useCallback } from 'react';
import cloneDeep from 'lodash-es/cloneDeep';
import {
  useNavigation,
  timespanSchema,
  generateTimespanFacet
} from '@sevone/insight-connect';
import { facetManager, FacetStack } from '@sevone/insight-wdk';
import { uuid } from '../utils/uuid';
import { createContainer } from '../utils/create-container';
import { useGql } from '../hooks/use-gql';
import {
  FacetType,
  ReportType,
  SectionType,
  WidgetType,
  LayoutType,
  ReportVariableType
} from '../pages/report/types';
import {
  subscribe,
  buildAllStacks,
  registerFacetContainer,
  addFacet,
  exportData as exportFacetManagerData,
  importData as importFacetManagerData,
  FacetManagerExportType,
  CONTAINER_TYPES,
  TYPE_PRIORITIES,
  DEPENDENCY_LIST
} from './facet-manager';
import { ReportVariableManager } from './report-variable-manager';
import { ReportLinkController } from './report-link-controller';
import { WidgetLinkManager } from './widget-link-manager';
import { WidgetManager } from './widget-manager';
import { useReportModifier } from './use-report-modifier';
import {
  WIDGET_DEFAULT_WIDTH,
  WIDGET_DEFAULT_HEIGHT,
  WIDGET_DEFAULT_X,
  WIDGET_DEFAULT_Y
} from '../pages/report/report-viewer/widget-grid/constants';
import {
  SAVE_REPORT,
  SaveReportResponseType
} from './save-report.mutation';
import {
  CREATE_REPORT,
  CreateReportResponseType
} from './create-report.mutation';
import {
  DELETE_REPORT,
  DeleteReportResponseType
} from './delete-report.mutation';

type WidgetRefreshFunctionsType = Record<string, () => void>;

type StateType = {
  report: ReportType
};

export type DataExportType = {
  report: ReportType,
  activeSectionId: number | null,
  facetManager: FacetManagerExportType
}

function useReportStore(initialState: StateType) {
  const { location, match, navigateTo } = useNavigation();
  // We don't need to store this specifically in state to trigger an update
  // because any change here will be preceded by an update from `useNavigation`.
  const activeSectionId = parseInt(match.params.section);
  const activeWidgetId = match.params.widget;
  const activeWidgetMaximized = location.params.maximized === 'true';
  // We can't drive fullscreen without user consent, so just track if any widget is currently fullscreened
  const [ fullscreenWidget, setFullscreenWidget ] = useState<boolean>(false);
  const reportVariables = ReportVariableManager.useContainer();
  const reportLinks = ReportLinkController.useContainer();
  const {
    getWidgetLink,
    getWidgetLinkByParent,
    getWidgetLinksByChild,
    createWidgetLink,
    deleteWidgetLink,
    clearWidgetLinks,
    addChildToWidgetLink,
    removeChildFromWidgetLink
  } = WidgetLinkManager.useContainer();
  const {
    registerWidget,
    unregisterWidget,
    clearWidgets
  } = WidgetManager.useContainer();
  const {
    runGql: doCreateReport
  } = useGql<CreateReportResponseType>(CREATE_REPORT);
  const {
    runGql: doSaveReport
  } = useGql<SaveReportResponseType>(SAVE_REPORT);
  const {
    runGql: doDeleteReport
  } = useGql<DeleteReportResponseType>(DELETE_REPORT);
  const [ report, setReport ] = useState(initialState.report);
  const [
    stacks,
    setStacks
  ] = useState<Record<string, FacetStack | undefined>>({});
  const [
    widgetRefreshFunctions,
    setWidgetRefreshOptions
  ] = useState<WidgetRefreshFunctionsType>({});
  const [ widgetDataMap, setWidgetDataMap ] = useState<Record<string, any>>({});
  const {
    report: dirtyReport,
    editReport,
    isDirty: reportIsDirty,
    edits: reportEdits,
    cleanReport
  } = useReportModifier(report);
  const activeReport: ReportType = dirtyReport;

  const createReport = (input: {
    name?: string,
    isTemplate?: boolean
  } = {}) => {
    const newReport = {
      name: activeReport.name,
      description: activeReport.description,
      folderId: activeReport.folder ? activeReport.folder.id : null,
      refreshInterval: activeReport.refreshInterval,
      rotateInterval: activeReport.rotateInterval,
      isTemplate: activeReport.isTemplate,
      content: JSON.stringify(activeReport.content),
      ...input
    };

    return doCreateReport({ input: newReport }).then((res) => {
      cleanReport();
      navigateTo(`/reports/${res.createReport.id}`);
      return Promise.resolve();
    });
  };

  const saveReport = (input: {
    isTemplate?: boolean
  } = {}) => {
    const nextReport = {
      name: activeReport.name,
      description: activeReport.description,
      isTemplate: activeReport.isTemplate,
      refreshInterval: activeReport.refreshInterval,
      rotateInterval: activeReport.rotateInterval,
      content: JSON.stringify(activeReport.content)
    };

    if (activeReport.id === 'new-report') {
      return createReport(input);
    }

    return doSaveReport({
      id: activeReport.id,
      input: nextReport
    }).then(() => {
      setReport(activeReport);
      cleanReport();
      return Promise.resolve();
    });
  };

  const renameReport = (name: string) => {
    if (!name) {
      return;
    }

    editReport({ type: 'renameReport', payload: name });
  };

  const updateTimespan = (timespan: ReportType['content']['timespan']) => {
    editReport({ type: 'updateTimespan', payload: timespan });

    // Only necessary until we update the timespan facet's schema to remove
    // the 'label' field.
    const modified = timespan && 'timespan' in timespan ? {
      ...timespan,
      label: ''
    } : timespan;

    reportLinks.addLink(generateTimespanFacet(modified));
  };

  const updateRefreshInterval = (interval: ReportType['refreshInterval']) => {
    editReport({ type: 'updateRefreshInterval', payload: interval });
  };

  const updateRotateInterval = (interval: ReportType['rotateInterval']) => {
    editReport({ type: 'updateRotateInterval', payload: interval });
  };

  const copyReport = (
    name: string = `${activeReport.name} Copy`,
    isTemplate: boolean = false
  ) => {
    return createReport({ name, isTemplate });
  };

  const deleteReport = () => {
    return doDeleteReport({ ids: [ activeReport.id ] }).then((res) => {
      if (res.deleteReport.length === 1) {
        navigateTo('/reports');
        return Promise.resolve();
      }

      return Promise.reject({
        message: 'Could not delete report. Report is currently used in link.'
      });
    });
  };

  const getStack = (id: string) => {
    return stacks[id] || null;
  };

  const getSection = (id: number) => {
    return activeReport.content.sections
      .find((section) => section.id === id) || null;
  };

  const getSections = () => {
    return activeReport.content.sections;
  };

  const getWidget = (id: string | null) => {
    const widgets = activeReport.content.sections
      .map((section) => section.widgets)
      .reduce((acc, curr) => acc.concat(curr));

    return widgets.find((widget) => widget.id === id) || null;
  };

  const getWidgetSection = (id: string | null): SectionType | null => {
    const sections = activeReport.content.sections.filter((s) => s.widgets.some((w) => w.id === id));
    return sections[0] ?? null;
  };

  const getWidgets = (sectionId: number) => {
    const section = getSection(sectionId);

    if (!section) {
      return [];
    }

    return section.widgets;
  };

  const addSection = (): SectionType => {
    const sections = getSections();
    const ids = sections.map((section) => section.id);
    const nextId = Math.max(...ids) + 1;
    const nextSection: SectionType = {
      id: nextId,
      title: `Untitled Section ${sections.length + 1}`,
      layout: [],
      widgetLinks: [],
      widgets: []
    };

    editReport({
      type: 'addSection',
      payload: nextSection
    });

    return nextSection;
  };

  const deleteSection = (id: number) => {
    editReport({ type: 'deleteSection', payload: id });
  };

  const renameSection = (
    sectionId: SectionType['id'],
    title: SectionType['title']
  ) => {
    if (!title) {
      return;
    }

    editReport({ type: 'renameSection', payload: { sectionId, title } });
  };

  const updateSectionLayout = (
    sectionId: SectionType['id'],
    layout: SectionType['layout']
  ) => {
    editReport({ type: 'updateSectionLayout', payload: { sectionId, layout } });
  };

  const reorderSections = (order: Array<number>) => {
    editReport({ type: 'reorderSections', payload: order });
  };

  const updateActiveSection = (sectionId: number) => {
    const target = {
      pathname: `/reports/${report.id}/${sectionId}`,
      params: location.params,
      hash: location.hash,
      state: location.state
    };

    navigateTo(target);
  };

  const updateActiveWidget = (
    sectionId: number,
    widgetId: string | null,
    isMaximized: boolean = false
  ) => {
    const pathname = widgetId !== null ? `/reports/${report.id}/${sectionId}/${widgetId}` :
      `/reports/${report.id}/${sectionId}`;
    const { maximized, ...params } = location.params;
    if (widgetId !== null) {
      params.maximized = isMaximized;
    }
    const target = {
      pathname,
      params,
      hash: location.hash,
      state: location.state
    };

    navigateTo(target);
  };

  const rotateTabs = () => {
    const sections = getSections();
    const sectionsLength = sections.length;
    // Find where in the list of sections we are
    const activeSectionIndex = sections.map((section) => section.id).indexOf(activeSectionId);
    // Go to the next section in the list of sections (not necessarily the next section id)
    const nextSectionIndex = ((activeSectionIndex + 1) >= sectionsLength) ? 0 : activeSectionIndex + 1;
    const nextSectionId = sections[nextSectionIndex]?.id;
    updateActiveSection(nextSectionId);
  };

  const setWidgetData = useCallback((id: string, data: any) => {
    setWidgetDataMap((cur) => ({ ...cur, [id]: data }));
  }, []);

  const getWidgetData = (id: string) => {
    return widgetDataMap[id];
  };

  const registerWidgetRefreshFunction = useCallback(
    (id: string, refresh: () => void) => {
      setWidgetRefreshOptions((cur) => ({ ...cur, [id]: refresh }));
    }, []
  );

  const unregisterWidgetRefreshFunction = (id: string) => {
    const { [id]: refresh, ...keptRefresh } = widgetRefreshFunctions;

    setWidgetRefreshOptions(keptRefresh);
  };

  const getNewWidgetDefaultLayout = (sectionId: number, widgetId: string) => {
    const section = getSection(sectionId);

    if (!section) {
      return [];
    }

    return [ ...section.layout, {
      i: widgetId,
      w: WIDGET_DEFAULT_WIDTH,
      h: WIDGET_DEFAULT_HEIGHT,
      x: WIDGET_DEFAULT_X,
      y: WIDGET_DEFAULT_Y
    } ];
  };

  const addWidget = (
    sectionId: number,
    widget: WidgetType,
    layout?: Array<LayoutType>
  ) => {
    registerWidget(widget);
    editReport({
      type: 'addWidget',
      payload: {
        sectionId,
        layout: layout || getNewWidgetDefaultLayout(sectionId, widget.id),
        widget
      }
    });
  };

  const deleteWidget = (id: string) => {
    const section = activeReport.content.sections.find((s) => {
      return s.widgets.find((widget) => widget.id === id);
    });

    if (!section) {
      return;
    }

    const parentLink = getWidgetLinkByParent(id);
    const childLinks = getWidgetLinksByChild(id);

    if (parentLink) {
      deleteWidgetLink(parentLink.id);
      // Child widgets chained to a widget are removed along with the parent
      parentLink.children.forEach((child) => {
        if (child.chain) {
          deleteWidget(child.id);
        }
      });
    }
    childLinks.forEach((link) => { removeChildFromWidgetLink(link.id, id); });
    unregisterWidgetRefreshFunction(id);
    unregisterWidget(id);
    editReport({
      type: 'deleteWidget',
      payload: {
        sectionId: section.id,
        widgetId: id
      }
    });
  };

  const renameWidget = (
    widgetId: WidgetType['id'],
    name: WidgetType['name']
  ) => {
    const section = getSections().find((s) => {
      return s.widgets.find((widget) => widget.id === widgetId);
    });

    if (!section) {
      return;
    }

    editReport({
      type: 'renameWidget',
      payload: { sectionId: section.id, widgetId, name }
    });
  };

  const updateWidgetConfiguration = useCallback((
    widgetId: WidgetType['id'],
    configuration: WidgetType['configuration']
  ) => {
    editReport({
      type: 'updateWidgetConfiguration',
      payload: {
        widgetId,
        configuration
      }
    });
  }, []);

  const getWidgetLayout = (widgetId: string) => {
    const section = getSections().find((s) => {
      return getWidgets(s.id).find((widget) => widget.id === widgetId);
    });

    if (!section) {
      return null;
    }

    const widgetLayout = section.layout.find((item) => item.i === widgetId);

    if (!widgetLayout) {
      return null;
    }

    return widgetLayout;
  };

  const copyWidget = (sectionId: number, widgetId: string) => {
    const targetSection = getSection(sectionId);
    const originalWidget = getWidget(widgetId);

    if (!targetSection || !originalWidget) {
      return;
    }

    const findWidgetName = (
      name: string,
      attempt: number,
      widgets: Array<WidgetType>
    ): string => {
      const potentialName = `${name}${attempt ? ` (${attempt})` : ''}`;
      if (!widgets.some((w) => w.name === potentialName)) {
        return potentialName;
      }

      return findWidgetName(name, attempt + 1, widgets);
    };

    const widgets = getWidgets(sectionId);
    const id = uuid();
    const widget = {
      ...originalWidget,
      id,
      name: findWidgetName(originalWidget.name, 0, widgets)
    };
    const originalLayout = getWidgetLayout(widgetId);

    if (!originalLayout) {
      return;
    }

    const { w, h, x, y } = originalLayout;
    const originalSection = getWidgetSection(originalWidget.id);
    const sameSection = originalSection && originalSection.id === targetSection.id;
    const newLayout: LayoutType = {
      i: id,
      w,
      h,
      x: sameSection ? x : WIDGET_DEFAULT_X,
      y: sameSection ? y : WIDGET_DEFAULT_Y
    };
    const layout = [ ...targetSection.layout, newLayout ];

    addWidget(sectionId, widget, layout);
  };

  const refreshWidget = (widgetId: string) => {
    const refresh = widgetRefreshFunctions[widgetId];

    if (refresh) {
      refresh();
    }
  };

  const refreshWidgets = () => {
    Object.keys(widgetRefreshFunctions).forEach((widgetId) => {
      refreshWidget(widgetId);
    });
  };

  const addWidgetLink = (
    sectionId: number,
    parentId: string,
    widgetId: string,
    chain: boolean
  ) => {
    const link = getWidgetLinkByParent(parentId);
    const section = getSection(sectionId);

    if (!section) {
      return;
    }

    if (link) {
      addChildToWidgetLink(link.id, widgetId, chain);
      editReport({
        type: 'addWidgetToLink',
        payload: { sectionId: section.id, linkId: link.id, widgetId, chain }
      });
    } else {
      const createdLink = createWidgetLink({
        parentId,
        children: [ { id: widgetId, chain } ]
      });
      editReport({
        type: 'addWidgetLink',
        payload: { sectionId: section.id, link: createdLink }
      });
    }
  };

  const deleteChildFromWidgetLink = (
    sectionId: number,
    linkId: string,
    childId: string
  ) => {
    const link = getWidgetLink(linkId);
    const section = getSection(sectionId);

    if (!link || !section) {
      return;
    }

    removeChildFromWidgetLink(linkId, childId);
    editReport({
      type: 'deleteWidgetLink',
      payload: {
        sectionId: section.id,
        linkId,
        widgetId: childId
      }
    });
  };

  const addWidgetChain = (
    sectionId: number,
    parentId: string,
    widgetType: string,
    options: {
      title?: string
    } = {}
  ) => {
    const opts = {
      ...{
        title: 'Chained Widget'
      },
      ...options
    };
    const section = getSection(sectionId);

    if (!section) {
      return;
    }

    const widget = {
      id: uuid(),
      name: opts.title,
      type: {
        name: widgetType
      },
      configuration: {}
    };

    let layout: Array<LayoutType> | undefined;
    const originalLayout = getWidgetLayout(parentId);
    if (originalLayout) {
      const { w, h, x, y } = originalLayout;
      layout = [ ...section.layout, {
        i: widget.id,
        w,
        h,
        x,
        y: y + 1
      } ];
    }

    addWidget(sectionId, widget, layout);
    addWidgetLink(sectionId, parentId, widget.id, true);
  };

  const addReportVariable = (variable: {
    label: ReportVariableType['label'],
    schema: ReportVariableType['schema'],
    value: ReportVariableType['value'],
    options: ReportVariableType['options'],
    config: ReportVariableType['config']
  }) => {
    const createdVariable = reportVariables.create(variable);

    editReport({ type: 'addReportVariable', payload: createdVariable });
  };

  const updateReportVariable = (variable: ReportVariableType) => {
    editReport({ type: 'updateReportVariable', payload: variable });
    reportVariables.updateVariables([ variable ]);
  };

  const deleteReportVariable = (id: string) => {
    editReport({ type: 'deleteReportVariable', payload: id });
    reportVariables.deleteVariables([ id ]);
  };

  const reorderReportVariables = (order: Array<string>) => {
    editReport({ type: 'reorderReportVariables', payload: order });
    reportVariables.updateOrder(order);
  };

  const updateReportVariableValue = (id: string, value: FacetType['data']) => {
    const variable = reportVariables.getVariable(id);

    if (!variable) {
      return;
    }

    reportVariables.addValue(facetManager.createFacet(variable.schema, value));
    editReport({
      type: 'updateReportVariableValue',
      payload: {
        variableId: id,
        value
      }
    });
  };

  const exportData = (): DataExportType => {
    return {
      report: activeReport,
      activeSectionId,
      facetManager: exportFacetManagerData()
    };
  };

  const importData = (data: DataExportType) => {
    // The facet manager mutates data, so from here on make sure we don't
    // accidentally have any side effecs on the data passed in.
    const clonedData = cloneDeep(data);

    let unsubscribe: (() => void) | null = null;
    return new Promise((resolve) => {
      unsubscribe = subscribe(() => {
        resolve();
      }).unsubscribe;

      importFacetManagerData(clonedData.facetManager);
    }).then(() => {
      unsubscribe?.();
    });
  };

  // On initial mount
  useEffect(() => {
    // Setup the report's timespan
    const { unregister: unregisterTimespan } = registerFacetContainer({
      id: CONTAINER_TYPES.reportTimespan,
      priority: TYPE_PRIORITIES.reportTimespan,
      dependencies: DEPENDENCY_LIST.reportTimespan
    }, [], { schemaWhitelist: [ timespanSchema ] });

    // Add the initial report variables that are saved to this report
    activeReport.content.variables.forEach((variable) => {
      reportVariables.create(variable);
    });

    // Build the entire facet stack system every time a change is made.
    const { unsubscribe } = subscribe(() => {
      setStacks(buildAllStacks());
    });

    return () => {
      unregisterTimespan();
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    const { timespan } = report.content;
    // Only necessary until we update the timespan facet's schema to remove
    // the 'label' field.
    const modified = timespan && 'timespan' in timespan ? {
      ...timespan,
      label: ''
    } : timespan;

    addFacet(
      CONTAINER_TYPES.reportTimespan,
      generateTimespanFacet(modified)
    );
  }, [ report.content.timespan ]);

  // When switching between sections
  useEffect(() => {
    const section = getSection(activeSectionId);

    if (!section) {
      return;
    }

    clearWidgetLinks();
    (section.widgetLinks || []).forEach((link) => {
      createWidgetLink(link);
    });

    clearWidgets();
    section.widgets.forEach((widget) => {
      registerWidget(widget);
    });
  }, [ activeSectionId ]);

  // Handling a report's refresh interval (automatic widget refreshing)
  useEffect(() => {
    if (!activeReport.refreshInterval) {
      return () => {};
    }

    const refreshTracker = window.setInterval(
      refreshWidgets,
      activeReport.refreshInterval
    );

    return () => {
      clearInterval(refreshTracker);
    };
  }, [ widgetRefreshFunctions, activeReport.refreshInterval ]);

  // Handling a report's refresh interval (automatic widget refreshing)
  useEffect(() => {
    if (!activeReport.rotateInterval || activeWidgetMaximized || fullscreenWidget) {
      return () => {};
    }

    const rotateTracker = window.setInterval(
      rotateTabs,
      activeReport.rotateInterval
    );

    return () => {
      clearInterval(rotateTracker);
    };
  }, [
    activeSectionId,
    getSections().map((section) => section.id).join(','),
    activeReport.rotateInterval,
    activeWidgetMaximized,
    fullscreenWidget
  ]);

  return {
    report: activeReport,
    activeSectionId,
    activeWidgetId,
    activeWidgetMaximized,
    stacks,
    isDirty: reportIsDirty,
    reportEdits,
    saveReport,
    renameReport,
    updateTimespan,
    updateRefreshInterval,
    updateRotateInterval,
    copyReport,
    deleteReport,
    setReport,
    setStacks,
    getStack,
    getSection,
    getSections,
    addSection,
    deleteSection,
    renameSection,
    updateSectionLayout,
    reorderSections,
    updateActiveSection,
    updateActiveWidget,
    getWidget,
    getWidgets,
    setWidgetData,
    setFullscreenWidget,
    getWidgetData,
    rotateTabs,
    registerWidgetRefreshFunction,
    unregisterWidgetRefreshFunction,
    addWidget,
    deleteWidget,
    renameWidget,
    updateWidgetConfiguration,
    copyWidget,
    refreshWidget,
    refreshWidgets,
    addWidgetChain,
    addWidgetLink,
    deleteChildFromWidgetLink,
    addReportVariable,
    updateReportVariable,
    deleteReportVariable,
    reorderReportVariables,
    updateReportVariableValue,
    exportData,
    importData
  };
}

const ReportStore = createContainer(useReportStore);

export { ReportStore };
