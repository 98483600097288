import { styled } from 'linaria/react';

const H2 = styled.h2`
  margin: 0;
  font-size: 1.2rem;
  font-weight: 600;
`;

const H3 = styled.h3`
  margin: 0;
  font-size: 1rem;
  font-weight: 600;
`;

const P = styled.p`
  font-size: 0.75rem;
  margin-bottom: 1.5em;
  margin-top: 0;
`;

export { H2, H3, P };
