import * as React from 'react';
import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { ReportType } from '../get-reports.query';
import { itemTypes } from './item-types';

export type DragItemType = {
  type: typeof itemTypes.REPORT,
  selectedReports: Array<ReportType['id']>
}

type Props = {
  disabled?: boolean,
  selectedReports: Array<ReportType['id']>,
  className?: string,
  children: React.ReactNode
};

function ReportDragSource(props: Props) {
  const { disabled, selectedReports, className, children } = props;
  const [ , drag, preview ] = useDrag<DragItemType, null, {}>({
    item: {
      type: itemTypes.REPORT,
      selectedReports
    },
    canDrag: !disabled
  });

  React.useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, []);

  return (
    <div ref={drag} className={className}>
      {children}
    </div>
  );
}

export { ReportDragSource };
