const SET_SCHEDULE = `
  mutation setReportSchedule($reportId: Int!, $schedule: ReportScheduleArgs!) {
    setReportSchedule(reportId: $reportId, settings: $schedule) {
      startDate
      timezone
      periodFrequency
      periodType
      emailDelivery
      emailRecipients
      ftpDelivery
      lastPrintStatus
      lastPrintTime
    }
  }
`;

export type SetScheduleResponseType = {
  setReportSchedule: {
    startDate: number,
    timezone: string,
    periodFrequency: number,
    periodType: 'HOURLY' | 'DAILY' | 'WEEKLY' | 'MONTHLY' | 'QUARTERLY' | 'YEARLY',
    emailDelivery: boolean,
    emailRecipients: Array<string>,
    ftpDelivery: boolean,
    lastPrintStatus: 'SUCCESS' | 'FAILURE' | 'NOT_RUN'
    lastPrintTime: number | null
  }
};

export { SET_SCHEDULE };
