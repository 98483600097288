
import { authenticateGqlFields } from '../login/authenticate.mutation';

// like authenticate(), but takes an OpenID id_token instead of credentials.
const AUTHENTICATE_OIDC_QUERY = `
  mutation ($oidcToken: String!) {
    authenticateOidc(oidcToken: $oidcToken) {
      ${authenticateGqlFields}
    }
  }
`;

export type AuthenticateOidcResponseType = {
  authenticateOidc: {
    success: boolean,
    token: string | null,
    user?: {
      id: number,
      username: string,
      homeReportId: number,
      permissions: Array<{
        id: number,
        name: string
      }>,
      tenant: {
        id: number,
        name: string,
        logoutURL: string,
        defaultThemeId: number,
        homeReportId: number,
        defaultLogo?: {
          id: number,
          image: string
        },
        smallLogo?: {
          id: number,
          image: string
        },
        themes: Array<{
          id: number
          name: string
          colors: string
        }>
      }
    }
  }
};

export { AUTHENTICATE_OIDC_QUERY };
