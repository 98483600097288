export const EDITABLE_COLORS = [
  {
    path: 'primary1.color',
    description: 'Table borders, button text and outlines'
  },
  {
    path: 'primary2.color',
    description: 'Table headers'
  },
  {
    path: 'primary3.color',
    description: 'Page background, form field background'
  },
  {
    path: 'primary5.color',
    description: 'Buttons, links, field borders, menu highlight'
  },
  {
    path: 'primary8.color',
    description: 'Footer, dropdowns'
  },
  {
    path: 'secondary3.color',
    description: 'Dropdown hover'
  },
  {
    path: 'primary4.color',
    description: 'Menu background'
  },
  {
    path: 'primary2.contrast',
    description: 'Colulmn header text'
  },
  {
    path: 'secondary1.color',
    description: 'Button hover'
  },
  {
    path: 'secondary2.color',
    description: 'Navigation bar'
  }
];
