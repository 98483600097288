export const GET_ASSOCIATED_TENANTS = `
  query ($filter: ThemeFilterArgs) {
    themes (filter: $filter) {
      tenants {
        id
      }
    }
  }
`;

export type GetAssociatedTenantsResponseType = {
  tenants: Array<number>
}
