const SAVE_REPORT = `
  mutation updateReport($id: Int!, $input: ReportInput!) {
    updateReport(id: $id, report: $input) {
      id
    }
  }
`;

export type SaveReportResponseType = {
  updateReport: {
    id: string
  }
};

export { SAVE_REPORT };
