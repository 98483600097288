import * as React from 'react';
import { styled } from 'linaria/react';
import { Card, Table } from '@sevone/scratch';
import { RoleType } from '../../roles-query';
import { VERTICAL_RHYTHM } from '../../../../utils/spacing';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin ${VERTICAL_RHYTHM}px 0px;
`;
const TableWrapper = styled.div`
  height: 100%;
`;

type Props = {
  currentRoles: Array<RoleType>,
  availableRoles: Array<RoleType>,
  onRoleSelect: (selectedRoleIds: Array<string>) => void
}

const COLUMNS = [
  {
    id: 'name',
    title: 'Name',
    render: (data: RoleType) => data.name
  }
];

function Roles(props: Props) {
  const { currentRoles, availableRoles, onRoleSelect } = props;
  return (
    <Container>
      <Card title={'Roles'}>
        <TableWrapper>
          <Table
            rows={availableRoles}
            selectedRows={currentRoles.map((role) => role.id)}
            columns={COLUMNS}
            onRowSelection={onRoleSelect}
          />
        </TableWrapper>
      </Card>
    </Container>
  );
}

export { Roles };
