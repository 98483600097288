import * as React from 'react';
import { styled } from 'linaria/react';
import {
  LoadingCircle
} from '@sevone/scratch';
import { DatasourceDetails } from './datasource-details';
import { Tenants } from './tenants';
import { TenantType } from '../queries/get-datasources-with-tenant.query';
import { HORIZONTAL_RHYTHM } from '../../../utils/spacing';

const EditorContianer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-start;
  margin-left: ${HORIZONTAL_RHYTHM}px;
`;
const LoadingWrapper = styled.div`
  align-self: center;
`;
const Section = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  overflow: auto;
`;

type Props = {
  isLoading: boolean,
  isCreating: boolean,
  name: string
  setName: (value: string) => void,
  dsType: string,
  setDsType: (value: string) => void,
  address: string,
  setAddress: (value: string) => void,
  apiKey: string
  setApiKey: (value: string) => void,
  isFetchingTenants: boolean,
  tenants: Array<TenantType>,
  setHasChanged: (value: boolean) => void
}

function Editor(props: Props) {
  const { isLoading, isCreating, name, setName, dsType, setDsType,
    address, setAddress, apiKey, setApiKey, isFetchingTenants,
    tenants, setHasChanged } = props;


  if (isLoading) {
    return (
      <EditorContianer>
        <LoadingWrapper>
          <LoadingCircle size={'large'} />
        </LoadingWrapper>
      </EditorContianer>
    );
  }

  return (
    <EditorContianer>
      <Section style={{ flex: 'none' }}>
        <DatasourceDetails
          isCreating={isCreating}
          name={name}
          setName={setName}
          dsType={dsType}
          setDsType={setDsType}
          address={address}
          setAddress={setAddress}
          apiKey={apiKey}
          setApiKey={setApiKey}
          setHasChanged={setHasChanged}
        />
      </Section>
      {!isCreating && <Section style={{ flex: 1 }}>
        <Tenants
          isLoading={isFetchingTenants}
          tenants={tenants}
        />
      </Section>}
    </EditorContianer>
  );
}

export { Editor };
