import * as React from 'react';
import { styled } from 'linaria/react';
import { css } from 'linaria';
import { Resizable } from 're-resizable';
import { Tree, VerticalGripIcon } from '@sevone/scratch';
import { allReports } from './immutable-folders/all-reports';
import { FolderNode } from './folder-node';
import { FolderTreeType } from './types';

const wrapper = css`
  --sev1-size: 14px;

  height: 100%;
  padding: 10px 0;
  overflow-y: auto;
  overflow-x: hidden;
  background: var(--sev1-primary-4-color);
`;

const ResizeFolderHandleWrapper = styled.div`
  position: relative;
  top: 50%;
  left: -5px;
  transform: translateY(-50%);
`;

function ResizeFolderHandle() {
  return (
    <ResizeFolderHandleWrapper>
      <VerticalGripIcon />
    </ResizeFolderHandleWrapper>
  );
}

type Props = {
  folderTree: Array<FolderTreeType>,
  selectedFolder: number | null,
  onFolderSelection: (folderId: number) => void,
  onEditFolder: (folderId: number) => void,
  onCreateFolder: (parentId: number | null) => void,
  onDeleteFolder: () => void,
  onMoveReports: () => void
};

function Folders(props: Props) {
  const {
    folderTree,
    selectedFolder,
    onFolderSelection,
    onEditFolder,
    onCreateFolder,
    onDeleteFolder,
    onMoveReports
  } = props;
  const [
    expandedFolders,
    setExpandedFolders
  ] = React.useState<Array<number>>([]);

  const generateFolderHierarchy = (
    treeFolders: Array<FolderTreeType>,
    node: FolderTreeType | null
  ) => {
    const parent = node || { id: null };

    const children: Array<FolderTreeType> = [];
    treeFolders.forEach((folder) => {
      if (folder.parentId === parent.id) {
        children.push(folder);
      }
    });

    // Assign the children to the parent.
    if (parent.id !== null && children.length > 0) {
      parent.children = children;
    }

    // Recurse on each child.
    for (let i = 0; i < children.length; ++i) {
      generateFolderHierarchy(treeFolders, children[i]);
    }

    return children;
  };

  const handleExpandFolder = (id: number) => {
    setExpandedFolders([ ...expandedFolders, id ]);
  };

  const handleCollapseFolder = (id: number) => {
    setExpandedFolders(expandedFolders.filter((folderId) => folderId !== id));
  };

  React.useEffect(() => {
    onFolderSelection(allReports.id);
  }, []);

  return (
    <Resizable
      className={wrapper}
      defaultSize={{ width: '265px', height: '100%' }}
      enable={{ right: true }}
      handleComponent={{ right: <ResizeFolderHandle /> }}
    >
      <Tree
        nodes={generateFolderHierarchy(folderTree, null)}
        renderNode={(p) => (
          // @ts-ignore: p.data is generic and doesn't include all fields passed
          // to a node in `nodes`.
          <FolderNode
            {...p}
            onExpandNode={handleExpandFolder}
            onEditNode={onEditFolder}
            onCreateNode={onCreateFolder}
            onDeleteNode={onDeleteFolder}
            onMoveReports={onMoveReports}
          />
        )}
        // @ts-ignore: this should allow for Array<number | string>
        selected={selectedFolder ? [ selectedFolder ] : []}
        expanded={expandedFolders}
        onSelect={onFolderSelection}
        onDeselect={() => {}}
        onExpand={handleExpandFolder}
        onCollapse={handleCollapseFolder}
      />
    </Resizable>
  );
}

export { Folders };
