const permissions = {
  modifyAllUsers: 1,
  viewAllUsers: 2,
  modifyTenantUsers: 3,
  viewTenantUsers: 4,
  assignSuperAdminRole: 5,
  assignTenantAdminRole: 6,
  assignRoles: 7,
  modifyTenantReports: 8,
  viewTenantReports: 9,
  configureScheduleReports: 10,
  modifyPersonalReports: 11,
  copyWidgetToReport: 12,
  viewPersonalReports: 13,
  exportWidget: 14,
  shareWidget: 15,
  createReport: 16,
  viewAllTenants: 17,
  createDeleteTenant: 18,
  updateAllTenants: 19,
  updateOwnTenant: 20,
  viewAllDatasources: 21,
  modifyAllDatasources: 22,
  manageEmailSettings: 23,
  manageFTPSettings: 24,
  switchTenants: 25,
  generateProxyKey: 26,
  manageReportLinks: 27,
  manageAllThemes: 30,
  manageTenantThemes: 31,
  manageAllLogos: 32,
  manageTenantLogos: 33,
  viewSystemConfiguration: 34,
  viewReportManager: 35,
  viewQueryManager: 36,
  viewExplorePage: 37
};

export { permissions };
