import * as React from 'react';
import { Button, CopyIcon } from '@sevone/scratch';
import { useGql } from '../../../../hooks/use-gql';
import { GET_REPORT, GetReportResponseType } from './get-report.query';
import {
  CREATE_REPORT,
  CreateReportResponseType
} from './create-report.mutation';

type ReportInputType = {
  name: string,
  description: string | null,
  refreshInterval: number,
  rotateInterval: number,
  folderId: number | null,
  content: string
  isTemplate: boolean
}

type Props = {
  reportIds: Array<string | number>,
  onError: (error: { message: string }) => void,
  onSuccess: () => void
};

function CopyReports(props: Props) {
  const { reportIds, onError, onSuccess } = props;
  const {
    runGql: fetchReport
  } = useGql<GetReportResponseType>(GET_REPORT);
  const {
    runGql: createReport
  } = useGql<CreateReportResponseType>(CREATE_REPORT);

  const copyReport = (
    report: ReportInputType,
    attempt: number = 0
  ): Promise<any> => {
    const name = `${report.name} Copy${attempt ? ` (${attempt})` : ''}`;

    return createReport({ report: { ...report, name } }).catch(() => {
      return copyReport(report, attempt + 1);
    });
  };

  const handleCopyReports = async () => {
    const fetchedReports = await Promise.all(reportIds.map((id) => {
      return fetchReport({ id }).then((res) => {
        return res.reports.map(({ folder, ...report }) => ({
          ...report,
          folderId: folder ? folder.id : null
        }));
      });
    })).then((reports) => reports.reduce(
      (prev, curr) => prev.concat(curr),
      [] as Array<ReportInputType>
    ));

    return Promise.all(fetchedReports.map((report) => {
      return copyReport(report);
    })).then(onSuccess).catch((e) => {
      onError(e);
      return Promise.reject();
    });
  };

  return (
    <Button
      type="outlined"
      prefixIcon={<CopyIcon />}
      disabled={reportIds.length !== 1}
      onClick={handleCopyReports}
    >
      {'Duplicate'}
    </Button>
  );
}

export { CopyReports };
