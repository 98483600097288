import { useState, useCallback } from 'react';
import { FacetType } from '@sevone/insight-wdk';
import { createContainer } from '../../utils/create-container';
import { WidgetType } from '../../pages/report/types';
import {
  TYPE_PRIORITIES,
  DEPENDENCY_LIST,
  registerFacetContainer,
  addFacet,
  CONTAINER_TYPES
} from '../facet-manager';

type UnregisterContainerType = () => void;

type UnregisterersType = Record<string, UnregisterContainerType>;

function useWidgetManager() {
  const [ widgets, setWidgets ] = useState<Record<string, WidgetType>>({});
  const [ unregisterers, setUnregisterers ] = useState<UnregisterersType>({});

  const getWidgetBroadcastName = (widgetId: WidgetType['id']) => {
    return `${widgetId}-out`;
  };

  const registerWidget = (widget: WidgetType) => {
    const { unregister: unregisterIn } = registerFacetContainer({
      id: widget.id,
      priority: TYPE_PRIORITIES[CONTAINER_TYPES.widget],
      dependencies: DEPENDENCY_LIST[CONTAINER_TYPES.widget]
    });
    const { unregister: unregisterOut } = registerFacetContainer({
      id: getWidgetBroadcastName(widget.id),
      priority: TYPE_PRIORITIES[CONTAINER_TYPES.widget],
      dependencies: []
    });
    const unregister = () => {
      unregisterIn();
      unregisterOut();
    };

    setWidgets((curr) => ({
      ...curr,
      [widget.id]: widget
    }));
    setUnregisterers((curr) => ({
      ...curr,
      [widget.id]: unregister
    }));
  };

  const unregisterWidget = (id: string) => {
    const unregister = unregisterers[id];

    setWidgets(({ [id]: _, ...kept }) => kept);

    if (unregister) {
      unregister();
      setUnregisterers(({ [id]: _, ...kept }) => kept);
    }
  };

  const clearWidgets = () => {
    Object.keys(widgets).forEach(unregisterWidget);
  };

  const broadcastFromWidget = useCallback((
    widgetId: WidgetType['id'],
    facet: FacetType
  ) => {
    addFacet(getWidgetBroadcastName(widgetId), facet);
  }, []);

  return {
    widgets,
    getWidgetBroadcastName,
    registerWidget,
    unregisterWidget,
    clearWidgets,
    broadcastFromWidget
  };
}

const WidgetManager = createContainer(useWidgetManager);

export { WidgetManager };
