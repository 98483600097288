import * as React from 'react';
import { styled } from 'linaria/react';
import { css } from 'linaria';
import {
  Dialog,
  Input,
  Button,
  Checkbox,
  Tooltip,
  CircleInfoIcon
} from '@sevone/scratch';
import {
  HORIZONTAL_RHYTHM,
  VERTICAL_RHYTHM
} from '../../../../../utils/spacing';
import { ReportType } from '../../../types';

const Wrapper = styled.form`
  width: 300px;
`;

const InputWrapper = styled.div`
  margin-bottom: ${HORIZONTAL_RHYTHM}px;
`;

const ControlsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const tooltipTargetStyles = css`
  margin-left: ${HORIZONTAL_RHYTHM}px;
`;

const TooltipWrapper = styled.div`
  max-width: 275px;
`;

const ButtonsWrapper = styled.div`
  margin-left: auto;

  & > * {
    margin-left: ${VERTICAL_RHYTHM}px;
  }
`;

type Props = {
  report: ReportType,
  onSave: (name: string, isTemplate: boolean) => Promise<void>,
  onCancel: () => void
};

function SaveAsDialog(props: Props) {
  const { report, onSave, onCancel } = props;
  const [ name, setName ] = React.useState('');
  const [ isTemplate, setIsTemplate ] = React.useState(report.isTemplate);

  const handleSave = () => {
    return onSave(name, isTemplate);
  };

  return (
    <Dialog title={'Save As'} onClose={onCancel}>
      <Wrapper onSubmit={handleSave}>
        <InputWrapper>
          <Input
            autofocus
            placeholder={'Report name...'}
            value={name}
            onChange={setName}
          />
        </InputWrapper>
        <ControlsWrapper>
          <Checkbox checked={isTemplate} onChange={setIsTemplate}>
            {'Mark as template'}
            <Tooltip
              className={tooltipTargetStyles}
              position={[ 'bottom', 'center' ]}
              triggerDelay={0}
              tooltip={(
                <TooltipWrapper>
                  {`
                    Templates are often reports designed to be used in report
                    linking relationships. Saving a report as a template will place
                    the report in the "All Templates" folder.
                  `}
                </TooltipWrapper>
              )}
            >
              <CircleInfoIcon />
            </Tooltip>
          </Checkbox>
          <ButtonsWrapper>
            <Button
              disabled={!name}
              onClick={handleSave}
            >
              {'Save'}
            </Button>
          </ButtonsWrapper>
        </ControlsWrapper>
      </Wrapper>
    </Dialog>
  );
}

export { SaveAsDialog };
