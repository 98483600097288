import * as React from 'react';
import { styled } from 'linaria/react';
import * as ReactMarkdown from 'react-markdown';
import trimEnd from 'lodash-es/trimEnd';
import { Dialog, Button, AngleRightIcon, AngleLeftIcon } from '@sevone/scratch';
import { gitTag } from '../../../../global';
import { HORIZONTAL_RHYTHM } from '../../../../utils/spacing';
import { baseUrl, basePath } from '../../../../utils/base-location';
import slides from './slides';

const CarouselWrapper = styled.div`
  max-width: 800px;
  width: 800px;
  height: 750px;
  max-height: 750px;

  @media only screen and (max-height: 900px) {
    height: 600px;
  }
`;

const TopWrapper = styled.div`
  display: flex;
  height: 90%;
  max-height: 90%;
  overflow-y: auto;
`;

const LeftWrapper = styled.div`
  align-self: center;
  text-align: left;
`;

const RightWrapper = styled.div`
  align-self: center;
  text-align: right;
`;

const ContentWrapper = styled.div`
  flex-grow: 1;
  margin: 0 ${HORIZONTAL_RHYTHM}px;

  img {
    width: 100%;
    height: auto;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
  }
`;

const Pagination = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 10%;
  max-height: 10%;
`;

const PaginationItem = styled.span`
  cursor: pointer;
  width: 8px;
  height: 8px;
  border-radius: 8px;
  border: 2px solid var(--sev1-primary-5-color);
  background-color: ${(props: any) => (props.active ? 'var(--sev1-primary-5-color)' : 'transparent')};

  & + & {
    margin-left: ${HORIZONTAL_RHYTHM}px;
  }
`;

type Props = {
  hideModal: () => void;
};

function Carousel(props: any) {
  const { onClose, data, showPagination } = props;
  const [ active, setActive ] = React.useState(0);

  const transformImageUri = (input: string) => {
    return `${baseUrl}${trimEnd(basePath, '/')}/${input}`;
  };

  return (
    <Dialog title={`What's New in SevOne Data Insight ${gitTag}`} onClose={onClose}>
      <CarouselWrapper>
        <TopWrapper>
          <LeftWrapper>
            <Button
              onClick={() => setActive(active - 1)}
              disabled={active === 0}
            >
              <AngleLeftIcon />
            </Button>
          </LeftWrapper>
          <ContentWrapper>
            <ReactMarkdown
              source={data[active]}
              transformImageUri={transformImageUri}
              escapeHtml={false}
            />
          </ContentWrapper>
          <RightWrapper>
            <Button
              onClick={() => setActive(active + 1)}
              disabled={active >= data.length - 1}
            >
              <AngleRightIcon />
            </Button>
          </RightWrapper>
        </TopWrapper>
        {showPagination && (
          <Pagination>
            {data.map((d: string, idx: number) => (
              <PaginationItem
                key={idx}
                active={idx === active}
                role="presentation"
                onClick={() => setActive(idx)}
              />
            ))}
          </Pagination>
        )}
      </CarouselWrapper>
    </Dialog>
  );
}

function WhatsNew(props: Props) {
  const { hideModal } = props;
  return (
    <Carousel showPagination onClose={() => hideModal()} data={slides} />
  );
}

export { WhatsNew };
