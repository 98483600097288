import * as React from 'react';
import { styled } from 'linaria/react';
import {
  Input,
  RadioGroup,
  Radio,
  UndoIcon,
  IconButton,
  Button,
  AngleDoubleRightIcon,
  AngleDoubleLeftIcon,
  Tooltip,
  Select
} from '@sevone/scratch';
import get from 'lodash-es/get';
import { ColorPicker } from './color-picker';
import { EDITABLE_COLORS } from './editable-colors';
import { CoreStore } from '../../../store';
import { hasPermission } from '../../../components/permission-gate';
import { HORIZONTAL_RHYTHM, VERTICAL_RHYTHM } from '../../../utils/spacing';

const Container = styled.div<{ isExpanded: boolean }>`
  width: ${({ isExpanded }) => (isExpanded ? '330px' : '30px')};
  transition: width 0.3s;
  height: 100%;
  display: flex;
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${VERTICAL_RHYTHM / 2}px ${HORIZONTAL_RHYTHM}px;
  flex: 1;

  & > * {
    margin: ${VERTICAL_RHYTHM / 2}px 0px;
  }
`;
const ArrowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${VERTICAL_RHYTHM / 2}px ${HORIZONTAL_RHYTHM}px;
  flex: 1;
  justify-content: center;
`;
const Content = styled.div`
  & > * {
    margin: ${VERTICAL_RHYTHM}px 0px;
  }
`;
const Divider = styled.div`
  border-bottom: 1px solid var(--sev1-primary-2-color);
`;
const ColorsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const ColorsLabel = styled.div`
  font-size: calc(var(--sev1-size) * 0.8);
`;
const Header = styled.div`
  font-size: calc(var(--sev1-size) * 0.9);
`;
const ColorPickerContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
`;
const ColorAndDescriptionContainer = styled.div`
  display: flex;
  align-items: center;
  margin: ${VERTICAL_RHYTHM / 2}px 0px;
  & > :first-child {
    margin-right: ${HORIZONTAL_RHYTHM / 2}px;
  }
`;
const ColorDescription = styled.div`
  font-size: calc(var(--sev1-size) * 0.7);
`;
const ButtonContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-self: flex-end;
  width: 100%;
`;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

type OptionType = { label: string, value: string | number };
type Props = {
  isExpanded: boolean
  name: string
  isGlobal: boolean
  colors?: { [key: string]: string }
  isBuiltIn: boolean
  onRevertAll: () => void,
  onNameChange: (value: string) => void;
  onGlobalChange: (value: boolean) => void
  onColorChange: (value: string, path: string) => void
  onRevertColor: (path: string) => void
  onBaseThemeChange: (value: string) => void
  baseThemeType: string
  isCreating: boolean,
  onExpand: (value: boolean) => void
  onViewChange: (value: OptionType | Array<OptionType>) => void
  viewOptions: Array<OptionType>
  currentView: OptionType | Array<OptionType>
}

function SidePanel(props: Props) {
  const { permissions } = CoreStore.useContainer().state;
  const { isExpanded, name, isGlobal, colors, onRevertAll, onNameChange, onGlobalChange,
    onColorChange, onRevertColor, onBaseThemeChange, isCreating, baseThemeType,
    onExpand, onViewChange, viewOptions, currentView } = props;

  const handleIsGlobalChange = (value: string) => {
    onGlobalChange(value === 'public');
  };

  const renderColorRows = () => {
    if (colors) {
      return EDITABLE_COLORS.map((colorInfo) => {
        const color = get(colors, colorInfo.path);

        return (
          <ColorPickerContainer key={colorInfo.path}>
            <ColorAndDescriptionContainer>
              <ColorPicker
                color={color}
                onChange={(value: string) => onColorChange(value, colorInfo.path)}
              />
              <ColorDescription>{colorInfo.description}</ColorDescription>
            </ColorAndDescriptionContainer>
            <IconButton onClick={() => onRevertColor(colorInfo.path)}>
              <UndoIcon />
            </IconButton>
          </ColorPickerContainer>
        );
      });
    }

    return [];
  };

  const renderBaseThemeSelection = () => {
    if (isCreating) {
      return (
        <RadioGroup
          onChange={onBaseThemeChange}
          value={baseThemeType}
        >
          <Radio value={'light'}>{'Light'}</Radio>
          <Radio value={'dark'}>{'Dark'}</Radio>
        </RadioGroup>
      );
    }

    return null;
  };

  const renderIsGlobalSelection = () => {
    const userPermissionsIds = permissions.map((perm) => perm.id);
    if (hasPermission([ 'manageAllThemes' ], userPermissionsIds)) {
      return (
        <RadioGroup
          onChange={handleIsGlobalChange}
          value={isGlobal ? 'public' : 'private'}
        >
          <Tooltip
            position={[ 'top', 'left' ]}
            tooltip={'Makes theme visible to your current tenant only.'}
          >
            <div style={{ margin: '0 1em 0 0' }}>
              <Radio value={'private'}>{'Private'}</Radio>
            </div>
          </Tooltip>
          <Tooltip
            position={[ 'top', 'left' ]}
            tooltip={'Allows theme to be share with multiple tenants.'}
          >
            <div style={{ margin: '0 1em 0 0' }}>
              <Radio value={'public'}>{'Public'}</Radio>
            </div>
          </Tooltip>
        </RadioGroup>
      );
    }

    return null;
  };

  return (
    <Container isExpanded={isExpanded}>
      {isExpanded ?
        <Wrapper>
          <Header>{`Theme Settings - Editing ${name || 'New Theme'}`}</Header>
          <Divider />
          <Content>
            <Select
              label={'Change view'}
              options={viewOptions}
              onChange={onViewChange}
              value={currentView}
            />
            <Input
              label={'Theme name'}
              onChange={onNameChange}
              value={name}
              status={name ? undefined : 'error'}
            />
            {renderBaseThemeSelection()}
            {renderIsGlobalSelection()}
            <ColorsContainer>
              <ColorsLabel>{'Colors'}</ColorsLabel>
              {renderColorRows()}
            </ColorsContainer>
          </Content>
          <ButtonContainer>
            <ButtonWrapper>
              <IconButton onClick={() => onExpand(!isExpanded)}><AngleDoubleRightIcon /></IconButton>
              <Button onClick={onRevertAll}>{'Revert All Changes'}</Button>
            </ButtonWrapper>
          </ButtonContainer>
        </Wrapper> :
        <ArrowWrapper>
          <IconButton onClick={() => onExpand(!isExpanded)}><AngleDoubleLeftIcon /></IconButton>
        </ArrowWrapper>
      }
    </Container>
  );
}

export { SidePanel };
