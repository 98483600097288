import { useState, useEffect } from 'react';
import { useGql } from '../../../hooks/use-gql';
import {
  ReportRuntime,
  ReportSessionExportType
} from '../../../report-runtime';
import {
  PRINT_SESSION_QUERY,
  PrintSessionResponseType
} from './print-session.query';

type ReportType = React.ComponentProps<typeof ReportRuntime>['report'];

function useSessionFetcher(sessionId: string | null) {
  const {
    runGql,
    isFetching
  } = useGql<PrintSessionResponseType>(PRINT_SESSION_QUERY, {
    isFetching: true
  });
  const [ report, setReport ] = useState<ReportType | null>(null);
  const [
    payload,
    setPayload
  ] = useState<Omit<ReportSessionExportType, 'report'> | null>(null);
  const session: ReportSessionExportType | null = !report ? null : {
    // Default empty session data. This will happen in the case of scheduled
    // reports.
    facetManager: {
      containerStore: []
    },
    activeSectionId: null,
    report,
    ...payload
  };

  useEffect(() => {
    const fetchSession = () => {
      runGql({ sessionId }).then(({ printSession }) => {
        if (!printSession) {
          return;
        }

        const fetchedReport = {
          content: printSession.content
        };
        const parsedReport = {
          ...fetchedReport,
          content: JSON.parse(fetchedReport.content)
        // This is a bit of a type hack here. The report runtime wants the full
        // report, but we only get back its content. Technically the runtime
        // doesn't need everything about the report, and in our limited, single
        // widget view we can get away with only the content. But we still
        // need to lie to the runtime to ignore any typescript error.
        } as unknown as ReportType;

        setReport(parsedReport);

        if (printSession.sessionPayload) {
          setPayload(JSON.parse(printSession.sessionPayload));
        }
      }).catch(() => {
        setReport(null);
        setPayload(null);
      });
    };

    if (sessionId) {
      fetchSession();
    }
  }, [ sessionId ]);

  return {
    session,
    fetchingSession: isFetching
  };
}

export { useSessionFetcher };
