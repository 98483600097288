import * as React from 'react';
import {
  Button,
  Input,
  Table,
  UndoIcon,
  SaveIcon
} from '@sevone/scratch';
import { useRequest, useNotification, useModal } from '@sevone/insight-connect';
import { styled } from 'linaria/react';
import uniqBy from 'lodash-es/uniqBy';
import differenceBy from 'lodash-es/differenceBy';
import {
  USERS_QUERY,
  UsersResponseType,
  UserType,
  INITIAL_USERS_QUERY,
  InitialUsersResponseType,
  InitialUserType
} from './user-query';
import { ROLES_QUERY, RolesResponseType, RoleType, PermissionType } from './roles-query';
import { ADD_USER_ROLE, REMOVE_USER_ROLE } from './update-user-roles';
import { Editor } from './editor';
import {
  Page,
  PageHeader,
  PageTitle,
  PageActions,
  PageSection
} from '../../components/page';
import { HORIZONTAL_RHYTHM, VERTICAL_RHYTHM } from '../../utils/spacing';
import { useDebounce } from '../../hooks/use-debounce';

const PageContainer = styled(Page)`
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;
const PageHeaderContainer = styled(PageHeader)`
  display: flex;
  justify-content: space-between;
`;
const PageBody = styled.div`
  display: flex;
  justify-content: center;
  flex: 1;
  overflow: hidden;
`;
const TableSection = styled(PageSection)`
  display: flex;
  flex-direction: column;
  width: 275px;
  align-items: center;
`;
const TableHeaderContainer = styled.div`
  display: flex;
`;
const ColumnLabel = styled.div`
  font-size: 1.2em;
  padding: ${VERTICAL_RHYTHM / 2}px ${HORIZONTAL_RHYTHM}px;
`;
const TableSearchWrapper = styled.div`
  padding: 5px;
`;
const TableWrapper = styled.div`
  height: 100%;
  width: 100%;
  overflow: auto;
`;

type RowType = {
  id: string,
  username: string
}

const COLUMNS = [
  {
    id: 'username',
    title: 'Username',
    render: (data: RowType) => data.username,
    sort: (row: RowType, otherRow: RowType) => {
      const { username } = row;
      const { username: otherUsername } = otherRow;
      if (username < otherUsername) {
        return -1;
      }
      if (username > otherUsername) {
        return 1;
      }
      return 0;
    }
  }
];

const PAGE_SIZE = 50;

function UserManager() {
  const { query } = useRequest();
  const { showModal } = useModal();
  const { showNotification } = useNotification();
  const [ users, setUsers ] = React.useState<Array<InitialUserType>>([]);
  const [ roles, setRoles ] = React.useState<Array<RoleType>>([]);
  const [ fetchingUsers, setFetchingUsers ] = React.useState(false);
  const [ fetchingRoles, setFetchingRoles ] = React.useState(false);
  const [ fetchingCurrentUser, setFetchingCurrentUser ] = React.useState(false);
  const [ selectedUser, setSelectedUser ] = React.useState<UserType>();
  const [ page, setPage ] = React.useState(1);
  const [ usersTableSearch, setUsersTableSearch ] = React.useState('');
  const [ currentRoles, setCurrentRoles ] = React.useState<Array<RoleType>>([]);
  const [ currentPermissions, setCurrentPermissions ] = React.useState<Array<PermissionType>>([]);
  const [ hasChanged, setHasChanged ] = React.useState(false);
  const [ filterRows, setFilterRows ] = React.useState<Array<InitialUserType>>([]);
  const debouncedSearchTerm = useDebounce(usersTableSearch, 200);


  const fetchUsers = (): Promise<Array<InitialUserType>> => {
    setFetchingUsers(true);
    return query(INITIAL_USERS_QUERY, {}).then((response: InitialUsersResponseType) => {
      if (response.data.errors) {
        const { errors } = response.data;
        errors.forEach((error) => {
          showNotification({
            type: 'error',
            message: `Error fetching users: ${error.message}`,
            lifespan: null
          });
        });
        return [];
      }
      setFetchingUsers(false);

      return response.data.data.users;
    }).catch((error) => {
      showNotification({
        type: 'error',
        message: `Error fetching users: ${error.message}`,
        lifespan: null
      });

      return [];
    });
  };

  const fetchRoles = () => {
    setFetchingRoles(true);
    return query(ROLES_QUERY, {}).then((response: RolesResponseType) => {
      if (response.data.errors) {
        const { errors } = response.data;
        errors.forEach((error) => {
          showNotification({
            type: 'error',
            message: `Error fetching roles: ${error.message}`,
            lifespan: null
          });
        });
        return [];
      }

      setFetchingRoles(false);

      return response.data.data.roles;
    }).catch((error) => {
      showNotification({
        type: 'error',
        message: `Error fetching roles: ${error.message}`,
        lifespan: null
      });

      return [];
    });
  };

  React.useEffect(() => {
    setFilterRows(users.filter((row) => row.username.toLowerCase().includes(debouncedSearchTerm.toLowerCase())));
  }, [ debouncedSearchTerm, users ]);

  const handleSetNewUser = async (user: InitialUserType) => {
    setFetchingCurrentUser(true);
    query(USERS_QUERY, user).then((response: UsersResponseType) => {
      if (response.data.errors) {
        const { errors } = response.data;
        errors.forEach((error) => {
          showNotification({
            type: 'error',
            message: `Error fetching users: ${error.message}`,
            lifespan: null
          });
        });
        return;
      }

      const newUser = response.data.data.users[0];
      setSelectedUser(newUser);
      setCurrentRoles(newUser.roles);
      setCurrentPermissions(uniqBy(newUser.permissions, 'id'));
      setHasChanged(false);
      setFetchingCurrentUser(false);
    }).catch((error) => {
      showNotification({
        type: 'error',
        message: `Error fetching users: ${error.message}`,
        lifespan: null
      });
    });
  };

  const handleUserSelect = (newSelectedUsers: Array<number | string>) => {
    const newSelectedUser = users.find((user: InitialUserType) => user.id === newSelectedUsers[0]);
    if (!newSelectedUser) {
      return;
    }

    if (hasChanged) {
      showModal({
        type: 'warning',
        header: 'Unsaved Changes',
        message: () => 'Are you sure you want to select another user? All current changes will not be saved.',
        actions: (props) => [
          (
            <Button key={'ok'} onClick={() => {
              handleSetNewUser(newSelectedUser);
              props.hideModal();
            }}
            >
              {'OK'}
            </Button>
          ),
          (<Button key={'cancel'} onClick={() => props.hideModal()}>{'Cancel'}</Button>)
        ]
      });
    } else {
      handleSetNewUser(newSelectedUser);
    }
  };

  React.useEffect(() => {
    fetchUsers().then((allUsers) => {
      setUsers(allUsers);
      if (allUsers.length > 0) {
        handleSetNewUser(allUsers[0]);
      }
    });
    fetchRoles().then(setRoles);
  }, []);


  const handleUsersTableSearch = (value: string) => {
    setUsersTableSearch(value);
    setPage(1);
  };

  const handleSelectRoles = (selectedRoleIds: Array<string>) => {
    const selectedRoleIdsSet = new Set(selectedRoleIds);
    const selectedRoles = roles.filter((role) => selectedRoleIdsSet.has(role.id));
    let selectedPermissions: Array<PermissionType> = [];
    selectedRoles.forEach((role) => {
      selectedPermissions = selectedPermissions.concat(role.permissions);
    });
    selectedPermissions = uniqBy(selectedPermissions, 'id');
    setCurrentRoles(selectedRoles);
    setCurrentPermissions(selectedPermissions);
    setHasChanged(true);
  };

  const handleRestore = () => {
    if (selectedUser) {
      setCurrentRoles(selectedUser.roles);
      setCurrentPermissions(uniqBy(selectedUser.permissions, 'id'));
      setHasChanged(false);
    }
  };

  const handleSaveUser = async (): Promise<any> => {
    if (selectedUser) {
      const rolesRemoved = differenceBy(selectedUser.roles, currentRoles, 'id');
      const rolesAdded = differenceBy(currentRoles, selectedUser.roles, 'id');
      const rolesAddedPromises: Array<Promise<UsersResponseType>> = [];
      rolesAdded.forEach((role) => {
        const vars = {
          userId: selectedUser.id,
          roleId: role.id
        };
        rolesAddedPromises.push(
          query(ADD_USER_ROLE, vars)
        );
      });
      try {
        const response = await Promise.all(rolesAddedPromises);
        const errorResponse = response.find((res) => res.data.errors);
        if (errorResponse?.data.errors) {
          throw Error(errorResponse.data.errors[0].message);
        }
      } catch (e) {
        showNotification({
          type: 'error',
          message: e.message
        });
        handleRestore();
        return Promise.reject();
      }

      const rolesRemovedPromises: Array<Promise<UsersResponseType>> = [];
      rolesRemoved.forEach((role) => {
        const vars = {
          userId: selectedUser.id,
          roleId: role.id
        };

        rolesRemovedPromises.push(
          query(REMOVE_USER_ROLE, vars)
        );
      });
      try {
        await Promise.all(rolesRemovedPromises);
      } catch (e) {
        showNotification({
          type: 'error',
          message: e.message
        });
        handleRestore();
        return Promise.reject();
      }

      const newUserData = await query(
        USERS_QUERY, { id: selectedUser.id }
      ).then((response: UsersResponseType) => {
        return response.data.data.users[0];
      });

      const newUsers = users.map((user) => {
        if (user.id === newUserData.id) {
          return newUserData;
        }

        return user;
      });

      setUsers(newUsers);
      setSelectedUser(newUserData);
      setHasChanged(false);
    }

    return Promise.resolve();
  };

  return (
    <PageContainer title={'Users Administration'}>
      <PageHeaderContainer>
        <PageTitle>{'Users, Roles, & Permissions'}</PageTitle>
        <PageActions>
          <Button
            onClick={handleRestore}
            prefixIcon={<UndoIcon />}
            type={'outlined'}
          >
            {'Restore'}
          </Button>
          <Button
            onClick={handleSaveUser}
            prefixIcon={<SaveIcon />}
            type={'outlined'}
          >
            {'Save'}
          </Button>
        </PageActions>
      </PageHeaderContainer>
      <PageBody>
        <TableSection>
          <TableHeaderContainer>
            <ColumnLabel>{'Users'}</ColumnLabel>
            <TableSearchWrapper data-test-id={'user-table-search'}>
              <Input
                placeholder={'Search for a user...'}
                onChange={handleUsersTableSearch}
                value={usersTableSearch}
              />
            </TableSearchWrapper>
          </TableHeaderContainer>
          <TableWrapper>
            <Table
              rows={filterRows}
              columns={COLUMNS}
              selectedRows={selectedUser ? [ selectedUser.id ] : []}
              onRowSelection={handleUserSelect}
              pageSize={PAGE_SIZE}
              onPageChange={setPage}
              page={page}
            />
          </TableWrapper>
        </TableSection>
        <Editor
          selectedUser={selectedUser}
          isLoading={fetchingUsers || fetchingRoles || fetchingCurrentUser}
          currentRoles={currentRoles}
          currentPermissions={currentPermissions}
          availableRoles={roles}
          onRoleSelect={handleSelectRoles}
        />
      </PageBody>
    </PageContainer>
  );
}

export { UserManager };
