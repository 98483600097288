import * as React from 'react';
import { styled } from 'linaria/react';
import { saveAs } from 'file-saver';
import { useRequest } from '@sevone/insight-connect';
import { Dialog, Button, Checkbox, Table } from '@sevone/scratch';
import { useGql } from '../../../../hooks/use-gql';
import { HORIZONTAL_RHYTHM, VERTICAL_RHYTHM } from '../../../../utils/spacing';
import { ReportType } from '../../get-reports.query';
import {
  EXPORT_REPORTS,
  ExportReportsResponseType
} from './export-reports.mutation';

const GenericWrapper = styled.div`
  margin-bottom: ${VERTICAL_RHYTHM}px;
`;

const TableWrapper = styled(GenericWrapper)`
  height: 250px;
  width: 400px;
`;

const ButtonsWrapper = styled.div`
  text-align: right;

  & > * {
    margin-left: ${HORIZONTAL_RHYTHM}px;
  }
`;

const COLUMNS = [
  {
    id: 'name',
    title: 'Report Name',
    render: (row: ReportType) => row.name,
    sort: (row: ReportType, otherRow: ReportType) => {
      const { name } = row;
      const { name: otherName } = otherRow;
      if (name < otherName) {
        return -1;
      }

      if (name > otherName) {
        return 1;
      }

      return 0;
    }
  }
];

type Props = {
  selectedReports: Array<ReportType>,
  hideModal: () => void,
  onError: (error: { message: string }) => void
};

function ExportDialog(props: Props) {
  const { selectedReports, hideModal, onError } = props;
  const { raw } = useRequest();
  const {
    runGql: exportReports
  } = useGql<ExportReportsResponseType>(EXPORT_REPORTS);
  const [
    exportedReports,
    setExportedReports
  ] = React.useState<Array<ReportType['id']>>(selectedReports.map((r) => r.id));
  const [ dependencies, setDependencies ] = React.useState(false);

  const handleExportReports = async () => {
    try {
      const { exportReports: url } = await exportReports({
        ids: exportedReports,
        dependencies
      });
      const filename = url.split('/')[2];
      const { data } = await raw({ url, method: 'GET' });
      const dataBlob = new Blob([ data ], { type: 'application/json' });
      saveAs(dataBlob, `${filename}-export.tar`);
      hideModal();
    } catch (e) {
      onError(e);
      throw new Error(e);
    }
  };

  const handleSelectReports = (ids: Array<string | number>) => {
    // TODO: Fix typing in scratch's table to better reflect a row's id type
    setExportedReports(ids.map(parseInt));
  };

  return (
    <Dialog title={'Export Selected Reports'} onClose={hideModal}>
      <GenericWrapper>
        {'Are you sure you want to export these reports?'}
      </GenericWrapper>
      <TableWrapper>
        <Table
          rows={selectedReports}
          columns={COLUMNS}
          selectedRows={exportedReports}
          onRowSelection={handleSelectReports}
        />
      </TableWrapper>
      <GenericWrapper>
        <Checkbox checked={dependencies} onChange={setDependencies}>
          {'Include report link dependencies'}
        </Checkbox>
      </GenericWrapper>
      <ButtonsWrapper>
        <Button secondary onClick={hideModal}>
          {'Cancel'}
        </Button>
        <Button
          disabled={exportedReports.length === 0}
          onClick={handleExportReports}
        >
          {'Export'}
        </Button>
      </ButtonsWrapper>
    </Dialog>
  );
}

export { ExportDialog };
