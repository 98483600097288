import { useReducer } from 'react';
import { navigation } from '@sevone/insight-connect';
import { createContainer } from '../utils/create-container';

type AuthTokenType = string;

type UserType = {
  id: number,
  username: string,
  homeReportId: number | null
};

type PermissionType = {
  id: number,
  name: string
};

type TenantType = {
  id: number,
  name: string,
  logoutURL: string | null,
  defaultThemeId: number
  themes: Array<{
    id: number
    name: string
    colors: string
  }>
};

type ActiveTenantType = TenantType & {
  defaultLogo?: {
    id: number,
    image: string
  },
  smallLogo?: {
    id: number,
    image: string
  },
  homeReportId: number | null
};

type ActiveThemeIdType = number | null

type LoginPayloadType = {
  authToken: AuthTokenType,
  user: {
    id: number,
    username: string,
    homeReportId: number | null,
    tenant: ActiveTenantType,
    permissions: Array<PermissionType>
  }
};

type StateType = {
  authToken: AuthTokenType | null,
  user: UserType | null,
  activeTenant: ActiveTenantType | null,
  permissions: Array<PermissionType>,
  activeThemeId: ActiveThemeIdType
};

const potentialStorageToken = localStorage.getItem('authToken');

const potentialUrlAuthToken = navigation.location.params.token;

const initial: StateType = {
  authToken: potentialUrlAuthToken || potentialStorageToken || null,
  user: null,
  activeTenant: null,
  permissions: [],
  activeThemeId: null
};

type ActionsType =
  { type: 'login', payload: LoginPayloadType & {
    activeThemeId: ActiveThemeIdType
  } } |
  { type: 'logout' } |
  { type: 'updateActiveTenant', payload: ActiveTenantType }|
  { type: 'switchTheme', payload: { id: number } };

function reducer(state: StateType, action: ActionsType) {
  switch (action.type) {
    case 'login':
      return {
        authToken: action.payload.authToken,
        user: {
          id: action.payload.user.id,
          username: action.payload.user.username,
          homeReportId: action.payload.user.homeReportId
        },
        activeTenant: action.payload.user.tenant,
        permissions: action.payload.user.permissions,
        activeThemeId: action.payload.activeThemeId
      };
    case 'logout':
      return {
        authToken: null,
        user: null,
        activeTenant: null,
        permissions: [],
        activeThemeId: null
      };
    case 'updateActiveTenant':
      return {
        ...state,
        activeTenant: action.payload
      };
    case 'switchTheme':
      return {
        ...state,
        activeThemeId: action.payload.id
      };
    default:
      return state;
  }
}

function useCoreStore(initialState: StateType = initial) {
  const [ state, dispatch ] = useReducer(reducer, initialState);
  const { user, authToken, activeTenant, permissions, activeThemeId } = state;

  const setTheme = (themeId: number, tenantId: number) => {
    // We use local storage to remember the user's selected themes.
    // The ui_themes object is in the format of [tenantId]: themeId.
    // This function sets the user's theme in local storage.
    const currentThemesString = localStorage.getItem('ui_themes');
    if (currentThemesString) {
      const currentThemes = JSON.parse(currentThemesString);
      if (!currentThemes[tenantId]) {
        const newTheme = { [tenantId]: themeId };
        const newThemes = {
          ...currentThemes,
          ...newTheme
        };
        localStorage.setItem('ui_themes', JSON.stringify(newThemes));
      } else {
        const newThemes = { [tenantId]: themeId };
        localStorage.setItem('ui_themes', JSON.stringify(newThemes));
      }
    } else {
      const newThemes = { [tenantId]: themeId };
      localStorage.setItem('ui_themes', JSON.stringify(newThemes));
    }
  };

  const login = (payload: LoginPayloadType) => {
    localStorage.setItem('authToken', payload.authToken);
    const payloadWithTheme = { ...payload, activeThemeId: null as ActiveThemeIdType };
    const localStorageThemes = localStorage.getItem('ui_themes');
    let parsedLocalThemeId;
    if (localStorageThemes) {
      parsedLocalThemeId = parseInt(JSON.parse(localStorageThemes)[payload.user.tenant.id]);
    }

    if (parsedLocalThemeId) {
      payloadWithTheme.activeThemeId = parsedLocalThemeId;
      setTheme(parsedLocalThemeId, payload.user.tenant.id);
    } else {
      payloadWithTheme.activeThemeId = payload.user.tenant.defaultThemeId;
      setTheme(payload.user.tenant.defaultThemeId, payload.user.tenant.id);
    }

    dispatch({ type: 'login', payload: payloadWithTheme });
  };

  const switchTheme = (id: number) => {
    dispatch({ type: 'switchTheme', payload: { id } });
    if (state.activeTenant) {
      setTheme(id, state.activeTenant.id);
    }
  };

  const logout = (opts: { clearSession?: boolean } = {}) => {
    localStorage.removeItem('authToken');
    if (opts.clearSession !== false) {
      dispatch({ type: 'logout' });
    }
  };

  const updateActiveTenant = (payload: ActiveTenantType) => {
    dispatch({ type: 'updateActiveTenant', payload });
  };

  return {
    state: {
      user,
      authToken,
      permissions,
      activeTenant,
      isAuthenticated: !!authToken,
      activeThemeId
    },
    login,
    logout,
    updateActiveTenant,
    switchTheme
  };
}

const CoreStore = createContainer(useCoreStore);

export { CoreStore };
