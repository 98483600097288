import * as React from 'react';
import { styled } from 'linaria/react';
import {
  Button,
  SaveIcon,
  PaperPlaneIcon,
  GlobeIcon
} from '@sevone/scratch';
import { useNotification } from '@sevone/insight-connect';
import get from 'lodash-es/get';
import isNumber from 'lodash-es/isNumber';
import { Email } from './email';
import { Delivery } from './delivery';
import {
  TENANT_EMAIL_SETTINGS_QUERY,
  TenantEmailSettingsResponseType
} from './queries/get-email-settings.query';
import {
  TENANT_FTP_SETTINGS_QUERY,
  TenantFTPSettingsResponseType
} from './queries/get-ftp-settings.query';
import {
  SET_TENANT_EMAIL_SETTINGS_MUTATION,
  SetTenantEmailSettingsResponseType
} from './queries/set-email-settings.mutation';
import {
  SET_TENANT_FTP_SETTINGS_MUTATION,
  SetTenantFTPSettingsResponseType
} from './queries/set-ftp-settings.mutation';
import {
  FTP_VERIFY_CREDS_MUTATION,
  FTPVerifyCredsResponseType
} from './queries/ftp-test-connection.mutation';
import {
  TEST_TENANT_EMAIL_SETTINGS_MUTATION,
  testTenantEmailSettings
} from './queries/test-tenant-email-settings.mutation';
import {
  Page,
  PageHeader,
  PageTitle,
  PageActions
} from '../../components/page';
import { HORIZONTAL_RHYTHM } from '../../utils/spacing';
import { useGql, ErrorType } from '../../hooks/use-gql';

const PageContainer = styled(Page)`
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;
const PageHeaderContainer = styled(PageHeader)`
  display: flex;
  justify-content: space-between;
`;
const PageBody = styled.div`
  display: flex;
  justify-content: center;
  flex: 1;
  overflow: hidden;
  padding: 0 ${HORIZONTAL_RHYTHM * 5}px;
`;
const Section = styled.div`
  flex: 1;
`;

function DeliveryManager() {
  const { showNotification } = useNotification();
  const {
    isFetching: isFetchingEmailSettings,
    runGql: requestEmailSettings
  } = useGql<TenantEmailSettingsResponseType>(TENANT_EMAIL_SETTINGS_QUERY);
  const {
    isFetching: isFetchingFTPSettings,
    runGql: requestFTPSettings
  } = useGql<TenantFTPSettingsResponseType>(TENANT_FTP_SETTINGS_QUERY);
  const {
    runGql: requestSetEmailSettings
  } = useGql<SetTenantEmailSettingsResponseType>(SET_TENANT_EMAIL_SETTINGS_MUTATION);
  const {
    runGql: requestSetFTPSettings
  } = useGql<SetTenantFTPSettingsResponseType>(SET_TENANT_FTP_SETTINGS_MUTATION);
  const {
    runGql: requestTestFTPConnection
  } = useGql<FTPVerifyCredsResponseType>(FTP_VERIFY_CREDS_MUTATION);
  const {
    runGql: requestTestEmailSettings
  } = useGql<testTenantEmailSettings>(TEST_TENANT_EMAIL_SETTINGS_MUTATION);
  // email config
  const [ emailServer, setEmailServer ] = React.useState('');
  const [ emailPort, setEmailPort ] = React.useState<string | null>(null);
  const [ emailUsername, setEmailUsername ] = React.useState('');
  const [ emailPassword, setemailPassword ] = React.useState('');
  const [ emailConnectionSecurity, setEmailConnectionSecurity ] = React.useState('');
  const [ emailSenderMail, setEmailSenderMail ] = React.useState('');
  const [ emailSenderName, setEmailSenderName ] = React.useState('');
  const [ emailSubject, setEmailSubject ] = React.useState('');
  const [ emailEnableAuthentication, setEmailEnableAuthentication ] = React.useState(false);
  const [ emailSettingsChanged, setEmailSettingsChanged ] = React.useState(false);
  const updateEmailSetting =
    (val: string | boolean | null, func: React.Dispatch<React.SetStateAction<string | boolean | null>>) => {
      func(val);
      setEmailSettingsChanged(true);
    };
  // ftp config
  const [ ftpProtocol, setFtpProtocol ] = React.useState('');
  const [ ftpServer, setFtpServer ] = React.useState('');
  const [ ftpPort, setFtpPort ] = React.useState<string | null>(null);
  const [ ftpUsername, setFtpUsername ] = React.useState('');
  const [ ftpPassword, setFtpPassword ] = React.useState('');
  const [ ftpPath, setFtpPath ] = React.useState('');
  const [ ftpSettingsChanged, setFtpSettingsChanged ] = React.useState(false);
  const updateFtpSetting =
    (val: string | boolean | null, func: React.Dispatch<React.SetStateAction<string | boolean | null>>) => {
      func(val);
      setFtpSettingsChanged(true);
    };

  const handleError = (errors: Array<ErrorType>) => {
    const { message } = errors[0];
    showNotification({
      type: 'error',
      message,
      lifespan: null
    });
  };

  React.useEffect(() => {
    requestEmailSettings().then((res) => {
      const settings = res.tenantEmailSettings;
      const port = get(settings, 'port', null);
      setEmailServer(get(settings, 'server', ''));
      setEmailPort(isNumber(port) ? port.toString() : port);
      setEmailUsername(get(settings, 'username', ''));
      setEmailConnectionSecurity(get(settings, 'connectionSecurity', ''));
      setEmailSenderMail(get(settings, 'senderMail', ''));
      setEmailSenderName(get(settings, 'senderName', ''));
      setEmailSubject(get(settings, 'subject', ''));
      setEmailEnableAuthentication(get(settings, 'enableAuthentication', false));
    });
  }, []);

  React.useEffect(() => {
    requestFTPSettings().then((res) => {
      const settings = res.tenantFtpSettings;
      const port = get(settings, 'port', null);
      setFtpProtocol(get(settings, 'protocol', ''));
      setFtpServer(get(settings, 'server', ''));
      setFtpPort(isNumber(port) ? port.toString() : port);
      setFtpUsername(get(settings, 'username', ''));
      setFtpPath(get(settings, 'path', ''));
    });
  }, []);

  const saveEmailSettings = () => {
    const vars = {
      settings: {
        server: emailServer,
        port: emailPort || '25',
        username: emailUsername,
        password: emailPassword,
        senderMail: emailSenderMail,
        senderName: emailSenderName,
        subject: emailSubject,
        enableAuthentication: emailEnableAuthentication,
        connectionSecurity: emailConnectionSecurity
      }
    };

    return requestSetEmailSettings(vars).catch((e) => {
      handleError(e);
      return Promise.reject();
    });
  };

  const saveFtpSettings = () => {
    const vars = {
      server: ftpServer,
      port: ftpPort || ftpServer === 'SFTP' ? '22' : '21',
      username: ftpUsername,
      password: ftpPassword,
      protocol: ftpProtocol,
      path: ftpPath
    };

    return requestSetFTPSettings(vars).catch((e) => {
      handleError(e);
      return Promise.reject();
    });
  };

  const handleSave = (): Promise<any> => {
    const promises: Array<Promise<any>> = [];

    if (emailSettingsChanged) {
      promises.push(saveEmailSettings());
      setEmailSettingsChanged(false);
    }

    if (ftpSettingsChanged) {
      promises.push(saveFtpSettings());
      setFtpSettingsChanged(false);
    }

    return Promise.all(promises);
  };

  const testFTPConnection = (): Promise<any> => {
    const vars = {
      server: ftpServer,
      port: ftpPort,
      username: ftpUsername,
      password: ftpPassword,
      protocol: ftpProtocol,
      path: ftpPath
    };
    return requestTestFTPConnection(vars).catch(() => {
      showNotification({
        type: 'error',
        message: 'The test connection was not successful.',
        lifespan: null
      });
    });
  };

  const sendTestEmail = (): Promise<any> => {
    const vars = {
      settings: {
        server: emailServer,
        port: emailPort,
        username: emailUsername,
        password: emailPassword,
        senderMail: emailSenderMail,
        senderName: emailSenderName,
        subject: emailSubject,
        enableAuthentication: emailEnableAuthentication,
        connectionSecurity: emailConnectionSecurity
      }
    };
    return requestTestEmailSettings(vars).catch(() => {
      showNotification({
        type: 'error',
        message: 'The test email was not successful.',
        lifespan: null
      });
    });
  };

  return (
    <PageContainer title={'Delivery Configuration'}>
      <PageHeaderContainer>
        <PageTitle>{'Delivery Configuration'}</PageTitle>
        <PageActions>
          <Button
            onClick={sendTestEmail}
            disabled={!emailServer}
            prefixIcon={<PaperPlaneIcon />}
            type={'outlined'}
          >
            {'Send Test Email'}
          </Button>
          <Button
            disabled={!ftpProtocol}
            onClick={testFTPConnection}
            prefixIcon={<GlobeIcon />}
            type={'outlined'}
          >
            {'Test FTP Connection'}
          </Button>
          <Button
            onClick={handleSave}
            prefixIcon={<SaveIcon />}
            type={'outlined'}
          >
            {'Save'}
          </Button>
        </PageActions>
      </PageHeaderContainer>
      <PageBody>
        <Section>
          <Email
            server={emailServer}
            setServer={(server) => updateEmailSetting(server, setEmailServer)}
            port={emailPort}
            setPort={(port) => updateEmailSetting(port, setEmailPort)}
            username={emailUsername}
            setUsername={(username) => updateEmailSetting(username, setEmailUsername)}
            password={emailPassword}
            setPassword={(password) => updateEmailSetting(password, setemailPassword)}
            connectionSecurity={emailConnectionSecurity}
            setConnectionSecurity={(security) => updateEmailSetting(security, setEmailConnectionSecurity)}
            senderMail={emailSenderMail}
            setSenderMail={(mail) => updateEmailSetting(mail, setEmailSenderMail)}
            senderName={emailSenderName}
            setSenderName={(name) => updateEmailSetting(name, setEmailSenderName)}
            subject={emailSubject}
            setSubject={(subject) => updateEmailSetting(subject, setEmailSubject)}
            enableAuthentication={emailEnableAuthentication}
            setEnableAuthentication={(auth) => updateEmailSetting(auth, setEmailEnableAuthentication)}
            isLoading={isFetchingEmailSettings}
          />
        </Section>
        <Section>
          <Delivery
            protocol={ftpProtocol}
            setProtocol={(protocol) => updateFtpSetting(protocol, setFtpProtocol)}
            server={ftpServer}
            setServer={(server) => updateFtpSetting(server, setFtpServer)}
            port={ftpPort}
            setPort={(port) => updateFtpSetting(port, setFtpPort)}
            username={ftpUsername}
            setUsername={(username) => updateFtpSetting(username, setFtpUsername)}
            password={ftpPassword}
            setPassword={(password) => updateFtpSetting(password, setFtpPassword)}
            path={ftpPath}
            setPath={(path) => updateFtpSetting(path, setFtpPath)}
            isLoading={isFetchingFTPSettings}
          />
        </Section>
      </PageBody>
    </PageContainer>
  );
}

export { DeliveryManager };
