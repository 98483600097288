export const ATTRIBUTE_QUERY = `
  query($filter: MetadataAttributesFilterArgs, $datasourceId: Int, $size: Int) {
    metadataAttributes (filter: $filter, datasourceId: $datasourceId, size: $size) {
      id,
      name
    }
  }
`;

export type AttributeType = {
  id: number,
  name: string
}

export type AttributeResponseType = {
  metadataAttributes: Array<AttributeType>
}
