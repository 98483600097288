export const SET_TENANT_EMAIL_SETTINGS_MUTATION = `
  mutation ($settings: EmailSettingsArgs!){
    setTenantEmailSettings(settings: $settings) {
      server
      port
      username
      senderMail
      senderName
      subject
      enableAuthentication
      connectionSecurity
    }
  }
`;

export type SetTenantEmailSettingsResponseType = {
  setTenantEmailSettings: {
    server: string,
    port: number,
    username: string,
    senderMail: string,
    senderName: string,
    subject: string,
    enableAuthentication: boolean,
    connectionSecurity: string,
  }
}
