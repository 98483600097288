import * as React from 'react';
import { styled } from 'linaria/react';
import {
  Dialog,
  Input,
  Button,
  Table,
  UserIcon,
  BuildingIcon,
  SearchIcon
} from '@sevone/scratch';
import { CoreStore } from '../../../store';
import { useGql } from '../../../hooks/use-gql';
import {
  HORIZONTAL_RHYTHM, VERTICAL_RHYTHM
} from '../../../utils/spacing';
import {
  TENANTS_QUERY,
  TenantsResponseType,
  TenantType
} from '../queries/current-tenant-datasources.query';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 600px;
  height: 500px;
`;

const HeaderWrapper = styled.div`
  flex: none;
  display: flex;
  align-items: center;
  margin-bottom: ${VERTICAL_RHYTHM}px;
`;

const HeaderControlWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${HORIZONTAL_RHYTHM}px;

  &:last-of-type {
    margin-right: 0;
  }
`;

const IconWrapper = styled.div`
  margin-right: ${HORIZONTAL_RHYTHM / 2}px;
`;

const SearchWrapper = styled.div`
  flex: 1;
`;

const TableWrapper = styled.div`
  flex: 1;
  overflow: hidden;
`;

const ButtonWrapper = styled.div`
  text-align: right;
`;

const LinkWrapper = styled.a`
  color: var(--sev1-primary-5-color);
  text-decoration: underline;
`;

type DatasourceType = TenantType['datasources'][number];

const columns = [
  {
    id: 'id',
    title: 'ID',
    width: '50px',
    render: (data: DatasourceType) => data.id
  },
  {
    id: 'name',
    title: 'Name',
    render: (data: DatasourceType) => data.name
  },
  {
    id: 'address',
    title: 'Address',
    render: (data: DatasourceType) => (
      <LinkWrapper href={data.address}>
        {data.address}
      </LinkWrapper>
    )
  },
  {
    id: 'type',
    title: 'Type',
    width: '110px',
    render: (data: DatasourceType) => data.dstype
  }
];

type Props = {
  hideModal: () => void
};

function ApiManagementModal(props: Props) {
  const { hideModal } = props;
  const { state } = CoreStore.useContainer();
  const {
    runGql: fetchTenantDatasources
  } = useGql<TenantsResponseType>(TENANTS_QUERY);
  const [ search, setSearch ] = React.useState('');
  const [
    datasources,
    setDatasources
  ] = React.useState<Array<DatasourceType>>([]);

  React.useEffect(() => {
    fetchTenantDatasources({ id: state.activeTenant?.id }).then((res) => {
      setDatasources(res.tenants[0].datasources);
    });
  }, []);

  return (
    <Dialog title={'API Management'} onClose={hideModal}>
      <Wrapper>
        <HeaderWrapper>
          <HeaderControlWrapper>
            <IconWrapper><UserIcon /></IconWrapper>
            {state.user?.username}
          </HeaderControlWrapper>
          <HeaderControlWrapper>
            <IconWrapper><BuildingIcon /></IconWrapper>
            {state.activeTenant?.name}
          </HeaderControlWrapper>
          <SearchWrapper>
            <Input
              prefixIcon={<SearchIcon />}
              placeholder={'Search by name...'}
              value={search}
              onChange={setSearch}
            />
          </SearchWrapper>
        </HeaderWrapper>
        <TableWrapper>
          <Table
            rows={datasources.filter((ds) => {
              return ds.name.toLowerCase().includes(search.toLowerCase());
            })}
            columns={columns}
          />
        </TableWrapper>
        <ButtonWrapper>
          <Button onClick={hideModal}>{'Close'}</Button>
        </ButtonWrapper>
      </Wrapper>
    </Dialog>
  );
}

export { ApiManagementModal };
