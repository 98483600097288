import * as React from 'react';
import { styled } from 'linaria/react';
import { css } from 'linaria';
import intersection from 'lodash-es/intersection';
import { Redirect, Prompt } from 'react-router-dom';
import {
  useNavigation,
  useModal,
  useNotification
} from '@sevone/insight-connect';
import { Tabs, Tab, Button, RemoveIcon, TrashIcon } from '@sevone/scratch';
import { Page } from '../../../components/page';
import { SectionType, LayoutType, WidgetType } from '../types';
import { useReportRuntime } from '../../../report-runtime';
import { Header } from './header';
import { WidgetGrid } from './widget-grid';
import { Sidebar } from './sidebar';
import { ReportVariablesPalette } from './report-variables-palette';
import { ReportVariablesPanel } from './report-variables-panel';
import { WidgetPalette } from './widget-palette';
import { WidgetEditor } from './widget-editor';
import { HORIZONTAL_RHYTHM, VERTICAL_RHYTHM } from '../../../utils/spacing';
import { Widget } from './widget';

const tabContentStyles = css`
  padding: ${VERTICAL_RHYTHM}px 0 0 0 !important;
`;

const Wrapper = styled(Page)`
  display: flex;
  flex-direction: column;
  background: var(--sev1-primary-2-color);
  color: var(--sev1-primary-2-contrast);
`;

const ContentWrapper = styled.div`
  display: flex;
  flex: 1;
  position: relative;
  overflow: hidden;
`;

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: ${VERTICAL_RHYTHM}px ${HORIZONTAL_RHYTHM}px;
  overflow: hidden;
  position: relative;
`;

const MaximizedWidget = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const TabsWrapper = styled.div`
  flex: 1;
  min-height: 0;
`;

const RemoveSectionWrapper = styled.span<{
  visible: boolean,
  onClick: React.MouseEventHandler
}>`
  cursor: pointer;
  visibility: ${(p) => (p.visible ? 'visible' : 'hidden')};
`;

function ReportViewer() {
  const {
    report,
    isDirty,
    reportEdits,
    activeSectionId,
    activeWidgetId,
    activeWidgetMaximized,
    renameReport,
    deleteReport,
    getSection,
    getSections,
    addSection,
    updateActiveSection,
    updateActiveWidget,
    setFullscreenWidget,
    deleteSection,
    renameSection,
    updateSectionLayout,
    reorderSections,
    getWidget,
    getWidgets,
    addWidget,
    deleteWidget
  } = useReportRuntime();
  const isNewReport = report.id === 'new-report';
  const { showModal } = useModal();
  const { showNotification } = useNotification();
  const { location, match } = useNavigation();
  const [
    showReportVariablesPalette,
    setShowReportVariablesPalette
  ] = React.useState(false);
  const [
    showReportVariablesPanel,
    setShowReportVariablesPanel
  ] = React.useState(true);
  const [
    showWidgetPalette,
    setShowWidgetPalette
  ] = React.useState(getWidgets(activeSectionId).length === 0);
  const [
    draggedWidget,
    setDraggedWidget
  ] = React.useState<WidgetType | null>(null);
  const [ editWidget, setEditWidget ] = React.useState<string | null>(null);
  const [ editVariable, setEditVariable ] = React.useState<string | null>(null);
  // Default to edit mode when it's a new report. Otherwise readonly mode.
  const [ isReadOnly, setIsReadOnly ] = React.useState(!isNewReport);
  const isWritable = report.isWritable && !isReadOnly;
  const navigationBlockingEdits: typeof reportEdits = [
    'addReportVariable',
    'updateReportVariable',
    'deleteReportVariable',
    'addWidget',
    'updateWidgetConfiguration',
    'deleteWidget',
    'addSection',
    'updateSectionLayout',
    'deleteSection'
  ];

  // Workaround because react-router's `<Prompt />` doesn't support page
  // refreshing or navigating away via manual url bar entry.
  React.useEffect(() => {
    const blockUnload = (e: Event) => {
      const shouldBlock = isDirty &&
        !!intersection(reportEdits, navigationBlockingEdits).length;

      if (shouldBlock && isWritable) {
        e.preventDefault();
        // Chrome workaround to actually trigger the block
        e.returnValue = false;
      }
    };
    window.addEventListener('beforeunload', blockUnload);

    return () => { window.removeEventListener('beforeunload', blockUnload); };
  }, [ isDirty, reportEdits, isWritable ]);

  const handleDeleteReport = React.useCallback(() => {
    return new Promise((resolve, reject) => {
      showModal({
        type: 'warning',
        header: 'Delete Report',
        message: () => (
          'Are you sure you want to delete this report? ' +
          'This cannot be undone.'
        ),
        actions: (props) => [
          <Button
            key="cancel"
            type="outlined"
            onClick={() => {
              reject();
              props.hideModal();
            }}
          >
            {'Cancel'}
          </Button>,
          <Button
            key="confirm"
            prefixIcon={<TrashIcon />}
            onClick={() => {
              deleteReport()
                .then(resolve)
                .catch((e) => {
                  showNotification({ type: 'error', message: e.message });
                  reject();
                })
                .finally(props.hideModal);
            }}
          >
            {'Yes, delete report'}
          </Button>
        ]
      });
    });
  }, [ showModal, deleteReport ]);

  const handleShowWidgetPalette = React.useCallback(() => {
    setEditVariable(null);
    setEditWidget(null);
    setShowReportVariablesPalette(false);
    setShowWidgetPalette(true);
  }, [ setEditVariable, setEditWidget, setShowReportVariablesPalette, setShowWidgetPalette ]);

  const handleShowReportVariablePalette = React.useCallback((id: string | null = null) => {
    setEditVariable(id);
    setShowReportVariablesPalette(true);
    setShowWidgetPalette(false);
    setEditWidget(null);
  }, [ setEditVariable, setShowReportVariablesPalette, setShowWidgetPalette, setEditWidget ]);

  // If requested section isn't found, redirect to the first one
  if (!getSection(activeSectionId)) {
    const defaultSectionId = report.content.sections[0].id;
    const redirectLocation = {
      pathname: `/reports/${match.params.id}/${defaultSectionId}`,
      search: `?${new URLSearchParams(location.params).toString()}`,
      hash: location.hash,
      state: location.state
    };

    return <Redirect to={redirectLocation} />;
  }

  const handleHideReportVariablePalette = () => {
    setEditVariable(null);
    setShowReportVariablesPalette(false);
  };

  const handleHideWidgetPalette = () => {
    setShowWidgetPalette(false);
  };

  const handleEditWidget = (id: string | null) => {
    // Don't allow users to edit widgets if the report itself can't be modified
    if (!isWritable) {
      return;
    }

    setEditVariable(null);
    setEditWidget(id);
    setShowReportVariablesPalette(false);
    setShowWidgetPalette(false);
  };

  const handleMaximizeWidget = (id: string | null) => {
    updateActiveWidget(activeSectionId, id, true);
  };

  const handleAddWidgetViaDrag = (layout: Array<LayoutType>) => {
    const newLayout = [ ...layout ];

    if (!draggedWidget) {
      return;
    }

    // TODO: The widget grid doesn't know the id of the new widget, so for
    // now we need to manually update it here like this. In the next version of
    // RGL we can remove this once its `onDrop` passes back the event.
    // ISSUE: https://github.com/STRML/react-grid-layout/pull/1065
    newLayout[newLayout.length - 1].i = draggedWidget.id;

    addWidget(activeSectionId, draggedWidget, newLayout);
    setDraggedWidget(null);
    handleEditWidget(draggedWidget.id);
  };

  const handleAddWidgetViaClick = (widget: WidgetType) => {
    const section = getSection(activeSectionId);

    if (!section) {
      return;
    }

    addWidget(section.id, widget);
    handleEditWidget(widget.id);
  };

  const handleDeleteSection = (section: SectionType) => {
    // We only need to warn the user if there are widgets in the section
    if (section.widgets.length === 0) {
      deleteSection(section.id);
      return;
    }

    showModal({
      type: 'warning',
      header: 'Delete Section',
      message: () => (`
        Are you sure you want to delete section ${section.title}?
        This cannot be undone.
      `),
      actions: ({ hideModal }) => [
        <Button key="cancel" type="outlined" onClick={hideModal}>
          {'Cancel'}
        </Button>,
        <Button
          key="delete"
          prefixIcon={<TrashIcon />}
          onClick={() => {
            deleteSection(section.id);
            hideModal();
          }}
        >
          {'Yes, delete my section'}
        </Button>
      ]
    });
  };

  const handleSectionChange = (sectionId: string) => {
    // If the user was editing a widget, we need to hide the editor because
    // the widget won't be visible in the new section.
    setEditWidget(null);
    updateActiveSection(parseInt(sectionId));
  };

  const handleLayoutChange = (layout: Array<LayoutType>) => {
    updateSectionLayout(activeSectionId, layout);
  };

  const handleAddSection = () => {
    const section = addSection();

    updateActiveSection(section.id);
    handleShowWidgetPalette();
  };

  const handleReorderSections = (order: Array<string>) => {
    reorderSections(order.map((id) => parseInt(id, 10)));
  };

  const handleDeleteWidget = (id: string) => {
    handleEditWidget(null);
    deleteWidget(id);
  };

  const handleFullscreenWidget = (id: string | null) => {
    if (id) {
      setFullscreenWidget(true);
    } else {
      setFullscreenWidget(false);
    }
  };

  const maximizedWidgetInstance = activeWidgetMaximized ? getWidget(activeWidgetId) : null;
  const shouldBlockNavigation = isDirty &&
    !!intersection(reportEdits, navigationBlockingEdits).length;

  return (
    <Wrapper title={report.name}>
      <Prompt
        // We only care if a report has unsaved changes if the user can actually
        // save those changes.
        when={shouldBlockNavigation && isWritable}
        message={(nextLocation) => {
          if (nextLocation.pathname.startsWith(`/reports/${report.id}`)) {
            return true;
          }

          return (
            'You may have unsaved changes. ' +
            'Are you sure you want to leave?'
          );
        }}
      />
      <Header
        report={report}
        isReadOnly={isReadOnly}
        isNewReport={isNewReport}
        isReportVariablesPanelVisible={showReportVariablesPanel}
        onReportNameChange={renameReport}
        onDeleteReport={handleDeleteReport}
        onShowReportVariablesPalette={handleShowReportVariablePalette}
        onToggleReadOnly={setIsReadOnly}
        onToggleReportVariablesPanel={setShowReportVariablesPanel}
        onShowWidgetPalette={handleShowWidgetPalette}
      />
      <ContentWrapper>
        <BodyWrapper>
          {showReportVariablesPanel &&
              <ReportVariablesPanel
                isWritable={isWritable}
                onEditVariable={handleShowReportVariablePalette}
              />
          }
          <TabsWrapper>
            {maximizedWidgetInstance ? (
              <MaximizedWidget>
                <Widget
                  isWritable={isWritable}
                  isEditing={maximizedWidgetInstance.id === editWidget}
                  isMaximized
                  widget={maximizedWidgetInstance}
                  onFullscreenWidget={handleFullscreenWidget}
                  onEditWidget={handleEditWidget}
                  onMaximizeWidget={handleMaximizeWidget}
                  onDeleteWidget={handleDeleteWidget}
                />
              </MaximizedWidget>
            ) : (
              <Tabs
                selectedTab={activeSectionId.toString()}
                onSelect={handleSectionChange}
                onAdd={isWritable ? handleAddSection : undefined}
                onReorder={isWritable ? handleReorderSections : undefined}
              >
                {getSections().map((section) => (
                  <Tab
                    className={tabContentStyles}
                    editable={isWritable}
                    key={section.id}
                    id={section.id.toString()}
                    title={section.title}
                    icon={getSections().length > 1 && (
                      <RemoveSectionWrapper
                        visible={isWritable}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          handleDeleteSection(section);
                        }}
                      >
                        <RemoveIcon />
                      </RemoveSectionWrapper>
                    )}
                    onChange={(id, title) => {
                      renameSection(parseInt(id), title);
                    }}
                  >
                    <WidgetGrid
                      isReadOnly={isReadOnly}
                      isWritable={report.isWritable}
                      widgets={section.widgets}
                      layout={section.layout}
                      editWidgetId={editWidget}
                      onFullscreenWidget={handleFullscreenWidget}
                      onLayoutChange={handleLayoutChange}
                      onAddWidget={handleAddWidgetViaDrag}
                      onEditWidget={handleEditWidget}
                      onDeleteWidget={handleDeleteWidget}
                      onMaximizeWidget={handleMaximizeWidget}
                    />
                  </Tab>
                ))}
              </Tabs>
            )}
          </TabsWrapper>
        </BodyWrapper>
        <Sidebar
          title={'Select a Widget'}
          visible={showWidgetPalette && !isReadOnly}
          onClose={handleHideWidgetPalette}
        >
          <WidgetPalette
            onDragWidget={setDraggedWidget}
            onAddWidget={handleAddWidgetViaClick}
          />
        </Sidebar>
        <Sidebar
          title={`${editVariable ? 'Edit' : 'Select a'} Variable`}
          visible={showReportVariablesPalette}
          onClose={handleHideReportVariablePalette}
        >
          <ReportVariablesPalette
            key={editVariable || ''}
            variableId={editVariable}
            onHide={handleHideReportVariablePalette}
          />
        </Sidebar>
        <Sidebar
          id={editWidget || ''}
          title={'Widget Settings'}
          visible={!!editWidget && !isReadOnly}
          onClose={() => { handleEditWidget(null); }}
        >
          <WidgetEditor
            key={editWidget || ''}
            widget={getWidget(editWidget)}
          />
        </Sidebar>
      </ContentWrapper>
    </Wrapper>
  );
}

export { ReportViewer };
