const GET_RELATIVE_TIMESPANS = `
{
  timespans: __type(name: "ReportTimeSpan") {
    options: enumValues {
      label: description
      timespan: name
    }
  }
}
`;

export type GetRelativeTimespansResponseType = {
  timespans: {
    options: Array<{
      label: string,
      timespan: string
    }>
  }
};

export { GET_RELATIVE_TIMESPANS };
