import * as React from 'react';
import { styled } from 'linaria/react';
import omit from 'lodash-es/omit';
import orderBy from 'lodash-es/orderBy';
import isEqual from 'lodash-es/isEqual';
import { IconButton, Select, Switch, RadioGroup, Radio, Button, AddIcon, TrashIcon } from '@sevone/scratch';
import { RuleBuilder, newRule, fieldOptionsByColumn } from '../rule-builder';
import { RuleType } from '../rule-builder/types';
import { ReportLinkType } from './types';
import { LinkColumn } from '../report-linking-manager';
import { VERTICAL_RHYTHM, HORIZONTAL_RHYTHM } from '../../../utils/spacing';


const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  padding: ${VERTICAL_RHYTHM}px ${HORIZONTAL_RHYTHM}px;
  
  > * {
    flex-grow: 1;
    min-width: 200px;
    border-right: 1px solid var(--sev1-primary-1-color);
    padding: 0 ${HORIZONTAL_RHYTHM}px;
  }

  > :first-child {
    padding-left: 0;
  }

  > :last-child {
    padding-right: 0;
    border-right: none;
  }
`;

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;


  > * {
    margin-bottom: ${VERTICAL_RHYTHM}px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const ButtonSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  > * {
    max-width: 200px; 
  }
`;

const ControlsWrapper = styled.div`
  display:flex;
  justify-content: space-between;
  align-items: center;
`;

const RemoveWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  align-items: flex-end;
`;

const IconWrapper = styled.div`
  cursor: pointer;

  &:hover {
    color: var(--sev1-primary-5-color);
  }
`;

type OptionType = { value: string | number, label: string };

type Props = {
  availableReports: {
    [key: string]: {
      id: number,
      name: string,
      isTemplate: boolean
    }
  },
  fetchingReports: boolean,
  id: number | undefined,
  name: string | null,
  column: string,
  selectedReports: Array<number>,
  enabled: boolean,
  filters: Array<RuleType>,
  onChange: (link: ReportLinkType) => void,
  onDelete: () => void
};

class Link extends React.Component<Props> {
  /**
   * Avoid re-rendering all links when only one changed. Especially prevents
   * input lag when editing the text in a filter rule value.
   */
  shouldComponentUpdate(nextProps: Props) {
    // availableReports should generally be consistent by identity, so no point
    // doing a deep comparison.
    if (this.props.availableReports !== nextProps.availableReports) {
      return true;
    }

    return !isEqual(
      omit(this.props, [ 'availableReports', 'onChange', 'onDelete' ]),
      omit(nextProps, [ 'availableReports', 'onChange', 'onDelete' ])
    );
  }

  getReportOptions = (filter = ''): Promise<Array<{label: string, options: OptionType[]}>> => {
    const { availableReports } = this.props;

    return new Promise((resolve) => {
      const templates: {label: string, options: OptionType[]} = { label: 'Templates', options: [] };
      const reports: {label: string, options: OptionType[]} = { label: 'Reports', options: [] };
      Object.values(availableReports)
        .filter((r) => r.name.toLowerCase().includes(filter.toLowerCase()))
        .sort((a) => { return a.isTemplate ? -1 : 1; })
        .slice(0, 100) // Only show 100 reports at a time.
        .sort((a, b) => a.name.localeCompare(b.name))
        .forEach((r) => {
          if (r.isTemplate) {
            templates.options.push({ label: r.name, value: r.id });
          } else {
            reports.options.push({ label: r.name, value: r.id });
          }
        });

      resolve([ templates, reports ]);
    });
  }

  getReportValues() {
    const { availableReports, selectedReports } = this.props;
    const reports = selectedReports.map((reportId) => {
      const details = availableReports[reportId];

      if (!details) {
        return null;
      }

      return {
        label: details.name,
        value: details.id
      };
    }).filter(Boolean) as OptionType[];

    return orderBy(reports, ({ label }) => (label ? label.toLowerCase() : ''));
  }

  handleEnableChange = (enabled: boolean) => {
    this.handleChange({ enabled });
  }

  handleColumnSelection = (column: { label: string, value: string }) => {
    this.handleChange({ column: column.value, filters: [] });
  }

  handleReportSelection = (reports: Array<{ label: string, value: number }>) => {
    const reportIds = reports.map((report) => report.value);

    this.handleChange({ reportIds });
  }

  handleChange(update: Partial<{
    column: string,
    filters: Array<RuleType>,
    reportIds: Array<number>,
    enabled: boolean
  }>) {
    const { id, name, column, filters, selectedReports, enabled, onChange } = this.props;
    const nextLink = {
      column,
      filters,
      reportIds: selectedReports,
      enabled,
      ...update,
      name,
      id
    };

    onChange(nextLink);
  }

  handleAddCondition = () => {
    this.handleChange({
      filters: [
        ...this.props.filters,
        newRule()
      ]
    });
  };

  render() {
    const {
      availableReports,
      fetchingReports,
      filters,
      column,
      enabled,
      onDelete
    } = this.props;
    const allFieldOptions = fieldOptionsByColumn[column] || [];
    const canAddRule = column && filters.length < allFieldOptions.length;
    return (
      <Wrapper>
        <SectionWrapper>
          <ControlsWrapper>
            <Switch
              checked={enabled}
              onChange={this.handleEnableChange}
            >
              {enabled ? 'Enabled' : 'Disabled'}
            </Switch>
            <RadioGroup
              value={column}
              onChange={(c: string) => this.handleChange({ column: c })}
            >
              <Radio value={LinkColumn.DEVICE}>{'Device'}</Radio>
              <Radio value={LinkColumn.OBJECT}>{'Object'}</Radio>
              <Radio value={LinkColumn.INDICATOR}>{'Indicator'}</Radio>
            </RadioGroup>
          </ControlsWrapper>
          <Select
            // We need a key here to make sure the options cache is reset
            // when this component receives the list of available reports.
            // Otherwise it won't reset until the user starts typing in a
            // filter.
            key={Object.keys(availableReports).length}
            isMulti
            placeholder={fetchingReports ? 'Loading...' : 'Select reports...'}
            label={'Links to report:'}
            options={this.getReportOptions}
            value={this.getReportValues()}
            onChange={this.handleReportSelection}
          />
          <RemoveWrapper>
            <IconWrapper>
              <IconButton disabled={!onDelete} onClick={onDelete}>
                <TrashIcon />
              </IconButton>
            </IconWrapper>
          </RemoveWrapper>
        </SectionWrapper>
        <RuleBuilder
          column={column}
          rules={filters || []}
          onChange={(rules) => {
            this.handleChange({ filters: rules });
          }}
        />
        {canAddRule &&
          <ButtonSectionWrapper>
            <Button
              onClick={this.handleAddCondition}
            >
              <AddIcon /><span style={{ marginLeft: '5px' }}>{'Add Condition'}</span>
            </Button>
          </ButtonSectionWrapper>
        }
      </Wrapper>
    );
  }
}

export { Link };
