const REPORT_QUERY = `
  query {
    reports {
      id
      name
      description
      updatedAt
      refreshInterval
      rotateInterval
      owner {
        id
        username
      }
      folder {
        id
        name
      }
      acl {
        id
        ownerId
        isPublic
        isReadOnly
        entries {
          sid {
            id
          }
          read
          write
        }
      }
      schedule {
        startDate
        timezone
        periodFrequency
        periodType
        emailDelivery
        emailRecipients
        ftpDelivery
        lastPrintStatus
        lastPrintTime
      }
      isTemplate
      isWritable
    }
  }
`;

export type ReportType = {
  id: number,
  name: string,
  description: string | null,
  updatedAt: number,
  owner: {
    id: number
    username: string
  },
  folder?: {
    id: number
    name: string
  },
  acl: {
    id: number,
    ownerId: number,
    isPublic: boolean
    isReadOnly: boolean,
    entries: Array<{
      sid: {
        id: number
      },
      read: boolean,
      write: boolean
    }>
  },
  schedule: {
    startDate: number,
    timezone: string,
    periodFrequency: number,
    periodType: 'HOURLY' | 'DAILY' | 'WEEKLY' | 'MONTHLY' | 'QUARTERLY' | 'YEARLY',
    emailDelivery: boolean,
    emailRecipients: Array<string>,
    ftpDelivery: boolean,
    lastPrintStatus: 'SUCCESS' | 'FAILURE' | 'NOT_RUN'
    lastPrintTime: number | null
  } | null,
  isTemplate: boolean,
  isWritable: boolean
};

export type ReportResponseType = {
  reports: Array<ReportType>
};

export { REPORT_QUERY };
