const DELETE_SCHEDULE = `
  mutation deleteReportSchedules($reportIds: [Int!]!) {
    deleteReportSchedules(reportIds: $reportIds) {
      rowsAffected
    }
  }
`;

export type DeleteScheduleResponseType = {
  deleteReportSchedule: {
    rowsAffected: number
  }
};

export { DELETE_SCHEDULE };
