import * as React from 'react';
import { styled } from 'linaria/react';
import { LoadingCircle } from '@sevone/scratch';
import isEqual from 'lodash-es/isEqual';
import isEmpty from 'lodash-es/isEmpty';
import zip from 'lodash-es/zip';
import sortBy from 'lodash-es/sortBy';
import { Page } from '../../components/page';
import { CoreStore } from '../../store';
import { isTruthy } from '../../utils/is-truthy';
import { useGql } from '../../hooks/use-gql';
import {
  REPORT_QUERY,
  ReportType, ReportResponseType
} from './get-reports.query';
import {
  FOLDER_QUERY,
  FolderType,
  FolderResponseType
} from './get-folders.query';
import {
  FilterType,
  generateOrFilter
} from './filter-reports';
import { Header } from './header';
import { Folders } from './folders';
import {
  immutableFolders,
  allReports as allReportsFolder,
  personal as personalFolder
} from './folders/immutable-folders';
import { FolderTreeType } from './folders/types';
import { Reports } from './reports';
import { ReportProperties } from './report-properties';
import { FolderPanel } from './folder-panel';
import { VERTICAL_RHYTHM, HORIZONTAL_RHYTHM } from '../../utils/spacing';

const Wrapper = styled(Page)`
  display: flex;
  flex-direction: column;
`;

const BodyWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: stretch;
  min-height: 0;
`;

const FoldersWrapper = styled.div`
  flex: none;
  margin: ${VERTICAL_RHYTHM}px ${HORIZONTAL_RHYTHM}px;
  overflow: hidden;
  position: relative;
  border-radius: .2em;
  border: 1px solid var(--sev1-primary-2-color);
`;

const ReportsWrapper = styled.div`
  flex: 1;
  margin: ${VERTICAL_RHYTHM}px ${HORIZONTAL_RHYTHM}px ${VERTICAL_RHYTHM}px 0;
  overflow: hidden;
  position: relative;
  border-radius: .2em;
  border: 1px solid var(--sev1-primary-2-color);
`;

const Loading = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  cursor: not-allowed;

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: var(--sev1-primary-4-color);
    opacity: 0.5;
  }
`;

function ReportManager() {
  const { state } = CoreStore.useContainer();
  const {
    runGql: doFetchReports,
    isFetching: isFetchingReports
  } = useGql<ReportResponseType>(REPORT_QUERY);
  const {
    runGql: doFetchFolders,
    isFetching: isFetchingFolders
  } = useGql<FolderResponseType>(FOLDER_QUERY);
  const [ reports, setReports ] = React.useState<Array<ReportType>>([]);
  const [ folders, setFolders ] = React.useState<Array<FolderType>>([]);
  const [
    selectedReportIds,
    setSelectedReportIds
  ] = React.useState<Array<ReportType['id']>>([]);
  const [
    selectedFolder,
    setSelectedFolder
  ] = React.useState<number | null>(null);
  const [
    editFolder,
    setEditFolder
  ] = React.useState<FolderType | null>(null);
  const [
    createFolder,
    setCreateFolder
  ] = React.useState<number | null>(null);
  const [ showReportSettings, setShowReportSettings ] = React.useState(false);
  const [
    searchFilters,
    setSearchFilters
  ] = React.useState<Array<FilterType>>([]);
  const [
    folderFilters,
    setFolderFilters
  ] = React.useState<Array<FilterType>>([]);
  const filters = React.useMemo(() => [
    ...searchFilters,
    ...folderFilters
  ], [ searchFilters, folderFilters ]);
  const selectedReports = reports.map((report) => {
    if (selectedReportIds.includes(report.id)) {
      return report;
    }

    return null;
  }).filter(isTruthy);

  const fetchReports = () => {
    return doFetchReports().then((res) => {
      setReports(res.reports);
      setSelectedReportIds([]);
      return res.reports;
    });
  };

  const fetchFolders = () => {
    return doFetchFolders().then((res) => {
      const sortedFolders = sortBy(res.folders, (folder) => folder.name.toUpperCase());
      setFolders(sortedFolders);
      return sortedFolders;
    });
  };

  const refreshData = () => {
    return Promise.all([ fetchReports(), fetchFolders() ]);
  };

  const generateFolderList = () => {
    const userId = state.user ? state.user.id : null;
    const nestedFolders: Array<FolderTreeType> = [];
    immutableFolders.forEach((folder) => {
      const parent: FolderTreeType = {
        id: folder.id,
        label: folder.name,
        parentId: folder.parentId,
        reportCount: folder.reportCount({ reports, userId }),
        filters: folder.filters
      };
      const children = folder.children({ folders, reports, userId });
      nestedFolders.push(...[ parent, ...children ]);
    });

    return nestedFolders;
  };

  const handleReportChange = (report: ReportType) => {
    setReports(reports.map((r) => {
      return (r.id !== report.id) ? r : report;
    }));
  };

  const handleShowReportSettings = () => {
    setEditFolder(null);
    setCreateFolder(null);
    setShowReportSettings(true);
  };

  const handleReportSelection = (ids: Array<ReportType['id']>) => {
    setSelectedReportIds(ids);

    if (ids.length === 1) {
      handleShowReportSettings();
    } else {
      setShowReportSettings(false);
    }
  };

  const handleEditFolder = (folderId: number) => {
    const folder = folders.find((f) => f.id === folderId);

    if (!folder) {
      return;
    }

    setEditFolder(folder);
    setCreateFolder(null);
    setShowReportSettings(false);
  };

  const handleCreateFolder = (parentId: number | null) => {
    setEditFolder(null);
    setCreateFolder(parentId);
    setShowReportSettings(false);
  };

  const handleSaveFolder = () => {
    refreshData();
  };

  const handleCloseFolderPanel = () => {
    setEditFolder(null);
    setCreateFolder(null);
  };

  const handleMoveReports = () => {
    refreshData();
  };

  const handleDeleteReports = () => {
    handleReportSelection([]);
  };

  // To enable searching subfolders, the folder filters need to be updated when a search query is added.
  // They also need to be updated when the search query is removed, so that all of the subfolders stop appearing
  // The logic to decide when to do this looked like a copy of the existing folder filter logic, so I put them
  // both into one function rather than having two nearly identical functions.
  const handleFiltersChange = ({ search, folder }:{search?: FilterType[], folder?: number | null}) => {
    if (search) {
      setSearchFilters(search);
    }
    // Reports themselves use a `folderId` of null when they are under the
    // "Personal" folder. The `folderList` though is a transformed list
    // of all custom folders and immutable folders, and immutable folders
    // have special ids that we use for selection. So if someone tries to
    // select a folder with an id of null, we need to make sure to translate
    // that to the correct personal folder id.
    const folderId = folder === null ? personalFolder.id : folder;
    const userId = state.user ? state.user.id : null;

    const folderList = generateFolderList();
    const getFolder = (fid: number | null) => folderList.find((f) => f.id === fid);
    const theFolder = folderId ? getFolder(folderId) : getFolder(selectedFolder);
    // folderId is only truthy when we've made a new selection. No need to update otherwise.
    if (!theFolder) {
      return;
    } else if (folderId) {
      setSelectedFolder(folderId);
    }

    // If we weren't just passed a search, check to see if one already exists.
    const hasSearch = (fil: FilterType[]) =>
      !isEmpty(searchFilters) &&
      !isEqual([ '' ], fil.find((filter) => filter.field === 'name')?.values);
    const isSearching = search ? hasSearch(search) : hasSearch(searchFilters);

    // If we have a search string, we need to add all subfolders to the folder filters.
    if (isSearching) {
      const folderFamily = [ theFolder ];

      const getChildren = (family: FolderTreeType[]) =>
        folderList.filter((f) =>
          f.parentId &&
          !family.some((f2) => f2.id === f.id) &&
          family.some((f2) => f2.id === f.parentId));

      while (getChildren(folderFamily).length !== 0) {
        folderFamily.push(...getChildren(folderFamily));
      }

      const familyFilters = folderFamily.map((f) => f.filters({ id: f.id, userId }));
      const combinedFilters = zip(...familyFilters).map(generateOrFilter);
      setFolderFilters(combinedFilters);
    } else {
      // Otherwise just show the selected folder
      setFolderFilters(theFolder.filters({ id: theFolder.id, userId }));
    }
  };

  // Effects when the user switches tenants.
  React.useEffect(() => {
    refreshData();
    handleFiltersChange({ folder: allReportsFolder.id });
  }, [ (state.activeTenant || {}).id ]);

  return (
    <Wrapper title={'Reports'}>
      <Header
        selectedReports={selectedReports}
        selectedFolder={folders.find((folder) => {
          return folder.id === selectedFolder;
        }) || null}
        fetchReports={fetchReports}
        fetchFolders={fetchFolders}
        onDeleteReports={handleDeleteReports}
        onSearchChange={(search) => handleFiltersChange({ search })}
      />
      <BodyWrapper>
        <FoldersWrapper>
          {isFetchingFolders &&
            <Loading>
              <LoadingCircle size="large" />
            </Loading>
          }
          <Folders
            folderTree={generateFolderList()}
            selectedFolder={selectedFolder}
            onFolderSelection={(folder) => handleFiltersChange({ folder })}
            onEditFolder={handleEditFolder}
            onCreateFolder={handleCreateFolder}
            onDeleteFolder={fetchFolders}
            onMoveReports={handleMoveReports}
          />
        </FoldersWrapper>
        <ReportsWrapper>
          {isFetchingReports &&
            <Loading>
              <LoadingCircle size="large" />
            </Loading>
          }
          <Reports
            folder={selectedFolder}
            reports={reports}
            selectedReports={selectedReportIds}
            filters={filters}
            onReportSelection={handleReportSelection}
            onFolderSelection={(folder) => handleFiltersChange({ folder })}
          />
        </ReportsWrapper>
        {(editFolder || createFolder) &&
          <FolderPanel
            key={editFolder ? editFolder.id : createFolder || ''}
            folder={editFolder}
            folders={folders}
            parentId={editFolder ? editFolder.parentId : createFolder}
            onSaveFolder={handleSaveFolder}
            onClose={handleCloseFolderPanel}
          />
        }
        {showReportSettings && selectedReports.length === 1 &&
          <ReportProperties
            key={selectedReports[0].id}
            report={selectedReports[0]}
            initialProperty="Details"
            onClose={() => { setShowReportSettings(false); }}
            onChange={handleReportChange}
          />
        }
      </BodyWrapper>
    </Wrapper>
  );
}

export { ReportManager };
