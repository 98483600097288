import * as React from 'react';
import { styled } from 'linaria/react';
import {
  Button,
  Table,
  Dialog
} from '@sevone/scratch';
import { TenantType } from '../queries/get-tenants.query';

const ModalContainer = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
`;
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 400px;
  width: 600px;
`;
const ActionButtonWrapper = styled.div`
  align-self: flex-end;
  & > * {
    margin: 5px;
  }
`;

type Props = {
  hideModal: () => void,
  tenants: Array<TenantType>,
  onConfirm: () => void
}

function DeleteTenantsModal(props: Props) {
  const { onConfirm, tenants, hideModal } = props;

  const handleConfirm = () => {
    onConfirm();
    hideModal();
  };

  return (
    <Dialog title={'Delete Tenant(s)'}>
      <ModalContainer>
        <ContentWrapper>
          <p>{'Are you sure you want to Delete all seleted tenants? This action cannot be undone.'}</p>
          <div style={{ overflow: 'auto' }}>
            <Table
              rows={tenants}
              columns={[
                {
                  id: 'id',
                  title: 'ID',
                  width: '55px',
                  render: (data) => data.id
                },
                {
                  id: 'name',
                  title: 'Name',
                  render: (data) => data.name
                }
              ]}
            />
          </div>
        </ContentWrapper>
        <ActionButtonWrapper>
          <Button onClick={handleConfirm}>
            {'OK'}
          </Button>
          <Button onClick={hideModal}>
            {'Cancel'}
          </Button>
        </ActionButtonWrapper>
      </ModalContainer>
    </Dialog>
  );
}

export { DeleteTenantsModal };
