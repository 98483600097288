export const LOGOS_QUERY = `
  query ($filter: TenantLogoFilterArgs) {
    tenantLogos(filter: $filter) {
      id
      image
    }
  }
`;

export type LogoType = {
  id: number,
  image: string
}

export type LogosResponseType = {
  tenantLogos: Array<LogoType>
}
