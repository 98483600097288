import * as React from 'react';
import { styled } from 'linaria/react';
import { useNotification, useNavigation } from '@sevone/insight-connect';
import { Dialog, Table, Input, Button, LoadingCircle } from '@sevone/scratch';
import { SWITCH_TENANT_QUERY, SwitchTenantResponseType } from './queries/switch-tenant.mutation';
import { TENANTS_QUERY, TenantType, TenantsResponseType } from './queries/get-tenants.query';
import { CoreStore } from '../../../../store';
import { useDebounce } from '../../../../hooks/use-debounce';
import { useGql } from '../../../../hooks/use-gql';

const LoadingWrapper = styled.div`
  align-self: center;
`;

const TableWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 500px;
  height: 300px;
  margin-top: 10px;
  overflow: auto;
`;

const ButtonsWrapper = styled.div`
  margin-top: 10px;
  text-align: right;

  & > * {
    margin-left: 10px;
  }
`;

const columns = [
  {
    id: 'name',
    title: 'Name',
    render: (data: { [key: string]: any }) => data.name
  }
];

type Props = {
  hideModal: () => void
};

function SwitchTenant(props: Props) {
  const { hideModal } = props;
  const { state, login } = CoreStore.useContainer();
  const { showNotification } = useNotification();
  const { navigateTo } = useNavigation();
  const [ searchTerm, setSearchTerm ] = React.useState('');
  const debouncedSearch = useDebounce(searchTerm, 200);
  const [ page, setPage ] = React.useState(1);
  const [ selected, setSelected ] = React.useState(state.activeTenant ? [ state.activeTenant.id ] : []);
  const { isFetching, runGql: fetchTenants } = useGql<TenantsResponseType>(TENANTS_QUERY);
  const { runGql: switchTenants } = useGql<SwitchTenantResponseType>(SWITCH_TENANT_QUERY);
  const [ tenants, setTenants ] = React.useState<Array<TenantType>>([]);
  const [ filteredRows, setFilteredRows ] = React.useState<Array<TenantType>>([]);

  React.useEffect(() => {
    fetchTenants().then((res) => {
      setTenants(res.tenants);
    }).catch((errors) => {
      const { message } = errors[0];
      showNotification({
        type: 'error',
        message,
        lifespan: null
      });
    });
  }, []);

  React.useEffect(() => {
    setFilteredRows(tenants.filter((tenant) => tenant.name.toLowerCase().includes(debouncedSearch.toLowerCase())));
  }, [ debouncedSearch, tenants ]);

  const handleTenantSelection = (ids: Array<number>) => {
    if (ids.length > 0) {
      setSelected([ ids[ids.length - 1] ]);
    } else {
      setSelected([]);
    }
  };

  const handleSwitchTenant = () => {
    if (selected.length !== 1) {
      return Promise.reject();
    }

    return switchTenants({
      tenantId: selected[0]
    }).then((response: SwitchTenantResponseType) => {
      const { success, token, user } = response.switchTenant;

      if (!success || !user || !token) {
        return;
      }

      login({ authToken: token, user });
      hideModal();
      navigateTo('/');
    }).catch((errors) => {
      const { message } = errors[0];
      showNotification({
        type: 'error',
        message,
        lifespan: null
      });
    });
  };

  return (
    <Dialog title={'Switch Tenant'} onClose={hideModal}>
      <div>
        <Input
          placeholder={'Search'}
          value={searchTerm}
          onChange={setSearchTerm}
        />
        <TableWrapper>
          {isFetching ? <LoadingWrapper><LoadingCircle size={'large'} /></LoadingWrapper> :
            <Table
              rows={filteredRows}
              columns={columns}
              selectedRows={selected}
              pageSize={50}
              page={page}
              onRowSelection={handleTenantSelection}
              onPageChange={setPage}
            />
          }
        </TableWrapper>
        <ButtonsWrapper>
          <Button
            disabled={selected.length !== 1 || selected[0] === (state.activeTenant || {}).id}
            onClick={handleSwitchTenant}
          >
            {'Switch'}
          </Button>
          <Button secondary onClick={hideModal}>{'Cancel'}</Button>
        </ButtonsWrapper>
      </div>
    </Dialog>
  );
}

export { SwitchTenant };
