const GET_REPORT = `
  query($id: Int) {
    reports(id: $id) {
      name
      description
      refreshInterval
      rotateInterval
      folder {
        id
      }
      content
      isTemplate
    }
  }
`;

export type GetReportResponseType = {
  reports: Array<{
    name: string,
    description: string | null,
    refreshInterval: number,
    rotateInterval: number,
    folder: {
      id: number
    } | null,
    content: string
    isTemplate: boolean
  }>
};

export { GET_REPORT };
