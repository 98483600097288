// @ts-nocheck
// eslint-disable-next-line
const basePathPattern: string = BASE_PATH;
// eslint-disable-next-line
const loginUrl: string = LOGIN_URL;
// eslint-disable-next-line
const wdkServerHost: string = new URLSearchParams(location.search).get('wdkserver_host') || WDKSERVER_HOST;
// eslint-disable-next-line
const serverHost: string = new URLSearchParams(location.search).get('retinaserver_host') || RETINASERVER_HOST;
// eslint-disable-next-line
const gitCommit: string = GIT_COMMIT;
// eslint-disable-next-line
const gitBranch: string = GIT_BRANCH;
// eslint-disable-next-line
const gitTag: string = GIT_TAG;
const nodeEnv: string = process.env.NODE_ENV || '';
// eslint-disable-next-line
const oidcEnabled: boolean = OIDC_ENABLED.toUpperCase() == "TRUE";
// eslint-disable-next-line
const oidcRedirectEnabled: boolean = OIDC_REDIRECT_ENABLED.toUpperCase() == "TRUE";
// eslint-disable-next-line
const oidcSignoutEnabled: boolean = OIDC_SIGNOUT_ENABLED.toUpperCase() == "TRUE";
// eslint-disable-next-line
const oidcAuthority: string = OIDC_AUTHORITY;
// eslint-disable-next-line
const oidcClientId: string = OIDC_CLIENT_ID;
// eslint-disable-next-line
const oidcClientSecret: string = OIDC_CLIENT_SECRET;
// eslint-disable-next-line
const oidcResponseType: string = OIDC_RESPONSE_TYPE;
// eslint-disable-next-line
const oidcScope: string = OIDC_SCOPE;
// eslint-disable-next-line
const obiEnabled: boolean = OBI_ENABLED.toUpperCase() == "TRUE";
// eslint-disable-next-line
const obiNewFeatures: string = OBI_NEW_FEATURES;

export {
  basePathPattern,
  loginUrl,
  oidcEnabled,
  oidcRedirectEnabled,
  oidcSignoutEnabled,
  oidcAuthority,
  oidcClientId,
  oidcClientSecret,
  oidcResponseType,
  oidcScope,
  wdkServerHost,
  serverHost,
  gitCommit,
  gitBranch,
  gitTag,
  nodeEnv,
  obiEnabled,
  obiNewFeatures
};
