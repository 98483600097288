import {
  generateFolderFilter,
  generateEqualsFilter
} from '../../filter-reports';
import { ReportType } from '../../get-reports.query';
import { FolderType } from '../../get-folders.query';
import { FolderTreeType, FilterArgsType } from '../types';

const id = -6;

const filters = (props: FilterArgsType) => {
  return [
    generateFolderFilter([ props.id === id ? null : props.id ]),
    generateEqualsFilter('isTemplate', false),
    generateEqualsFilter('owner.id', props.userId)
  ];
};

const personal = {
  id,
  parentId: null,
  name: 'Personal',
  reportCount: ({ reports, userId }: {
    reports: Array<ReportType>,
    userId: number | null
  }): number => {
    return reports.filter((report) => {
      return report.folder === null && report.owner.id === userId;
    }).length;
  },
  children: (
    { folders }: { folders: Array<FolderType>}
  ): Array<FolderTreeType> => {
    return folders.map((folder) => {
      const child = {
        id: folder.id,
        label: folder.name,
        parentId: folder.parentId,
        reportCount: folder.reportCount,
        filters: (props: FilterArgsType) => { return filters(props); }
      };

      // Nest top level custom folders under 'Personal'
      if (folder.parentId === null) {
        child.parentId = id;
      }

      return child;
    });
  },
  filters
};

export { personal };
