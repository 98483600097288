import { flowViewSchema } from '@sevone/insight-connect';
import { Configure } from './configure';
import { Variable } from './variable';
import { VariableType } from './types';

const flowViews: VariableType = {
  label: 'Flow Views',
  schema: flowViewSchema,
  defaultConfiguration: {
    datasources: [],
    isAggregated: true,
    isClearable: true,
    selectAll: false
  },
  configure: Configure,
  render: Variable
};

export { flowViews };
