const MULTI_TENANT_QUERY = `
  query {
    isMultiTenant
  }
`;

export type MultiTenantResponseType = {
  isMultiTenant: boolean
};

export { MULTI_TENANT_QUERY };
