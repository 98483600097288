import * as React from 'react';
import { FlowViewSelector, FlowViewType } from '@sevone/insight-connect';
import { RenderVariableProps } from '../../types';
import { useSingleSelection } from '../use-single-selection';
import { ConfigurationType, OptionType, ValueType } from './types';

type Props = RenderVariableProps<ConfigurationType, OptionType, ValueType>;

function Variable(props: Props) {
  const { value, options, config, onValueChange } = props;

  const getOptions = () => {
    if (config.selectAll) {
      return undefined;
    }

    return options.length > 0 ? options : undefined;
  };

  const handleChange = (values: Array<FlowViewType>) => {
    const nextValue = values.length === 1 ? {
      flowView: values[0]
    } as const : null;

    onValueChange(nextValue);
  };

  const handleFetchValueError = () => {
    onValueChange(null);
  };

  const {
    isSingleOption,
    handleOptionsChange
  } = useSingleSelection(value?.flowView, handleChange, [
    options,
    config.selectAll
  ]);

  return (
    <FlowViewSelector
      clearable={config.isClearable}
      disabled={isSingleOption}
      isMulti={false}
      isAggregated={config.isAggregated}
      datasources={config.datasources}
      value={value ? [ value.flowView ] : []}
      flowViewOptions={getOptions()}
      onChange={handleChange}
      onOptionsChange={handleOptionsChange}
      onFetchValueError={handleFetchValueError}
    />
  );
}

export { Variable };
