export const TENANT_FTP_SETTINGS_QUERY = `
  query {
    tenantFtpSettings {
      server
      port
      username
      protocol
      path
    }
  }
`;

export type TenantFTPSettingsResponseType= {
  tenantFtpSettings: {
    server?: string,
    port?: number,
    username?: string,
    protocol?: string
    path?: string
  } | null
}
