export const UPDATE_TENANT_MUTATION = `
  mutation ($id: Int!, $tenant: TenantDetailsArgs!) {
    updateTenant(id: $id, tenant: $tenant) {
      id
    }
  }
`;

export type UpdateTenantResponseType = {
  updateTenant: { id: number }
}
