import * as React from 'react';
import { useDrop } from 'react-dnd';
import { FolderType } from '../get-folders.query';
import { ReportType } from '../get-reports.query';
import { itemTypes } from './item-types';
import { DragItemType } from './report-drag-source';

type Props = {
  folderId: FolderType['id'],
  disabled: boolean,
  children: React.ReactNode,
  onDrop?: (
    folderId: FolderType['id'],
    reportIds: Array<ReportType['id']>
  ) => void,
  onHover?: () => void,
  onLeave?: () => void
};

function ReportDropTarget(props: Props) {
  const { folderId, disabled, children, onDrop, onHover, onLeave } = props;
  const [
    { isOverCurrent },
    drop
  ] = useDrop<DragItemType, void, { isOverCurrent: boolean }>({
    accept: itemTypes.REPORT,
    canDrop: () => !disabled,
    drop: (item) => {
      const { selectedReports } = item;

      if (onDrop) {
        onDrop(folderId, selectedReports);
      }
    },
    collect: (monitor) => ({
      isOverCurrent: monitor.isOver({ shallow: true })
    })
  });

  React.useEffect(() => {
    if (isOverCurrent && onHover) {
      onHover();
    } else if (onLeave) {
      onLeave();
    }
  }, [ isOverCurrent ]);

  return (
    <div ref={drop}>
      {children}
    </div>
  );
}

export { ReportDropTarget };
