export const ROLES_QUERY = `
  query {
    roles {
      id
      name
      permissions {
        id
        name
      }
    }
  }
`;

export type PermissionType = {
  id: string,
  name: string
};

export type RoleType = {
  id: string,
  name: string,
  permissions: Array<PermissionType>
};

export type RolesResponseType = {
  data: {
    data: {
      roles: Array<RoleType>
    },
    errors?: Array<{
      message: string
    }>
  }
};
