/* eslint-disable camelcase */
import * as React from 'react';
import { useNavigation } from '@sevone/insight-connect';
import { LoadingCircle } from '@sevone/scratch';
import { styled } from 'linaria/react';
import { useGql } from '../../hooks/use-gql';
import { CoreStore } from '../../store';
import { openidCallback } from '../../utils/openid-connect';
import {
  AUTHENTICATE_OIDC_QUERY,
  AuthenticateOidcResponseType
} from './authenticateOidc.mutation';

const LoadingWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

function OpenIDCallbackPage() {
  const { login } = CoreStore.useContainer();
  const { navigateInPlace } = useNavigation();
  const {
    runGql: authenticateOidc
  } = useGql<AuthenticateOidcResponseType>(AUTHENTICATE_OIDC_QUERY);

  React.useEffect(() => {
    let redirectUrl = '/';
    // server login response handler
    const handleOidcLogin = (res:AuthenticateOidcResponseType) => {
      // check for success, user and token
      const { success, user, token } = res.authenticateOidc;
      if (!success || !user || !token) {
        throw new Error('Unable to validate OpenID Connect id_token.');
      }
      login({ authToken: token, user });
    };
    // openid signin success handler
    const onSuccess = async (openid:{ id_token:string, state:any }) => {
      const { id_token, state } = openid;
      // retrieve stored redirectUrl
      redirectUrl = state.redirectUrl || '/';
      // login with server using openid id_token
      await authenticateOidc({ oidcToken: id_token }).then(handleOidcLogin);
      // return openid info to be stored
      return openid;
    };
    openidCallback(onSuccess).then(() => {
      navigateInPlace(redirectUrl);
    });
  }, []);
  return (
    <LoadingWrapper>
      <LoadingCircle size="large" />
    </LoadingWrapper>
  );
}

export { OpenIDCallbackPage };
