import { DatasourceType } from './queries/get-datasource.query';

export const COLUMNS = [
  {
    id: 'name',
    title: 'Name',
    render: (data: DatasourceType) => data.name,
    sort: (row: DatasourceType, otherRow: DatasourceType) => {
      const { name } = row;
      const { name: otherName } = otherRow;

      if (name < otherName) {
        return -1;
      }
      if (name > otherName) {
        return 1;
      }

      return 0;
    }
  }
];
