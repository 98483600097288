import * as React from 'react';
import { styled } from 'linaria/react';
import { IconButton, Select, TrashIcon } from '@sevone/scratch';
import { ValueSelector } from './value-selector';
import { RuleType } from './types';
import { HORIZONTAL_RHYTHM } from '../../../utils/spacing';

const RemoveWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  align-items: flex-end;
`;

const IconWrapper = styled.div`
  cursor: pointer;

  &:hover {
    color: var(--sev1-primary-5-color);
  }
`;

const RuleWrapper = styled.div`
  display: flex;
  flex-direction: column;

  > * {
    margin-bottom: ${HORIZONTAL_RHYTHM}px;
    &:last-child {
      margin-bottom:0;
    }
  }
`;

const FieldPredicateWrapper = styled.div`
  display: flex;

  > * {
    flex-grow: 1;
    margin-right: ${HORIZONTAL_RHYTHM}px;
    &:last-child {
      margin-right:0;
    }
  }
`;

type OptionType = {
  value: string,
  label: string
};

const operatorOptionsByField: { [field: string]: OptionType[] } = {
  inDeviceTypePaths: [
    { value: 'includes', label: 'includes' }
  ],
  objectTypePaths: [
    { value: 'includes', label: 'includes' }
  ],
  DEFAULT: [
    { value: 'like', label: 'is like' }
  ]
};

type Props = {
  rule: RuleType,
  column: string,
  fieldOptions: Array<{ value: string, label: string }>,
  onDelete?: () => void,
  onChange: (value: RuleType) => void
};

export function Rule(props: Props) {
  const { column, fieldOptions, rule, onDelete, onChange } = props;
  const { field, operator, value } = rule;
  let operatorOptions: OptionType[];

  if (field) {
    operatorOptions = operatorOptionsByField[field] || operatorOptionsByField.DEFAULT;
  } else {
    operatorOptions = [];
  }

  const getFieldValue = () => {
    if (!field) {
      return null;
    }

    const option = fieldOptions.find((op) => op.value === field);
    if (!option) {
      return null;
    }
    const label = option ? option.label : field;

    return {
      value: field,
      label
    };
  };

  const getOperatorValue = () => {
    if (!operator) {
      return null;
    }

    const option = operatorOptions.find((op: OptionType) => op.value === operator);
    const label = option ? option.label : operator;

    return {
      value: operator,
      label
    };
  };

  const getValueValue = () => {
    return value;
  };

  const handleFieldChange = (selection: OptionType) => {
    const nextValue = {
      ...rule,
      value: null,
      field: selection.value
    };
    const nextOperatorOptions = operatorOptionsByField[selection.value] ||
      operatorOptionsByField.DEFAULT;

    if (!nextOperatorOptions.find((op) => op.value === operator)) {
      nextValue.operator = nextOperatorOptions[0].value;
    }

    onChange(nextValue);
  };

  const handleOperatorChange = (selection: OptionType) => {
    onChange({
      ...rule,
      operator: selection.value
    });
  };

  const handleValueChange = (newValue: string) => {
    onChange({
      ...rule,
      value: newValue
    });
  };

  return (
    <RuleWrapper>
      <FieldPredicateWrapper>
        <Select
          value={getFieldValue()}
          onChange={handleFieldChange}
          options={fieldOptions}
          label={'Field'}
        />
        <Select
          value={getOperatorValue()}
          options={operatorOptions}
          onChange={handleOperatorChange}
          disabled
          label={'Operator'}
        />
      </FieldPredicateWrapper>
      <ValueSelector
        column={column}
        field={field || ''}
        operator={operator || ''}
        value={getValueValue() || ''}
        onChange={handleValueChange}
      />
      {onDelete &&
        <RemoveWrapper>
          <IconWrapper>
            <IconButton onClick={onDelete}>
              <TrashIcon />
            </IconButton>
          </IconWrapper>
        </RemoveWrapper>
      }
    </RuleWrapper>
  );
}
