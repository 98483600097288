
import { WebStorageStateStore } from 'oidc-client';
import {
  oidcRedirectEnabled,
  oidcClientId,
  oidcAuthority,
  oidcClientSecret,
  oidcScope
} from '../../global';

/* custom StateStore used to return a state for IdP-initated
 * flows that do not specify one */
class LocalStorageStateStore extends WebStorageStateStore {
  remove(state:string) {
    if (!oidcRedirectEnabled && state === '0') {
      // IdP-initiated flow has missing state
      const replacementState = {
        id: '0', // missing state key
        code_verifier: '0',
        data: {},
        client_id: oidcClientId,
        authority: oidcAuthority,
        client_secret: oidcClientSecret,
        scope: oidcScope
      };
      return Promise.resolve(JSON.stringify(replacementState));
    }
    return super.remove(state);
  }
}

export { LocalStorageStateStore };
