import {
  ResourceType,
  ResourcesType,
  ResourceTypesType
} from '@sevone/insight-connect';

const MAX_RESOURCES_DISPLAYED = 4;

function getFilterDataOfType<T extends ResourcesType>(
  type: ResourceTypesType,
  hierarchicalData: Array<ResourceType>,
  value: ResourceType | null
) {
  const data = [ ...hierarchicalData, value ].filter((d) => {
    return d && d.type === type;
  }).reduce((prev, curr) => {
    // @ts-ignore: Not sure how to properly type this...
    return prev.concat(curr.resources);
  }, [] as Array<T>).map((resource: T) => resource);

  return data;
}

export {
  MAX_RESOURCES_DISPLAYED,
  getFilterDataOfType
};
