import * as React from 'react';
import { ReportType } from '../pages/report/types';
import { ReportStore } from './report-store';
import { ReportLinkController } from './report-link-controller';
import { ReportVariableManager } from './report-variable-manager';
import { WidgetLinkManager } from './widget-link-manager';
import { WidgetManager } from './widget-manager';

type ReportHandlerProps = {
  report: ReportType,
  children: React.ReactNode
}

function ReportHandler(props: ReportHandlerProps) {
  const { report, children } = props;
  const { setReport } = ReportStore.useContainer();

  React.useEffect(() => {
    setReport(report);
  }, [ report ]);

  return <>{children}</>;
}

type ReportRuntimeProps = {
  report: ReportType,
  children: React.ReactNode
}

// We only need this component to setup the report store so that we can call
// `setReport()` below it to provide the report to the rest of the runtime.
function ReportRuntime(props: ReportRuntimeProps) {
  const { report, children } = props;

  return (
    <ReportLinkController.Provider>
      <WidgetManager.Provider>
        <ReportVariableManager.Provider>
          <WidgetLinkManager.Provider>
            <ReportStore.Provider key={report.id} initialState={{ report }}>
              <ReportHandler report={report}>
                {children}
              </ReportHandler>
            </ReportStore.Provider>
          </WidgetLinkManager.Provider>
        </ReportVariableManager.Provider>
      </WidgetManager.Provider>
    </ReportLinkController.Provider>
  );
}

export { ReportRuntime };
