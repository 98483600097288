import * as React from 'react';
import { css } from 'linaria';
import { styled } from 'linaria/react';
import { SizeMe } from 'react-sizeme';
import {
  LinkIcon,
  InlineEdit,
  AngleRightIcon
} from '@sevone/scratch';
import { HORIZONTAL_RHYTHM } from '../../../../../utils/spacing';
import {
  useReportRuntime,
  useWidgetLinks
} from '../../../../../report-runtime';
import { WidgetType, FacetStackType } from '../../../types';
import { ActionBar } from './action-bar';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: .8rem;
  padding: .2em .4em;
  border-bottom: 1px solid var(--sev1-primary-1-color);
`;

const dragHandlerStyles = css`
  flex: 1;
  align-self: stretch;
  min-width: 10px;
  cursor: move;
`;

const ActiveLinkIndicatorWrapper = styled.div<{ active: boolean }>`
  margin-right: ${HORIZONTAL_RHYTHM / 2}px;
  color: ${(p) => (p.active ? 'var(--sev1-primary-5-color)' : 'inherit')};
`;

const ParentNameWrapper = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ParentNameSeparator = styled.div`
  padding: 0 ${HORIZONTAL_RHYTHM / 2}px;
`;

const NameWrapper = styled.div<{
  isWritable: boolean,
  onDoubleClick: React.MouseEventHandler
}>`
  min-width: 100px;
  position: relative;
  z-index: 1;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: -2px;
    bottom: 0;
    left: -2px;
    z-index: -1;
    border-radius: 2px;
    transition: background-color .1s ease-in;
  }

  &:hover {
    &:before {
      background: ${(p) => (p.isWritable ? 'var(--sev1-primary-2-color)' : '')};
    }
  }
`;

const ActionBarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: auto;
  flex: 1;
`;

type Props = {
  isWritable: boolean,
  isEditing?: boolean,
  isMaximized?: boolean,
  widget: WidgetType,
  facetStack: FacetStackType | null,
  exportDataFn: ((mime: string) => Blob | Promise<Blob | null> | null) | null,
  onEditWidget: (id: string) => void,
  onDeleteWidget: (id: string) => void
  onMaximizeWidget: (id: string | null) => void
  onFullscreenWidget: (fullscreen: boolean) => void
};

function WidgetHeader(props: Props) {
  const {
    isWritable,
    isEditing,
    isMaximized,
    widget,
    facetStack,
    exportDataFn,
    onEditWidget,
    onDeleteWidget,
    onMaximizeWidget,
    onFullscreenWidget
  } = props;
  const {
    getStack,
    getWidget,
    renameWidget,
    refreshWidget
  } = useReportRuntime();
  const { getWidgetLinksByChild } = useWidgetLinks();
  const [ nameUpdate, setNameUpdate ] = React.useState(0);
  const [ link ] = getWidgetLinksByChild(widget.id);
  const isLinked = !!link;
  // We want to strip out any null facets, essentially removing those schemas
  // from the widget's list of known facets to process.
  const facets = (facetStack?.list() || []).filter((facet) => {
    return facet.data !== null;
  });
  // Widget links have no dependencies (other than the parent widget), so we
  // know all the facets in here are directly from the parent widget.
  const linkFacets = link ? getStack(link.id)?.list() || [] : [];
  const parentWidget = link ? getWidget(link.parentId) : null;
  const usingLinkFacets = facets.filter((facet) => {
    return linkFacets.includes(facet);
  }).length > 0;

  const handleEditWidget = () => {
    onEditWidget(widget.id);
  };

  const handleNameChange = (name: string) => {
    setNameUpdate(nameUpdate + 1);
    renameWidget(widget.id, name);
  };

  return (
    <Wrapper onDoubleClick={handleEditWidget}>
      {parentWidget &&
        <>
          <ActiveLinkIndicatorWrapper active={usingLinkFacets}>
            <LinkIcon />
          </ActiveLinkIndicatorWrapper>
          <ParentNameWrapper>
            {parentWidget.name}
          </ParentNameWrapper>
          <ParentNameSeparator><AngleRightIcon /></ParentNameSeparator>
        </>
      }
      <NameWrapper
        isWritable={isWritable}
        // Don't trigger the edit widget action when trying to double click
        // the name to highlight the text.
        onDoubleClick={(e: React.MouseEvent) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <InlineEdit
          key={`${widget.name}-${nameUpdate}`}
          multiLine={false}
          disabled={!isWritable}
          value={widget.name}
          onBlur={handleNameChange}
        />
      </NameWrapper>
      <SizeMe>
        {({ size }) => (
          <ActionBarWrapper className={isWritable ? dragHandlerStyles : ''}>
            {size.width &&
              <ActionBar
                widget={widget}
                isLinked={isLinked}
                isCompact={size.width < 200}
                isWritable={isWritable}
                isEditing={!!isEditing}
                isMaximized={!!isMaximized}
                refreshWidget={refreshWidget}
                exportDataFn={exportDataFn}
                onEditWidget={handleEditWidget}
                onDeleteWidget={onDeleteWidget}
                onMaximizeWidget={onMaximizeWidget}
                onFullscreenWidget={onFullscreenWidget}
              />
            }
          </ActionBarWrapper>
        )}
      </SizeMe>
    </Wrapper>
  );
}

export { WidgetHeader, dragHandlerStyles };
