import { ThemeType } from './get-themes.query';

export const UPDATE_THEME_MUTATION = `
  mutation UpdateTheme (
    $id: Int!
    $theme: ThemeInputArgs!
  ) {
    updateTheme (
      id: $id,
      theme: $theme
    ) {
      id
      name
      colors
      isGlobal
      isBuiltIn
    }
  }
`;

export type UpdateThemeResponseType = {
  updateTheme: ThemeType
};
