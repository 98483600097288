import { topNViewSchema } from '@sevone/insight-connect';
import { Configure } from './configure';
import { Variable } from './variable';
import { VariableType } from './types';

const topNViews: VariableType = {
  label: 'TopN Views',
  schema: topNViewSchema,
  defaultConfiguration: {
    datasources: [],
    isClearable: true,
    selectAll: false
  },
  configure: Configure,
  render: Variable
};

export { topNViews };
