import * as React from 'react';
import { css } from 'linaria';
import { styled } from 'linaria/react';
import SVG from 'react-inlinesvg';
import { getWidgetIconUrl } from '../../../../utils/wdk';

const Wrapper = styled.div`
  width: 30px;
`;

const svgStyles = css`
  width: 100%;
  height: 100%;
  display: block;
`;

type Props = {
  type: string
};

function WidgetIcon(props: Props) {
  const { type } = props;

  return (
    <Wrapper>
      {/* eslint-disable-next-line react/jsx-pascal-case */}
      <SVG
        src={getWidgetIconUrl(type)}
        className={svgStyles}
      />
    </Wrapper>
  );
}

export { WidgetIcon };
