export const DEAFULT_BUILT_IN_COLUMNS = [
  {
    id: 'name',
    title: 'Default',
    render: (data: any) => data.name
  }
];

export const DEAFULT_CUSTOM_COLUMNS = [
  {
    id: 'name',
    title: 'Custom',
    render: (data: any) => data.name
  }
];
