import { resourcesSchema } from '@sevone/insight-connect';
import { Configure } from './configure';
import { Variable } from './variable';
import { VariableType } from './types';

const flowInterfaces: VariableType = {
  label: 'Flow Interfaces',
  schema: resourcesSchema({ allowedResources: [ 'INTERFACE' ] }),
  defaultConfiguration: {
    isMulti: true,
    isClearable: true,
    selectAll: false,
    datasource: null,
    hierarchicalData: [],
    value: null
  },
  configure: Configure,
  render: Variable
};

export { flowInterfaces };
