import * as React from 'react';
import { styled } from 'linaria/react';
import {
  Dialog,
  Button
} from '@sevone/scratch';
import { CoreStore } from '../../../../store';
import { HORIZONTAL_RHYTHM, VERTICAL_RHYTHM } from '../../../../utils/spacing';

const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 545px;
  max-height: 500px;
  overflow: auto;
`;
const ButtonsWrapper = styled.div`
  margin-top: ${VERTICAL_RHYTHM}px;
  text-align: right;

  & > * {
    margin-left: ${HORIZONTAL_RHYTHM}px;
  }
`;
const SwatchContainer = styled.div`
  flex: 1;  
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
`;
const Swatch = styled.div<{ isSelected: boolean, onClick: () => void }>`
  width: 120px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--sev1-primary-2-color);
  border-radius: 3px;
  padding: 2px;
  margin: ${VERTICAL_RHYTHM / 2}px ${HORIZONTAL_RHYTHM / 2}px;
  background-color: ${({ isSelected }) => (isSelected ? 'var(--sev1-primary-2-color)' : '')};
  cursor: pointer;

  &:hover {
    background-color: var(--sev1-primary-2-color);
  }
`;
const ColorContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const Color = styled.div<{ color: string }>`
  width: 100%;
  height: 10px;
  background-color: ${({ color }) => color};
`;
const Label = styled.div`
  margin: ${VERTICAL_RHYTHM / 2}px 0px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

type Props = {
  hideModal: () => void
}

function SwitchTheme(props: Props) {
  const { hideModal } = props;
  const { switchTheme, state: coreState } = CoreStore.useContainer();
  const { activeTenant, activeThemeId } = coreState;

  const handleSwitchTheme = (id: number) => {
    switchTheme(id);
  };

  const renderSwatches = () => {
    if (activeTenant) {
      return activeTenant.themes.map((theme) => {
        const colorsKeys = Object.keys(JSON.parse(activeTenant.themes[0].colors));
        const colorPalleteKeys = colorsKeys.splice(3, colorsKeys.length - 1);
        const colors = colorPalleteKeys.map((key) => JSON.parse(theme.colors)[key].color);

        return (
          <Swatch
            key={theme.id}
            isSelected={activeThemeId === theme.id}
            onClick={() => handleSwitchTheme(theme.id)}
          >
            <ColorContainer>
              {colors.map((color, index) => {
                return (<Color key={`${color}-${index}`} color={color} />);
              })}
            </ColorContainer>
            <Label><span>{theme.name}</span></Label>
          </Swatch>
        );
      });
    }

    return [];
  };

  return (
    <Dialog title={'Switch Theme'} onClose={hideModal}>
      <div>
        <ContentContainer>
          <SwatchContainer>
            {renderSwatches()}
          </SwatchContainer>
        </ContentContainer>
        <ButtonsWrapper>
          <Button onClick={hideModal}>{'OK'}</Button>
        </ButtonsWrapper>
      </div>
    </Dialog>
  );
}

export { SwitchTheme };
