export const ADD_TENANT_MUTATION = `
  mutation (
    $tenantId: Int!
    $themeId: Int!
  ) {
    addTenantTheme (tenantId: $tenantId, themeId: $themeId) {
      id
    }
  }
`;

export type AddTenantResponseType = {
  addTenantTheme: number
}
