import * as React from 'react';
import { PageSidebar } from '../../components/page/page-sidebar';
import { SidebarContext, SidebarContextType } from './context';

type Props = {
  children: React.ReactNode
};

function SidebarProvider(props: Props) {
  const { children } = props;
  const [ sidebar, setSidebar ] = React.useState<React.ReactNode>(null);

  const hideSidebar = () => {
    setSidebar(null);
  };

  const showSidebar: SidebarContextType['showSidebar'] = (title, Component) => {
    setSidebar((
      <PageSidebar
        title={title}
      >
        <Component hideSidebar={hideSidebar} />
      </PageSidebar>
    ));
  };

  return (
    <SidebarContext.Provider
      value={{
        sidebar,
        showSidebar,
        hideSidebar
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
}

export { SidebarProvider };
