import * as React from 'react';
import { css } from 'linaria';
import { styled } from 'linaria/react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { AngleDoubleLeftIcon, AngleDoubleRightIcon } from '@sevone/scratch';
import get from 'lodash-es/get';
import { Logo } from '../../../components/logo';
import { Navigation } from './navigation';
import { UserMenu } from './user-menu';
import { CoreStore } from '../../../store';
import { hasPermission } from '../../../components/permission-gate';
import { VERTICAL_RHYTHM, HORIZONTAL_RHYTHM } from '../../../utils/spacing';

const EXPANDED_WIDTH = '176px';
const COLLAPSED_WIDTH = '56px';

const wrapperStyles = css`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  background: var(--sev1-primary-3-color);
  color: var(--sev1-primary-3-contrast);
  border-right: 1px solid var(--sev1-primary-2-color);
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: ${COLLAPSED_WIDTH};
  box-sizing: border-box;
  padding: ${VERTICAL_RHYTHM}px ${HORIZONTAL_RHYTHM}px;
`;

const Footer = styled.div`
  margin-top: auto;
`;

const CollapseToggle = styled.div`
  text-align: center;
  cursor: pointer;
  padding: 10px;
  line-height: 1.5;

  &:hover {
    background: var(--sev1-primary-2-color);
    color: var(--sev1-primary-2-contrast);
  }
`;

const logoStyles = css`
  height: 100%;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  cursor: pointer;
`;

function Sidebar() {
  const { state: coreState } = CoreStore.useContainer();
  const { permissions } = coreState;
  const userPermissionsIds = permissions.map((perm) => perm.id);
  const initialCollapsed = localStorage.getItem('isNavCollapsed') === 'true';
  const [ collapsed, setCollapsed ] = React.useState(initialCollapsed);
  const ToggleIcon = collapsed ? AngleDoubleRightIcon : AngleDoubleLeftIcon;
  const canClickLogo = hasPermission([ 'viewReportManager' ], userPermissionsIds);
  // If a user has a home report id, send us there instead
  const homeReportId =
    get(coreState, 'user.homeReportId') ||
    get(coreState, 'activeTenant.homeReportId');
  const homeLink = `/reports/${homeReportId}`;

  return (
    <motion.div
      className={wrapperStyles}
      initial={false}
      animate={collapsed ? 'collapsed' : 'expanded'}
      variants={{
        expanded: { width: EXPANDED_WIDTH },
        collapsed: { width: COLLAPSED_WIDTH }
      }}
      transition={{ type: 'tween', duration: 0.2 }}
    >
      {(canClickLogo || homeReportId) ? (
        <StyledLink to={homeReportId ? homeLink : '/'}>
          <LogoWrapper>
            <Logo className={logoStyles} minimal={collapsed} />
          </LogoWrapper>
        </StyledLink>
      ) : (
        <LogoWrapper>
          <Logo className={logoStyles} minimal={collapsed} />
        </LogoWrapper>
      )}
      <Navigation collapsed={collapsed} collapsedWidth={COLLAPSED_WIDTH} />
      <Footer>
        <UserMenu collapsed={collapsed} />
        <CollapseToggle
          onClick={() => {
            setCollapsed(!collapsed);
            localStorage.setItem('isNavCollapsed', String(!collapsed));
          }}
        >
          <ToggleIcon />
        </CollapseToggle>
      </Footer>
    </motion.div>
  );
}

export { Sidebar };
