import * as React from 'react';
import { styled } from 'linaria/react';
import {
  ColorPicker as BasicColorPicker,
  Dropdown
} from '@sevone/scratch';

// Modeled off of color picker from insight-components;
const ColorSqaureWrapper = styled.div`
  width: 14px;
  height: 14px;
  padding: 2px;
  border: 1px solid #999;
  border-radius: 2px;
  box-shadow: 0 0 0 2px #fff inset;
`;
const ColorSqaure = styled.div<{ color: string }>`
  height: 100%;
  width: 100%;
  background-color: ${({ color }) => color};
`;
const ColorPickerWrapper = styled.div`
  width: 250px;
  background-color: var(--sev1-primary-4-color);
`;

type Props = {
  color?: string,
  onChange: (value: string) => void
}

const DEFAULT_COLOR = '#000000';

function ColorPicker(props: Props) {
  const { color, onChange } = props;
  const [ isVisible, setIsVisible ] = React.useState(false);

  return (
    <Dropdown
      visible={isVisible}
      trigger={[ 'click' ]}
      position={'bottomLeft'}
      menu={
        <ColorPickerWrapper onClick={(event) => event.stopPropagation()}>
          <BasicColorPicker
            value={color || DEFAULT_COLOR}
            onChange={onChange}
          />
        </ColorPickerWrapper>
      }
      onVisiblityChange={setIsVisible}
    >
      <ColorSqaureWrapper>
        <ColorSqaure color={color || DEFAULT_COLOR} />
      </ColorSqaureWrapper>
    </Dropdown>
  );
}

export { ColorPicker };
