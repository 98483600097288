import * as React from 'react';
import { saveAs } from 'file-saver';
import format from 'date-fns/format';
import {
  Dropdown,
  DropdownMenu,
  DropdownSubMenu,
  DropdownItem,
  FileDownloadIcon
} from '@sevone/scratch';
import { useWidgetFetcher } from '../../../../../hooks/wdk-server';
import { WidgetType } from '../../../types';

type MimeInfoType = {
  label: string,
  ext: string
};

const MIME_TYPE_LABELS: Record<string, MimeInfoType> = {
  'application/json': {
    label: 'JSON',
    ext: '.json'
  },
  'text/csv': {
    label: 'CSV',
    ext: '.csv'
  },
  'text/csv;charset=utf-8': {
    label: 'CSV',
    ext: '.csv'
  }
};

type Props = {
  widget: WidgetType,
  isCompact: boolean,
  className?: string,
  exportDataFn: ((mime: string) => Blob | Promise<Blob | null> | null) | null
};

function ExportDataMenu(props: Props) {
  const { widget, isCompact, className, exportDataFn } = props;
  const { widgetMeta } = useWidgetFetcher(widget.type.name);
  const [ menuVisibility, setMenuVisibility ] = React.useState(false);
  const mimeTypes = (widgetMeta && widgetMeta.exportMimeTypes ?
    widgetMeta.exportMimeTypes : []).filter((mime) => MIME_TYPE_LABELS[mime]);
  const Tag = isCompact ? DropdownSubMenu : 'div';

  const handleExport = async (mimeType: string) => {
    if (!exportDataFn || !MIME_TYPE_LABELS[mimeType]) {
      return;
    }

    const { ext } = MIME_TYPE_LABELS[mimeType];
    const data = await exportDataFn(mimeType);

    if (!data) {
      return;
    }

    saveAs(
      data,
      `${widget.name} at ${format(new Date(), 'y-MM-dd HH:mm')}${ext}`
    );
  };

  if (mimeTypes.length === 0) {
    return null;
  }

  const items = mimeTypes.map((mime) => (
    <DropdownItem
      key={mime}
      onClick={() => { handleExport(mime); }}
    >
      {`Export as ${MIME_TYPE_LABELS[mime].label}`}
    </DropdownItem>
  ));

  return (
    <Tag title="Export Data" position="left" className={className}>
      {isCompact && items}
      {!isCompact &&
        <Dropdown
          trigger={[ 'click' ]}
          position="bottomRight"
          visible={menuVisibility}
          menu={(
            <DropdownMenu>
              {items}
            </DropdownMenu>
          )}
          onVisiblityChange={setMenuVisibility}
        >
          <FileDownloadIcon />
        </Dropdown>
      }
    </Tag>
  );
}

export { ExportDataMenu };
