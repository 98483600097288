import keyBy from 'lodash-es/keyBy';
import { ReportSessionExportType } from '../../report-runtime';
import { debug } from '../../utils/logger';

const EVENTS = {
  reportLoad: 'reportload',
  sectionChange: 'sectionchange',
  sectionReady: 'sectionready',
  widgetChange: 'widgetchange',
  widgetReady: 'widgetready'
};

export type ReportLoadType = CustomEvent<{
  sections: Array<{
    id: number,
    title: string,
    widgets: Array<{
      id: string,
      name: string
    }>
  }>
}>

export type SectionChangeType = CustomEvent<{ sectionId: number }>;

export type SectionReadyType = CustomEvent<{ sectionId: number }>;

export type WidgetChangeType = CustomEvent<{ widgetId: string }>;

export type WidgetReadyType = CustomEvent<{ widgetId: string }>;

function generateReportLoadEvent(
  report: ReportSessionExportType['report']
): ReportLoadType {
  const sections = report.content.sections.map((section) => {
    const widgetMap = keyBy(section.widgets, 'id');
    const orderedWidgets = [ ...section.layout ]
      .sort((a, b) => (a.y - b.y) || (a.x - b.x))
      .map((layout) => ({
        id: layout.i,
        name: widgetMap[layout.i].name
      }));

    return {
      id: section.id,
      title: section.title,
      widgets: orderedWidgets
    };
  });
  const payload = { sections };

  debug('pdf', EVENTS.reportLoad, payload);
  return new CustomEvent(EVENTS.reportLoad, { detail: payload });
}

function generateSectionReadyEvent(sectionId: number): SectionReadyType {
  const payload = { sectionId };

  debug('pdf', EVENTS.sectionReady, payload);
  return new CustomEvent(EVENTS.sectionReady, { detail: payload });
}

function generateWidgetReadyEvent(widgetId: string): WidgetReadyType {
  const payload = { widgetId };

  debug('pdf', EVENTS.widgetReady, payload);
  return new CustomEvent(EVENTS.widgetReady, { detail: payload });
}

export {
  EVENTS,
  generateReportLoadEvent,
  generateSectionReadyEvent,
  generateWidgetReadyEvent
};
