import * as React from 'react';
import { useRequest } from '@sevone/insight-connect';
import { cancelable, Cancelable } from '../../utils/cancelable';
import { ReportType } from './types';
import {
  GET_REPORT_QUERY,
  GetReportResponseType
} from './get-report.query';

const NEW_REPORT_ID = 'new-report';

function generateDefaultReport(
  id: string,
  folderId?: number
): ReportType {
  return {
    id,
    name: 'Untitled Report',
    description: '',
    updatedAt: new Date().getTime().toString(),
    owner: null,
    folder: folderId ? { id: folderId } : null,
    acl: null,
    isWritable: true,
    isTemplate: false,
    refreshInterval: null,
    rotateInterval: null,
    content: {
      sections: [
        {
          id: 1,
          title: 'Untitled Section',
          layout: [],
          widgetLinks: [],
          widgets: []
        }
      ],
      variables: [],
      timespan: null
    }
  };
}

function useReportFetcher(
  id: string | number,
  opts: {
    useDefault?: boolean,
    defaultOverrides?: {
      folderId?: number
    }
  } = {}
) {
  const { query } = useRequest();
  const [ error, setError ] = React.useState<Error | null>(null);
  const [ report, setReport ] = React.useState<ReportType | null>(null);
  // Start as true because we'll immediately trigger a fetch and we don't want
  // to render the initial `null` state between the first mount of this
  // component and the fetch state change triggered by the initial fetch.
  const [ fetchingReport, setFetchingReport ] = React.useState(true);
  // Make sure we don't render an invalid state between getting a new report ID
  // and setting `fetchingReport` to true. If we had already fetched a report,
  // then there'd be an intermediant state where we have a report, initialized
  // the next fetch, but haven't yet rerendered after the call to
  // `setFetchingReport` to set `fetchingReport` to true.
  const isFetching = fetchingReport || (report?.id.toString() !== id.toString() && error === null);

  React.useEffect(() => {
    let dataFetch: Cancelable<void> | null = null;
    const fetchReport = () => {
      setFetchingReport(true);
      setError(null);

      return query(GET_REPORT_QUERY, { id })
        .then((res: GetReportResponseType) => {
          const fetchedReport = res.data.data.reports[0];
          const parsedReport = {
            ...fetchedReport,
            content: JSON.parse(fetchedReport.content)
          };

          setReport(parsedReport);
        }).catch(() => {
          setReport(null);
          setError(new Error('Failed to fetch report'));
        }).finally(() => {
          setFetchingReport(false);
        });
    };

    if (id === NEW_REPORT_ID) {
      if (opts.useDefault) {
        setReport(generateDefaultReport(id, opts.defaultOverrides?.folderId));
      }

      setFetchingReport(false);
    } else {
      dataFetch = cancelable(fetchReport());
      dataFetch.promise.then(() => {
        dataFetch = null;
      });
    }

    return () => { dataFetch?.cancel(); };
  }, [ id ]);

  return {
    report,
    fetchingReport: isFetching
  };
}

export { useReportFetcher };
