import { ExtendedDatasourceType } from './get-tenant-datasource.query';
import { ThemeType } from './get-themes.query';
import { LogoType } from './get-logos.query';

export const ALL_TENANTS_QUERY = `
  query ($id: Int) {
    tenants(id: $id) {
      id
      name
      logoutURL
      authSource {
        id
        name
      }
      nmsRoleId
      defaultLogoId
      smallLogoId
      defaultThemeId
      updatedAt,
      useAlternateName
    }
  }
`;

export const TENANT_BY_ID_QUERY = `
  query ($id: Int) {
    tenants(id: $id) {
      id
      name
      logoutURL
      authSource {
        id
        name
      }
      nmsRoleId
      logos {
        id
        image
      }
      themes {
        id
        name
        colors
        isGlobal
        isBuiltIn
      }
      defaultLogoId
      smallLogoId
      defaultThemeId
      updatedAt,
      useAlternateName
    }
  }
`;

export type AuthSourceType = {
  id: number,
  name: string
}

export type TenantType = {
  id: number,
  name: string,
  logoutURL: string | null,
  authSource?: AuthSourceType,
  nmsRoleId?: number,
  logos?: Array<LogoType>,
  themes: Array<ThemeType>,
  defaultLogoId?: number,
  smallLogoId?: number,
  defaultThemeId: number,
  updatedAt: string,
  useAlternateName: boolean
};

type TenantExtras = {
  datasources: Array<ExtendedDatasourceType>,
  logos: Array<LogoType>
};

export type ExtendedTenantType = TenantType & TenantExtras;

export type TenantsResponseType = {
  tenants: Array<TenantType>
}
