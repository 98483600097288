export const GET_THEMES_QUERY = `
  query GetThemes($filter: ThemeFilterArgs) {
    themes(filter: $filter) {
      id,
      name,
      colors,
      isGlobal,
      isBuiltIn
    }
  }
`;

export type ThemeType = {
  id: number,
  name: string,
  colors: string,
  isGlobal: boolean,
  isBuiltIn: boolean
}

export type GetThemeResponseType = {
  themes: Array<ThemeType>
}
