import camelCase from 'lodash-es/camelCase';
import { registerWidget } from '@sevone/insight-wdk';
import { WidgetInterface, WidgetMeta } from '@sevone/insight-widget-runtime';
import { wdkServerHost } from '../../global';
import { baseUrl } from '../base-location';

type UnknownObject = { [key: string]: any };

function getWdkServerBase() {
  if (wdkServerHost) {
    return wdkServerHost;
  }

  return `${baseUrl}/wdkserver`;
}

function getWdkServer() {
  return `${getWdkServerBase()}/api/v1`;
}

function getWidgetIconUrl(name: string) {
  return `${getWdkServer()}/widgets/icon/${name}.svg`;
}

async function fetchInterface(name: string): Promise<WidgetInterface> {
  const url = `${getWdkServer()}/widgets/bundle/${name}.js`;
  const globalName = camelCase(name);
  const rawInterface = await fetch(url).then((res) => res.body).then((body) => {
    return new Response(body);
  }).then((response) => response.text());

  // The interface came back as raw src of a umd. So, we need to turn it into
  // a function and call it to extract the interface as the umd payload.
  try {
    const interfaceBootstrap = new Function(rawInterface); // eslint-disable-line
    interfaceBootstrap();

    // Grab the interface from the global scope.
    const widgetInterface = (window as UnknownObject)[globalName].default;

    // Clean up the window so we don't pollute it.
    delete (window as UnknownObject)[globalName];

    return widgetInterface;
  } catch (err) {
    return Promise.reject();
  }
}

async function fetchWidgetList(): Promise<Array<WidgetMeta>> {
  const url = `${getWdkServer()}/widgets/metadata`;
  const widgets: Array<WidgetMeta> = await fetch(url).then((response) => response.json());

  widgets.forEach((widget) => registerWidget(widget));

  return widgets;
}

export type WidgetInterfaceType = WidgetInterface;
export type WidgetMetaType = WidgetMeta;

export {
  getWdkServer,
  getWidgetIconUrl,
  fetchInterface,
  fetchWidgetList
};
