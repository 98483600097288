import * as React from 'react';
import { styled } from 'linaria/react';
import { Tooltip, CircleInfoIcon } from '@sevone/scratch';
import { WidgetMetaType } from '../../../../utils/wdk';
import { uuid } from '../../../../utils/uuid';
import { VERTICAL_RHYTHM, HORIZONTAL_RHYTHM } from '../../../../utils/spacing';
import { WidgetType } from '../../types';
import { WidgetIcon } from './widget-icon';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: ${VERTICAL_RHYTHM}px ${HORIZONTAL_RHYTHM}px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color .1s ease-in;

  &:hover {
    background: var(--sev1-primary-2-color);
    color: var(--sev1-primary-2-contrast);
  }
`;

const IconWrapper = styled.div`
  flex: none;
`;

const ContentWrapper = styled.div`
  margin: 0 ${HORIZONTAL_RHYTHM}px;
`;

const TitleWrapper = styled.div`
  font-size: .8em;
`;

const DescriptionWrapper = styled.div`
  font-size: .7em;
  font-style: italic;
  color: var(--sev1-secondary-2-color);
`;

const InfoWrapper = styled.div`
  margin-left: auto;
  color: var(--sev1-secondary-2-color);
`;

type Props = {
  meta: WidgetMetaType,
  onDrag: (widget: WidgetType) => void,
  onSelect: (widget: WidgetType) => void
};

function WidgetOption(props: Props) {
  const { meta, onDrag, onSelect } = props;

  const generateNewWidget = () => {
    return {
      id: uuid(),
      name: meta.title,
      type: {
        name: meta.name
      },
      configuration: {}
    };
  };

  const handleClick = () => {
    onSelect(generateNewWidget());
  };

  const handleDragStart = (e: React.DragEvent) => {
    // Fix for drag/drop bug in firefox.
    // ISSUE: https://bugzilla.mozilla.org/show_bug.cgi?id=568313
    e.dataTransfer.setData('text/plain', '');

    onDrag(generateNewWidget());
  };

  return (
    <Wrapper
      onClick={handleClick}
      onDragStart={handleDragStart}
    >
      <IconWrapper>
        <WidgetIcon type={meta.name} />
      </IconWrapper>
      <ContentWrapper>
        <TitleWrapper>
          {meta.title}
        </TitleWrapper>
        <DescriptionWrapper>
          {meta.description}
        </DescriptionWrapper>
      </ContentWrapper>
      <InfoWrapper>
        <Tooltip position={[ 'top', 'right' ]} tooltip={`v${meta.version}`}>
          <CircleInfoIcon />
        </Tooltip>
      </InfoWrapper>
    </Wrapper>
  );
}

export { WidgetOption };
