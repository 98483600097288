export const THEMES_QUERY = `
  query ($filter: ThemeFilterArgs) {
    themes(filter: $filter) {
      id
      name
      colors
      isGlobal
      isBuiltIn
    }
  }
`;

export type ThemeType = {
  id: number,
  name: string,
  colors: string,
  isGlobal: boolean,
  isBuiltIn: boolean
}

export type ThemesResponseType = {
  themes: Array<ThemeType>
}
