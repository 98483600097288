import * as React from 'react';
import sortBy from 'lodash-es/sortBy';
import { Select } from '@sevone/scratch';
import { isTruthy } from '../../../../../utils/is-truthy';
import { GqlTimezoneType } from './get-timezones.query';

const convertMsToTimezoneOffset = (offset: number) => {
  return offset / (1000 * 60 * 60);
};

const generateTimezoneLabel = (tz: GqlTimezoneType) => {
  const { displayName, offset } = tz;
  const sign = offset >= 0 ? '+' : '-';
  const m = Math.floor((Math.abs(offset) / (1000 * 60)) % 60).toString();
  const h = Math.floor((Math.abs(offset) / (1000 * 60 * 60)) % 24).toString();
  const utc = `UTC${sign}${h.padStart(2, '0')}:${m.padStart(2, '0')}`;

  return `(${utc}) -- ${displayName}`;
};

const generateTimezoneOption = (tz: GqlTimezoneType | null) => {
  if (!tz) {
    return null;
  }

  return {
    label: generateTimezoneLabel(tz),
    value: tz.timezone
  };
};

const generateTimezoneList = (zones: Array<GqlTimezoneType>) => {
  return sortBy(zones, 'offset')
    .map(generateTimezoneOption)
    .filter(isTruthy);
};

const getLocalTimezone = (zones: Array<GqlTimezoneType>) => {
  // `getTimezoneOffset` returns the inverse minutes of the offset we need
  const localTimezoneOffset = (new Date().getTimezoneOffset() / 60) * -1;

  return zones.find((tz) => {
    return convertMsToTimezoneOffset(tz.offset) === localTimezoneOffset;
  }) || null;
};

type Props = {
  timezone: string | null,
  timezones: Array<GqlTimezoneType>,
  onChange: (timezone: string | null) => void
};

function TimezoneSelector(props: Props) {
  const { timezone, timezones, onChange } = props;

  const getValue = () => {
    const foundTz = timezones.find((tz) => {
      return tz.timezone === timezone;
    });

    return foundTz ? generateTimezoneOption(foundTz) : null;
  };

  const handleChange = (opt: { label: string, value: string } | null) => {
    onChange(opt?.value || null);
  };

  React.useEffect(() => {
    // Auto-select the user's timezone if there isn't one already selected
    if (!timezone && timezones.length) {
      handleChange(generateTimezoneOption(getLocalTimezone(timezones)));
    }
  }, [ timezone, timezones ]);

  return (
    <Select
      value={getValue()}
      options={generateTimezoneList(timezones)}
      // @ts-ignore: TS thinks this might pass back number values, which is
      // impossible because we're only giving it string values
      onChange={handleChange}
    />
  );
}

export { TimezoneSelector };
