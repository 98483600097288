import * as React from 'react';
import {
  useReportRuntime,
  ReportSessionExportType
} from '../../../report-runtime';
import { generateSectionReadyEvent } from '../events';
import { Widget } from './widget';

type Props = {
  sectionId: number,
  activeWidgetId: string | null,
  sessionPayload: ReportSessionExportType
};

function Section(props: Props) {
  const { sectionId, activeWidgetId, sessionPayload } = props;
  const { getWidgets, getStack, importData } = useReportRuntime();
  const [
    readyWidgets,
    setReadyWidgets
  ] = React.useState<Record<string, boolean>>({});
  const [ hasImported, setHasImported ] = React.useState(false);
  const widgets = sectionId ? getWidgets(sectionId) : [];
  const stacksAreCreated = widgets
    .map((widget) => getStack(widget.id)).every(Boolean);
  const widgetsAreReady = widgets.length === Object.keys(readyWidgets).length;
  const hasImportData = sectionId === sessionPayload.activeSectionId;

  const handleOnReady = React.useCallback((widgetId: string) => {
    setReadyWidgets((curr) => ({ ...curr, [widgetId]: true }));
  }, [ setReadyWidgets ]);

  // Use the existance of the widget's stack as an indicator that the runtime
  // has finished its initial bootstrap. This isn't a guarantee though and we
  // should try to find a better way to determine this. But ultimately we need
  // to wait until the runtime has built facet containers for all of its
  // parts (widgets, links, etc) based on the core report before we try to
  // import data into them.
  // React.useEffect(() => {
  //   if (!stacksAreCreated || !widgetsAreReady) {
  //     return () => {};
  //   }

  //   if (hasImportData && !hasImported) {
  //     isImporting.current = true;

  //     const pendingImport = cancelable(importData(sessionPayload));
  //     pendingImport.promise.then(() => {
  //       setReadyWidgets({});
  //       setHasImported(true);
  //       document.dispatchEvent(generateSectionReadyEvent(sectionId));
  //     });

  //     return pendingImport.cancel;
  //   }

  //   document.dispatchEvent(generateSectionReadyEvent(sectionId));
  //   return () => {};
  // }, [
  //   stacksAreCreated,
  //   widgetsAreReady,
  //   hasImportData,
  //   setHasImported,
  //   importData,
  //   sessionPayload
  // ]);

  React.useEffect(() => {
    if (
      stacksAreCreated &&
      widgetsAreReady &&
      (!hasImportData || (hasImportData && hasImported))
    ) {
      document.dispatchEvent(generateSectionReadyEvent(sectionId));
    }
  }, [ stacksAreCreated, widgetsAreReady, hasImportData, hasImported ]);

  React.useEffect(() => {
    if (stacksAreCreated && hasImportData) {
      importData(sessionPayload).then(() => {
        setHasImported(true);
      });
    }
  }, [ stacksAreCreated, hasImportData, sessionPayload, setHasImported ]);

  // React.useEffect(() => { console.log('pdf mountsection'); }, []);

  if (hasImportData && !hasImported) {
    return null;
  }

  // console.log('pdf rerendersection');
  return (
    <>
      {widgets.map((widget) => (
        <Widget
          key={widget.id}
          active={widget.id === activeWidgetId}
          hasImportData={hasImportData}
          widget={widget}
          facets={getStack(widget.id)?.list() || []}
          readyWidgets={readyWidgets}
          onReady={handleOnReady}
        />
      ))}
    </>
  );
}

export { Section };
