import * as React from 'react';
import { styled } from 'linaria/react';
import {
  AddIcon,
  Button,
  SaveIcon,
  UndoIcon,
  LoadingCircle
} from '@sevone/scratch';
import { useModal, useNotification } from '@sevone/insight-connect';
import {
  Page,
  PageHeader,
  PageTitle,
  PageActions
} from '../../components/page';
import { useGql } from '../../hooks/use-gql';
import { P } from '../../components/typography';
import ReportLinkEditor from './report-link-editor';
import { HORIZONTAL_RHYTHM, VERTICAL_RHYTHM } from '../../utils/spacing';
import { GET_REPORT_LINKS_QUERY, GetReportLinksResponseType } from './queries/get-report-links.query';
import { UPDATE_REPORT_LINKS_MUTATION } from './queries/update-report-links.mutation';
import { ReportLinkType } from './report-link-editor/types';
import { RuleType } from './rule-builder/types';

const PageWrapper = styled(Page)`
  display: flex;
  flex-direction: column;
`;

const PageHeaderContainer = styled(PageHeader)`
  display: flex;
  justify-content: space-between;
`;

const PageBody = styled.div`
  display: flex;
  flex: 1;
  overflow: auto;
  flex-direction: column;
`;

const BodyWrapper = styled.div`
  padding: ${VERTICAL_RHYTHM}px ${HORIZONTAL_RHYTHM}px;
`;

const LoadingWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

export enum LinkColumn {
  DEVICE = 'DEVICE',
  OBJECT = 'OBJECT',
  INDICATOR = 'INDICATOR'
}

const defaultLinks: ReportLinkType[] = [
  { enabled: true, column: LinkColumn.DEVICE, filters: [], reportIds: [], name: null },
  { enabled: true, column: LinkColumn.OBJECT, filters: [], reportIds: [], name: null }
];

const newLink = (): ReportLinkType => (
  { id: undefined, name: 'Untitled Global Report Link', enabled: true, column: null, filters: [], reportIds: [] }
);

// Depending on the operator the value could be a string or a json object. As the number
// of fields/operators grows we may need a more sophisticated way to deal with this.
function serializeFilter(filter: RuleType) {
  if (filter.operator === 'like') {
    return filter;
  }

  return {
    ...filter,
    value: JSON.stringify(filter.value)
  };
}

function deserializeFilter(filter: RuleType) {
  if (filter.operator === 'like') {
    return filter;
  }

  let value;

  try {
    value = typeof filter.value === 'string' ? JSON.parse(filter.value) : filter.value;
  } catch (e) {
    value = null;
  }

  return {
    ...filter,
    value
  };
}

export function ReportLinkingManager(): JSX.Element {
  const [ settings, setSettings ] = React.useState<{
    reportLinks: ReportLinkType[]
  }>({
    reportLinks: defaultLinks
  });
  const [ idsToDelete, setIdsToDelete ] = React.useState<number[]>([]);
  const { showNotification } = useNotification();
  const { showModal } = useModal();

  const {
    isFetching: isFetchingReportLinks,
    runGql: fetchReportLinks
  } = useGql<GetReportLinksResponseType>(GET_REPORT_LINKS_QUERY);
  const {
    isFetching: isSavingReportLinks,
    runGql: saveReportLinks
  } = useGql(UPDATE_REPORT_LINKS_MUTATION);

  // We only want to show the loading circle on initial load, not on save or revert.
  // Initial load if we're fetching and reportLinks match defaultLinks by identity.
  const isInitialLoad = isFetchingReportLinks && settings.reportLinks === defaultLinks;

  const loadReportLinks = async () => {
    const { reportLinks } = await fetchReportLinks();

    if (reportLinks && reportLinks.length) {
      setSettings({
        reportLinks: reportLinks.map((link) => {
          return {
            id: link.id,
            name: link.name,
            column: link.column,
            enabled: link.enabled,
            filters: link.filters.map(deserializeFilter),
            reportIds: link.reports.map((report) => report.id)
          };
        })
      });
    }
  };

  React.useEffect(() => {
    loadReportLinks();
  }, []);

  const handleAddLink = () => {
    setSettings({
      ...settings,
      reportLinks: [
        ...settings.reportLinks,
        newLink()
      ]
    });
  };

  const handleChange = (nextLinks: { reportLinks: ReportLinkType[] }) => {
    setSettings(nextLinks);
  };

  const handleDelete = (id: number | undefined) => {
    if (id) {
      setIdsToDelete([ ...idsToDelete, id ]);
    }
  };

  const handleSave = async () => {
    try {
      await saveReportLinks({
        update: settings.reportLinks.map((link: ReportLinkType, i: number) => {
          return {
            ...{
              ...link,
              filters: link.filters ? link.filters.map(serializeFilter) : link.filters
            },
            position: i
          };
        }),
        delete: idsToDelete
      });

      return loadReportLinks();
    } catch (e) {
      showNotification({
        type: 'error',
        message: `There was an error saving your changes: ${e.message}`
      });

      return Promise.reject(e);
    }
  };

  const revertChanges = () => {
    setIdsToDelete([]);

    return loadReportLinks();
  };

  const handleRevert = () => {
    showModal({
      type: 'confirmation',
      header: 'Revert changes?',
      message: () => `This will load the report linking settings from the server
        and overwrite your changes. Are you sure you wish to do this?`,
      actions: ({ hideModal: handleHide }: { hideModal: () => void }) => [
        (
          <Button key={'cancel'} secondary onClick={handleHide}>
            {'Cancel'}
          </Button>
        ),
        (
          <Button
            key={'ok'}
            onClick={async () => {
              await revertChanges();
              handleHide();
            }}
          >
            {'OK'}
          </Button>
        )
      ]
    });
  };

  return (
    <PageWrapper title={'Report Linking'}>
      <PageHeaderContainer>
        <PageTitle>
          {'Global Report Linking'}
        </PageTitle>
        <PageActions>
          <Button
            onClick={handleAddLink}
            prefixIcon={<AddIcon />}
          >
            {'Add Link'}
          </Button>
          <Button
            onClick={handleRevert}
            disabled={isSavingReportLinks || isFetchingReportLinks}
            prefixIcon={<UndoIcon />}
            type={'outlined'}
          >
            {'Restore'}
          </Button>
          <Button
            onClick={handleSave}
            disabled={isSavingReportLinks || isFetchingReportLinks}
            prefixIcon={<SaveIcon />}
            type={'outlined'}
          >
            {'Save'}
          </Button>
        </PageActions>
      </PageHeaderContainer>
      <PageBody>
        {isInitialLoad ? (
          <LoadingWrapper><LoadingCircle /></LoadingWrapper>
        ) : (
          <BodyWrapper>
            <P>
              {`Report links allow you to click on a value in a widget and navigate to
              another report which can provide further context.`}
            </P>
            <ReportLinkEditor
              reportLinking={settings}
              onChange={handleChange}
              onDelete={handleDelete}
            />
          </BodyWrapper>
        )}
      </PageBody>
    </PageWrapper>
  );
}
