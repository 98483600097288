import * as React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { useNavigation } from '@sevone/insight-connect';
import { CoreStore } from '../../store';

function AuthenticatedRoute({ children, ...rest }: RouteProps) {
  const coreStore = CoreStore.useContainer();
  const { location } = useNavigation();

  return (
    <Route
      {...rest}
      render={() =>
        (coreStore.state.isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { redirectTo: location }
            }}
          />
        ))
      }
    />
  );
}

export { AuthenticatedRoute };
