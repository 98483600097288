import * as React from 'react';
import { Select } from '@sevone/scratch';
import {
  VALUE_QUERY,
  ValueResponseType
} from '../queries/get-values.query';
import { useGql } from '../../../../../../../hooks/use-gql';

type Props = {
  clearable: boolean,
  attributeName: string | null,
  namespaceName: string | null,
  entityType: 'OBJECT', // The only option currently.
  datasourceIds: Array<number>,
  value: string | null,
  onChange: (value: string | null) => void
}
type OptionType = {
  value: string,
  label: string
}

const SIZE = 200;

function ValueSelector(props: Props) {
  const {
    clearable,
    attributeName,
    namespaceName,
    datasourceIds,
    entityType,
    value,
    onChange
  } = props;
  const {
    runGql: requestValues
  } = useGql<ValueResponseType>(VALUE_QUERY);

  const fetchValueOptions = async (search: string = '') => {
    if (!attributeName || !namespaceName) {
      return [];
    }

    const valueLists = await Promise.all(datasourceIds.map((datasourceId) => {
      const vars = {
        datasourceId,
        filter: {
          value: search,
          entityType,
          attributeName: {
            attribute: attributeName,
            namespace: namespaceName
          }
        },
        size: SIZE
      };
      return requestValues(vars).then((res) => {
        return res.metadataValues.map((val) => {
          return val.value;
        });
      });
    }));

    const valueSet: Set<string> = new Set();
    valueLists.forEach((valueList) => {
      valueList.forEach((val) => {
        valueSet.add(val);
      });
    });
    const mergedValues = [ ...valueSet ];

    return mergedValues
      .sort((a, b) => a.localeCompare(b, undefined, { numeric: true, ignorePunctuation: true }))
      .map((attr) => ({ label: attr, value: attr }));
  };

  const handleChange = (option: OptionType) => {
    onChange(option?.value || null);
  };

  return (
    <Select
      key={datasourceIds.join('-') + attributeName + namespaceName}
      clearable={clearable}
      disabled={!namespaceName || !attributeName}
      options={fetchValueOptions}
      onChange={handleChange}
      value={value ? { value, label: value } : null}
    />
  );
}

export { ValueSelector };
