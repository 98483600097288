import * as React from 'react';
import { styled } from 'linaria/react';
import {
  Card,
  Input,
  Select,
  LoadingCircle
} from '@sevone/scratch';
import clamp from 'lodash-es/clamp';
import get from 'lodash-es/get';
import { HORIZONTAL_RHYTHM, VERTICAL_RHYTHM } from '../../../utils/spacing';

const CardContainer = styled.div`
  margin-left: ${HORIZONTAL_RHYTHM}px;
  height: 100%;
`;

const ContentContainer = styled.div`
  & > * {
    padding: 0 ${HORIZONTAL_RHYTHM * 2}px ${VERTICAL_RHYTHM / 2}px ${HORIZONTAL_RHYTHM * 2}px;
  }
`;
const LoadingContainer = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

type Props = {
  protocol: string,
  setProtocol: (value: string) => void,
  server: string,
  setServer: (value: string) => void,
  port: string | null,
  setPort: (value: string) => void,
  username: string,
  setUsername: (value: string) => void,
  password: string,
  setPassword: (value: string) => void,
  path: string,
  setPath: (value: string) => void,
  isLoading: boolean
}


type OptionType = {
  value: string,
  label: string
}

const PROTOCOL_OPTIONS = [
  { label: 'FTP', value: 'FTP' },
  { label: 'SFTP', value: 'SFTP' }
];

function Delivery(props: Props) {
  const { protocol, setProtocol, server, setServer, port, setPort, username,
    setUsername, password, setPassword, path, setPath, isLoading } = props;


  const generateProtocol = () => {
    return PROTOCOL_OPTIONS.find((proto) => proto.value === protocol);
  };

  const handleProtocolChange = (opt: OptionType) => {
    setProtocol(opt.value);
  };

  const handlePortBlur = () => {
    const clampedVal = clamp(Number(port), 1, 65535);
    setPort(clampedVal.toString());
  };

  const defaultPort = get(generateProtocol(), 'value', '') === 'SFTP' ? '22' : '21';

  return (
    <CardContainer>
      <Card title={'FTP / SFTP'}>
        {!isLoading ?
          <ContentContainer>
            <Select
              label={'Protocol'}
              value={generateProtocol()}
              options={PROTOCOL_OPTIONS}
              onChange={handleProtocolChange}
            />
            <Input
              label={'Server'}
              value={server}
              onChange={setServer}
            />
            <Input
              type={'number'}
              label={'Port'}
              value={port === null ? defaultPort : port}
              onChange={setPort}
              onBlur={handlePortBlur}
            />
            <Input
              label={'Username'}
              value={username}
              onChange={setUsername}
            />
            <Input
              label={'Password'}
              value={password}
              onChange={setPassword}
              type={'password'}
            />
            <Input
              label={'Path'}
              value={path}
              onChange={setPath}
            />
          </ContentContainer> :
          <LoadingContainer><LoadingCircle size={'large'} /></LoadingContainer>
        }
      </Card>
    </CardContainer>
  );
}

export { Delivery };
