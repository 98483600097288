export const SET_SMALL_LOGO_MUTATION = `
  mutation SetTenantSmallLogo($logoId: Int!, $tenantId: Int!) {
    setTenantSmallLogo(logoId: $logoId, tenantId: $tenantId) {
      id
      smallLogoId
    }
  }
`;

export type SetSmallLogoResponseType = {
  setTenantSmallLogo: {
    id: number,
    smallLogoId: number
  }
}
