import * as React from 'react';
import { AnimateSharedLayout } from 'framer-motion';
import { TopLevelItem } from './top-level-item';
import { TopLevelType } from '../../../navigation';
import { hasPermission } from '../../../../components/permission-gate';
import * as styles from './styles';
import { CoreStore } from '../../../../store';

type Props = {
  compact?: boolean,
  compactWidth?: string,
  menuItems: Array<TopLevelType>,
  selectedItem: string | null,
  expandedItems: Array<string>,
  onExpansionChange: (ids: Array<string>) => void
};

function NavigationMenu(props: Props) {
  const {
    compact,
    compactWidth,
    menuItems,
    selectedItem,
    expandedItems,
    onExpansionChange
  } = props;

  const { state: coreState } = CoreStore.useContainer();
  const { permissions } = coreState;
  const userPermissionsIds = permissions.map((perm) => perm.id);

  const handleExpand = (id: string) => {
    // It doesn't make sense to change a top level item's expanded state while
    // in compact mode.
    if (compact) {
      return;
    }

    onExpansionChange(expandedItems.concat(id));
  };

  const handleCollapse = (id: string) => {
    if (compact) {
      return;
    }

    onExpansionChange(expandedItems.filter((i) => i !== id));
  };

  return (
    <AnimateSharedLayout
      transition={{ type: 'tween', ease: [ 1, 0.01, 0, 1.22 ] }}
    >
      <div
        className={styles.wrapper}
        // @ts-ignore: React/TS don't like css vars
        style={{ '--sev1-nav-menu-compact-width': compactWidth }}
      >
        {menuItems.map((item) => {
          const itemPermissions = 'permissions' in item ?
            [ item.permissions ] :
            item.children.map((child) => child.permissions);
          return (
            itemPermissions.some((perm) => hasPermission(perm, userPermissionsIds)) &&
              <TopLevelItem
                key={item.id}
                compact={!!compact}
                item={item}
                selectedItem={selectedItem}
                expanded={expandedItems.includes(item.id)}
                onExpand={handleExpand}
                onCollapse={handleCollapse}
              />
          );
        })}
      </div>
    </AnimateSharedLayout>
  );
}

export { NavigationMenu };
