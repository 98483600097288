import * as React from 'react';
import { styled } from 'linaria/react';
import { navigation } from '@sevone/insight-connect';
import { LoadingCircle } from '@sevone/scratch';
import { CoreStore } from '../store';
import { useGql } from '../hooks/use-gql';
import { CURRENT_USER, CurrentUserResponseType } from './current-user.query';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

type Props = {
  children: React.ReactNode
};

function Bootstrap(props: Props) {
  const { children } = props;
  const { state, login, logout } = CoreStore.useContainer();
  const { runGql } = useGql<CurrentUserResponseType>(CURRENT_USER);

  // Validate any pre-existing auth token the user has stored.
  React.useEffect(() => {
    if (!state.authToken) {
      return;
    }

    runGql().then((res) => {
      const { token, ...safeParams } = navigation.location.params;
      const { currentUser: user } = res;

      if (state.authToken !== null) {
        login({ authToken: state.authToken, user });
      }

      // It's possible the initial authToken read was a url auth token. If
      // that's the case, we don't want that token to stick around in the url
      // once they've been logged in. So if the user logs in this way, strip the
      // token from the url and redirect.
      if (token) {
        navigation.navigateTo({
          ...navigation.location,
          params: safeParams
        });
      }
    }).catch(() => { logout(); });
  }, []);

  // User has an auth token already, but we haven't verified the user associated
  // with it or that it's a valid token. Until we do, hold up rendering the
  // rest of the app.
  if (state.authToken && !state.user) {
    return (
      <Wrapper>
        <LoadingCircle size="large" />
      </Wrapper>
    );
  }

  // ts-bug: ReactNode and JSX.Element are not compatible.
  // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/20544
  // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/18912
  // https://github.com/microsoft/TypeScript/issues/21699
  // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/20544
  return <>{children}</>;
}

export { Bootstrap };
