import difference from 'lodash-es/difference';
import { ExtendedDatasourceType } from '../queries/get-tenant-datasource.query';
import { ThemeType } from '../queries/get-themes.query';

export const calcDSAssociations = (
  oldDatasources: Array<ExtendedDatasourceType>,
  newDatasources: Array<ExtendedDatasourceType>
) => {
  const oldData = oldDatasources.map((ds) => ds.id);
  const newData = newDatasources.map((ds) => ds.id);
  const removedData = difference(oldData, newData);
  const addedData = difference(newData, oldData);

  return {
    removed: removedData,
    added: addedData
  };
};

export const calcThemeAssociations = (
  oldThemes: Array<ThemeType>,
  newThemes: Array<ThemeType>
) => {
  const oldData = oldThemes.map((theme) => theme.id);
  const newData = newThemes.map((theme) => theme.id);
  const removedData = difference(oldData, newData);
  const addedData = difference(newData, oldData);

  return {
    removed: removedData,
    added: addedData
  };
};
