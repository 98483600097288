import * as React from 'react';
import { styled } from 'linaria/react';
import { useNotification, useModal } from '@sevone/insight-connect';
import {
  FileSelector,
  Table,
  Checkbox,
  Button,
  LoadingCircle,
  Tooltip
} from '@sevone/scratch';
import { LocalLogo } from '../..';
import { LogoType } from '../../queries/get-logos.query';
import { VERTICAL_RHYTHM, HORIZONTAL_RHYTHM } from '../../../../utils/spacing';

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
`;
const LogoWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  flex-wrap: wrap;
`;
const LoadingWrapper = styled.div`
  align-self: center;
`;
const FileSelectorWrapper = styled.div`
  margin: ${VERTICAL_RHYTHM / 2}px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const FileSelectorContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px dashed;
  border-radius: 5px;
  border-color: var(--sev1-primary-1-color);
  width: 200px;
  height: 150px;
  margin-bottom: ${VERTICAL_RHYTHM / 2}px;
`;
const FileSelectorInstructions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${VERTICAL_RHYTHM / 2}px ${HORIZONTAL_RHYTHM / 2}px;
  min-height: 125px;
`;
const SizeWarningWrapper = styled.div`
  font-size: calc(var(--sev1-size)*0.7);
  align-self: flex-end;
  font-style: italic;
`;
const ThumbnailWrapper = styled.div`
  width: 200px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const LogoCellWrapper = styled.div`
  width: 100px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const ImageWrapper = styled.img`
  max-width: 100%;
  max-height: 100%;
`;
const ActionButtonWrapper = styled.div`
  & > * {
    margin: 0px ${HORIZONTAL_RHYTHM / 2}px;
  }
`;

type DataURLType = string | ArrayBuffer | null;
type Props = {
  tenantId?: number,
  isFetchingLogos: boolean,
  logos: Array<LogoType>,
  isEditing: boolean,
  defaultLogoId?: number,
  smallLogoId?: number,
  setDefaultLogoId: (id: number) => void,
  setSmallLogoId: (id: number) => void,
  localTenantLogo?: LocalLogo
  setLocalTenantLogo: (logo: LocalLogo) => void,
  onUploadLogo: (tenantId?: number) => void,
  onDeleteLogo: (ids: Array<number | string>) => void,
  onHasChanged: (hasChanged: boolean) => void
};

function Logos(props: Props) {
  const { showNotification } = useNotification();
  const { showModal } = useModal();
  const { isFetchingLogos, logos, isEditing, defaultLogoId, smallLogoId,
    setDefaultLogoId, setSmallLogoId, localTenantLogo, setLocalTenantLogo,
    onUploadLogo, tenantId, onDeleteLogo, onHasChanged } = props;
  const [ selectedLogoIds, setSelectedLogoIds ] = React.useState<Array<number | string>>([]);

  React.useEffect(() => {
    setSelectedLogoIds([]);
  }, [ tenantId ]);

  const handleDeleteLogosConfirmation = () => {
    showModal({
      type: 'confirmation',
      header: 'Delete Logo(s)',
      message: () => 'Are you sure you want to delete the selected logos? This cannot be undone.',
      actions: (modalProps: { hideModal: () => void }) => [
        (<Button key={'cancel'} onClick={() => modalProps.hideModal()}>{'Cancel'}</Button>),
        (
          <Button
            key={'delete'}
            onClick={() => {
              onDeleteLogo(selectedLogoIds);
              modalProps.hideModal();
              setSelectedLogoIds([]);
            }}
          >
            {'Delete'}
          </Button>
        )
      ]
    });
  };

  const handleDefaultLogoChange = (id: number) => {
    setDefaultLogoId(id);
    onHasChanged(true);
  };

  const handleSmallLogoChange = (id: number) => {
    setSmallLogoId(id);
    onHasChanged(true);
  };

  const handleLogoUpload = (files: Array<File>): Promise<Event> => {
    const file = files[0];
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = (img) => {
        // 1.5 MB
        if (file.size <= 1500000) {
          const newImage = {
            file,
            dataURL: img.target ? img.target.result : ''
          };
          setLocalTenantLogo(newImage);
          onHasChanged(true);
        } else {
          showNotification({
            type: 'error',
            message: 'File size exceeds upload limit of 1.5MB',
            lifespan: null
          });
        }
        resolve(img);
      };
      reader.readAsDataURL(file);
    });
  };

  const renderThumbnail = () => {
    if (localTenantLogo && localTenantLogo.dataURL) {
      let { dataURL } = localTenantLogo;
      if (dataURL && typeof dataURL !== 'string' && dataURL instanceof ArrayBuffer) {
        dataURL = String.fromCharCode.apply(null, new Uint16Array(dataURL));
      }

      return (
        <ThumbnailWrapper>
          <ImageWrapper
            src={String(localTenantLogo.dataURL)}
            alt={'Custom logo'}
          />
        </ThumbnailWrapper>
      );
    }
    return null;
  };

  const renderInstructions = () => {
    return (
      <FileSelectorInstructions>
        <div style={{ margin: 'auto', textAlign: 'center' }}>
          {'Drag and drop or browse to upload a logo.'}
        </div>
        <SizeWarningWrapper>
          <div>{'No images larger than 1.5MB'}</div>
        </SizeWarningWrapper>
      </FileSelectorInstructions>
    );
  };

  const renderActionButtons = () => {
    return (
      <ActionButtonWrapper>
        <Button onClick={() => onUploadLogo(tenantId)} disabled={!localTenantLogo}>
          {'Upload Logo'}
        </Button>
        <Button onClick={handleDeleteLogosConfirmation} disabled={selectedLogoIds.length < 1}>
          {'Delete'}
        </Button>
      </ActionButtonWrapper>
    );
  };

  const renderLoading = () => {
    return (
      <LoadingWrapper>
        <LoadingCircle size={'large'} />
      </LoadingWrapper>
    );
  };

  return (
    <LogoContainer>
      {isFetchingLogos ? renderLoading() :
        <LogoWrapper>
          <FileSelectorWrapper>
            <FileSelector
              accept={[ 'image/png' ]}
              files={localTenantLogo ? [ localTenantLogo.file ] : []}
              onChange={handleLogoUpload}
            >
              <FileSelectorContent>
                {localTenantLogo ? renderThumbnail() : renderInstructions()}
              </FileSelectorContent>
            </FileSelector>
            {isEditing ? renderActionButtons() : null}
          </FileSelectorWrapper>
          {isEditing && <div>
            <Table
              selectedRows={selectedLogoIds}
              onRowSelection={setSelectedLogoIds}
              rows={logos}
              columns={[
                {
                  id: 'logo',
                  title: 'Logo',
                  render: (data: LogoType) => {
                    return (
                      <LogoCellWrapper>
                        <ImageWrapper src={data.image} />
                      </LogoCellWrapper>
                    );
                  }
                },
                {
                  id: 'expanded',
                  title: (
                    <Tooltip
                      position={[ 'top', 'left' ]}
                      tooltip={'Use this logo when the side navigation is expanded.'}
                    >
                      <div>
                        {'Expanded'}
                      </div>
                    </Tooltip>
                  ),
                  width: '70px',
                  render: (data: LogoType) => {
                    return (
                      <div onClick={(event) => event.stopPropagation()} data-test-id={'logo-default-logo-checkbox'}>
                        <Checkbox
                          checked={data.id === defaultLogoId}
                          onChange={() => handleDefaultLogoChange(data.id)}
                        />
                      </div>
                    );
                  }
                },
                {
                  id: 'collapsed',
                  title: (
                    <Tooltip
                      position={[ 'top', 'left' ]}
                      tooltip={'Use this logo when the side navigation is collapsed.'}
                    >
                      <div>
                        {'Collapsed'}
                      </div>
                    </Tooltip>
                  ),
                  width: '70px',
                  render: (data: LogoType) => {
                    return (
                      <div onClick={(event) => event.stopPropagation()} data-test-id={'logo-small-logo-checkbox'}>
                        <Checkbox
                          checked={data.id === smallLogoId}
                          onChange={() => handleSmallLogoChange(data.id)}
                        />
                      </div>
                    );
                  }
                }
              ]}
            />
          </div>}
        </LogoWrapper>
      }
    </LogoContainer>
  );
}

export { Logos };
