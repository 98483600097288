import { flowDirectionSchema } from '@sevone/insight-connect';
import { Variable } from './variable';
import { VariableType } from './types';

const flowDirections: VariableType = {
  label: 'Flow Directions',
  schema: flowDirectionSchema,
  defaultConfiguration: {},
  // There's nothing to configure for flow directions. It's just a static list.
  configure: () => null,
  render: Variable
};

export { flowDirections };
