import { DatasourceType } from './get-datasource.query';

export type FilterType = {
  id: number,
  namespaceName: string,
  namespaceId: number,
  attributeName: string,
  attributeId: number,
  attributeValue: string
}

type RawExtendedDatasourceType = {
  datasourceId: number,
  datasourceName: string,
  tenantDatasourceFilters: Array<FilterType>
}

export type ActionType = 'ADD' | 'DELETE' | 'EDIT';

export type ExtendedFilterType = {
  id?: number,
  namespaceName: string,
  namespaceId: number,
  attributeName: string,
  attributeId: number,
  attributeValue: string,
  action?: ActionType
}

export type ExtendedDatasourceType = DatasourceType & { tenantDatasourceFilters: Array<ExtendedFilterType> }

export const TENANT_DATASOURCE_QUERY = `
  query ($tenantId: Int!) {
    tenantDatasourceFilters(tenantId: $tenantId) {
      datasourceId
      datasourceName
      tenantDatasourceFilters {
        id
        namespaceName
        namespaceId
        attributeName
        attributeId
        attributeValue
      }
    }
  }
`;

export type TenantDatasourceFiltersResponseType = {
  tenantDatasourceFilters: Array<RawExtendedDatasourceType>
}
