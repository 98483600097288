export type DatasourceType = {
  id: number,
  name: string
};

export const DATASOURCE_QUERY = `
  query {
    datasources {
      id,
      name
    }
  }
`;

export type DatasourceResponseType = {
  datasources: Array<DatasourceType>
}
