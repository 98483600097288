const errMap = new Map<RegExp, string>();
errMap.set(/Tenant with name "(\w+)" already exists\./, 'A tenant with the name $1 already exists.');
errMap.set(/User already owns a report with this name\.?/, 'A report with this name already exists.');
errMap.set(/Report name is longer than (\d+) characters/, 'Report name cannot exceed $1 characters.');

const rewriteError = (err: string): string => {
  let mappedError = 'Unknown error';
  const it = errMap.entries();
  let result = it.next();
  while (!result.done) {
    const [ reg, repl ] = result.value;
    if (err.match(reg)) {
      mappedError = err.replace(reg, repl);
      break;
    }
    result = it.next();
  }
  return mappedError;
};

export { rewriteError };
