const DELETE_REPORT = `
  mutation deleteReport($ids: [Int!]!) {
    deleteReport(id: $ids)
  }
`;

export type DeleteReportResponseType = {
  deleteReport: Array<number>
};

export { DELETE_REPORT };
