import * as React from 'react';
import { useModal, useNotification } from '@sevone/insight-connect';
import { Button, FileExportIcon } from '@sevone/scratch';
import { ReportType } from '../../get-reports.query';
import { ExportDialog } from './export-dialog';

type Props = {
  selectedReports: Array<ReportType>
};

function ExportReports(props: Props) {
  const { selectedReports } = props;
  const { showModal } = useModal();
  const { showNotification } = useNotification();

  const handleShowExportDialog = () => {
    showModal((p) => (
      <ExportDialog
        {...p}
        selectedReports={selectedReports}
        onError={(e) => {
          showNotification({ type: 'error', message: e.message });
        }}
      />
    ));
  };

  return (
    <Button
      type="outlined"
      disabled={selectedReports.length === 0}
      prefixIcon={<FileExportIcon />}
      onClick={handleShowExportDialog}
    >
      {'Export'}
    </Button>
  );
}

export { ExportReports };
