export const CREATE_LOGO_MUTATION = `
  mutation CreateTenantLogo($logo: TenantLogoInputArgs!) {
    createTenantLogo(logo: $logo) {
      id
      image
    }
  }
`;

export type CreateLogoResponseType = {
  createTenantLogo: { id: number, image: string }
}
