import * as React from 'react';
import { styled } from 'linaria/react';
import {
  Button,
  LoadingCircle,
  Input
} from '@sevone/scratch';
import { useModal } from '@sevone/insight-connect';
import { TenantDatasourceFilters } from './tenant-datasource-filters';
import { ColumnSelector } from '../columns-selector';
import { ExtendedDatasourceType } from '../../queries/get-tenant-datasource.query';
import { useDebounce } from '../../../../hooks/use-debounce';

const ContentContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  min-width: 590px;
`;
const ContentWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const LoadingWrapper = styled.div`
  align-self: center;
`;

type Props = {
  tenantId?: number,
  onAddDatasources: (ids: Array<number | string>) => void,
  onRemoveDatasources: (ids: Array<number | string>) => void,
  availableDatasources: Array<ExtendedDatasourceType>,
  selectedDatasources: Array<number | string>,
  datasources: Array<ExtendedDatasourceType>,
  onFiltersChange: (datasource: ExtendedDatasourceType) => void,
  isLoading: boolean
}
type ModalProps = {
  hideModal: () => void
};

function Datasources(props: Props) {
  const { showModal } = useModal();
  const { tenantId, availableDatasources, selectedDatasources, isLoading,
    onAddDatasources, onRemoveDatasources, datasources, onFiltersChange } = props;
  const [ searchTerm, setSearchTerm ] = React.useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 200);

  const Loading = (
    <LoadingWrapper>
      <LoadingCircle size={'large'} />
    </LoadingWrapper>
  );

  const handleEditFiltersClick = (event: React.SyntheticEvent, datasourceId: number) => {
    event.stopPropagation();
    const datasource = datasources.find((ds) => ds.id === datasourceId);
    if (datasource) {
      showModal((modalProps: ModalProps) => {
        return (
          <TenantDatasourceFilters
            {...modalProps}
            datasource={datasource}
            onFiltersChange={onFiltersChange}
          />
        );
      });
    }
  };

  return (
    <ContentContainer>
      {isLoading ? Loading :
        <ContentWrapper>
          <Input
            onChange={setSearchTerm}
            value={searchTerm}
            placeholder={'Search data sources...'}
          />
          <ColumnSelector
            key={tenantId}
            leftLabel={'Available Data Sources'}
            rightLabel={'Active Data Sources'}
            onAdd={onAddDatasources}
            onRemove={onRemoveDatasources}
            availableRows={availableDatasources}
            selectedRows={selectedDatasources}
            availableColumns={[
              {
                id: 'name',
                title: 'Name',
                width: '150px',
                render: (data: ExtendedDatasourceType) => data.name
              }
            ]}
            selectedColumns={[
              {
                id: 'name',
                title: 'Name',
                width: '150px',
                render: (data: ExtendedDatasourceType) => data.name
              },
              {
                id: 'filters',
                title: 'Filters',
                width: '70px',
                render: (data: ExtendedDatasourceType) => {
                  return (
                    <Button onClick={(event: React.SyntheticEvent) => handleEditFiltersClick(event, data.id)}>
                      {'Edit'}
                    </Button>
                  );
                }
              }
            ]}
            searchTerm={debouncedSearchTerm}
            pagination
          />
        </ContentWrapper>
      }
    </ContentContainer>
  );
}

export { Datasources };
