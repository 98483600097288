import * as React from 'react';
import { styled } from 'linaria/react';
import {
  Card,
  Input,
  Select
} from '@sevone/scratch';
import isEmpty from 'lodash-es/isEmpty';
import isURL from 'validator/lib/isURL';
import { HORIZONTAL_RHYTHM, VERTICAL_RHYTHM } from '../../../../utils/spacing';

const CardWrapper = styled.div`
  border: none;
  border-radius: 4px;
  flex: 1;
  margin: ${HORIZONTAL_RHYTHM / 2}px 0;
`;
const ContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: ${VERTICAL_RHYTHM / 5}px ${HORIZONTAL_RHYTHM}px;
  & > * {
    width: 400px;
    margin: ${VERTICAL_RHYTHM}px ${HORIZONTAL_RHYTHM}px;
  }
`;


type Props = {
  isCreating: boolean,
  name: string
  setName: (value: string) => void,
  dsType: string,
  setDsType: (value: string) => void,
  address: string,
  setAddress: (value: string) => void,
  apiKey: string
  setApiKey: (value: string) => void,
  setHasChanged: (value: boolean) => void
}


const OPTIONS = [
  {
    label: 'METRICS/FLOW',
    value: 'METRICS/FLOW'
  },
  {
    label: 'FLOW',
    value: 'FLOW'
  }
];

function DatasourceDetails(props: Props) {
  const { isCreating, name, setName, dsType, setDsType,
    address, setAddress, apiKey, setApiKey, setHasChanged } = props;

  const generateValue = () => {
    if (!dsType) {
      return null;
    }
    return {
      label: dsType,
      value: dsType
    };
  };

  const handleNameChange = (value: string) => {
    setHasChanged(true);
    setName(value);
  };

  const handleAddressChange = (value: string) => {
    setHasChanged(true);
    setAddress(value);
  };

  const handleSetDsType = (option: { label: string, value: string }) => {
    setHasChanged(true);
    setDsType(option.value);
  };

  const handleSetApiKey = (value: string) => {
    setHasChanged(true);
    setApiKey(value);
  };

  return (
    <CardWrapper>
      <Card title={'Datasource Details'}>
        <ContentWrapper>
          <Input
            label={'Name'}
            value={name}
            onChange={handleNameChange}
            placeholder={'Enter data source name'}
            status={isEmpty(name) ? 'error' : undefined}
            // This should be "off" but the browser ignores "off". A common solution is to set
            // this field to new-password which browsers have choosen to respect and treat as off.
            autocomplete={'new-password'}
          />
          <Input
            label={'Address'}
            value={address}
            onChange={handleAddressChange}
            placeholder={'Enter data source address'}
            status={!isURL(address, { require_protocol: true }) ? 'error' : undefined}
            // This should be "off" but the browser ignores "off". A common solution is to set
            // this field to new-password which browsers have choosen to respect and treat as off.
            autocomplete={'new-password'}
          />
          <Select
            placeholder={'Please select a data source type'}
            label={'Type'}
            options={OPTIONS}
            value={generateValue()}
            onChange={handleSetDsType}
            status={isEmpty(generateValue()) ? 'error' : undefined}
          />
          {isCreating &&
            <Input
              label={'API Key'}
              value={apiKey}
              onChange={handleSetApiKey}
              placeholder={'Enter API key'}
              status={isEmpty(apiKey) ? 'error' : undefined}
              // This should be "off" but the browser ignores "off". A common solution is to set
              // this field to new-password which browsers have choosen to respect and treat as off.
              autocomplete={'new-password'}
            />
          }
        </ContentWrapper>
      </Card>
    </CardWrapper>
  );
}

export { DatasourceDetails };
