import * as React from 'react';
import { css } from 'linaria';
import { styled } from 'linaria/react';
import { SizeMe } from 'react-sizeme';
import { Responsive as ResponsiveGridLayout } from 'react-grid-layout';
import { uuid } from '../../../../utils/uuid';
import { LayoutType, WidgetType } from '../../types';
import { Widget, dragHandlerStyles } from '../widget';
import { EmptyPlaceholder } from './empty-placeholder';
import ResizableHandleIcon from './resizable-handle.svg';
import {
  WIDGET_DEFAULT_WIDTH,
  WIDGET_DEFAULT_HEIGHT,
  GRID_ROW_HEIGHT
} from './constants';

const NEW_WIDGET_ID = 'new-widget';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  min-height: 100%;
  width: 100%;
`;

const GridWrapper = styled.div`
  min-height: 100%;
  width: 100%;
`;

const ResizeHandle = styled(ResizableHandleIcon)`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 12px;
  height: 12px;

  path {
    fill: var(--sev1-primary-5-color);
  }
`;

const responsiveGridStyles = css`
  overflow: hidden;
  min-height: 100%;
`;

type Props = {
  isReadOnly: boolean,
  isWritable: boolean,
  widgets: Array<WidgetType>,
  layout: Array<LayoutType>,
  editWidgetId: string | null,
  onLayoutChange: (layout: Array<LayoutType>) => void,
  onAddWidget: (layout: Array<LayoutType>) => void,
  onEditWidget: (id: string) => void,
  onDeleteWidget: (id: string) => void
  onMaximizeWidget: (id: string | null) => void
  onFullscreenWidget: (id: string | null) => void
};

function WidgetGrid(props: Props) {
  const {
    isReadOnly,
    isWritable,
    widgets,
    layout,
    editWidgetId,
    onLayoutChange,
    onAddWidget,
    onEditWidget,
    onDeleteWidget,
    onMaximizeWidget,
    onFullscreenWidget
  } = props;
  // When we add a new widget via drag/drop, we need to know not only the
  // layout of the new widget, but also the global layout of all the widgets
  // and how this new widget affected them. We don't get the global info in
  // RGL's `onDrop` callback, so we need to track it manually via its `onDrag`
  // and reference if when the widget is finally dropped/added.
  // ISSUE: https://github.com/STRML/react-grid-layout/issues/1068
  const dirtyLayout = React.useRef(layout);

  const handleLayoutChange = (
    curLayout: Array<LayoutType>,
    layouts: { lg: Array<LayoutType> }
  ) => {
    const lgLayout = layouts.lg;

    // RGL has an issue where it'll immediately trigger two layout changes when
    // a new item is being dragged onto it. The first is accurate, but the
    // second one is missing the new item. Because it comes second, that's the
    // one that is saved, and so RGL no longer is aware of the new item. So,
    // if we see a situation where there are more widgets in the layout than
    // there are widgets, we can assume we're in the "dropping new widget"
    // mode and safely ignore any layout updates until the new widget is
    // successfully added to/dropped on the grid.
    if (!lgLayout || lgLayout.length !== widgets.length) {
      return;
    }

    onLayoutChange(lgLayout);
  };

  const handleDrop = () => {
    const id = uuid();
    const nextLayout = dirtyLayout.current.map((l) => {
      if (l.i !== NEW_WIDGET_ID) {
        return l;
      }

      // We need to replace the new widget's static id with a uuid
      return { ...l, i: id };
    });

    onAddWidget(nextLayout);
  };

  return (
    <Wrapper>
      <SizeMe noPlaceholder>
        {({ size }) => (
          <GridWrapper>
            {size.width &&
              <ResponsiveGridLayout
                isDraggable={isWritable}
                isResizable={isWritable}
                width={size.width}
                draggableCancel={'.widget-grid-no-drag'}
                draggableHandle={`.${dragHandlerStyles}`}
                className={responsiveGridStyles}
                // Ideally we'd have no padding here, but an active widget gets
                // a box-shadow to indicate its status, and that box shadow will
                // bleed outside this container. Because this container and its
                // parents need `overflow: auto` to scroll within themselves,
                // any overflowing shadow gets cut off. So this is the smallest
                // padding we can get to give it enough breathing room to not
                // mess with the effect.
                containerPadding={[ 2, 2 ]}
                breakpoints={ { lg: 768, sm: 480 } }
                cols={ { lg: 12, sm: 1 } }
                // TODO(report-migration): Previously we used the default row
                // height of 150px, which means all the widgets' `layout` have
                // an `h` value of 1/3 of what it should be now. h: 2 => h: 6.
                rowHeight={GRID_ROW_HEIGHT}
                layouts={{ lg: layout }}
                droppingItem={{
                  i: NEW_WIDGET_ID,
                  w: WIDGET_DEFAULT_WIDTH,
                  h: WIDGET_DEFAULT_HEIGHT
                }}
                onLayoutChange={handleLayoutChange}
                onDrag={(curLayout) => {
                  // @ts-ignore: We know every layout will have an `i` field.
                  dirtyLayout.current = curLayout;
                }}
                onDrop={handleDrop}
              >
                {widgets.map((widget) => (
                  <div key={widget.id}>
                    <Widget
                      isWritable={isWritable && !isReadOnly}
                      isEditing={widget.id === editWidgetId}
                      widget={widget}
                      onEditWidget={onEditWidget}
                      onDeleteWidget={onDeleteWidget}
                      onMaximizeWidget={onMaximizeWidget}
                      onFullscreenWidget={onFullscreenWidget}
                    />
                    {isWritable && !isReadOnly &&
                      <ResizeHandle />
                    }
                  </div>
                ))}
              </ResponsiveGridLayout>
            }
          </GridWrapper>
        )}
      </SizeMe>
      {widgets.length === 0 &&
        <EmptyPlaceholder />
      }
    </Wrapper>
  );
}

export { WidgetGrid };
