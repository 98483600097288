/**
 * NOTE:
 * There's a bug with the combination of (react-hot-loader, linaria, scratch)
 * where if we try to export this component (which renders a scratch component)
 * from the root index of `page` we get an error about `setTimeout`
 * (from react-hot-loader) being undefined. The work around for this is to
 * import this component seperately from the root `page` directory, eg:
 *
 * import { PageSidebar } from './components/page/page-sidebar
 *
 * But you will also receive the same bug if you import this component and try
 * to wrap a linaria component around it, eg:
 *
 * const Wrapper = styled(PageSidebar)``;
 *
 * So the conditions around this file are:
 * - Do not try to mark as an export of `page/index.tsx`
 * - Do not try to wrap a linaria component around it
 */
import * as React from 'react';
import { styled } from 'linaria/react';
import { IconButton, RemoveIcon } from '@sevone/scratch';
import { VERTICAL_RHYTHM, HORIZONTAL_RHYTHM } from '../../utils/spacing';
import { H3 } from '../typography';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: none;
  width: 352px;
  height: 100%;
  background: var(--sev1-primary-4-color);
  color: var(--sev1-primary-4-contrast);
  border-left: 1px solid var(--sev1-primary-2-color);
  box-sizing: border-box;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: none;
  padding: ${VERTICAL_RHYTHM}px ${HORIZONTAL_RHYTHM}px;
`;

const Content = styled.div`
  flex: 1;
  overflow: auto;
`;

type PageSidebarProps = {
  title: string,
  className?: string,
  style?: React.CSSProperties,
  children: React.ReactNode,
  onClose?: () => void
};

function PageSidebar(props: PageSidebarProps) {
  const { title, className, style, children, onClose } = props;

  return (
    <Wrapper className={className} style={style}>
      <Header>
        <H3>{title}</H3>
        {onClose &&
          <IconButton onClick={onClose}>
            <RemoveIcon fixedWidth />
          </IconButton>
        }
      </Header>
      <Content>
        {children}
      </Content>
    </Wrapper>
  );
}

export { PageSidebar };
