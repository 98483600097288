import { useReducer, useState } from 'react';
import keyBy from 'lodash-es/keyBy';
import { isTruthy } from '../utils/is-truthy';
import {
  ReportType,
  SectionType,
  WidgetType,
  LayoutType,
  ReportVariableType,
  WidgetLinkType
} from '../pages/report/types';

type ActionType<T, P> = {
  type: T,
  payload: P
};

type ActionsType =
  ActionType<'renameReport', ReportType['name']> |
  ActionType<'updateTimespan', ReportType['content']['timespan']> |
  ActionType<'updateRefreshInterval', ReportType['refreshInterval']> |
  ActionType<'updateRotateInterval', ReportType['rotateInterval']> |
  ActionType<'addSection', SectionType> |
  ActionType<'deleteSection', SectionType['id']> |
  ActionType<'renameSection', {
    sectionId: SectionType['id'],
    title: SectionType['title']
  }> |
  ActionType<'updateSectionLayout', {
    sectionId: SectionType['id'],
    layout: SectionType['layout']
  }> |
  ActionType<'reorderSections', Array<SectionType['id']>> |
  ActionType<'addWidget', {
    sectionId: SectionType['id'],
    layout: Array<LayoutType>,
    widget: WidgetType
  }> |
  { type: 'deleteWidget', payload: {
    sectionId: SectionType['id'],
    widgetId: WidgetType['id']
  }} |
  ActionType<'renameWidget', {
    sectionId: SectionType['id'],
    widgetId: WidgetType['id'],
    name: WidgetType['name']
  }> |
  ActionType<'updateWidgetConfiguration', {
    widgetId: WidgetType['id'],
    configuration: WidgetType['configuration']
  }> |
  ActionType<'addWidgetLink', {
    sectionId: SectionType['id'],
    link: WidgetLinkType
  }> |
  ActionType<'addWidgetToLink', {
    sectionId: SectionType['id'],
    linkId: WidgetLinkType['id'],
    widgetId: WidgetType['id'],
    chain: boolean
  }> |
  ActionType<'deleteWidgetLink', {
    sectionId: SectionType['id'],
    linkId: WidgetLinkType['id'],
    widgetId: WidgetType['id']
  }> |
  ActionType<'addReportVariable', ReportVariableType> |
  ActionType<'updateReportVariable', ReportVariableType> |
  ActionType<'deleteReportVariable', ReportVariableType['id']> |
  ActionType<'reorderReportVariables', Array<ReportVariableType['id']>> |
  ActionType<'updateReportVariableValue', {
    variableId: ReportVariableType['id'],
    value: any
  }>;

function reducer(state: ReportType, action: ActionsType): ReportType {
  switch (action.type) {
    case 'renameReport': {
      return {
        ...state,
        name: action.payload
      };
    }
    case 'updateTimespan': {
      return {
        ...state,
        content: {
          ...state.content,
          timespan: action.payload
        }
      };
    }
    case 'updateRefreshInterval': {
      return {
        ...state,
        refreshInterval: action.payload
      };
    }
    case 'updateRotateInterval': {
      return {
        ...state,
        rotateInterval: action.payload
      };
    }
    case 'addSection': {
      return {
        ...state,
        content: {
          ...state.content,
          sections: [ ...state.content.sections, action.payload ]
        }
      };
    }
    case 'deleteSection': {
      return {
        ...state,
        content: {
          ...state.content,
          sections: state.content.sections.filter((section) => {
            return section.id !== action.payload;
          })
        }
      };
    }
    case 'renameSection': {
      return {
        ...state,
        content: {
          ...state.content,
          sections: state.content.sections.map((section) => {
            if (section.id !== action.payload.sectionId) {
              return section;
            }

            return { ...section, title: action.payload.title };
          })
        }
      };
    }
    case 'updateSectionLayout': {
      return {
        ...state,
        content: {
          ...state.content,
          sections: state.content.sections.map((section) => {
            if (section.id !== action.payload.sectionId) {
              return section;
            }

            return { ...section, layout: action.payload.layout };
          })
        }
      };
    }
    case 'reorderSections': {
      const sectionMap = keyBy(state.content.sections, 'id');

      return {
        ...state,
        content: {
          ...state.content,
          sections: action.payload.map((id) => sectionMap[id])
        }
      };
    }
    case 'addWidget': {
      return {
        ...state,
        content: {
          ...state.content,
          sections: state.content.sections.map((section) => {
            if (section.id !== action.payload.sectionId) {
              return section;
            }

            return {
              ...section,
              layout: action.payload.layout,
              widgets: [ ...section.widgets, action.payload.widget ]
            };
          })
        }
      };
    }
    case 'deleteWidget': {
      return {
        ...state,
        content: {
          ...state.content,
          sections: state.content.sections.map((section) => {
            const { sectionId, widgetId } = action.payload;
            if (section.id !== sectionId) {
              return section;
            }

            return {
              ...section,
              layout: section.layout.filter((l) => l.i !== widgetId),
              widgets: section.widgets.filter((w) => w.id !== widgetId),
              widgetLinks: section.widgetLinks.map((l) => {
                // Delete any link this widget is the parent of
                if (l.parentId === widgetId) {
                  return null;
                }

                const children = l.children.filter(({ id }) => id !== widgetId);
                // Delete the link if there are no children left after removing
                // this widget from them.
                if (children.length === 0) {
                  return null;
                }

                return { ...l, children };
              }).filter(isTruthy)
            };
          })
        }
      };
    }
    case 'renameWidget': {
      return {
        ...state,
        content: {
          ...state.content,
          sections: state.content.sections.map((section) => {
            if (section.id !== action.payload.sectionId) {
              return section;
            }

            return {
              ...section,
              widgets: section.widgets.map((widget) => {
                if (widget.id !== action.payload.widgetId) {
                  return widget;
                }

                return {
                  ...widget,
                  name: action.payload.name
                };
              })
            };
          })
        }
      };
    }
    case 'updateWidgetConfiguration': {
      const { widgetId, configuration } = action.payload;

      return {
        ...state,
        content: {
          ...state.content,
          sections: state.content.sections.map((section) => {
            if (!section.widgets.some(({ id }) => id === widgetId)) {
              return section;
            }

            return {
              ...section,
              widgets: section.widgets.map((widget) => {
                if (widget.id !== widgetId) {
                  return widget;
                }

                return {
                  ...widget,
                  configuration
                };
              })
            };
          })
        }
      };
    }
    case 'addWidgetLink': {
      return {
        ...state,
        content: {
          ...state.content,
          sections: state.content.sections.map((section) => {
            if (section.id !== action.payload.sectionId) {
              return section;
            }

            return {
              ...section,
              widgetLinks: [ ...section.widgetLinks, action.payload.link ]
            };
          })
        }
      };
    }
    case 'addWidgetToLink': {
      return {
        ...state,
        content: {
          ...state.content,
          sections: state.content.sections.map((section) => {
            if (section.id !== action.payload.sectionId) {
              return section;
            }

            return {
              ...section,
              widgetLinks: section.widgetLinks.map((link) => {
                if (link.id !== action.payload.linkId) {
                  return link;
                }

                return {
                  ...link,
                  children: [ ...link.children, {
                    id: action.payload.widgetId,
                    chain: action.payload.chain
                  } ]
                };
              })
            };
          })
        }
      };
    }
    case 'deleteWidgetLink': {
      return {
        ...state,
        content: {
          ...state.content,
          sections: state.content.sections.map((section) => {
            if (section.id !== action.payload.sectionId) {
              return section;
            }

            return {
              ...section,
              widgetLinks: section.widgetLinks.map((link) => {
                if (link.id !== action.payload.linkId) {
                  return link;
                }

                const nextChildren = link.children.filter(({ id }) => {
                  return id !== action.payload.widgetId;
                });

                // Delete this link if it no longer has any children
                if (nextChildren.length === 0) {
                  return null;
                }

                return {
                  ...link,
                  children: nextChildren
                };
              }).filter(isTruthy)
            };
          })
        }
      };
    }
    case 'addReportVariable': {
      return {
        ...state,
        content: {
          ...state.content,
          variables: [ ...state.content.variables, action.payload ]
        }
      };
    }
    case 'updateReportVariable': {
      return {
        ...state,
        content: {
          ...state.content,
          variables: state.content.variables.map((variable) => {
            if (variable.id !== action.payload.id) {
              return variable;
            }

            return action.payload;
          })
        }
      };
    }
    case 'deleteReportVariable': {
      return {
        ...state,
        content: {
          ...state.content,
          variables: state.content.variables.filter((variable) => {
            return variable.id !== action.payload;
          })
        }
      };
    }
    case 'reorderReportVariables': {
      const variablesMap = keyBy(state.content.variables, 'id');
      return {
        ...state,
        content: {
          ...state.content,
          variables: action.payload.map((id) => variablesMap[id])
        }
      };
    }
    case 'updateReportVariableValue': {
      return {
        ...state,
        content: {
          ...state.content,
          variables: state.content.variables.map((variable) => {
            if (variable.id !== action.payload.variableId) {
              return variable;
            }

            return { ...variable, value: action.payload.value };
          })
        }
      };
    }
    default:
      return state;
  }
}

function useReportModifier(initialReport: ReportType) {
  const [ report, modifyReport ] = useReducer(reducer, initialReport);
  const [
    edits,
    setEdits
  ] = useState<Array<ActionsType['type']>>([]);

  const cleanReport = () => {
    setEdits([]);
  };

  const handleModifyReport: typeof modifyReport = (...args) => {
    modifyReport(...args);
    setEdits((curr) => [ ...curr, ...args.map((arg) => arg.type) ]);
  };

  return {
    report,
    editReport: handleModifyReport,
    isDirty: edits.length > 0,
    edits,
    cleanReport
  };
}

export { useReportModifier };
