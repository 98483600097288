const WIDGETS = {
  alertDetails: 'alert-details',
  alertSummary: 'alert-summary',
  flow: 'flow',
  foundry: 'foundry',
  groupMetrics: 'groupMetrics',
  ipsla: 'ipsla',
  livemaps: 'livemaps',
  metadata: 'metadata',
  pm: 'pm',
  topN: 'topn',
  topology: 'topology',
  url: 'url'
};

const widgetWhitelist: Record<string, Array<string>> = {
  [WIDGETS.alertDetails]: [
    WIDGETS.alertDetails,
    WIDGETS.alertSummary
  ],
  [WIDGETS.alertSummary]: [
    WIDGETS.alertDetails,
    WIDGETS.alertSummary
  ],
  [WIDGETS.flow]: [
    WIDGETS.flow
  ],
  [WIDGETS.foundry]: [],
  [WIDGETS.groupMetrics]: [
    WIDGETS.alertDetails,
    WIDGETS.alertSummary,
    WIDGETS.pm
  ],
  [WIDGETS.ipsla]: [],
  [WIDGETS.livemaps]: [
    WIDGETS.alertDetails,
    WIDGETS.alertSummary
  ],
  [WIDGETS.metadata]: [
    WIDGETS.alertDetails,
    WIDGETS.alertSummary,
    WIDGETS.metadata
  ],
  [WIDGETS.pm]: [
    WIDGETS.alertDetails,
    WIDGETS.alertSummary,
    WIDGETS.flow,
    WIDGETS.pm
  ],
  [WIDGETS.topN]: [
    WIDGETS.alertDetails,
    WIDGETS.alertSummary,
    WIDGETS.flow,
    WIDGETS.pm,
    WIDGETS.topN
  ],
  [WIDGETS.topology]: [
    WIDGETS.alertDetails,
    WIDGETS.alertSummary
  ],
  [WIDGETS.url]: []
};

export { widgetWhitelist };
