export const DEAUTHENTICATE_QUERY = `
  mutation {
    deauthenticate {
      success
    }
  }
`;

export type DeauthenticateResponseType = {
  deauthenticate: {
    success: boolean
  }
};
