import { useState } from 'react';
import { useRequest } from '@sevone/insight-connect';
import { CoreStore } from '../../store';
import { rewriteError } from './error-map';

const AUTH_ERROR = 'Authentication failure.';

type GqlVariablesType = {
  [key: string]: any
};

export type ErrorType = {
  message: string
};

type GqlResponseType<T> = {
  data: {
    data?: T,
    errors?: Array<ErrorType>
  }
};

type OptionsType = {
  isFetching?: boolean
};

function useGql<ValueType>(
  gqlQuery: string,
  opts: OptionsType = { isFetching: false }
) {
  const { logout } = CoreStore.useContainer();
  const { query } = useRequest();
  const [ isFetching, setIsFetching ] = useState(!!opts.isFetching);

  const runGql = (variables: GqlVariablesType = {}): Promise<ValueType> => {
    setIsFetching(true);

    return new Promise((resolve, reject) => {
      const unknownError = 'There was an unknown network error.';

      query(gqlQuery, variables)
        .then((res: GqlResponseType<ValueType>) => {
          if (res.data.errors && res.data.errors.length > 0) {
            const error = res.data.errors[0];

            if (error.message === AUTH_ERROR) {
              logout();
            }

            reject({ message: rewriteError(error.message) });
          }

          if (!res.data.data) {
            reject({ message: unknownError });
          }

          resolve(res.data.data);
        })
        .catch(() => {
          reject({ message: unknownError });
        })
        .finally(() => {
          setIsFetching(false);
        });
    });
  };

  return {
    isFetching,
    runGql
  };
}

export { useGql };
