import * as React from 'react';
import { styled } from 'linaria/react';
import { useNavigation, Link } from '@sevone/insight-connect';
import { AngleRightIcon } from '@sevone/scratch';
import { HORIZONTAL_RHYTHM } from '../../../../utils/spacing';

const Wrapper = styled.div`
  font-size: .8rem;
`;

const BreadcrumbWrapper = styled.div`
  display: inline-block;
  color: var(--sev1-primary-5-color);
  text-decoration: underline;
`;

const DividerWrapper = styled.div`
  display: inline-block;
  margin: 0 ${HORIZONTAL_RHYTHM / 2}px;
`;

type LocationType = {
  pathname: string,
  hash?: string,
  params?: { [key: string]: any },
  state?: {
    breadcrumbs?: Array<{
      label: string,
      location: LocationType
    }>,
    [key: string]: any
  }
};

function Breadcrumbs(props: {reportTitle: string}) {
  const { location } = useNavigation();
  const { breadcrumbs } = location.state;
  const { reportTitle } = props;

  if (!breadcrumbs) {
    return null;
  }

  return (
    <Wrapper>
      {breadcrumbs.map((breadcrumb) => (
        <>
          <BreadcrumbWrapper>
            <Link to={breadcrumb.location}>
              {breadcrumb.label}
            </Link>
          </BreadcrumbWrapper>
          <DividerWrapper><AngleRightIcon /></DividerWrapper>
        </>
      ))}
      {reportTitle}
    </Wrapper>
  );
}

export { Breadcrumbs };
