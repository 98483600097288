import * as React from 'react';
import { styled } from 'linaria/react';
import { HomeIcon } from '@sevone/scratch';
import MinimalLogo from './logo-minimal.svg';
import LargeLogo from './logo-large.svg';
import { CoreStore } from '../../store';

type WrapperProps = {
  primaryColor: string,
  secondaryColor: string,
  className?: string
};

const Wrapper = styled.div<WrapperProps>`
  --logo-primary-color: ${({ primaryColor }) => primaryColor};
  --logo-secondary-color: ${({ secondaryColor }) => secondaryColor};
`;
const CustomLogoContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
`;
const CustomLogo = styled.img`
  height: 100%;
  object-fit: scale-down;
`;
const HomeIconWrapper = styled.div`
  font-size: var(--sev1-size);
`;

type Props = {
  className?: string,
  minimal?: boolean,
  primaryColor?: string,
  secondaryColor?: string
};

function Logo(props: Props) {
  const { activeTenant } = CoreStore.useContainer().state;
  const {
    className,
    minimal,
    primaryColor = '#7998b9', // Marketing logo colors not included in our theme
    secondaryColor = '#ed8b00'
  } = props;

  if (minimal && activeTenant) {
    if (activeTenant.smallLogo) {
      return (
        <CustomLogoContainer>
          <CustomLogo src={activeTenant.smallLogo.image} />
        </CustomLogoContainer>
      );
    }

    if (activeTenant.defaultLogo) {
      return (
        <HomeIconWrapper>
          <HomeIcon />
        </HomeIconWrapper>
      );
    }
  }

  if (activeTenant && activeTenant.defaultLogo) {
    return (
      <CustomLogoContainer>
        <CustomLogo src={activeTenant.defaultLogo.image} />
      </CustomLogoContainer>
    );
  }

  return (
    <Wrapper
      className={className}
      primaryColor={primaryColor}
      secondaryColor={secondaryColor}
    >
      {minimal ?
        <MinimalLogo height="100%" /> :
        <LargeLogo height="100%" />
      }
    </Wrapper>
  );
}

export { Logo };
