import { generateEqualsFilter } from '../../filter-reports';
import { ReportType } from '../../get-reports.query';

const allTemplates = {
  id: -2,
  parentId: null,
  name: 'All Templates',
  reportCount: ({ reports }: { reports: Array<ReportType> }): number => {
    return reports.filter((r) => r.isTemplate).length;
  },
  children: () => [],
  filters: () => {
    return [
      generateEqualsFilter('isTemplate', true)
    ];
  }
};

export { allTemplates };
