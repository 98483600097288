import * as React from 'react';
import { Select, Input, Button } from '@sevone/scratch';
import { styled } from 'linaria/react';
import isNil from 'lodash-es/isNil';
import {
  NAMESPACE_QUERY,
  NamespaceResponseType
} from './queries/get-namespaces.query';
import {
  ATTRIBUTE_QUERY,
  AttributeResponseType
} from './queries/get-attributes.query';
import { ExtendedFilterType, ActionType } from '../../../../queries/get-tenant-datasource.query';
import { useGql } from '../../../../../../hooks/use-gql';

const Container = styled.div`
  display: flex;
  justify-content: space-between;

  & > * {
    flex: 1;
    margin: 0 5px;
  }
`;
const ButtonContainer = styled.div`
  display: flex;
  flex: none;
  align-items: flex-end;
`;
type Props = {
  filterId?: number,
  datasourceId: number,
  initalNamespaceName?: string,
  initalNamespaceId?: number,
  initalAttributeName?: string,
  initalAttributeId?: number,
  initalAttributeValue?: string,
  renderIcon: () => React.ReactNode,
  onDelete: (newFilter: ExtendedFilterType) => void,
  onAdd: (newFilter: ExtendedFilterType) => void,
  onEdit: (newFilter: ExtendedFilterType) => void,
  isEditable: boolean
}
type OptionType = {
  value: number,
  label: string
}

const SIZE = 100;

function MetadataSelector(props: Props) {
  const {
    runGql: requestNamespaces
  } = useGql<NamespaceResponseType>(NAMESPACE_QUERY);
  const {
    runGql: requestAttributes
  } = useGql<AttributeResponseType>(ATTRIBUTE_QUERY);
  const { datasourceId, initalNamespaceName, initalNamespaceId, initalAttributeName,
    initalAttributeId, initalAttributeValue, renderIcon, filterId, onAdd,
    onEdit, onDelete, isEditable } = props;
  const [ namespaceName, setNamespaceName ] = React.useState(initalNamespaceName);
  const [ namespaceId, setNamespaceId ] = React.useState(initalNamespaceId);
  const [ attributeName, setAttributeName ] = React.useState(initalAttributeName);
  const [ attributeId, setAttributeId ] = React.useState(initalAttributeId);
  const [ attributeValue, setAttributeValue ] = React.useState(initalAttributeValue);
  const [ selectedNamespace, setSelectedNamespace ] = React.useState<OptionType | null>(null);
  const [ selectedAttribute, setSelectedAttribute ] = React.useState<OptionType | null>(null);

  const fetchNamespaceOptions = (name: string = '') => {
    const vars = {
      datasourceId,
      filter: {
        name
      },
      size: SIZE
    };
    return requestNamespaces(vars).then((res) => {
      return res.metadataNamespaces.map((ns) => {
        return {
          label: ns.name,
          value: ns.id
        };
      });
    });
  };

  const fetchAttributeOptions = (name: string = '') => {
    if (namespaceName) {
      const vars = {
        datasourceId,
        filter: {
          name,
          namespaceId
        },
        size: SIZE
      };
      return requestAttributes(vars).then((res) => {
        return res.metadataAttributes.map((attr) => {
          return {
            label: attr.name,
            value: attr.id
          };
        });
      });
    }

    return Promise.resolve([]);
  };

  React.useEffect(() => {
    if (namespaceName && namespaceId) {
      setSelectedNamespace({
        label: namespaceName,
        value: namespaceId
      });
    } else {
      setSelectedNamespace(null);
    }
  }, [ namespaceName, namespaceId ]);

  React.useEffect(() => {
    if (attributeName && attributeId) {
      setSelectedAttribute({
        label: attributeName,
        value: attributeId
      });
    } else {
      setSelectedAttribute(null);
    }
  }, [ attributeName, attributeId ]);

  const handleNamespaceChange = (option: OptionType) => {
    setNamespaceName(option.label);
    setNamespaceId(option.value);
    setAttributeName(undefined);
    setAttributeId(undefined);
    setAttributeValue('');
  };

  const handleAttributeChange = (option: OptionType) => {
    setAttributeName(option.label);
    setAttributeId(option.value);
    setAttributeValue('');
  };

  const handleAttributeValueChange = (value: string) => {
    setAttributeValue(value);
    if (filterId && namespaceName && namespaceId && attributeName && attributeId && attributeValue) {
      const newFilter = {
        id: filterId,
        namespaceName,
        namespaceId,
        attributeName,
        attributeId,
        attributeValue,
        action: 'EDIT' as ActionType
      };
      onEdit(newFilter);
    }
  };

  const handleSubmit = () => {
    if (namespaceName && namespaceId && attributeName && attributeId && attributeValue) {
      const newFilter = {
        id: filterId || new Date().getTime(),
        namespaceName,
        namespaceId,
        attributeName,
        attributeId,
        attributeValue,
        action: filterId ? 'DELETE' as ActionType : 'ADD' as ActionType
      };
      if (filterId) {
        onDelete(newFilter);
      } else {
        onAdd(newFilter);
        setNamespaceName(undefined);
        setNamespaceId(undefined);
        setAttributeName(undefined);
        setAttributeId(undefined);
        setAttributeValue('');
      }
    }
  };

  return (
    <Container>
      <div>
        <Select
          key={filterId}
          label={'Namespace'}
          placeholder={'Namespace'}
          onChange={handleNamespaceChange}
          options={fetchNamespaceOptions}
          value={selectedNamespace}
          disabled={!isEditable}
        />
      </div>
      <div>
        <Select
          key={namespaceId}
          disabled={!isEditable || isNil(selectedNamespace)}
          label={'Attribute'}
          placeholder={'Attribute'}
          onChange={handleAttributeChange}
          options={fetchAttributeOptions}
          value={selectedAttribute}
        />
      </div>
      <div>
        <Input
          label={'Value'}
          placeholder={'Value'}
          value={attributeValue}
          onChange={handleAttributeValueChange}
          disabled={isNil(selectedAttribute)}
        />
      </div>
      <ButtonContainer>
        <Button
          onClick={handleSubmit}
          disabled={isEditable && (!namespaceName || !namespaceId || !attributeName || !attributeId || !attributeValue)}
        >
          {renderIcon()}
        </Button>
      </ButtonContainer>
    </Container>
  );
}

MetadataSelector.defaultProps = {
  initalAttributeValue: ''
};

export { MetadataSelector };
