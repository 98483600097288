import { devices } from './devices';
import { deviceGroups } from './device-groups';
import { objects } from './objects';
import { objectGroups } from './object-groups';
import { objectMetadata } from './object-metadata';
import { indicators } from './indicators';
import { flowInterfaces } from './flow-interfaces';
import { flowViews } from './flow-views';
import { flowDirections } from './flow-directions';
import { topNViews } from './topn-views';

const variableTypes = [
  devices,
  deviceGroups,
  objects,
  objectGroups,
  objectMetadata,
  indicators,
  flowInterfaces,
  flowViews,
  flowDirections,
  topNViews
];

export { variableTypes };
