import { TenantType } from './queries/get-tenants.query';

export const DEFAULT_COLUMNS = [
  {
    id: 'name',
    title: 'Name',
    render: (data: TenantType) => data.name,
    sort: (row: TenantType, otherRow: TenantType) => {
      const { name } = row;
      const { name: otherName } = otherRow;
      if (name < otherName) {
        return -1;
      }
      if (name > otherName) {
        return 1;
      }

      return 0;
    }
  }
];
