import * as React from 'react';
import { css } from 'linaria';
import { motion, AnimatePresence } from 'framer-motion';
import { PageSidebar } from '../../../../components/page/page-sidebar';

const wrapperStyles = css`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 2000;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.25);
`;

type Props = {
  id?: string,
  title: string,
  visible: boolean,
  children: React.ReactNode,
  onClose: () => void
};

function Sidebar(props: Props) {
  const { id, title, visible, children, onClose } = props;

  return (
    <AnimatePresence>
      {visible &&
        <motion.div
          key={id}
          className={wrapperStyles}
          initial="hidden"
          animate="visible"
          exit="hidden"
          variants={{
            visible: { x: 0 },
            hidden: { x: '100%' }
          }}
          transition={{ type: 'tween', ease: [ 1, 0.01, 0, 1 ] }}
        >
          <PageSidebar title={title} onClose={onClose}>
            {children}
          </PageSidebar>
        </motion.div>
      }
    </AnimatePresence>
  );
}

export { Sidebar };
