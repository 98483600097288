import * as React from 'react';
import { styled } from 'linaria/react';
import isUndefined from 'lodash-es/isUndefined';
import isEmpty from 'lodash-es/isEmpty';
import { useNotification } from '@sevone/insight-connect';
import { Tabs, Tab, Button } from '@sevone/scratch';
import { CoreStore } from '../../../store';
import { HORIZONTAL_RHYTHM } from '../../../utils/spacing';
import { cancelable } from '../../../utils/cancelable';
import { useGql } from '../../../hooks/use-gql';
import { PageSidebar } from '../../../components/page/page-sidebar';
import {
  hasPermission,
  RequiredPermissionsType
} from '../../../components/permission-gate';
import { ReportType } from '../get-reports.query';
import { LoadingIndicator } from './loading-indicator';
import { Details } from './details';
import { UserAccess } from './user-access';
import { Schedule } from './schedule';
import { GET_USERS, GetUsersResponseType } from './get-users.query';
import { GET_TIMEZONES, GetTimezonesResponseType } from './get-timezones.query';
import { UPDATE_ACL, UpdateAclResponseType } from './update-acl.mutation';
import { SET_SCHEDULE, SetScheduleResponseType } from './set-schedule.mutation';
import {
  DELETE_SCHEDULE,
  DeleteScheduleResponseType
} from './delete-schedule.mutation';
import {
  UPDATE_REPORT,
  UpdateReportResponseType
} from './update-report.mutation';

const Wrapper = styled.div`
  position: relative;
`;

const ButtonsWrapper = styled.div`
  text-align: right;
  margin: 0 ${HORIZONTAL_RHYTHM}px;

  & > * {
    margin-left: ${HORIZONTAL_RHYTHM}px;
  }
`;

type PropertyType = {
  name: string,
  permissions: RequiredPermissionsType,
  component: React.ComponentType<{
    report: ReportType,
    users: Array<{ label: string, value: number }>,
    timezones: Array<{ label: string, value: string }>,
    onChange: (report: Partial<ReportType>) => void
  }>
};

type Props = {
  report: ReportType,
  initialProperty: string,
  onClose: () => void,
  onChange: (report: ReportType) => void
};

function ReportProperties(props: Props) {
  const { report, initialProperty, onClose, onChange } = props;
  const { state } = CoreStore.useContainer();
  const { showNotification } = useNotification();
  const {
    runGql: saveDetails
  } = useGql<UpdateReportResponseType>(UPDATE_REPORT);
  const { runGql: saveAcl } = useGql<UpdateAclResponseType>(UPDATE_ACL);
  const {
    runGql: saveSchedule
  } = useGql<SetScheduleResponseType>(SET_SCHEDULE);
  const {
    runGql: deleteSchedule
  } = useGql<DeleteScheduleResponseType>(DELETE_SCHEDULE);
  const {
    isFetching: isFetchingUsers,
    runGql: fetchUsers
  } = useGql<GetUsersResponseType>(GET_USERS);
  const {
    isFetching: isFetchingTimezones,
    runGql: fetchTimezones
  } = useGql<GetTimezonesResponseType>(GET_TIMEZONES);
  const [
    users,
    setUsers
  ] = React.useState<Array<{ label: string, value: number }>>([]);
  const [
    timezones,
    setTimezones
  ] = React.useState<Array<{ label: string, value: string }>>([]);
  const [
    selectedProperty,
    setSelectedProperty
  ] = React.useState<string | number>(initialProperty);
  const [
    dirtyReport,
    setDirtyReport
  ] = React.useState<Partial<ReportType>>({});
  const activeReport: ReportType = {
    ...report,
    ...dirtyReport
  };
  const properties: Array<PropertyType> = [
    {
      name: 'Details',
      permissions: [],
      component: Details
    },
    // These properties should only be displayed if the report is editable
    ...(report.isWritable ? [
      {
        name: 'Permissions',
        permissions: [],
        component: UserAccess
      }
    ] as Array<PropertyType> : []),
    {
      name: 'Scheduling',
      permissions: [],
      component: Schedule
    }
  ];

  const handleChange = (update: Partial<ReportType>) => {
    setDirtyReport({ ...dirtyReport, ...update });
  };

  const handleSave = () => {
    const {
      name,
      description,
      acl: dirtyAcl,
      schedule: dirtySchedule
    } = dirtyReport;
    const { id, ...acl } = activeReport.acl;
    const dirtyDetails = { name, description };
    const saves: Array<Promise<any>> = [];

    if (dirtyDetails && report.isWritable) {
      saves.push(saveDetails({
        id: activeReport.id,
        report: {
          name: activeReport.name,
          description: activeReport.description,
          // The server requires we tell it whether it's a template or not
          // when renaming, otherwise it defaults to false.
          isTemplate: activeReport.isTemplate
        }
      }));
    }

    if (dirtyAcl && report.isWritable) {
      saves.push(saveAcl({ id, acl }));
    }

    if (!isUndefined(dirtySchedule)) {
      if (dirtySchedule === null) {
        saves.push(deleteSchedule({ reportIds: [ activeReport.id ] }));
      } else if (activeReport.schedule) {
        const {
          lastPrintStatus,
          lastPrintTime,
          // The above fields are readonly, so we need to remove them from
          // the schedule structure.
          ...relevantSchedule
        } = activeReport.schedule;

        saves.push(saveSchedule({
          reportId: activeReport.id,
          schedule: relevantSchedule
        }));
      }
    }

    return Promise.all(saves)
      .then(() => {
        onChange(activeReport);
      })
      .catch((e) => {
        showNotification({
          type: 'error',
          message: e?.message || 'There was an error saving report properties.'
        });

        return Promise.reject();
      });
  };

  React.useEffect(() => {
    const fetching = cancelable(fetchUsers());
    fetching.promise.then((res) => res.users.map((user) => ({
      label: user.username,
      value: user.sid.id
    }))).then(setUsers);

    return fetching.cancel;
  }, []);

  React.useEffect(() => {
    const convertOffsetToUtc = (offset: number) => {
      const sign = offset >= 0 ? '+' : '-';
      const m = Math.floor((Math.abs(offset) / (1000 * 60)) % 60).toString();
      const h = Math.floor((Math.abs(offset) / (1000 * 60 * 60)) % 24).toString();

      return `(UTC${sign}${h.padStart(2, '0')}:${m.padStart(2, '0')})`;
    };

    const fetching = cancelable(fetchTimezones());
    fetching.promise.then((res) => res.timezones.map((timezone) => ({
      label: `${timezone.displayName} ${convertOffsetToUtc(timezone.offset)}`,
      value: timezone.timezone
    }))).then(setTimezones);

    return fetching.cancel;
  }, []);

  return (
    <PageSidebar title={'Report Settings'} onClose={onClose}>
      <Wrapper>
        {(isFetchingUsers || isFetchingTimezones) &&
          <LoadingIndicator />
        }
        <Tabs selectedTab={selectedProperty} onSelect={setSelectedProperty}>
          {properties.filter((property) => {
            return hasPermission(
              property.permissions,
              state.permissions.map((permission) => permission.id)
            );
          }).map((property) => (
            <Tab key={property.name} id={property.name} title={property.name}>
              <property.component
                report={activeReport}
                users={users}
                timezones={timezones}
                onChange={handleChange}
              />
            </Tab>
          ))}
        </Tabs>
        <ButtonsWrapper>
          <Button secondary onClick={onClose}>
            {'Cancel'}
          </Button>
          <Button disabled={isEmpty(dirtyReport)} onClick={handleSave}>
            {'Save'}
          </Button>
        </ButtonsWrapper>
      </Wrapper>
    </PageSidebar>
  );
}

export { ReportProperties };
