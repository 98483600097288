import { useState, useEffect } from 'react';
import { cancelable, Cancelable } from '../../utils/cancelable';
import { WidgetMetaType } from '../../utils/wdk';
import { getWidgetList } from './store';

function useWidgetListFetcher() {
  const [
    widgetList,
    setWidgetList
  ] = useState<Array<WidgetMetaType>>([]);
  const [ isFetching, setIsFetching ] = useState(true);

  useEffect(() => {
    let dataFetch: Cancelable<void> | null = null;
    const fetchData = () => {
      setIsFetching(true);
      return getWidgetList().then((list) => {
        setWidgetList(list);
        setIsFetching(false);
      }).catch((e) => {
        if (!e.canceled) {
          setIsFetching(false);
        }
      });
    };

    if (!widgetList) {
      setIsFetching(false);

      return () => {};
    }

    dataFetch = cancelable(fetchData());
    dataFetch.promise.then(() => {
      dataFetch = null;
    });

    return () => { dataFetch?.cancel(); };
  }, []);

  return {
    isFetching,
    widgetList
  };
}

export { useWidgetListFetcher };
