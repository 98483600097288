import isEqual from 'lodash-es/isEqual';
import get from 'lodash-es/get';
import { ReportType } from '../get-reports.query';

type ConditionType = 'contains' | 'equals' | 'notEquals' | 'matches' | 'or';

export type FilterType = {
  field: string,
  condition: ConditionType,
  values: Array<any>
};

const conditions: {
  [T in ConditionType]: (
    value: any,
    expectedValues: Array<any>,
    report: ReportType
  ) => boolean
} = {
  contains: (value: any, validValues: Array<any>) => {
    return validValues.includes(value);
  },
  equals: (value: any, expectedValue: Array<any>) => {
    if (typeof value === 'string') {
      return value.toLowerCase() === expectedValue[0].toLowerCase();
    }

    return isEqual(value, expectedValue[0]);
  },
  notEquals: (value: any, expectedValue: Array<any>) => {
    if (typeof value === 'string') {
      return value.toLowerCase() !== expectedValue[0].toLowerCase();
    }

    return !isEqual(value, expectedValue[0]);
  },
  matches: (fullValue: string, partialValue: string[]) => {
    return fullValue.toLowerCase().includes(partialValue[0].toLowerCase());
  },
  or: (value, filters: Array<FilterType>, report: ReportType) => {
    if (filters.length === 0) {
      return true;
    }

    // Run each sub-filter against the given report.
    // If one passes, return true.
    return filters.some((filter) => {
      // eslint-disable-next-line no-use-before-define
      return testReportAgainstFilter(report, filter);
    });
  }
};

const testReportAgainstFilter = (report: ReportType, filter: FilterType) => {
  const tester = conditions[filter.condition];
  const testValue = get(report, filter.field);

  // Test the report's value against the filter's supplied valid values
  return tester(testValue, filter.values, report);
};

const generateContainsFilter = (
  field: string,
  values: Array<string | null> | Array<number | null>
): FilterType => {
  return {
    field,
    condition: 'contains',
    values
  };
};

const generateEqualsFilter = (field: string, value: any): FilterType => {
  return {
    field,
    condition: 'equals',
    values: [ value ]
  };
};

const generateNotEqualsFilter = (field: string, value: any): FilterType => {
  return {
    field,
    condition: 'notEquals',
    values: [ value ]
  };
};

const generateMatchesFilter = (field: string, value: string): FilterType => {
  return {
    field,
    condition: 'matches',
    values: [ value ]
  };
};

const generateOrFilter = (otherFilters: Array<FilterType>): FilterType => {
  return {
    field: '',
    condition: 'or',
    values: otherFilters
  };
};

const generateFolderFilter = (folders: Array<number | null>): FilterType => {
  // If filtering on non-existent folders, the folder.id field won't exist,
  // folder will just be null
  if (folders.includes(null)) {
    return generateContainsFilter('folder', folders);
  }

  return generateContainsFilter('folder.id', folders);
};

const generateAllColumnsFilter = (value: string): FilterType => {
  return generateOrFilter([
    // NOTE(bheng): Add more columns to include in filtering as required.
    //   ex: description column: generateEqualsFilter('description', value)
    generateMatchesFilter('name', value)
  ]);
};

const filterReports = (
  reports: Array<ReportType>,
  filters: Array<FilterType>
) => {
  // No filters? Return everything.
  if (filters.length === 0) {
    return reports;
  }

  // Step through each report and remove reports that fail the filter tests
  return reports.filter((report) => {
    // Create a list of tests to run for each report and each supplied filter
    const tests = filters.map((filter) => {
      return () => {
        return testReportAgainstFilter(report, filter);
      };
    });

    // Execute each test, one by one. If a test fails, exit early, return false.
    // A report must pass all tests.
    return tests.every((tester) => {
      return tester();
    });
  });
};

export {
  generateContainsFilter,
  generateEqualsFilter,
  generateNotEqualsFilter,
  generateMatchesFilter,
  generateOrFilter,
  generateFolderFilter,
  generateAllColumnsFilter,
  filterReports
};
