const GET_USERS = `
  query users {
    users {
      username
      sid {
        id
      }
    }
  }
`;

export type GetUsersResponseType = {
  users: Array<{
    username: string,
    sid: {
      id: number
    }
  }>
};

export { GET_USERS };
