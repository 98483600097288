export const CREATE_TENANT_DATASOURCE_FILTER = `
  mutation(
    $attributeName: String!
    $attributeValue: String!
    $namespaceName: String!
    $tenantId: Int!
    $datasourceId: Int!
  ) {
    createTenantDatasourceFilter(
      attributeName: $attributeName
      attributeValue: $attributeValue
      namespaceName: $namespaceName
      tenantId: $tenantId
      datasourceId: $datasourceId
    ) {
      id
    }
  }
`;

export type CreateTenantDatasourceFilterResponseType = {
  createTenantDatasourceFilter: { id: number }
}
