import * as React from 'react';
import { styled } from 'linaria/react';
import { format } from 'date-fns';
import { Input, Textarea } from '@sevone/scratch';
import { VERTICAL_RHYTHM, HORIZONTAL_RHYTHM } from '../../../utils/spacing';
import { ReportType } from '../get-reports.query';

const ControlWrapper = styled.div`
  margin-bottom: ${VERTICAL_RHYTHM}px;
`;

const InfoWrapper = styled.div`
  font-size: .75em;
`;

const LabelWrapper = styled.div`
  display: inline-block;
  font-weight: 600;
  margin-right: ${HORIZONTAL_RHYTHM}px;
`;

type Props = {
  report: ReportType,
  users: Array<{ label: string, value: number }>,
  onChange: (update: Partial<ReportType>) => void
}

function Details(props: Props) {
  const { report, onChange } = props;

  const handleNameChange = (name: string) => {
    onChange({ name });
  };

  const handleDescriptionChange = (description: string) => {
    onChange({ description });
  };

  return (
    <div>
      <ControlWrapper>
        <Input
          label={'Report name'}
          disabled={!report.isWritable}
          value={report.name}
          onChange={handleNameChange}
        />
      </ControlWrapper>
      <ControlWrapper>
        <Textarea
          label={'Description'}
          disabled={!report.isWritable}
          value={report.description || ''}
          onChange={handleDescriptionChange}
        />
      </ControlWrapper>
      <InfoWrapper>
        <LabelWrapper>{'Report owner:'}</LabelWrapper>
        {report.owner.username}
      </InfoWrapper>
      <InfoWrapper>
        <LabelWrapper>{'Last modified:'}</LabelWrapper>
        {format(new Date(report.updatedAt), 'MMM dd, y - HH:mm OOOO')}
      </InfoWrapper>
    </div>
  );
}

export { Details };
