import * as React from 'react';
import { ValueSelector } from './selectors/value-selector';
import { RenderVariableProps } from '../../types';
import {
  ConfigurationType,
  OptionType,
  ValueType
} from './types';

type Props = RenderVariableProps<ConfigurationType, OptionType, ValueType>;

function Variable(props: Props) {
  const { config, value, onValueChange } = props;

  const handleChange = (attributeValue: string) => {
    const nextValue = attributeValue ? {
      entityType: 'OBJECT' as 'OBJECT',
      metadataFilter: [ {
        attributeName: {
          attribute: config.attributeName || '',
          namespace: config.namespaceName || ''
        },
        attributeValue: attributeValue || ''
      } ]
    } : null;

    onValueChange(nextValue);
  };

  return (
    <ValueSelector
      clearable={config.isClearable}
      datasourceIds={config.datasources}
      attributeName={config.attributeName}
      namespaceName={config.namespaceName}
      entityType={'OBJECT'}
      onChange={handleChange}
      value={value?.metadataFilter?.[0]?.attributeValue || null}
    />
  );
}

export { Variable };
