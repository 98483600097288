import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { styled } from 'linaria/react';
import { useNavigation } from '@sevone/insight-connect';
import { Input, Button, LoadingCircle, Message } from '@sevone/scratch';
import { Logo } from '../../components/logo';
import { useGql } from '../../hooks/use-gql';
import { CoreStore } from '../../store';
import { loginUrl, oidcRedirectEnabled } from '../../global';
import { openidSigninRedirect } from '../../utils/openid-connect';
import { EulaInput } from './eula-input';
import {
  AUTHENTICATE_QUERY,
  AuthenticateResponseType
} from './authenticate.mutation';
import {
  MULTI_TENANT_QUERY,
  MultiTenantResponseType
} from './multi-tenant.query';
import { HORIZONTAL_RHYTHM, VERTICAL_RHYTHM } from '../../utils/spacing';
import bgCellTower from './bg/cell-tower.jpg';
import bgChip from './bg/chip.jpg';
import bgDatacenter from './bg/datacenter.jpg';

const backgrounds = [ bgCellTower, bgChip, bgDatacenter ];
const backgroundImage = backgrounds[Math.floor(Math.random() * backgrounds.length)];

const Wrapper = styled.div`
  height: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-position: bottom;
`;

const LoginForm = styled.form`
  position: relative;
  color: var(--sev1-primary-4-contrast);
  width: 500px;
  padding: ${VERTICAL_RHYTHM * 2}px ${HORIZONTAL_RHYTHM * 4}px;
  border-radius: 5px;
  box-sizing: border-box;
  z-index:1;

  ::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: .9;
    z-index: -1;
    background: var(--sev1-primary-4-color);
}
`;

const InputsWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const LoadingWrapper = styled.div<{ show: boolean }>`
  display: ${(props) => (props.show ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  background: var(--sev1-primary-4-color);
`;

const LogoWrapper = styled.div`
  width: 200px;
  margin: 0 auto ${VERTICAL_RHYTHM * 2}px auto;
`;

const InputWrapper = styled.div`
  width: 100%;
  margin-bottom: ${VERTICAL_RHYTHM}px;
  label {
    font-size: 1.2em;
  }
  input {
    font-size: 1.2em;
  }
`;

const ButtonWrapper = styled.div`
  width: 100%;
  height: 2.5em;
  display: flex;
  align-items: stretch;
  margin-top: ${VERTICAL_RHYTHM}px;
  margin-bottom: ${VERTICAL_RHYTHM}px;
  button {
    height: 100%;
    font-size: 14px;
  }
`;

function LoginPage() {
  const { state, login } = CoreStore.useContainer();
  const {
    runGql: authenticate
  } = useGql<AuthenticateResponseType>(AUTHENTICATE_QUERY);
  const {
    isFetching: fetchingMultiTenant,
    runGql: fetchMultiTenant
  } = useGql<MultiTenantResponseType>(MULTI_TENANT_QUERY);
  const { location, navigateInPlace } = useNavigation();
  const [
    isMultiTenant,
    setMultiTenant
  ] = React.useState<boolean | null>(null);
  const [ tenant, setTenant ] = React.useState<string | null>('');
  const [ username, setUsername ] = React.useState('');
  const [ password, setPassword ] = React.useState('');
  const [ eula, setEula ] = React.useState(false);
  const [ error, setError ] = React.useState('');
  const redirectUrl = (location.state || {}).redirectTo || '/';

  React.useEffect(() => {
    if (oidcRedirectEnabled) {
      // OpenID signin redirect, if enabled
      //   (do not show DI login page)
      openidSigninRedirect(redirectUrl);
    }
    fetchMultiTenant().then((res) => {
      if (!res.isMultiTenant) {
        setTenant(null);
      }
      setMultiTenant(res.isMultiTenant);
    });
  }, []);

  const handleLogin = (event?: React.FormEvent) => {
    if (event) {
      event.preventDefault();
    }

    if (!eula) {
      setError('EULA must be accepted before logging in');
      throw new Error();
    }

    if (!!isMultiTenant && !tenant) {
      const err = 'Tenant must be specified';
      setError(err);
      throw new Error(err);
    }

    return authenticate({ name: username, password, tenant }).then((res) => {
      const { token, success, user } = res.authenticate;

      if (!success || !user || !token) {
        throw new Error('Incorrect username or password - please try again!');
      }

      login({ authToken: token, user });
      navigateInPlace(redirectUrl);
    }).catch((e) => {
      setError(e.message || 'There was an unknown server error.');

      throw new Error();
    });
  };

  if (state.isAuthenticated) {
    return <Redirect to={redirectUrl} />;
  }

  // Bypass our DI login page if configured to redirect to OpenID login endpoint.
  if (oidcRedirectEnabled) {
    return null;
  }

  // Bypass our entire login page if the system is configured to use a custom
  // login process outside our control.
  if (loginUrl) {
    window.location.assign(loginUrl);
    return null;
  }

  // Linaria is attepmting to parse the backgroundImage as a path instead of a
  // data URL. Putting "" around the image should tell Linaria to leave it
  // alone, but it isn't working and Linaria will attempt to read
  // '-!url-loader!./backgroundImage.svg' as a path.
  // ISSUE: https://github.com/callstack/linaria/issues/159
  // ISSUE: https://github.com/callstack/linaria/issues/368
  return (
    <Wrapper style={{ backgroundSize: 'cover', backgroundImage: `url(${backgroundImage})` }}>
      <LoginForm onSubmit={handleLogin}>
        <LogoWrapper>
          <Logo />
        </LogoWrapper>
        <InputsWrapper>
          <LoadingWrapper
            show={fetchingMultiTenant || isMultiTenant === null}
          >
            <LoadingCircle size="large" />
          </LoadingWrapper>
          {isMultiTenant &&
            <InputWrapper>
              <Input
                autofocus
                label={'Tenant'}
                value={tenant}
                onChange={setTenant}
                placeholder={'Enter tenant...'}
                name={'formControlTenant'}
              />
            </InputWrapper>
          }
          <InputWrapper>
            <Input
              autofocus
              label={'Username'}
              value={username}
              onChange={setUsername}
              placeholder={'Enter username...'}
              name={'formControlName'}
            />
          </InputWrapper>
          <InputWrapper>
            <Input
              label={'Password'}
              value={password}
              onChange={setPassword}
              placeholder={'Enter password...'}
              type={'password'}
              name={'formControlPassword'}
            />
          </InputWrapper>
          <ButtonWrapper>
            <Button
              fullWidth
              onClick={handleLogin}
            >
              {'Login'}
            </Button>
          </ButtonWrapper>
          <InputWrapper>
            <EulaInput checked={eula} onChange={setEula} />
          </InputWrapper>
        </InputsWrapper>
        {error &&
          <Message type="error">
            {error}
          </Message>
        }
      </LoginForm>
    </Wrapper>
  );
}

export { LoginPage };
