import * as React from 'react';
import { DeviceGroupSelector, DeviceGroupType } from '@sevone/insight-connect';
import { RenderVariableProps } from '../../types';
import { MAX_RESOURCES_DISPLAYED } from '../utils';
import { ConfigurationType, OptionType, ValueType } from './types';

type Props = RenderVariableProps<ConfigurationType, OptionType, ValueType>;

function Variable(props: Props) {
  const { value, options, config, onValueChange } = props;

  const getOptions = () => {
    if (config.selectAll) {
      return undefined;
    }

    return options.length > 0 ? options : undefined;
  };

  const handleChange = (values: Array<DeviceGroupType>) => {
    const nextValue = values.length ? {
      type: 'DEVICE_GROUP',
      resources: values
    } as const : null;

    onValueChange(nextValue);
  };

  return (
    <DeviceGroupSelector
      clearable={config.isClearable}
      isMulti={config.isMulti}
      maxDisplayed={MAX_RESOURCES_DISPLAYED}
      datasources={config.datasources}
      value={value ? value.resources : []}
      deviceGroupOptions={getOptions()}
      onChange={handleChange}
    />
  );
}

export { Variable };
