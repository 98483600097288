export const VALUE_QUERY = `
  query($filter: MetadataValuesFilterArgs, $datasourceId: Int, $size: Int) {
    metadataValues (filter: $filter, datasourceId: $datasourceId, size: $size) {
      value
    }
  }
`;

export type ValueType = {
  value: string
}

export type ValueResponseType = {
  metadataValues: Array<ValueType>
}
