import * as React from 'react';
import { styled } from 'linaria/react';
import { Switch } from '@sevone/scratch';
import {
  DatasourceSelector,
  TopNViewSelector,
  TopNViewType
} from '@sevone/insight-connect';
import { VERTICAL_RHYTHM } from '../../../../../../utils/spacing';
import { ConfigureVariableProps } from '../../types';
import { ConfigurationType, OptionType, ValueType } from './types';

const Wrapper = styled.div``;

const SwitchWrapper = styled.div`
  display: flex;
  margin-bottom: ${VERTICAL_RHYTHM}px;
`;

const ControlWrapper = styled.div`
  margin-bottom: ${VERTICAL_RHYTHM}px;
`;

type Props = ConfigureVariableProps<ConfigurationType, OptionType, ValueType>;

function Configure(props: Props) {
  const { config, options, onConfigChange, onOptionsChange } = props;

  const handleClearableChange = (checked: boolean) => {
    onConfigChange({ isClearable: checked });
  };

  const handleSelectAllChange = (checked: boolean) => {
    onConfigChange({ selectAll: checked });
  };

  const handleDatasourceChange = (datasources: Array<number>) => {
    onConfigChange({ datasources });
  };

  const handleTopNViewChange = (views: Array<TopNViewType>) => {
    onOptionsChange(views);
  };

  return (
    <Wrapper>
      <SwitchWrapper>
        <Switch checked={config.isClearable} onChange={handleClearableChange}>
          {'Clear selection'}
        </Switch>
      </SwitchWrapper>
      <SwitchWrapper>
        <Switch checked={config.selectAll} onChange={handleSelectAllChange}>
          {'Select all'}
        </Switch>
      </SwitchWrapper>
      <ControlWrapper>
        <DatasourceSelector
          isMulti
          label="Datasources"
          value={config.datasources}
          onChange={handleDatasourceChange}
        />
      </ControlWrapper>
      {!config.selectAll &&
        <ControlWrapper>
          <TopNViewSelector
            label="TopN Views"
            datasources={config.datasources}
            value={options}
            onChange={handleTopNViewChange}
          />
        </ControlWrapper>
      }
    </Wrapper>
  );
}

export { Configure };
