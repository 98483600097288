import * as facetSystem from './facet-manager';
import { ReportVariableManager } from './report-variable-manager';
import { WidgetLinkManager } from './widget-link-manager';
import { WidgetManager } from './widget-manager';
import { ReportStore, DataExportType } from './report-store';
import { ReportRuntime } from './report-runtime';

const useReportVariables = ReportVariableManager.useContainer;

const useWidgetLinks = WidgetLinkManager.useContainer;

const useWidgets = WidgetManager.useContainer;

const useReportRuntime = ReportStore.useContainer;

export type ReportSessionExportType = DataExportType;

export {
  facetSystem,
  ReportRuntime,
  useReportVariables,
  useWidgetLinks,
  useWidgets,
  useReportRuntime
};
