import * as React from 'react';
import { styled } from 'linaria/react';
import { useModal } from '@sevone/insight-connect';
import { Checkbox } from '@sevone/scratch';
import { EulaDialog } from './eula-dialog';

const EulaLink = styled.span`
  color: var(--sev1-primary-5-color);
  text-decoration: underline;
`;

type Props = {
  checked: boolean,
  onChange: (checked: boolean) => void
};

function EulaInput(props: Props) {
  const { checked, onChange } = props;
  const { showModal } = useModal();

  React.useEffect(() => {
    // Read saved eula state and use that as the initial value.
    onChange(!!localStorage.getItem('eula'));
  }, []);

  const handleSaveEula = (nextCheck: boolean) => {
    if (!nextCheck) {
      localStorage.removeItem('eula');
    } else {
      localStorage.setItem('eula', 'true');
    }

    onChange(nextCheck);
  };

  const handleShowEula = (event: React.MouseEvent) => {
    // Because the "Show Eula" link is part of the checkbox's label, we need
    // to make sure clicking the link doesn't also change the checkbox.
    event.stopPropagation();
    event.preventDefault();

    showModal(EulaDialog);
  };

  return (
    <Checkbox checked={checked} onChange={handleSaveEula}>
      <span>
        {'I accept the '}
        <EulaLink onClick={handleShowEula}>{'EULA'}</EulaLink>
      </span>
    </Checkbox>
  );
}

export { EulaInput };
