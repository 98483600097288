export const UPDATE_TENANT_DATASOURCE_MUTATION = `
  mutation (
    $datasourceId: Int!,
    $tenantId: Int!,
    $useAsAuthsource: Boolean,
    $useAsDatasource: Boolean
  ) {
    updateTenantDatasource (
      datasourceId: $datasourceId,
      tenantId: $tenantId,
      useAsAuthsource: $useAsAuthsource,
      useAsDatasource: $useAsDatasource
    ) {
      id
    }
  }
`;

export type UpdateTenantDatasourceResponseType = {
  updateTenantDatasource: { id: number }
}
