export const TENANTS_QUERY = `
  query {
    tenants {
      id
      name
    }
  }
`;

export type TenantType = {
  id: number,
  name: string
}

export type TenantsResponseType = { tenants: Array<TenantType> }
