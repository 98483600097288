const resourcePaths = {
  DEVICE_GROUP: {
    allowedValue: true,
    next: {
      DEVICE: {
        allowedValue: true
      }
    }
  },
  DEVICE: {
    allowedValue: true
  }
};

export { resourcePaths };
