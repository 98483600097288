import { css } from 'linaria';

const indicatorBaseStyles = `
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 4px;
`;

const hoverIndicatorStyles = `
  ${indicatorBaseStyles}

  content: '';
  transform: scale(0.8);
  opacity: 0;
  transition: all .1s ease-in;
  background: var(--sev1-primary-2-color);
  z-index: -1;
`;

const activeHoverIndicatorStyles = `
  transform: scale(1);
  opacity: 1;
`;

const activeHoverItemStyles = `
  color: var(--sev1-primary-2-contrast);
`;

const wrapper = css`
  font-size: var(--sev1-size);
  position: relative;
  padding: 8px;
  /**
   * There's an issue when moving from the state
   * (compact, parent of selected item) -> (not compact, selected child item).
   * The selection indicator's animation gets wonky and visually breaks out of
   * the container. So we hide it the best we can be hiding any overflow.
   */
  overflow: hidden;
`;

const compact = css``;

const isHovered = css``;

const selected = css`
  cursor: default;
  color: var(--sev1-primary-5-contrast);
`;

const itemWrapper = css`
  position: relative;
  padding: 4px 0;
  white-space: nowrap;
`;

const topLevelItem = css`
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  text-decoration: none;
  overflow: hidden;
  height: 32px;
  padding: 8px;
  box-sizing: border-box;
  z-index: 400;

  &:before {
    ${hoverIndicatorStyles}
  }

  .${isHovered} &:not(.${selected}),
  &:not(.${selected}):hover {
    ${activeHoverItemStyles}

    &:before {
      ${activeHoverIndicatorStyles}
    }
  }
`;

const icon = css`
  flex: none;
  width: 16px;
  margin-right: 16px;
  text-align: center;

  .${compact} & {
    /** available space - wrapper padding - item padding */
    width: calc(var(--sev1-nav-menu-compact-width) - 16px - 16px);
    height: 1.2em;
    margin-right: 0;
    text-align: center;
  }
`;

const expandToggle = css`
  cursor: pointer;
  margin-left: auto;
  padding-left: .75em;
`;

const secondaryItemsNestedWrapper = css`
  margin-left: 2em;
`;

const popoutWrapper = css`
  z-index: 2000;
`;

const secondaryItemsPopoutWrapper = css`
  background: var(--sev1-primary-4-color);
  color: var(--sev1-primary-4-contrast);
  border-radius: 4px;
  min-width: 150px;
  padding: .2em .4em;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25);
  margin-left: 10px;
`;

const secondaryLevelItem = css`
  display: block;
  position: relative;
  padding: 2px 8px;
  text-decoration: none;
  z-index: 400;

  &:before {
    ${hoverIndicatorStyles}
  }

  .${isHovered} &:not(.${selected}),
  &:not(.${selected}):hover {
    ${activeHoverItemStyles}

    &:before {
      ${activeHoverIndicatorStyles}
    }
  }
`;

const selectionIndicator = css`
  ${indicatorBaseStyles}

  margin: .2em 0;
  background: var(--sev1-primary-5-color);
  z-index: 200;
`;

export {
  wrapper,
  compact,
  isHovered,
  itemWrapper,
  topLevelItem,
  icon,
  expandToggle,
  secondaryItemsNestedWrapper,
  secondaryItemsPopoutWrapper,
  popoutWrapper,
  secondaryLevelItem,
  selectionIndicator,
  selected
};
