import { generateEqualsFilter } from '../../filter-reports';
import { ReportType } from '../../get-reports.query';

const allReports = {
  id: -1,
  parentId: null,
  name: 'All Reports',
  reportCount: ({ reports }: { reports: Array<ReportType> }) => {
    return reports.length;
  },
  children: () => [],
  filters: () => {
    return [
      generateEqualsFilter('isTemplate', false)
    ];
  }
};

export { allReports };
