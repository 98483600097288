const IMPORT_REPORTS = `
  mutation {
    importReports {
      error
      report {
        id
        name
      }
    }
  }
`;

export type ImportReportsResponseType = {
  data: {
    data: {
      importReports: Array<{
        error: string | null,
        report: {
          id: number,
          name: string,
        }
      }>
    }
  }
};

export { IMPORT_REPORTS };
