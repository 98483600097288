import isPlainObject from 'lodash-es/isPlainObject';
import keyBy from 'lodash-es/keyBy';
import { isPromise } from '../../utils/is-promise';
import {
  WidgetInterfaceType,
  WidgetMetaType,
  fetchInterface,
  fetchWidgetList
} from '../../utils/wdk';

const interfaces: Record<string, WidgetInterfaceType | Promise<WidgetInterfaceType | null> | null> = {};

let meta: Record<string, WidgetMetaType> | Promise<Record<string, WidgetMetaType> | null> | null = null;

function getWidgetList(): Promise<Array<WidgetMetaType>> {
  if (isPromise(meta)) {
    return meta.then((list) => Object.values(list || {}));
  } else if (isPlainObject(meta) && meta !== null) {
    return Promise.resolve(Object.values(meta));
  }

  meta = fetchWidgetList().then((list) => {
    meta = keyBy(list, 'name');

    return meta;
  }).catch(() => {
    meta = null;

    return null;
  });

  return meta.then((list) => Object.values(list || {}));
}

function getWidgetMeta(name: string): Promise<WidgetMetaType | null> {
  if (isPromise(meta)) {
    return meta.then((list) => (list ? list[name] : null));
  } else if (isPlainObject(meta) && meta !== null) {
    return Promise.resolve(meta[name]);
  }

  return getWidgetList().then((list) => {
    return list.find((widgetMeta) => widgetMeta.name === name) || null;
  });
}

function getWidgetInterface(name: string): Promise<WidgetInterfaceType | null> {
  const widgetInterface = interfaces[name];

  if (isPromise(widgetInterface)) {
    return widgetInterface;
  } else if (isPlainObject(widgetInterface)) {
    return Promise.resolve(widgetInterface);
  }

  const fetchingInterface = fetchInterface(name).catch(() => {
    interfaces[name] = null;

    return null;
  });

  interfaces[name] = fetchingInterface;

  return fetchingInterface;
}

export {
  getWidgetList,
  getWidgetMeta,
  getWidgetInterface
};
