import { allReports } from './all-reports';
import { allTemplates } from './all-templates';
import { personal } from './personal';
import { scheduledReports } from './scheduled-reports';
import { sharedReports } from './shared-reports';
import { sharedTemplates } from './shared-templates';
import { ImmutableFolderType } from '../types';

const immutableFolders: Array<ImmutableFolderType> = [
  allReports,
  allTemplates,
  sharedReports,
  sharedTemplates,
  scheduledReports,
  personal
];

const isImmutableFolder = (folderId: number) => {
  const immutableIds = immutableFolders.map((folder) => folder.id);

  return immutableIds.includes(folderId);
};

const isPersonalFolder = (folderId: number) => {
  return folderId === personal.id;
};

export {
  allReports,
  allTemplates,
  personal,
  scheduledReports,
  sharedReports,
  sharedTemplates,
  isImmutableFolder,
  isPersonalFolder,
  immutableFolders
};
