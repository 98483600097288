import * as React from 'react';
import { styled } from 'linaria/react';
import { css } from 'linaria';
import cx from 'classnames';
import { format } from 'date-fns';
import { Link } from '@sevone/insight-connect';
import {
  Table,
  Tooltip,
  CheckIcon,
  RemoveIcon,
  MinusIcon
} from '@sevone/scratch';
import { CoreStore } from '../../../store';
import { ReportDragSource, ReportDragLayer } from '../draggable-report';
import {
  scheduledReports
} from '../folders/immutable-folders/scheduled-reports';
import { ReportType } from '../get-reports.query';
import { FolderType } from '../get-folders.query';

function sort<T>(a: T, b: T) {
  if (a < b) {
    return -1;
  }

  if (a > b) {
    return 1;
  }

  return 0;
}

const truncatedCell = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.2;
`;

const cellStyles = css`
  /**
   * There's something about long strings that cause the min-width of a cell
   * to default to >0 despite being explicitly set as 0. Not entirely sure
   * why this works, but it does.
   */
  width: 0;
`;

const CellLink = styled.span`
  color: var(--sev1-primary-5-color);
`;

type Props = {
  folder: number | null,
  reports: Array<ReportType>,
  selectedReports: Array<ReportType['id']>,
  page: number,
  pageSize: number,
  onPageChange: (page: number) => void,
  onReportSelection: (reports: Array<ReportType['id']>) => void,
  onFolderSelection: (folderId: FolderType['id'] | null) => void
};

function ReportList(props: Props) {
  const {
    folder,
    reports,
    selectedReports,
    page,
    pageSize,
    onPageChange,
    onReportSelection
  } = props;
  const { state } = CoreStore.useContainer();
  const [ columnWidths, setColumnWidth ] = React.useState({
    name: undefined,
    description: undefined,
    folder: '210px',
    owner: '175px',
    lastUpdated: '125px',
    lastPrintTime: '125px',
    lastPrintStatus: '125px'
  });

  const columns: React.ComponentProps<typeof Table>['columns'] = [
    {
      id: 'name',
      title: 'Name',
      width: columnWidths.name,
      renderCellClassname: cx(cellStyles, truncatedCell),
      sort: (a: ReportType, b: ReportType) => {
        const valueA = a.name.toLowerCase();
        const valueB = b.name.toLowerCase();

        return sort(valueA, valueB);
      },
      render: (row: ReportType) => {
        // Only move other selected reports if this report is also selected.
        const isSelected = !!selectedReports.find((id) => id === row.id);
        const movedReports = isSelected ? selectedReports : [ row.id ];

        return (
          <ReportDragSource
            disabled={row.owner.id !== state.user?.id}
            selectedReports={movedReports}
            className={truncatedCell}
          >
            <Tooltip position={[ 'top', 'left' ]} tooltip={row.name}>
              <CellLink>
                <Link to={`/reports/${row.id}`}>
                  {row.name}
                </Link>
              </CellLink>
            </Tooltip>
          </ReportDragSource>
        );
      }
    },
    {
      id: 'description',
      title: 'Description',
      width: columnWidths.description,
      renderCellClassname: cx(cellStyles, truncatedCell),
      sort: (a: ReportType, b: ReportType) => {
        const valueA = (a.description || '').toLowerCase();
        const valueB = (b.description || '').toLowerCase();

        return sort(valueA, valueB);
      },
      render: (row: ReportType) => (
        <Tooltip position={[ 'top', 'left' ]} tooltip={row.description}>
          {row.description}
        </Tooltip>
      )
    },
    {
      id: 'folder',
      title: 'Folder',
      width: columnWidths.folder,
      renderCellClassname: cx(cellStyles, truncatedCell),
      sort: (a: ReportType, b: ReportType) => {
        const valueA = (a.folder || { name: 'Personal' }).name.toLowerCase();
        const valueB = (b.folder || { name: 'Personal' }).name.toLowerCase();

        return sort(valueA, valueB);
      },
      render: (row: ReportType) => (row.folder ? row.folder.name : 'Personal')
    },
    {
      id: 'owner',
      title: 'Owner',
      width: columnWidths.owner,
      renderCellClassname: cx(cellStyles, truncatedCell),
      sort: (a: ReportType, b: ReportType) => {
        const valueA = a.owner.username.toLowerCase();
        const valueB = b.owner.username.toLowerCase();

        return sort(valueA, valueB);
      },
      render: (row: ReportType) => row.owner.username
    },
    {
      id: 'lastUpdated',
      title: 'Last Updated',
      width: columnWidths.lastUpdated,
      renderCellClassname: cx(cellStyles, truncatedCell),
      sort: (a: ReportType, b: ReportType) => {
        const valueA = new Date(a.updatedAt).getTime();
        const valueB = new Date(b.updatedAt).getTime();

        return sort(valueA, valueB);
      },
      render: (row: ReportType) => {
        return format(new Date(row.updatedAt), 'y-MM-dd HH:mm');
      }
    }
  ];

  if (folder === scheduledReports.id) {
    columns.push(...[
      {
        id: 'lastPrintTime',
        title: 'Last Run',
        width: columnWidths.lastPrintTime,
        renderCellClassname: cx(cellStyles, truncatedCell),
        sort: (a: ReportType, b: ReportType) => {
          const valueA = new Date(a.schedule?.lastPrintTime || 0).getTime();
          const valueB = new Date(b.schedule?.lastPrintTime || 0).getTime();

          return sort(valueA, valueB);
        },
        render: (row: ReportType) => {
          if (!row.schedule || !row.schedule.lastPrintTime) {
            return '';
          }

          return format(new Date(row.schedule.lastPrintTime), 'y-MM-dd HH:mm');
        }
      },
      {
        id: 'lastPrintStatus',
        title: 'Last Status',
        width: columnWidths.lastPrintStatus,
        renderCellClassname: cx(cellStyles, truncatedCell),
        sort: (a: ReportType, b: ReportType) => {
          if (!a.schedule || !b.schedule) {
            return 0;
          }

          const valueA = a.schedule.lastPrintStatus;
          const valueB = b.schedule.lastPrintStatus;

          return sort(valueA, valueB);
        },
        render: (row: ReportType) => {
          if (!row.schedule) {
            return '';
          }
          const Icon = {
            SUCCESS: CheckIcon,
            FAILURE: RemoveIcon,
            NOT_RUN: MinusIcon
          }[row.schedule.lastPrintStatus];

          return <Icon />;
        }
      }
    ]);
  }

  const handleColumnResize = (
    column: keyof typeof columnWidths,
    width: string
  ) => {
    setColumnWidth((curr) => ({ ...curr, [column]: width }));
  };

  return (
    <>
      <ReportDragLayer />
      <Table
        // Sort by `name` by default
        rows={reports.sort(columns[0].sort)}
        columns={columns}
        page={page}
        pageSize={pageSize}
        selectedRows={selectedReports}
        onPageChange={onPageChange}
        onRowSelection={onReportSelection}
        onColumnResize={handleColumnResize}
      />
    </>
  );
}

export { ReportList };
