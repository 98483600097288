import * as React from 'react';
import { motion } from 'framer-motion';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { SecondaryLevelType } from '../../../navigation';
import * as styles from './styles';

type Props = {
  item: SecondaryLevelType,
  selectedItem: string | null
};

function SecondaryLevelItem(props: Props) {
  const { item, selectedItem } = props;
  const selected = selectedItem === item.id;
  const classNames = cx(styles.secondaryLevelItem, {
    [styles.selected]: selected
  });

  return (
    <div className={styles.itemWrapper}>
      {selected &&
        <span className={classNames}>{item.title}</span>
      }
      {!selected &&
        <Link to={item.goTo()} className={classNames}>{item.title}</Link>
      }

      {selected &&
        <motion.div
          layoutId="selectionIndicator"
          className={styles.selectionIndicator}
        />
      }
    </div>
  );
}

export { SecondaryLevelItem };
