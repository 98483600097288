import * as React from 'react';
import { styled } from 'linaria/react';
import { HORIZONTAL_RHYTHM } from '../../../utils/spacing';
import { ReportType } from '../get-reports.query';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: .5em;
`;

const ReportCount = styled.div`
  font-size: .8em;
  margin-right: ${HORIZONTAL_RHYTHM}px;
`;

type Props = {
  reports: Array<ReportType>,
  selectedReports: Array<ReportType['id']>,
  page: number,
  pageSize: number
};

function ActionBar(props: Props) {
  const {
    reports,
    selectedReports,
    page,
    pageSize
  } = props;
  const start = Math.min(((page - 1) * pageSize + 1), reports.length);
  const end = Math.min((pageSize * page), reports.length);

  return (
    <Wrapper>
      <ReportCount>
        {`Showing ${start} - ${end} of ${reports.length}`}
        {selectedReports.length ? `, ${selectedReports.length} selected` : ''}
      </ReportCount>
    </Wrapper>
  );
}

export { ActionBar };
