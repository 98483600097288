import { generateEqualsFilter, generateNotEqualsFilter } from '../../filter-reports';
import { ReportType } from '../../get-reports.query';
import { FilterArgsType } from '../types';

const sharedTemplates = {
  id: -4,
  parentId: null,
  name: 'Shared Templates',
  reportCount: ({ reports, userId }: {
    reports: Array<ReportType>,
    userId: number | null
  }): number => {
    return reports.filter((r) => {
      return r.isTemplate && r.owner.id !== userId;
    }).length;
  },
  children: () => [],
  filters: (props: FilterArgsType) => {
    return [
      generateEqualsFilter('isTemplate', true),
      generateNotEqualsFilter('owner.id', props.userId)
    ];
  }
};

export { sharedTemplates };
