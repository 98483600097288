import { deviceType } from './device';
import { deviceGroupType } from './device-group';
import { flowDirectionType } from './flow-direction';
import { flowInterfaceType } from './flow-interface';
import { flowViewType } from './flow-view';
import { indicatorType } from './indicator';
import { objectType } from './object';
import { objectGroupType } from './object-group';
import { topNViewType } from './topn-view';

const variableTypePresets = [
  deviceType,
  deviceGroupType,
  flowDirectionType,
  flowInterfaceType,
  flowViewType,
  indicatorType,
  objectType,
  objectGroupType,
  topNViewType
];

export { variableTypePresets };
