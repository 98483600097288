import * as React from 'react';
import { styled } from 'linaria/react';
import { LoadingCircle } from '@sevone/scratch';
import { Page, PageHeader, PageTitle } from '../../components/page';

const PageWrapper = styled(Page)`
  display: flex;
  flex-direction: column;
`;

const PageBody = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

function PdfLoading() {
  return (
    <PageWrapper title={'Preparing PDF...'}>
      <PageHeader>
        <PageTitle>{'Preparing your PDF...'}</PageTitle>
      </PageHeader>
      <PageBody>
        <LoadingCircle size={'large'} />
      </PageBody>
    </PageWrapper>
  );
}

export { PdfLoading };
