import * as React from 'react';
import { styled } from 'linaria/react';
import {
  InlineEdit,
  Button,
  IconButton,
  AddIcon,
  TrashIcon,
  EditIcon,
  EyeIcon
} from '@sevone/scratch';
import { PageHeader, PageTitle } from '../../../../components/page';
import { HORIZONTAL_RHYTHM } from '../../../../utils/spacing';
import { ReportType } from '../../types';
import { ReportTimespan } from './report-timespan';
import { VariablesMenu } from './variables-menu';
import { RefreshMenu } from './refresh-menu';
import { RotateMenu } from './rotate-menu';
import { SaveMenu } from './save-menu';
import { Breadcrumbs } from '../breadcrumbs';

const EditNameIconWrapper = styled.div`
  margin: 0 ${HORIZONTAL_RHYTHM}px;
  opacity: 0;
  transition: opacity .1s ease-in;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 100px;
`;

const ReportTitle = styled(PageTitle)<{ isWritable: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;

  // @ts-ignore: linaria TS is missing typing for component interpolations
  &:hover ${EditNameIconWrapper} {
    // @ts-ignore For some reason TS keeps complaining here about having an
    // any type, while still displaying its type.
    opacity: ${(p) => (p.isWritable ? 1 : 0)};
  }
`;

const NameWrapper = styled.div<{
  isWritable: boolean,
  onClick: React.MouseEventHandler
}>`
  min-width: 100px;
  position: relative;
  z-index: 1;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: -.2em;
    bottom: 0;
    left: -.2em;
    z-index: -1;
    border-radius: 2px;
    transition: background .1s ease-in;
  }

  ${ReportTitle}:hover &,
  &:hover {
    &:before {
      background: ${(p) => (p.isWritable ? 'var(--sev1-primary-2-color)' : '')};
    }
  }
`;

const ControlsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  flex: none;

  & > * {
    margin: 0 2px;
  }
`;

type Props = {
  report: ReportType,
  isReadOnly: boolean,
  isNewReport: boolean,
  isReportVariablesPanelVisible: boolean,
  onReportNameChange: (name: string) => void,
  onDeleteReport: () => Promise<any>,
  onShowReportVariablesPalette: () => void,
  onToggleReadOnly: (readOnly: boolean) => void,
  onToggleReportVariablesPanel: (visible: boolean) => void,
  onShowWidgetPalette: () => void
};

const Header = React.memo((props: Props) => {
  const {
    report,
    isReadOnly,
    isNewReport,
    isReportVariablesPanelVisible,
    onReportNameChange,
    onDeleteReport,
    onShowReportVariablesPalette,
    onToggleReadOnly,
    onToggleReportVariablesPanel,
    onShowWidgetPalette
  } = props;
  // Strange sorta hack here. `InlineEdit` isn't controlled, so updating its
  // `value` won't actually change what is displayed. Because we want to
  // require a non-empty report name, we check for an empty string before
  // officially saving it to the report. But by that point the change has
  // already been made to `InlineEdit`. So, we need to force the component to
  // remount. If we simply key'd by `report.name`, when the report name goes
  // from 'foo' -> '' (empty string, don't save) -> 'foo', the component doesn't
  // ever receive a new key. So, we add a counter of how many times the name
  // has been touched, ensuring a unique key after every possible edit.
  const [ nameUpdate, setNameUpdate ] = React.useState(0);
  const isWritable = report.isWritable && !isReadOnly;

  const handleTitleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    // This is the event handler to auto-highlight the entire title.
    // We know this is an `Element` because it's attached to a div
    const el = event.target as Element;
    const selection = window.getSelection();

    if (selection && !selection.toString()) {
      const range = document.createRange();
      range.selectNodeContents(el);
      selection.removeAllRanges();
      selection.addRange(range);
    }
  };

  const handleReadOnlyToggle = () => {
    onToggleReadOnly(!isReadOnly);
  };

  return (
    <PageHeader>
      <TitleWrapper>
        <ReportTitle isWritable={report.isWritable}>
          <NameWrapper isWritable={report.isWritable} onClick={handleTitleClick}>
            <InlineEdit
              key={`${report.name}-${nameUpdate}`}
              multiLine={false}
              disabled={!report.isWritable}
              value={report.name}
              onBlur={(name) => {
                setNameUpdate(nameUpdate + 1);
                onReportNameChange(name);
              }}
            />
          </NameWrapper>
          <EditNameIconWrapper>
            <EditIcon />
          </EditNameIconWrapper>
        </ReportTitle>
        <Breadcrumbs reportTitle={report.name} />
      </TitleWrapper>
      <ControlsWrapper>
        <span title={'Change report timespan and time zone'}>
          <ReportTimespan />
        </span>
        <VariablesMenu
          isWritable={isWritable}
          isPanelVisible={isReportVariablesPanelVisible}
          onShowReportVariablesPalette={onShowReportVariablesPalette}
          onToggleReportVariablesPanel={onToggleReportVariablesPanel}
        />
        <span title={'Enable report refresh on a set time interval'}>
          <RefreshMenu />
        </span>
        <span
          title={'Enable rotation of report of tabs on a set time interval'}
        >
          <RotateMenu />
        </span>
        {isWritable &&
          <Button
            prefixIcon={<AddIcon />}
            onClick={onShowWidgetPalette}
          >
            {'Add widget'}
          </Button>
        }
        <SaveMenu isWritable={report.isWritable} isNewReport={isNewReport} />
        {!isNewReport && isWritable &&
          <Button
            type="outlined"
            prefixIcon={<TrashIcon />}
            onClick={onDeleteReport}
          >
            {'Delete'}
          </Button>
        }
        {/*
         * This ignores the `isReadOnly` check for writability because its the
         * toggle for the readonly setting. Only users with write access can
         * toggle between read/edit mode.
         */}
        {report.isWritable &&
          <span title={isReadOnly ? 'Report edit mode' : 'Report view mode'}>
            <IconButton onClick={handleReadOnlyToggle}>
              {isReadOnly ? <EditIcon fixedWidth /> : <EyeIcon fixedWidth />}
            </IconButton>
          </span>
        }
      </ControlsWrapper>
    </PageHeader>
  );
});

export { Header };
