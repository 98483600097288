import * as React from 'react';
import { styled } from 'linaria/react';
import {
  Button,
  AddIcon,
  MinusIcon,
  Dialog
} from '@sevone/scratch';
import { MetadataSelector } from './metadata-selector';
import { ExtendedFilterType, ExtendedDatasourceType } from '../../../queries/get-tenant-datasource.query';

const ModalContainer = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
`;
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 400px;
  width: 600px;
`;
const ActionButtonWrapper = styled.div`
  align-self: flex-end;
  & > * {
    margin: 5px;
  }
`;
const Divider = styled.div`
  border-bottom: 2px solid var(--sev1-primary-2-color);
  width: 100%;
  height: 10px;
`;

type Props = {
  hideModal: () => void,
  datasource: ExtendedDatasourceType,
  onFiltersChange: (datasource: ExtendedDatasourceType) => void
}

function TenantDatasourceFilters(props: Props) {
  const { hideModal, datasource, onFiltersChange } = props;
  const [ updatedFilters, setUpdatedFilters ] = React.useState(datasource.tenantDatasourceFilters);

  const handleEditFilter = (newFilter: ExtendedFilterType) => {
    const newFilters = updatedFilters.map((filter) => {
      if (filter.id === newFilter.id) {
        return newFilter;
      }

      return filter;
    });

    setUpdatedFilters(newFilters);
  };

  const handleDeleteFilter = (newFilter: ExtendedFilterType) => {
    const newFilters: Array<ExtendedFilterType> = [];
    updatedFilters.forEach((filter) => {
      if (filter.id === newFilter.id) {
        if (filter.action !== 'ADD') {
          newFilters.push(newFilter);
        }
      } else {
        newFilters.push(filter);
      }
    });

    setUpdatedFilters(newFilters);
  };

  const handleAddFilter = (newFilter: ExtendedFilterType) => {
    const newFilters = [ ...updatedFilters ];
    newFilters.push(newFilter);

    setUpdatedFilters(newFilters);
  };

  const handleSubmit = () => {
    onFiltersChange({
      ...datasource,
      tenantDatasourceFilters: updatedFilters
    });
    hideModal();
  };

  const renderFilterNew = () => {
    return (
      <MetadataSelector
        datasourceId={datasource.id}
        renderIcon={() => (<AddIcon />)}
        onAdd={handleAddFilter}
        onDelete={handleDeleteFilter}
        onEdit={handleEditFilter}
        isEditable
      />
    );
  };

  const renderFilterEdit = (filter: ExtendedFilterType) => {
    return (
      <MetadataSelector
        key={filter.id}
        filterId={filter.id}
        datasourceId={datasource.id}
        initalNamespaceName={filter.namespaceName}
        initalNamespaceId={filter.attributeId}
        initalAttributeName={filter.attributeName}
        initalAttributeId={filter.attributeId}
        initalAttributeValue={filter.attributeValue}
        renderIcon={() => (<MinusIcon />)}
        onAdd={handleAddFilter}
        onDelete={handleDeleteFilter}
        onEdit={handleEditFilter}
        isEditable={false}
      />
    );
  };

  const renderFilters = () => {
    const filtersToRender: Array<React.ReactNode> = [];
    updatedFilters.forEach((filter) => {
      if (filter.action !== 'DELETE') {
        filtersToRender.push(renderFilterEdit(filter));
      }
    });

    return filtersToRender;
  };

  return (
    <Dialog title={'Tenant Datasource Filters'}>
      <ModalContainer>
        <ContentWrapper>
          {renderFilters()}
          <Divider />
          {renderFilterNew()}
        </ContentWrapper>
        <ActionButtonWrapper>
          <Button onClick={handleSubmit}>
            {'OK'}
          </Button>
        </ActionButtonWrapper>
      </ModalContainer>
    </Dialog>
  );
}

export { TenantDatasourceFilters };
