export const REMOVE_TENANT_MUTATION = `
  mutation RemoveTenantTheme (
    $tenantId: Int!
    $themeId: Int!
  ) {
    removeTenantTheme (tenantId: $tenantId, themeId: $themeId) {
      id
    }
  }
`;

export type RemoveTenantResponseType = {
  removeTenantTheme: number
}
