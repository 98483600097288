import * as React from 'react';
import { SettingsRuntime } from '@sevone/insight-widget-runtime';
import { messages } from '@sevone/insight-wdk';
import { WidgetType } from '../../types';
import { useReportRuntime } from '../../../../report-runtime';
import { WidgetLoader } from '../widget-loader';

type Props = {
  widget: WidgetType | null
};

function WidgetEditor(props: Props) {
  const { widget } = props;
  const {
    updateWidgetConfiguration,
    getWidgetData,
    getStack
  } = useReportRuntime();
  const facetStack = widget ? getStack(widget.id) : null;
  // We want to strip out any null facets, essentially removing those schemas
  // from the widget's list of known facets to process.
  const facets = (facetStack?.list() || []).filter((facet) => {
    return facet.data !== null;
  });

  if (!widget) {
    return null;
  }

  const handleMessage = (type: string, payload: { [key: string]: any }) => {
    if (type === messages.updateConfiguration) {
      updateWidgetConfiguration(widget.id, payload);
    }
  };

  return (
    <WidgetLoader type={widget.type.name}>
      {(widgetInterface, widgetMeta) => {
        if (widgetInterface === null || widgetMeta === null) {
          return null;
        }

        return (
          <SettingsRuntime
            data={getWidgetData(widget.id)}
            facets={facets}
            widget={widgetInterface}
            configuration={widget.configuration}
            onConfigurationChange={(payload) => {
              updateWidgetConfiguration(widget.id, payload);
            }}
            onMessage={handleMessage}
          />
        );
      }}
    </WidgetLoader>
  );
}

export { WidgetEditor };
