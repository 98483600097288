const UPDATE_ACL = `
  mutation updateAcl($id: Int!, $acl: AclInput!) {
    updateAcl(id: $id, acl: $acl) {
      id
      ownerId
      isPublic
      isReadOnly
      entries {
        sid {
          id
        }
        read
        write
      }
    }
  }
`;

export type UpdateAclResponseType = {
  updateAcl: {
    id: number,
    ownerId: number,
    isPublic: boolean
    isReadOnly: boolean,
    entries: Array<{
      sid: {
        id: number
      },
      read: boolean,
      write: boolean
    }>
  }
};

export { UPDATE_ACL };
