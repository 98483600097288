import * as React from 'react';
import { styled } from 'linaria/react';
import {
  SortableContainer,
  SortableElement,
  arrayMove
} from 'react-sortable-hoc';
import { VERTICAL_RHYTHM, HORIZONTAL_RHYTHM } from '../../../../utils/spacing';
import { useReportRuntime, useReportVariables } from '../../../../report-runtime';
import { ReportVariable } from './report-variable';

const Wrapper = styled.div``;

const VariableWrapper = styled.div<{ isWritable: boolean }>`
  min-width: 200px;
  display: inline-block;
  cursor: ${(p) => (p.isWritable ? 'move' : 'default')};
  margin: 0 ${HORIZONTAL_RHYTHM}px ${VERTICAL_RHYTHM}px 0;
`;

type SortableVariableProps = {
  isWritable: boolean,
  children: React.ReactNode
}
const SortableVariable = SortableElement((props: SortableVariableProps) => (
  <VariableWrapper isWritable={props.isWritable}>
    {props.children}
  </VariableWrapper>
));

type VariableListProps = {
  children: React.ReactNode
};
const VariableList = SortableContainer((props: VariableListProps) => (
  <Wrapper>{props.children}</Wrapper>
));

type Props = {
  isWritable: boolean,
  onEditVariable: (id: string) => void
};

function ReportVariablesPanel(props: Props) {
  const { isWritable, onEditVariable } = props;
  const { reorderReportVariables } = useReportRuntime();
  const reportVariables = useReportVariables();
  const variables = reportVariables.getVariables();

  const handleSortEnd = (
    { oldIndex, newIndex }: { oldIndex: number, newIndex: number }
  ) => {
    const next = arrayMove(variables.map((v) => v.id), oldIndex, newIndex);

    reorderReportVariables(next);
  };

  return (
    <VariableList
      axis="xy"
      distance={15}
      onSortEnd={handleSortEnd}
    >
      {variables.map((variable, idx) => (
        <SortableVariable
          key={variable.id}
          index={idx}
          disabled={!isWritable}
          isWritable={isWritable}
        >
          <ReportVariable
            isWritable={isWritable}
            variable={variable}
            onEdit={onEditVariable}
          />
        </SortableVariable>
      ))}
    </VariableList>
  );
}

export { ReportVariablesPanel };
