import * as React from 'react';
import { styled } from 'linaria/react';
import { useModal, useNotification } from '@sevone/insight-connect';
import {
  Button,
  Input,
  Table,
  LoadingCircle,
  DropdownButton,
  DropdownMenu,
  DropdownItem,
  TrashIcon,
  NewEntityIcon,
  CopyIcon,
  UndoIcon,
  SaveIcon
} from '@sevone/scratch';
import { DEFAULT_COLUMNS } from './columns';
import {
  ALL_TENANTS_QUERY,
  TENANT_BY_ID_QUERY,
  TenantType,
  TenantsResponseType,
  AuthSourceType,
  ExtendedTenantType
} from './queries/get-tenants.query';
import {
  DATASOURCE_QUERY,
  DatasourceResponseType
} from './queries/get-datasource.query';
import {
  TENANT_DATASOURCE_QUERY,
  TenantDatasourceFiltersResponseType,
  ExtendedDatasourceType,
  FilterType,
  ExtendedFilterType
} from './queries/get-tenant-datasource.query';
import { THEMES_QUERY, ThemeType, ThemesResponseType } from './queries/get-themes.query';
import { LOGOS_QUERY, LogosResponseType, LogoType } from './queries/get-logos.query';
import {
  CREATE_TENANT_MUTATION,
  CreateTenantResponse
} from './queries/create-tenant.mutation';
import {
  UPDATE_TENANT_MUTATION,
  UpdateTenantResponseType
} from './queries/update-tenant.mutation';
import { DELETE_TENANT_MUTATION, DeleteTenantResponse } from './queries/delete-tenant.mutation';
import {
  ADD_TENANT_DATASOURCE_MUTATION,
  AddTenantDatasourceResponseType
} from './queries/add-tenant-datasource.mutation';
import {
  UPDATE_TENANT_DATASOURCE_MUTATION,
  UpdateTenantDatasourceResponseType
} from './queries/update-tenant-datasource.mutation';
import {
  ADD_TENANT_DATASOURCE_FILTER_MUTATION,
  AddTenantDatasourceFilterResponseType
} from './queries/add-tenant-datasource-filter.mutation';
import {
  UPADATE_TENANT_DATASOURCE_FILTER_MUTATION,
  UpdateTenantDatasourceFilterResponseType
} from './queries/update_tenant_datasource_filter.mutation';
import {
  CREATE_TENANT_DATASOURCE_FILTER,
  CreateTenantDatasourceFilterResponseType
} from './queries/create-tenant-datasource-filter.mutation';
import {
  ADD_THEME_MUTATION,
  AddThemeResponseType
} from './queries/add-themes.mutation';
import {
  SET_DEFAULT_THEME_MUTATION,
  SetDefaultThemeResponseType
} from './queries/set-default-theme.mutation';
import {
  REMOVE_THEME_MUTATION,
  RemoveThemeResponseType
} from './queries/remove-theme-mutation';
import {
  CREATE_LOGO_MUTATION,
  CreateLogoResponseType
} from './queries/create-logo.mutation';
import {
  SET_DEFAULT_LOGO_MUTATION,
  SetDefaultLogoResponseType
} from './queries/set-default-logo.mutation';
import {
  SET_SMALL_LOGO_MUTATION,
  SetSmallLogoResponseType
} from './queries/set-small-logo.mutation';
import {
  REMOVE_TENANT_DATASOURCE_MUTATION,
  RemoveTenantDatasourceResponseType
} from './queries/remove-tenant-datasource.mutation';
import {
  DELETE_TENANT_DATASOURCE_FILTERS_MUTATION,
  DeleteTenantDatasourceFilterResponse
} from './queries/delete-tenant-datasource.mutation';
import {
  DELETE_LOGOS_MUTATION,
  DeleteLogosResponse
} from './queries/delete-logos.mutation';
import { calcDSAssociations, calcThemeAssociations } from './utils';
import { DeleteTenantsModal } from './modals';
import { TenantDetails } from './tenant-details';
import { ColumnSelectors } from './column-selectors';
import {
  Page,
  PageHeader,
  PageTitle,
  PageActions,
  PageSection
} from '../../components/page';
import { CoreStore } from '../../store';
import {
  PermissionGate,
  hasPermission,
  RequiredPermissionsType
} from '../../components/permission-gate';
import { HORIZONTAL_RHYTHM, VERTICAL_RHYTHM } from '../../utils/spacing';
import { useDebounce } from '../../hooks/use-debounce';
import { useGql } from '../../hooks/use-gql';

const PageContainer = styled(Page)`
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;
const PageHeaderContainer = styled(PageHeader)`
  display: flex;
  justify-content: space-between;
`;
const PageBody = styled.div`
  display: flex;
  flex: 1;
  overflow: auto;
`;
const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 0 ${HORIZONTAL_RHYTHM / 2}px;
`;
const TenantsContainer = styled(PageSection)`
  display: flex;
  flex: none;
  width: 275px;
  flex-direction: column;
  margin: 0 ${HORIZONTAL_RHYTHM / 2}px;
  justify-content: center;
`;
const TableSection = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 33%;
`;
const TableSearchWrapper = styled.div`
  margin: ${VERTICAL_RHYTHM / 2}px ${HORIZONTAL_RHYTHM / 2}px;
`;
const TableWrapper = styled.div`
  height: 100%;
  width: 100%;
  background-color: var(--sev1-primary-3-color);
  overflow: auto;
`;
const LoadingWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

type DataURLType = string | ArrayBuffer | null;
export type LocalLogo = {
  file: File,
  dataURL: DataURLType
}
type ModalProps = {
  hideModal: () => void
}

const PAGE_SIZE = 50;
const INIT_TENANT_STATE: ExtendedTenantType = {
  id: -1,
  name: '',
  logoutURL: null,
  nmsRoleId: undefined,
  useAlternateName: false,
  datasources: [],
  authSource: undefined,
  themes: [],
  logos: [],
  updatedAt: '',
  defaultLogoId: undefined,
  defaultThemeId: 1,
  smallLogoId: undefined
};

function TenantManager() {
  const {
    runGql: requestTenantById
  } = useGql<TenantsResponseType>(TENANT_BY_ID_QUERY);
  const {
    runGql: requestUpdateTenant
  } = useGql<UpdateTenantResponseType>(UPDATE_TENANT_MUTATION);
  const {
    runGql: requestUpdateTenantDatasource
  } = useGql<UpdateTenantDatasourceResponseType>(UPDATE_TENANT_DATASOURCE_MUTATION);
  const {
    runGql: requestAddTenantDatasource
  } = useGql<AddTenantDatasourceResponseType>(ADD_TENANT_DATASOURCE_MUTATION);
  const {
    runGql: requestRemoveTenantDatasource
  } = useGql<RemoveTenantDatasourceResponseType>(REMOVE_TENANT_DATASOURCE_MUTATION);
  const {
    runGql: requestDeleteTenantDatasourceFilter
  } = useGql<DeleteTenantDatasourceFilterResponse>(DELETE_TENANT_DATASOURCE_FILTERS_MUTATION);
  const {
    runGql: requestAddTenantDatasourceFilter
  } = useGql<AddTenantDatasourceFilterResponseType>(ADD_TENANT_DATASOURCE_FILTER_MUTATION);
  const {
    runGql: requestUpdateTenantDatasourceFilter
  } = useGql<UpdateTenantDatasourceFilterResponseType>(UPADATE_TENANT_DATASOURCE_FILTER_MUTATION);
  const {
    runGql: requestAddTheme
  } = useGql<AddThemeResponseType>(ADD_THEME_MUTATION);
  const {
    runGql: requestRemoveTheme
  } = useGql<RemoveThemeResponseType>(REMOVE_THEME_MUTATION);
  const {
    runGql: requestSetDefaultTheme
  } = useGql<SetDefaultThemeResponseType>(SET_DEFAULT_THEME_MUTATION);
  const {
    runGql: requestSetDefaultLogo
  } = useGql<SetDefaultLogoResponseType>(SET_DEFAULT_LOGO_MUTATION);
  const {
    runGql: requestSetSmallLogo
  } = useGql<SetSmallLogoResponseType>(SET_SMALL_LOGO_MUTATION);
  const {
    runGql: requestCreateTenant
  } = useGql<CreateTenantResponse>(CREATE_TENANT_MUTATION);
  const {
    runGql: requestCreateTenantDatasourceFilter
  } = useGql<CreateTenantDatasourceFilterResponseType>(CREATE_TENANT_DATASOURCE_FILTER);
  const {
    runGql: requestDeleteTenants
  } = useGql<DeleteTenantResponse>(DELETE_TENANT_MUTATION);
  const {
    runGql: requestCreateLogo
  } = useGql<CreateLogoResponseType>(CREATE_LOGO_MUTATION);
  const {
    runGql: requestDeleteLogos
  } = useGql<DeleteLogosResponse>(DELETE_LOGOS_MUTATION);
  const {
    isFetching: isFetchingTenants,
    runGql: requestTenants
  } = useGql<TenantsResponseType>(ALL_TENANTS_QUERY);
  const {
    isFetching: isFetchingAvailableDatasources,
    runGql: fetchDatasources
  } = useGql<DatasourceResponseType>(DATASOURCE_QUERY);
  const {
    isFetching: isFetchingThemes,
    runGql: fetchThemes
  } = useGql<ThemesResponseType>(THEMES_QUERY);
  const {
    isFetching: isFetchingTenantThemes,
    runGql: fetchTenantThemes
  } = useGql<ThemesResponseType>(THEMES_QUERY);
  const {
    isFetching: isFetchingTenantDatasources,
    runGql: requestTenantDatasourceFilters
  } = useGql<TenantDatasourceFiltersResponseType>(TENANT_DATASOURCE_QUERY);
  const {
    isFetching: isFetchingLogos,
    runGql: requestLogos
  } = useGql<LogosResponseType>(LOGOS_QUERY);
  const { showModal } = useModal();
  const { showNotification } = useNotification();
  const { updateActiveTenant } = CoreStore.useContainer();
  const { activeTenant, permissions } = CoreStore.useContainer().state;
  const [ tenants, setTenants ] = React.useState<Array<TenantType>>([]);
  const [ tableSearch, setTableSearch ] = React.useState('');
  const [ filterRows, setFilterRows ] = React.useState<Array<TenantType>>([]);
  const [ page, setPage ] = React.useState(1);
  const debouncedSearchTerm = useDebounce(tableSearch, 200);
  const [ availableDatasources, setAvailableDatasources ] = React.useState<Array<ExtendedDatasourceType>>([]);
  const [ availableThemes, setAvailableThemes ] = React.useState<Array<ThemeType>>([]);
  const [ selectedTenants, setSelectedTenants ] = React.useState<Array<number | string>>([]);
  const [ isSaveButtonMenuVisible, setIsSaveButtonMenuVisible ] = React.useState(false);
  const [ hasChanged, setHasChanged ] = React.useState(false);
  // Tenant config state
  const [ name, setName ] = React.useState('');
  const [ logoutURL, setLogoutURL ] = React.useState('');
  const [ nmsRoleId, setNmsRoleId ] = React.useState('');
  const [ useAlternateName, setUseAlternateName ] = React.useState(false);
  const [ datasources, setDatasources ] = React.useState<Array<ExtendedDatasourceType>>([]);
  const [ initialDatasources, setInitialDatasources ] = React.useState<Array<ExtendedDatasourceType>>([]);
  const [ authSource, setAuthSource ] = React.useState<AuthSourceType>();
  const [ themes, setThemes ] = React.useState<Array<ThemeType>>([]);
  const [ initialThemes, setInitialthemes ] = React.useState<Array<ThemeType>>([]);
  const [ logos, setLogos ] = React.useState<Array<LogoType>>([]);
  const [ initialLogos, setinitialLogos ] = React.useState<Array<LogoType>>([]);
  const [ defaultThemeId, setDefaultThemeId ] = React.useState<number>();
  const [ defaultLogoId, setDefaultLogoId ] = React.useState<number>();
  const [ smallLogoId, setSmallLogoId ] = React.useState<number>();
  // local image settings not yet uploaded (used during creation of a tenant)
  const [ localTenantLogo, setLocalTenantLogo ] = React.useState<LocalLogo>();

  const handleError = (error: { message: string }) => {
    const userPermissionsIds = permissions.map((perm) => perm.id);
    const expectedPersmission: RequiredPermissionsType = [ 'updateAllTenants', 'createDeleteTenant' ];
    if (hasPermission(expectedPersmission, userPermissionsIds)) {
      setSelectedTenants([]);
    }
    showNotification({
      type: 'error',
      message: `${error.message}`
    });
  };

  const fetchLogos = (tenantId: number) => {
    const vars = { filter: { tenantId } };
    return requestLogos(vars).then((res) => res.tenantLogos);
  };

  const fetchTenants = (id?: number) => {
    // used to query for all tenants or tennat admin tenant.
    let vars = {};
    if (id) {
      vars = { id };
    }
    return requestTenants(vars).then((res) => res.tenants);
  };

  const fetchTenantDatasourceFilters = (tenantId: number) => {
    const vars = { tenantId };
    return requestTenantDatasourceFilters(vars).then((res) => {
      return res.tenantDatasourceFilters.map((ds) => {
        return {
          id: ds.datasourceId,
          name: ds.datasourceName,
          tenantDatasourceFilters: ds.tenantDatasourceFilters
        };
      });
    });
  };

  const initTenantConfigState = (tenant: TenantType) => {
    setName(tenant.name);
    setLogoutURL(tenant.logoutURL || '');
    setNmsRoleId(tenant.nmsRoleId ? tenant.nmsRoleId.toString() : '');
    setUseAlternateName(tenant.useAlternateName);
    setThemes([]);
    setAuthSource(tenant.authSource);
    setDatasources([]);
    setLogos([]);
    setDefaultThemeId(tenant.defaultThemeId);
    setDefaultLogoId(tenant.defaultLogoId);
    setSmallLogoId(tenant.smallLogoId);
    setLocalTenantLogo(undefined);
    setHasChanged(false);
  };

  const initSelectedTenant = (id: number) => {
    const currentTenant = tenants.find((tenant: TenantType) => tenant.id === id);

    if (currentTenant) {
      initTenantConfigState(currentTenant);
    }
  };

  React.useEffect(() => {
    setFilterRows(tenants.filter((row) => row.name.toLowerCase().includes(debouncedSearchTerm.toLowerCase())));
  }, [ debouncedSearchTerm, tenants ]);

  React.useEffect(() => {
    const userPermissionsIds = permissions.map((perm) => perm.id);
    if (hasPermission([ 'viewAllTenants', 'manageAllThemes', 'updateAllTenants' ], userPermissionsIds)) {
      fetchTenants().then((allTenants) => {
        setTenants(allTenants);
        if (allTenants.length > 0) {
          setSelectedTenants([ allTenants[0].id ]);
        }
      });
      fetchDatasources().then((res) => {
        const newDatasources = res.datasources.map((ds) => {
          return {
            ...ds,
            tenantDatasourceFilters: [] as Array<FilterType>
          };
        });
        setAvailableDatasources(newDatasources);
      });
      fetchThemes({ filter: { isGlobal: true, isBuiltIn: false } }).then((res) => {
        setAvailableThemes(res.themes);
      });
    } else if (activeTenant) {
      fetchTenants(activeTenant.id).then((allTenants) => {
        setTenants(allTenants);
        if (allTenants.length > 0) {
          setSelectedTenants([ allTenants[0].id ]);
        }
      });
    }
  }, [ activeTenant, permissions ]);

  React.useEffect(() => {
    if (selectedTenants.length === 1) {
      const tenantId = Number(selectedTenants[0]);
      initSelectedTenant(tenantId);
      fetchTenantDatasourceFilters(tenantId).then((tenantDatasources) => {
        setDatasources(tenantDatasources);
        setInitialDatasources(tenantDatasources);
      });
      fetchLogos(tenantId).then((tenantLogos) => {
        setLogos(tenantLogos);
        setinitialLogos(tenantLogos);
      });
      fetchTenantThemes(
        { filter: { tenantId, isBuiltIn: false, isGlobal: true } }
      ).then((res) => {
        setThemes(res.themes);
        setInitialthemes(res.themes);
      });
    }
    if (selectedTenants.length === 0) {
      initTenantConfigState(INIT_TENANT_STATE);
    }
  }, [ selectedTenants ]);

  const handleTableSearch = (value: string) => {
    setTableSearch(value);
    setPage(1);
  };

  const handleRevert = () => {
    const tenantId = Number(selectedTenants[0]);
    const currentTenant = tenants.find((tenant: TenantType) => tenant.id === tenantId);
    if (currentTenant) {
      initTenantConfigState(currentTenant);
      setDatasources(initialDatasources);
      setThemes(initialThemes);
      setLogos(initialLogos);
      setHasChanged(false);
    }
  };

  const handleConfirmSwitchTenants = (callback: () => void) => {
    if (hasChanged) {
      showModal({
        type: 'confirmation',
        header: 'Unsaved Changes',
        message: () => 'You have unsaved changes. Are you sure you want to continue?',
        actions: (modalProps: { hideModal: () => void }) => [
          (<Button key={'cancel'} onClick={() => modalProps.hideModal()}>{'Cancel'}</Button>),
          (
            <Button
              key={'OK'}
              onClick={() => {
                callback();
                modalProps.hideModal();
              }}
            >
              {'OK'}
            </Button>
          )
        ]
      });
    } else {
      callback();
    }
  };

  const handleCreate = () => {
    initTenantConfigState(INIT_TENANT_STATE);
    setSelectedTenants([]);
  };

  const handleSelectTenants = (ids: Array<number | string>) => {
    const userPermissionsIds = permissions.map((perm) => perm.id);
    const expectedPersmission: RequiredPermissionsType = [ 'updateAllTenants', 'createDeleteTenant' ];
    if (hasPermission(expectedPersmission, userPermissionsIds)) {
      handleConfirmSwitchTenants(() => setSelectedTenants(ids));
    }
  };

  const updateTenantStore = (newTenant: TenantType) => {
    const newDefaultLogo = newTenant.defaultLogoId && newTenant.logos ?
      newTenant.logos.find((logo) => logo.id === newTenant.defaultLogoId) : undefined;
    const newSmallLogo = newTenant.smallLogoId && newTenant.logos ?
      newTenant.logos.find((logo) => logo.id === newTenant.smallLogoId) : undefined;
    updateActiveTenant({
      id: newTenant.id,
      name: newTenant.name,
      logoutURL: newTenant.logoutURL,
      defaultThemeId: newTenant.defaultThemeId,
      themes: newTenant.themes,
      defaultLogo: newDefaultLogo,
      smallLogo: newSmallLogo,
      homeReportId: (activeTenant ? activeTenant.homeReportId : null)
    });
  };

  const saveTenant = async () => {
    const userPermissionsIds = permissions.map((perm) => perm.id);
    const previousBasicTenant = tenants.find((tenant) => tenant.id === selectedTenants[0]);
    if (previousBasicTenant && selectedTenants[0]) {
      const previousTenant: ExtendedTenantType = {
        ...previousBasicTenant,
        datasources: initialDatasources,
        themes: initialThemes,
        logos: initialLogos
      };
      const tenantId = selectedTenants[0];
      const newTenantVars = {
        id: tenantId,
        tenant: {
          name,
          logoutURL: logoutURL || null,
          nmsRoleId: nmsRoleId || undefined,
          useAlternateName
        }
      };
      try {
        // Update basic tenant info
        if (hasPermission([ 'updateAllTenants' ], userPermissionsIds)) {
          await requestUpdateTenant(newTenantVars);
          // remove and add datasources
          const dsDifference = calcDSAssociations(
            previousTenant.datasources,
            datasources
          );
          const addDatasourcePromises: Array<Promise<AddTenantDatasourceResponseType>> = [];
          const removeDatasourcePromises: Array<Promise<RemoveTenantDatasourceResponseType>> = [];
          const updatedDatasourcePromises: Array<Promise<UpdateTenantDatasourceResponseType>> = [];
          const oldTenantAssociatedDatasourcesIds = new Set(previousTenant.datasources.map((ds) => ds.id).concat(
            (previousTenant.authSource ? previousTenant.authSource.id : [])
          ));

          if (authSource) {
            const currentTenantDatasourcesIds = new Set(datasources.map((ds) => ds.id));
            // if the authsource is already associated, update it
            if (oldTenantAssociatedDatasourcesIds.has(authSource.id)) {
              const updateDsVars = {
                datasourceId: authSource.id,
                tenantId,
                useAsAuthsource: true
              };
              await requestUpdateTenantDatasource(updateDsVars);
            } else { // else associated it
              const asVars = {
                datasourceId: authSource.id,
                tenantId,
                useAsDatasource: false,
                useAsAuthsource: true
              };
              await requestAddTenantDatasource(asVars);
            }
            // if there was a previous authsource, remove it.
            if (previousTenant.authSource &&
              !currentTenantDatasourcesIds.has(previousTenant.authSource.id) &&
              previousTenant.authSource.id !== authSource.id) {
              const removeDsVars = {
                datasourceId: previousTenant.authSource.id,
                tenantId
              };
              await requestRemoveTenantDatasource(removeDsVars);
            }
          }

          dsDifference.added.forEach((id) => {
            // if the ds === the as, update it. The ds is already associated
            if (id === (authSource ? authSource.id : undefined)) {
              const updateDsVars = {
                datasourceId: id,
                tenantId,
                useAsDatasource: true
              };

              updatedDatasourcePromises.push(
                requestUpdateTenantDatasource(updateDsVars)
              );
            } else { // ds hasn't been added yet, create a new Tenant datasource
              const dsVars = {
                datasourceId: id,
                tenantId,
                useAsDatasource: true,
                useAsAuthsource: false
              };

              addDatasourcePromises.push(
                requestAddTenantDatasource(dsVars)
              );
            }
          });
          await Promise.all(updatedDatasourcePromises);
          await Promise.all(addDatasourcePromises);
          // We remove the authsource here if it is no longer being used as a datasource because
          // if you wanted to change your ds from { useAsAuthsource: false, useAsDatasource: true } to
          // { useAsAuthsource: true, useAsDatasource: false } you would need to remove it and re-added. The current
          // API doesn't support updating a DS with these values.
          let needToUpdateAuthSource = false;
          dsDifference.removed.forEach((id) => {
            if (id === (authSource ? authSource.id : undefined)) {
              needToUpdateAuthSource = true;
            }
            const removeDsVars = {
              datasourceId: id,
              tenantId
            };

            removeDatasourcePromises.push(
              requestRemoveTenantDatasource(removeDsVars)
            );
          });
          await Promise.all(removeDatasourcePromises);
          // Check if we need to re-add removed DS to function as AS
          if (authSource && needToUpdateAuthSource) {
            const asVars = {
              datasourceId: authSource.id,
              tenantId,
              useAsDatasource: false,
              useAsAuthsource: true
            };
            await requestAddTenantDatasource(asVars);
          }
          // Add, Edit, and Delete Tenant Datasource Filters;
          const tenantDatasourceFilterPromises: Array<Promise<any>> = [];
          datasources.forEach((ds) => {
            if (ds.tenantDatasourceFilters) {
              const filtersToUpdate: Array<ExtendedFilterType> = [];
              const filtersToAdd: Array<ExtendedFilterType> = [];
              const filtersToDelete: Array<ExtendedFilterType> = [];

              ds.tenantDatasourceFilters.forEach((filter) => {
                switch (filter.action) {
                  case 'EDIT':
                    filtersToUpdate.push(filter);
                    break;
                  case 'DELETE':
                    filtersToDelete.push(filter);
                    break;
                  case 'ADD':
                    filtersToAdd.push(filter);
                    break;
                  default:
                    break;
                }
              });
              // Delete filters
              const filtersToDeleteVars = {
                ids: filtersToDelete.map((filter) => filter.id)
              };
              tenantDatasourceFilterPromises.push(
                requestDeleteTenantDatasourceFilter(filtersToDeleteVars)
              );
              // Create Filters
              filtersToAdd.forEach((filter) => {
                const filterToAddVars = {
                  attributeName: filter.attributeName,
                  attributeValue: filter.attributeValue,
                  namespaceName: filter.namespaceName,
                  tenantId,
                  datasourceId: ds.id
                };
                tenantDatasourceFilterPromises.push(
                  requestAddTenantDatasourceFilter(filterToAddVars)
                );
              });
              // Update Filters
              filtersToUpdate.forEach((filter) => {
                const filterToUpdateVars = {
                  attributeName: filter.attributeName,
                  attributeValue: filter.attributeValue,
                  namespaceName: filter.namespaceName,
                  id: filter.id
                };
                tenantDatasourceFilterPromises.push(
                  requestUpdateTenantDatasourceFilter(filterToUpdateVars)
                );
              });
            }
          });
          await Promise.all(tenantDatasourceFilterPromises);
          // associate themes
          const previousThemes = previousTenant.themes.filter((theme) => !theme.isBuiltIn);
          const themesDifference = calcThemeAssociations(previousThemes || [], themes);
          const addThemePromises: Array<Promise<any>> = [];
          const removeThemePromises: Array<Promise<any>> = [];

          themesDifference.added.forEach((id) => {
            const addThemeVars = {
              tenantId,
              themeId: id
            };
            addThemePromises.push(
              requestAddTheme(addThemeVars)
            );
          });

          await Promise.all(addThemePromises);

          themesDifference.removed.forEach((id) => {
            const removeThemeVars = {
              tenantId,
              themeId: id
            };
            removeThemePromises.push(
              requestRemoveTheme(removeThemeVars)
            );
          });

          await Promise.all(removeThemePromises);

          if (defaultThemeId && defaultThemeId !== previousTenant.defaultThemeId) {
            const tenantDefaultThemeVars = {
              themeId: defaultThemeId,
              tenantId
            };
            await requestSetDefaultTheme(tenantDefaultThemeVars);
          }
        }
        // handle default/small logo update
        if (defaultLogoId && defaultLogoId !== previousTenant.defaultLogoId) {
          const defaultLogoVars = { logoId: defaultLogoId, tenantId };
          await requestSetDefaultLogo(defaultLogoVars);
        }

        if (smallLogoId && smallLogoId !== previousTenant.smallLogoId) {
          const smallLogoVars = { logoId: smallLogoId, tenantId };
          await requestSetSmallLogo(smallLogoVars);
        }

        // query for new tenant
        // the end
        const newTenant = await requestTenantById({ id: tenantId })
          .then((res) => res.tenants[0]);
        const newTenants = tenants.map((tenant) => {
          if (tenant.id === newTenant.id) {
            return newTenant;
          }

          return tenant;
        });
        setTenants(newTenants);
        setSelectedTenants([ newTenant.id ]);
        setHasChanged(false);
        if (activeTenant && activeTenant.id === newTenant.id) {
          updateTenantStore(newTenant);
        }
      } catch (error) {
        handleError(error);
      }
    }
  };

  const confirmSaveTenant = () => {
    return new Promise((resolve, reject) => {
      showModal({
        type: 'confirmation',
        header: 'Tenant Changes',
        message: () => 'Are you sure you want to update this tenant?',
        actions: (modalProps: { hideModal: () => void }) => [
          (<Button key={'cancel'} onClick={() => modalProps.hideModal()}>{'Cancel'}</Button>),
          (
            <Button
              key={'OK'}
              onClick={() => {
                saveTenant().then(resolve).catch(reject);
                modalProps.hideModal();
              }}
            >
              {'OK'}
            </Button>
          )
        ]
      });
    });
  };


  const saveAsTenant = async (newName?: string) => {
    const vars = {
      tenant: {
        name: newName || name,
        logoutURL: logoutURL || null,
        nmsRoleId: nmsRoleId || null,
        useAlternateName
      }
    };
    try {
      const newTenantId = await requestCreateTenant(vars).then(
        (res) => res.createTenant.id
      );
      // associate each datasource to the tenant
      const assoicateDatasourcesPromises: Array<Promise<AddTenantDatasourceResponseType>> = [];
      let authSourceIsDatasource = false;
      datasources.forEach((ds) => {
        if (authSource && ds.id === authSource.id) {
          authSourceIsDatasource = true;
        }
        const dsVars = {
          datasourceId: ds.id,
          tenantId: newTenantId,
          useAsDatasource: false,
          useAsAuthsource: authSourceIsDatasource
        };
        assoicateDatasourcesPromises.push(
          requestAddTenantDatasource(dsVars)
        );
      });
      // if the authsource wasn't a datasource, we need to associate it
      if (!authSourceIsDatasource && authSource) {
        const asVars = {
          datasourceId: authSource.id,
          tenantId: newTenantId,
          useAsDatasource: false,
          useAsAuthsource: true
        };
        assoicateDatasourcesPromises.push(
          requestAddTenantDatasource(asVars)
        );
      }
      await Promise.all(assoicateDatasourcesPromises);
      // update each datasource to be used as an auth source or datasource
      const updateDatasourcePromises: Array<Promise<UpdateTenantDatasourceResponseType>> = [];
      datasources.forEach((ds) => {
        const updateDsVars = {
          datasourceId: ds.id,
          tenantId: newTenantId,
          useAsDatasource: true
        };
        updateDatasourcePromises.push(
          requestUpdateTenantDatasource(updateDsVars)
        );
      });

      await Promise.all(updateDatasourcePromises);
      // loop over each datasource and create the tenant datasource filters
      const tenantDatasourceFilterPromises: Array<Promise<CreateTenantDatasourceFilterResponseType>> = [];
      datasources.forEach((ds) => {
        ds.tenantDatasourceFilters.forEach((filter) => {
          if (filter.action !== 'DELETE') {
            const tenantDatasourceFilterVars = {
              attributeName: filter.attributeName,
              attributeValue: filter.attributeValue,
              namespaceName: filter.namespaceName,
              tenantId: newTenantId,
              datasourceId: ds.id
            };
            tenantDatasourceFilterPromises.push(
              requestCreateTenantDatasourceFilter(tenantDatasourceFilterVars)
            );
          }
        });
      });

      await Promise.all(tenantDatasourceFilterPromises);
      // loop over each theme and associate it to the tenant
      const tenantThemesPromises: Array<Promise<AddThemeResponseType>> = [];
      themes.forEach((theme) => {
        const themeVars = {
          tenantId: newTenantId,
          themeId: theme.id
        };
        tenantThemesPromises.push(
          requestAddTheme(themeVars)
        );
      });
      await Promise.all(tenantThemesPromises);
      // set the default theme
      if (defaultThemeId) {
        const tenantDefaultThemeVars = {
          themeId: defaultThemeId,
          tenantId: newTenantId
        };
        await requestSetDefaultTheme(tenantDefaultThemeVars);
      }
      // create and set the tenant logo
      if (localTenantLogo) {
        const createTenantLogosVars = { logo: { tenantId: newTenantId, image: localTenantLogo.dataURL } };
        const newTenantLogo = await requestCreateLogo(createTenantLogosVars)
          .then((res) => res.createTenantLogo);

        const setTenantLogoVars = { logoId: newTenantLogo.id, tenantId: newTenantId };
        await requestSetDefaultLogo(setTenantLogoVars);
      }
      // query for new tenant
      // the end
      const newTenant = await requestTenantById({ id: newTenantId })
        .then((res) => res.tenants[0]);
      const newTenants = [ ...tenants, newTenant ];
      setTenants(newTenants);
      setSelectedTenants([ newTenant.id ]);
      setHasChanged(false);
      if (activeTenant && activeTenant.id === newTenantId) {
        updateTenantStore(newTenant);
      }
    } catch (error) {
      handleError(error);
    }
  };

  const deleteTenants = (resolve: () => void, reject: () => void): Promise<any> => {
    const vars = { ids: selectedTenants };
    return requestDeleteTenants(vars).then((res) => {
      const deletedTenantIds = new Set(res.deleteTenant);
      const newTenants = tenants.filter((oldTenant) => !deletedTenantIds.has(oldTenant.id));
      setSelectedTenants([]);
      setTenants(newTenants);
      resolve();
    }).catch((e) => {
      reject();
      handleError(e);
    });
  };

  const handleDeleteConfirmation = (): Promise<any> => {
    const ids = new Set(selectedTenants);
    const selectedTenantObjects: Array<TenantType> = [];
    tenants.forEach((tenant) => {
      if (ids.has(tenant.id)) {
        selectedTenantObjects.push(tenant);
      }
    });
    return new Promise((resolve, reject) => {
      showModal((modalProps: ModalProps) => {
        return (
          <DeleteTenantsModal
            {...modalProps}
            tenants={selectedTenantObjects}
            onConfirm={() => {
              deleteTenants(resolve, reject);
            }}
          />
        );
      });
    });
  };

  const handleUploadLogo = (tenantId?: number): (Promise<any>) => {
    if (localTenantLogo && tenantId) {
      const tenantLogoVars = { logo: { tenantId, image: localTenantLogo.dataURL } };
      return requestCreateLogo(tenantLogoVars).then((res) => {
        const newLogo = res.createTenantLogo;
        setLocalTenantLogo(undefined);
        setLogos([ ...logos, newLogo ]);
        setLogos([ ...logos, newLogo ]);
        return Promise.resolve();
      }).catch((e) => {
        handleError(e);
        return Promise.reject();
      });
    }

    return Promise.resolve();
  };

  const handleDeleteLogos = async (ids: Array<number>) => {
    const vars = { ids };
    try {
      await requestDeleteLogos(vars);
      const oldLogoIds = new Set(ids);
      const newLogos = logos.filter((logo) => !oldLogoIds.has(logo.id));
      setLogos(newLogos);
      setinitialLogos(logos.filter((logo) => !oldLogoIds.has(logo.id)));
      if ((defaultLogoId && oldLogoIds.has(defaultLogoId)) || (smallLogoId && oldLogoIds.has(smallLogoId))) {
        const currentTenant = tenants.find((tenant) => tenant.id === selectedTenants[0]);
        if (currentTenant) {
          updateTenantStore({
            ...activeTenant,
            ...currentTenant,
            logos: newLogos
          });
        }
      }
    } catch (e) {
      handleError(e);
    }
  };

  const handleCopyTenant = () => {
    let index = 1;
    let newName = `${name} - copy(${index})`;
    let targetTenant = tenants.find((target) => target.name === newName);
    while (targetTenant) {
      index += 1;
      newName = `${name} - copy(${index})`;
      const targetName = `${name} - copy(${index})`;
      targetTenant = tenants.find((target) => target.name === targetName);
    }

    return saveAsTenant(newName);
  };

  const renderSaveButtons = () => {
    const userPermissionsIds = permissions.map((perm) => perm.id);
    if (selectedTenants.length > 0 && hasPermission([ 'createDeleteTenant' ], userPermissionsIds)) {
      return (
        <div data-test-id={'save-drop-down-button'} style={{ display: 'flex' }}>
          <DropdownButton
            prefixIcon={<SaveIcon />}
            visible={isSaveButtonMenuVisible}
            onVisiblityChange={setIsSaveButtonMenuVisible}
            trigger={[ 'click' ]}
            type={'outlined'}
            menu={
              <DropdownMenu>
                <DropdownItem onClick={() => saveAsTenant()}>{'Save As'}</DropdownItem>
                <DropdownItem onClick={confirmSaveTenant}>{'Save'}</DropdownItem>
              </DropdownMenu>
            }
            onClick={confirmSaveTenant}
            position={'bottomRight'}
            disabled={!name}
          >
            {'Save'}
          </DropdownButton>
        </div>
      );
    }

    if (!hasPermission([ 'createDeleteTenant' ], userPermissionsIds)) {
      return (
        <Button
          onClick={confirmSaveTenant}
          disabled={!name}
          prefixIcon={<SaveIcon />}
          type={'outlined'}
        >
          {'Save'}
        </Button>
      );
    }

    return (
      <Button
        onClick={() => saveAsTenant()}
        disabled={!name}
        prefixIcon={<SaveIcon />}
        type={'outlined'}
      >
        {'Save as'}
      </Button>
    );
  };

  const renderEditor = () => {
    return (
      <PageBody>
        <TenantsContainer>
          <TableSection>
            <TableSearchWrapper>
              <Input
                placeholder={'Search for a tenant...'}
                onChange={handleTableSearch}
                value={tableSearch}
              />
            </TableSearchWrapper>
            <TableWrapper>
              <Table
                rows={filterRows}
                columns={DEFAULT_COLUMNS}
                onRowSelection={handleSelectTenants}
                selectedRows={selectedTenants}
                page={page}
                pageSize={PAGE_SIZE}
                onPageChange={setPage}
              />
            </TableWrapper>
          </TableSection>
        </TenantsContainer>
        <ColumnContainer>
          <TenantDetails
            tenantId={selectedTenants[0] ? Number(selectedTenants[0]) : undefined}
            name={name}
            onNameChange={setName}
            logoutURL={logoutURL}
            onLogoutURLChange={setLogoutURL}
            nmsRoleId={nmsRoleId}
            onNmsRoleIdChange={setNmsRoleId}
            useAlternateName={useAlternateName}
            onAlternateNameChange={setUseAlternateName}
            authSource={authSource}
            onAuthSourceChange={setAuthSource}
            availableDatasources={availableDatasources}
            isFetchingLogos={isFetchingLogos}
            logos={logos}
            isEditing={selectedTenants.length >= 1}
            defaultLogoId={defaultLogoId}
            smallLogoId={smallLogoId}
            setDefaultLogoId={setDefaultLogoId}
            setSmallLogoId={setSmallLogoId}
            localTenantLogo={localTenantLogo}
            setLocalTenantLogo={setLocalTenantLogo}
            onUploadLogo={handleUploadLogo}
            onDeleteLogo={handleDeleteLogos}
            onHasChanged={setHasChanged}
          />
        </ColumnContainer>
        <ColumnContainer>
          <PermissionGate requiredPermissions={[ 'updateAllTenants', 'viewAllTenants' ]}>
            <ColumnSelectors
              key={selectedTenants[0] ? Number(selectedTenants[0]) : undefined}
              availableDatasources={availableDatasources}
              datasources={datasources}
              availableThemes={availableThemes}
              themes={themes}
              isFetchingTenantDatasources={isFetchingTenantDatasources}
              isFetchingTenantThemes={isFetchingTenantThemes}
              setDatasources={setDatasources}
              setThemes={setThemes}
              defaultThemeId={defaultThemeId}
              setDefaultThemeId={setDefaultThemeId}
              onHasChanged={setHasChanged}
            />
          </PermissionGate>
        </ColumnContainer>
      </PageBody>
    );
  };

  const renderLoading = () => {
    return (
      <PageBody>
        <LoadingWrapper>
          <LoadingCircle size={'large'} />
        </LoadingWrapper>
      </PageBody>
    );
  };

  return (
    <PageContainer title={'Tenant Administration'}>
      <PageHeaderContainer>
        <PageTitle>{'Tenant Administration'}</PageTitle>
        <PageActions>
          <PermissionGate requiredPermissions={[ 'createDeleteTenant' ]}>
            <Button
              onClick={() => handleConfirmSwitchTenants(handleCreate)}
              disabled={selectedTenants.length < 1}
              prefixIcon={<NewEntityIcon />}
              type={'outlined'}
            >
              {'Create Tenant'}
            </Button>
          </PermissionGate>
          <PermissionGate requiredPermissions={[ 'createDeleteTenant' ]}>
            <Button
              onClick={handleDeleteConfirmation}
              disabled={selectedTenants.length === 0}
              prefixIcon={<TrashIcon />}
              type={'outlined'}
            >
              {'Delete'}
            </Button>
          </PermissionGate>
          <PermissionGate requiredPermissions={[ 'createDeleteTenant' ]}>
            <Button
              onClick={handleCopyTenant}
              disabled={selectedTenants.length !== 1}
              prefixIcon={<CopyIcon />}
              type={'outlined'}
            >
              {'Duplicate'}
            </Button>
          </PermissionGate>
          <Button
            onClick={handleRevert}
            disabled={selectedTenants.length !== 1}
            prefixIcon={<UndoIcon />}
            type={'outlined'}
          >
            {'Restore'}
          </Button>
          {renderSaveButtons()}
        </PageActions>
      </PageHeaderContainer>
      { isFetchingTenants || isFetchingAvailableDatasources || isFetchingThemes ? renderLoading() : renderEditor() }
    </PageContainer>
  );
}

export { TenantManager };
