export const DATASOURCE_QUERY = `
  query {
    datasources {
      id
      name
      dstype
      address
    }
  }
`;

export type DatasourceType = {
  id: number,
  name: string,
  dstype: string,
  address: string
}

export type DatasourceResponseType = {
  datasources: Array<DatasourceType>
}
