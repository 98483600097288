import * as React from 'react';
import { styled } from 'linaria/react';
import { ReportType } from '../get-reports.query';
import { FolderType } from '../get-folders.query';
import {
  filterReports,
  FilterType
} from '../filter-reports';
import { ActionBar } from './action-bar';
import { ReportList } from './report-list';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  background: var(--sev1-primary-4-color);
  color: var(--sev1-primary-4-contrast);
`;

const ActionBarWrapper = styled.div`
  flex: none;
`;

const ReportListWrapper = styled.div`
  flex: 1;
  min-height: 0;
  overflow-x: auto;
`;

type Props = {
  folder: number | null,
  reports: Array<ReportType>,
  selectedReports: Array<ReportType['id']>,
  filters: Array<FilterType>
  onReportSelection: (reports: Array<ReportType['id']>) => void,
  onFolderSelection: (folderId: FolderType['id'] | null) => void
};

function Reports(props: Props) {
  const {
    folder,
    reports,
    selectedReports,
    filters,
    onReportSelection,
    onFolderSelection
  } = props;
  const [ currentPage, setCurrentPage ] = React.useState(1);
  const REPORT_PAGE_SIZE = 50;
  const filteredReports = filterReports(reports, filters);

  // List of conditions where we want to reset the current page back to 1
  React.useEffect(() => {
    setCurrentPage(1);
  }, [ reports.length, filters ]);

  return (
    <Wrapper>
      <ActionBarWrapper>
        <ActionBar
          reports={filteredReports}
          selectedReports={selectedReports}
          page={currentPage}
          pageSize={REPORT_PAGE_SIZE}
        />
      </ActionBarWrapper>
      <ReportListWrapper>
        <ReportList
          folder={folder}
          reports={filteredReports}
          selectedReports={selectedReports}
          page={currentPage}
          pageSize={REPORT_PAGE_SIZE}
          onPageChange={setCurrentPage}
          onReportSelection={onReportSelection}
          onFolderSelection={onFolderSelection}
        />
      </ReportListWrapper>
    </Wrapper>
  );
}

export { Reports };
