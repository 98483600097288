import * as React from 'react';
import { Rule } from './rule';
import { RuleType } from './types';

type Props = {
  rules: RuleType[],
  column: string,
  onChange: (value: RuleType[]) => void
};

export const newRule = (): RuleType => (
  { field: null, operator: null, value: '' }
);

type OptionType = { value: string, label: string };

export const fieldOptionsByColumn: { [column: string]: OptionType[] } = {
  DEVICE: [
    { value: 'name', label: 'Device name' },
    { value: 'alternateName', label: 'Device alternate name' },
    { value: 'ipAddress', label: 'Device IP' },
    { value: 'description', label: 'Device description' },
    { value: 'inDeviceTypePaths', label: 'Device type' }
  ],
  OBJECT: [
    { value: 'name', label: 'Object name' },
    { value: 'objectTypePaths', label: 'Object type' }
  ],
  INDICATOR: [
    { value: 'indicatorTypeName', label: 'Indicator type name' }
  ]
};

export function RuleBuilder(props: Props) {
  const { onChange, column } = props;
  const rules = props.rules && props.rules.length ? props.rules : [ newRule() ];

  // Don't allow picking a field more than once since the server doesn't support it.
  const getFieldOptions = (idx: number) => {
    const alreadySelectedFields = new Set(rules.map((rule) => rule.field));

    return (fieldOptionsByColumn[column] || []).filter((option) => (
      rules[idx].field === option.value || !alreadySelectedFields.has(option.value)
    ));
  };

  const handleDelete = (idx: number) => {
    onChange([
      ...rules.slice(0, idx),
      ...rules.slice(idx + 1)
    ]);
  };

  const handleChange = (newValue: RuleType, idx: number) => {
    onChange([
      ...rules.slice(0, idx),
      newValue,
      ...rules.slice(idx + 1)
    ]);
  };

  return (
    <>
      {(rules).map((rule, i) => (
        <Rule
          key={i}
          rule={rule}
          column={column}
          fieldOptions={getFieldOptions(i)}
          onChange={(updatedRule: RuleType) => handleChange(updatedRule, i)}
          onDelete={rules.length > 1 ? () => handleDelete(i) : undefined}
        />
      ))}
    </>
  );
}
