import * as React from 'react';
import { styled } from 'linaria/react';
import uniqWith from 'lodash-es/uniqWith';
import isEqual from 'lodash-es/isEqual';
import {
  FlowDeviceSelector,
  FlowInterfaceSelector,
  InterfaceType,
  FlowDeviceType
} from '@sevone/insight-connect';
import { HORIZONTAL_RHYTHM } from '../../../../../../utils/spacing';
import { RenderVariableProps } from '../../types';
import { MAX_RESOURCES_DISPLAYED } from '../utils';
import { ConfigurationType, OptionType, ValueType } from './types';

const Wrapper = styled.div`
  display: flex;
`;

const SelectWrapper = styled.div`
  flex: 1;
  margin-right: ${HORIZONTAL_RHYTHM}px;
  min-width: 150px;

  &:last-of-type {
    margin-right: 0;
  }
`;

type Props = RenderVariableProps<ConfigurationType, OptionType, ValueType>;

function Variable(props: Props) {
  const { value, options, config, onValueChange } = props;
  const [
    flowDevices,
    setFlowDevices
  ] = React.useState<Array<FlowDeviceType>>(
    // We don't want duplicate flow devices for every interface using the
    // same device.
    value ? uniqWith(
      value.resources.map((fInterface) => fInterface.flowDevice),
      isEqual
    ) : []
  );

  const getOptions = () => {
    if (config.selectAll) {
      return undefined;
    }

    return options.length > 0 ? options : undefined;
  };

  const handleFlowDeviceChange = (devices: Array<FlowDeviceType>) => {
    setFlowDevices(devices);
    onValueChange(null);
  };

  const handleChange = (values: Array<InterfaceType>) => {
    const nextValue = values.length ? {
      type: 'INTERFACE',
      resources: values
    } as const : null;

    onValueChange(nextValue);
  };

  return (
    <Wrapper>
      <SelectWrapper>
        <FlowDeviceSelector
          clearable={config.isClearable}
          isMulti={config.isMulti}
          maxDisplayed={MAX_RESOURCES_DISPLAYED}
          datasource={config.datasource}
          value={flowDevices}
          flowDeviceOptions={getOptions()}
          onChange={handleFlowDeviceChange}
        />
      </SelectWrapper>
      <SelectWrapper>
        <FlowInterfaceSelector
          clearable={config.isClearable}
          disabled={flowDevices.length === 0}
          isMulti={config.isMulti}
          maxDisplayed={MAX_RESOURCES_DISPLAYED}
          datasource={config.datasource}
          value={value ? value.resources : []}
          flowDevices={flowDevices}
          onChange={handleChange}
        />
      </SelectWrapper>
    </Wrapper>
  );
}

export { Variable };
