import * as React from 'react';
import { ReportVariableType } from '../../types';
import { VariableBlueprintType } from './types';

type Props = {
  type: VariableBlueprintType<Record<string, any>, any, any>,
  config: ReportVariableType['config'],
  options: ReportVariableType['options'],
  onConfigChange: (config: Record<string, any>) => void,
  onOptionsChange: (options: Array<any>) => void,
  onValueChange: (value: any) => void
};

function ConfigureVariable(props: Props) {
  const {
    type,
    config,
    options,
    onConfigChange,
    onOptionsChange,
    onValueChange
  } = props;

  const generateConfig = () => {
    return {
      ...type.defaultConfiguration,
      ...config
    };
  };

  const handleConfigChange = (update: Record<string, any>) => {
    onConfigChange({
      ...type.defaultConfiguration,
      ...config,
      ...update
    });
  };

  const handleOptionsChange = (opts: Array<any>) => {
    onOptionsChange(opts);
    onValueChange(null);
  };

  return (
    <type.configure
      config={generateConfig()}
      options={options}
      onConfigChange={handleConfigChange}
      onOptionsChange={handleOptionsChange}
      onValueChange={onValueChange}
    />
  );
}

export { ConfigureVariable };
