import * as React from 'react';
import { styled } from 'linaria/react';
import isEqual from 'lodash-es/isEqual';
import { Select, Button, Input } from '@sevone/scratch';
import { VERTICAL_RHYTHM, HORIZONTAL_RHYTHM } from '../../../../utils/spacing';
import {
  useReportRuntime,
  useReportVariables
} from '../../../../report-runtime';
import { ConfigureVariable, variableTypes } from '../report-variables';

const Wrapper = styled.div`
  padding: ${VERTICAL_RHYTHM}px ${HORIZONTAL_RHYTHM}px;
`;

const SelectWrapper = styled.div`
  padding-bottom: ${VERTICAL_RHYTHM}px;
`;

const LabelWrapper = styled.div`
  padding-bottom: ${VERTICAL_RHYTHM}px;
`;

const ButtonsWrapper = styled.div`
  text-align: right;

  & > * {
    margin-left: ${HORIZONTAL_RHYTHM}px;
  }
`;

type Props = {
  variableId: string | null,
  onHide: () => void
};

function ReportVariablesPalette(props: Props) {
  const { variableId, onHide } = props;
  const {
    addReportVariable,
    updateReportVariable,
    updateReportVariableValue
  } = useReportRuntime();
  const {
    getVariablesBySchema,
    getVariable
  } = useReportVariables();
  const variable = variableId ? getVariable(variableId) : null;
  const [
    selectedVariable,
    setSelectedVariable
  ] = React.useState<{ label: string, value: string } | null>(null);
  const [ label, setLabel ] = React.useState(variable?.label || '');
  const [
    variableConfig,
    setVariableConfig
  ] = React.useState<Record<string, any>>(variable?.config || {});
  const [
    variableOptions,
    setVariableOptions
  ] = React.useState(variable?.options || []);
  const [
    variableValue,
    setVariableValue
  ] = React.useState(variable?.value || null);
  const variableType = variableTypes.find((v) => {
    return (selectedVariable && v.label === selectedVariable.value) ||
      // If we're editing a variable, look for the one with a matching schema
      (variable && isEqual(variable.schema, v.schema));
  });
  const availableVariables = variableTypes.filter((v) => {
    return getVariablesBySchema(v.schema).length === 0;
  }).map((v) => ({ label: v.label, value: v.label }));
  const editMode = !!variable;

  const handleSelectReportVariable = (v: { label: string, value: string }) => {
    setSelectedVariable(v);
    setLabel(v.label);
  };

  const handleAddReportVariable = () => {
    if (!selectedVariable || !variableType) {
      return;
    }

    addReportVariable({
      label,
      schema: variableType.schema,
      config: variableConfig,
      options: variableOptions,
      value: variableValue
    });
    onHide();
  };

  const handleUpdateReportVariable = (): Promise<void> => {
    if (!variable) {
      return Promise.reject();
    }

    updateReportVariable({
      ...variable,
      label,
      config: variableConfig,
      options: variableOptions
    });

    if (variable.value !== variableValue) {
      updateReportVariableValue(variable.id, variableValue);
    }

    return Promise.resolve();
  };

  // We need to keep the local value synced in case the user changes the value
  // of the variable while also editing. Another option would be to disable
  // interacting with the variable itself if it's actively being edited.
  React.useEffect(() => {
    setVariableValue(variable?.value || null);
  }, [ variable?.value ]);

  return (
    <Wrapper>
      {!editMode &&
        <SelectWrapper>
          <Select
            value={selectedVariable}
            options={availableVariables}
            onChange={handleSelectReportVariable}
          />
        </SelectWrapper>
      }
      <LabelWrapper>
        <Input
          label={'Name'}
          value={label}
          onChange={setLabel}
        />
      </LabelWrapper>
      {(variableType) &&
        <ConfigureVariable
          // @ts-ignore: Need to find a better way to type generics
          type={variableType}
          config={variableConfig}
          options={variableOptions}
          onConfigChange={setVariableConfig}
          onOptionsChange={setVariableOptions}
          onValueChange={setVariableValue}
        />
      }
      <ButtonsWrapper>
        <Button type="outlined" onClick={onHide}>
          {'Cancel'}
        </Button>
        {(variableType && !editMode) &&
          <Button
            disabled={!label}
            onClick={handleAddReportVariable}
          >
            {'Add'}
          </Button>
        }
        {editMode &&
          <Button
            disabled={!label}
            onClick={handleUpdateReportVariable}
          >
            {'Update'}
          </Button>
        }
      </ButtonsWrapper>
    </Wrapper>
  );
}

export { ReportVariablesPalette };
