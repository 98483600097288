import * as React from 'react';
import { styled } from 'linaria/react';
import { Switch } from '@sevone/scratch';
import { DatasourceSelector } from '@sevone/insight-connect';
import { VERTICAL_RHYTHM } from '../../../../../../utils/spacing';
import { NamespaceSelector } from './selectors/namespace-selector';
import { AttributeSelector } from './selectors/attribute-selector';
import { ConfigureVariableProps } from '../../types';
import { ConfigurationType, OptionType, ValueType } from './types';

const Wrapper = styled.div``;

const SwitchWrapper = styled.div`
  display: flex;
  margin-bottom: ${VERTICAL_RHYTHM}px;
`;

const SelectWrapper = styled.div`
  margin-bottom: ${VERTICAL_RHYTHM}px;
`;

type Props = ConfigureVariableProps<ConfigurationType, OptionType, ValueType>;

function Configure(props: Props) {
  const { config, onConfigChange } = props;

  const handleClearableChange = (checked: boolean) => {
    onConfigChange({ isClearable: checked });
  };

  const handleDatasourceSelection = (datasources: Array<number>) => {
    onConfigChange({ datasources });
  };

  const handleNamespaceChange = (namespaceName: string) => {
    onConfigChange({ namespaceName, attributeName: undefined });
  };

  const handleAttributeChange = (attributeName: string) => {
    onConfigChange({ attributeName });
  };

  return (
    <Wrapper>
      <SwitchWrapper>
        <Switch checked={config.isClearable} onChange={handleClearableChange}>
          {'Clear selection'}
        </Switch>
      </SwitchWrapper>
      <SelectWrapper>
        <DatasourceSelector
          isMulti
          label="Datasources"
          value={config.datasources}
          onChange={handleDatasourceSelection}
        />
      </SelectWrapper>
      <SelectWrapper>
        <NamespaceSelector
          datasourceIds={config.datasources}
          value={config.namespaceName}
          onChange={handleNamespaceChange}
        />
      </SelectWrapper>
      <SelectWrapper>
        <AttributeSelector
          datasourceIds={config.datasources}
          namespaceName={config.namespaceName}
          value={config.attributeName}
          onChange={handleAttributeChange}
        />
      </SelectWrapper>
    </Wrapper>
  );
}

export { Configure };
