import * as React from 'react';
import { styled } from 'linaria/react';
import { Helmet } from 'react-helmet';
import { VERTICAL_RHYTHM, HORIZONTAL_RHYTHM } from '../../utils/spacing';
import { useSidebar } from '../../hooks/use-sidebar/use-sidebar';
import { H2 } from '../typography';

const PageWrapper = styled.div`
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
`;

const PageHeader = styled.div`
  display: flex;
  align-items: center;
  padding: ${VERTICAL_RHYTHM / 2}px ${HORIZONTAL_RHYTHM}px;
  background: var(--sev1-primary-4-color);
  color: var(--sev1-primary-4-contrast);
  border-bottom: 1px solid var(--sev1-primary-2-color);
`;

const PageActions = styled.div`
  display: flex;

  & > * {
    margin: 0 ${HORIZONTAL_RHYTHM / 2}px;
  }
`;

const PageSection = styled.div`
  background-color: var(--sev1-primary-4-color);
  border-radius: .2rem;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25);
  overflow: hidden;
`;

const PageTitle = H2;

type PageProps = {
  title: string,
  className?: string,
  style?: React.CSSProperties,
  children: React.ReactNode
};

function Page(props: PageProps) {
  const { title, className, style, children } = props;
  const { hideSidebar } = useSidebar();

  React.useEffect(() => {
    // Close any open sidebar when a page is unmounted (navigated away from)
    return hideSidebar;
  }, []);

  return (
    <PageWrapper className={className} style={style}>
      <Helmet>
        <title>{`${title} - SevOne Data Insight`}</title>
      </Helmet>
      {children}
    </PageWrapper>
  );
}

export {
  Page,
  PageHeader,
  PageTitle,
  PageActions,
  PageSection
};
