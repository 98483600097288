import * as React from 'react';
import { styled } from 'linaria/react';
import difference from 'lodash-es/difference';
import {
  Dialog,
  Table,
  Button,
  TriangleExclamationIcon,
  TrashIcon,
  Message
} from '@sevone/scratch';
import { HORIZONTAL_RHYTHM, VERTICAL_RHYTHM } from '../../../../utils/spacing';
import { useGql } from '../../../../hooks/use-gql';
import {
  DELETE_REPORT,
  DeleteReportResponseType
} from './delete-report.mutation';
import { ReportType } from '../../get-reports.query';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 400px;
`;

const MessageWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.2em;
  margin-bottom: ${VERTICAL_RHYTHM}px;
`;

const ErrorWrapper = styled.div`
  margin-bottom: ${VERTICAL_RHYTHM}px;
`;

const IconWrapper = styled.div`
  font-size: 2em;
  color: var(--sev1-warning-color);
  margin-right: .5em;
  display: flex;
`;

const TableWrapper = styled.div`
  height: 200px;
  margin-bottom: ${VERTICAL_RHYTHM}px;
`;

const StatusCell = styled.div<{ isError: boolean }>`
  color: ${((p) => (p.isError ? 'var(--sev1-error-color)' : 'inherit'))};
`;

const ButtonsWrapper = styled.div`
  flex: none;
  text-align: right;

  & > * {
    margin-left: ${HORIZONTAL_RHYTHM}px;
  }
`;

type Props = {
  reports: Array<ReportType>,
  onClose: () => void,
  onError: (error: { message: string }) => void,
  onConfirm: (reports: Array<ReportType['id']>) => void
};

function DeleteReports(props: Props) {
  const { reports, onClose, onError, onConfirm } = props;
  const {
    runGql: deleteReports
  } = useGql<DeleteReportResponseType>(DELETE_REPORT);
  const [ reportList, setReportList ] = React.useState(reports);
  const [
    selectedReports,
    setSelectedReports
  ] = React.useState<Array<ReportType['id']>>(reports.map(({ id }) => id));
  const [
    undeletedReports,
    setUndeletedReports
  ] = React.useState<Array<ReportType['id']>>([]);

  const handleSelectReports = (ids: Array<ReportType['id']>) => {
    setSelectedReports(ids);
  };

  const handleConfirm = () => {
    return new Promise((resolve, reject) => {
      deleteReports({ ids: selectedReports }).then((res) => {
        const { deleteReport } = res;
        if (deleteReport.length === selectedReports.length) {
          onConfirm(selectedReports);
          resolve();
          onClose();
          return;
        }

        const missingReports = difference(selectedReports, deleteReport);
        setReportList((curr) => curr.filter(({ id }) => {
          return missingReports.includes(id);
        }));
        setUndeletedReports(missingReports);
        setSelectedReports(missingReports);
        reject();
      }).catch((e) => {
        onError(e);
        reject();
      });
    });
  };

  return (
    <Dialog title={'Delete Reports'} onClose={onClose}>
      <Wrapper>
        <MessageWrapper>
          <IconWrapper>
            <TriangleExclamationIcon />
          </IconWrapper>
          <div>
            {`
              Are you sure you want to delete ${reportList.length}
              report${reportList.length > 1 ? 's' : ''}? This cannot be undone.
            `}
          </div>
        </MessageWrapper>

        {undeletedReports.length > 0 &&
          <ErrorWrapper>
            <Message type="error">
              {`
                The following reports cannot be deleted because they are being
                used in a linking relationship. To delete them you must first
                remove the links on which it depends.
              `}
            </Message>
          </ErrorWrapper>
        }

        <TableWrapper>
          <Table
            rows={reportList}
            columns={[ {
              id: 'name',
              title: 'Report Name',
              render: (report: ReportType) => (
                <StatusCell isError={undeletedReports.includes(report.id)}>
                  {report.name}
                </StatusCell>
              )
            } ]}
            selectedRows={selectedReports}
            onRowSelection={handleSelectReports}
          />
        </TableWrapper>

        <ButtonsWrapper>
          <Button type="outlined" onClick={onClose}>
            {'Cancel'}
          </Button>
          <Button
            disabled={selectedReports.length === 0}
            prefixIcon={<TrashIcon />}
            onClick={handleConfirm}
          >
            {'Delete'}
          </Button>
        </ButtonsWrapper>
      </Wrapper>
    </Dialog>
  );
}

export { DeleteReports };
