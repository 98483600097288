import trimStart from 'lodash-es/trimStart';
import trimEnd from 'lodash-es/trimEnd';
import { basePathPattern } from '../../global';

function getBasePath(pathname: string) {
  if (basePathPattern) {
    const tester = new RegExp(`^${basePathPattern}`);
    const basename = pathname.match(tester);
    const cleanBase = basename ? trimStart(trimEnd(basename[0], '/'), '/') : '';

    if (cleanBase) {
      return `/${cleanBase}/`;
    }
  }

  return '/';
}

function getBaseUrl(origin: string, pathname: string) {
  const cleanOrigin = trimEnd(origin, '/');
  const basePath = getBasePath(pathname);

  return trimEnd(`${cleanOrigin}${basePath}`, '/');
}

const basePath = getBasePath(window.location.pathname);
const baseUrl = getBaseUrl(window.location.origin, window.location.pathname);

export { basePath, baseUrl };
