import * as React from 'react';
import { CoreStore } from '../../store';
import { permissions } from './permissions';

type AvailablePermissionsType = keyof typeof permissions;

export type RequiredPermissionsType =
  Array<AvailablePermissionsType | Array<AvailablePermissionsType>>;

const hasPermission = (
  requiredPermissions: RequiredPermissionsType,
  userPermissions: Array<number>
) => {
  for (let i = 0; i < requiredPermissions.length; i++) {
    let permissionList: Array<AvailablePermissionsType> = [];
    const requiredPermission = requiredPermissions[i];

    // Turn single permission string into an array of 1
    if (!Array.isArray(requiredPermission)) {
      permissionList = [ requiredPermission ];
    } else {
      permissionList = requiredPermission;
    }

    const validPermissions = permissionList.map((permission) => {
      return userPermissions.includes(permissions[permission]);
    });

    // We OR multiple permissions passed as the same group.
    if (!validPermissions.some(Boolean)) {
      return false;
    }
  }

  return true;
};

type Props = {
  requiredPermissions: RequiredPermissionsType,
  fallback?: React.ReactNode,
  children: React.ReactNode
};

const PermissionGate = (props: Props) => {
  const { requiredPermissions, fallback, children } = props;
  const { state } = CoreStore.useContainer();

  if (!state.user) {
    return null;
  }

  const userPermissions = state.permissions.map(({ id }) => id);

  return hasPermission(requiredPermissions, userPermissions) ?
    <>{children}</> : (<>{fallback}</> || null);
};

export {
  hasPermission,
  PermissionGate
};
