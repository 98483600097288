const UPDATE_FOLDER = `
  mutation ($id: Int!, $folder: FolderInput!) {
    updateFolder(id: $id, folder: $folder) {
      id
    }
  }
`;

export type UpdateFolderResponseType = {
  updateFolder: {
    id: number
  }
};

export { UPDATE_FOLDER };
