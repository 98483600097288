import * as React from 'react';
import { useModal, useNotification } from '@sevone/insight-connect';
import { Button, FileImportIcon } from '@sevone/scratch';
import { ImportDialog } from './import-dialog';

type Props = {
  onImportSuccess: () => void
};

function ImportReports(props: Props) {
  const { onImportSuccess } = props;
  const { showModal } = useModal();
  const { showNotification } = useNotification();

  const handleShowImportDialog = () => {
    showModal((p) => (
      <ImportDialog
        {...p}
        onSuccess={onImportSuccess}
        onError={(e) => {
          showNotification({ type: 'error', message: e.message });
        }}
      />
    ));
  };

  return (
    <Button
      type="outlined"
      prefixIcon={<FileImportIcon />}
      onClick={handleShowImportDialog}
    >
      {'Import'}
    </Button>
  );
}

export { ImportReports };
