
import { userGqlFields } from '../pages/login/authenticate.mutation';

const CURRENT_USER = `
  query {
    currentUser {
      ${userGqlFields}
    }
  }
`;

export type CurrentUserResponseType = {
  currentUser: {
    id: number,
    username: string,
    homeReportId: number,
    permissions: Array<{
      id: number,
      name: string
    }>,
    tenant: {
      id: number,
      name: string,
      logoutURL: string,
      defaultThemeId: number,
      homeReportId: number,
      defaultLogo?: {
        id: number,
        image: string
      },
      smallLogo?: {
        id: number,
        image: string
      },
      themes: Array<{
        id: number
        name: string
        colors: string
      }>
    }
  }
};

export { CURRENT_USER };
