import { authenticateGqlFields } from '../../../../../pages/login/authenticate.mutation';

export const SWITCH_TENANT_QUERY = `
  mutation ($tenantId: Int) {
    switchTenant(nextTenantId: $tenantId) {
      ${authenticateGqlFields}
    }
  }
`;

export type SwitchTenantResponseType = {
  switchTenant: {
    success: boolean,
    token: string | null,
    user: {
      id: number,
      username: string,
      homeReportId: number,
      permissions: Array<{
        id: number,
        name: string
      }>,
      tenant: {
        id: number,
        name: string,
        logoutURL: string,
        defaultThemeId: number,
        homeReportId: number,
        defaultLogo?: {
          id: number,
          image: string
        },
        smallLogo?: {
          id: number,
          image: string
        },
        themes: Array<{
          id: number
          name: string
          colors: string
        }>
      }
    }
  }
};
