import * as React from 'react';
import keyBy from 'lodash-es/keyBy';
import {
  Dropdown,
  DropdownMenu,
  DropdownSubMenu,
  DropdownItem,
  NewEntityIcon
} from '@sevone/scratch';
import { useWidgetListFetcher } from '../../../../../../hooks/wdk-server';
import {
  useReportRuntime,
  useWidgetLinks
} from '../../../../../../report-runtime';
import { WidgetType } from '../../../../types';
import { widgetWhitelist } from './widget-whitelist';

type Props = {
  widget: WidgetType,
  isCompact: boolean,
  className?: string
};

const ChainMenu = React.memo((props: Props) => {
  const { widget, isCompact, className } = props;
  const { activeSectionId, addWidgetChain } = useReportRuntime();
  const { getCompatibleChildWidgetTypes } = useWidgetLinks();
  const { widgetList } = useWidgetListFetcher();
  const [ menuVisibility, setMenuVisibility ] = React.useState(false);
  const whitelist = widgetWhitelist[widget.type.name] || [];
  const compatibleTypes = getCompatibleChildWidgetTypes(widget.type.name);
  const filteredTypes = compatibleTypes.filter((type) => {
    return whitelist.includes(type);
  });
  const widgetMap = keyBy(widgetList, 'name');
  const Tag = isCompact ? DropdownSubMenu : 'div';

  const createLink = (type: string) => {
    addWidgetChain(activeSectionId, widget.id, type, {
      title: widgetMap[type].title
    });
  };

  if (filteredTypes.length === 0) {
    return null;
  }

  const items = filteredTypes.map((type) => (
    <DropdownItem
      key={type}
      onClick={() => { createLink(type); }}
    >
      {widgetMap[type]?.title || type}
    </DropdownItem>
  ));

  return (
    <Tag title="Chain to" position="left" className={className}>
      {isCompact && items}
      {!isCompact &&
        <Dropdown
          trigger={[ 'click' ]}
          position="bottomLeft"
          visible={menuVisibility}
          menu={(
            <DropdownMenu>
              {items}
            </DropdownMenu>
          )}
          onVisiblityChange={setMenuVisibility}
        >
          <NewEntityIcon />
        </Dropdown>
      }
    </Tag>
  );
});

export { ChainMenu };
