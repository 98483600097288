export const TENANT_EMAIL_SETTINGS_QUERY = `
  query {
    tenantEmailSettings {
      server
      port
      username
      senderMail
      senderName
      subject
      enableAuthentication
      connectionSecurity
    }
  }
`;

export type TenantEmailSettingsResponseType = {
  tenantEmailSettings: {
    server?: string,
    port?: number,
    username?: string,
    senderMail?: string,
    senderName?: string,
    subject?: string,
    enableAuthentication?: boolean,
    connectionSecurity?: string
  } | null
}
