export const SET_DEFAULT_LOGO_MUTATION = `
  mutation SetTenantDefaultLogo($logoId: Int!, $tenantId: Int!) {
    setTenantDefaultLogo(logoId: $logoId, tenantId: $tenantId) {
      id
      defaultLogoId
    }
  }
`;

export type SetDefaultLogoResponseType = {
  setTenantDefaultLogo: { id: number, defaultLogoId: number }
}
