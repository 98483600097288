
export const userGqlFields = `
  id
  username
  homeReportId
  permissions {
    id
    name
  }
  tenant {
    id
    name
    logoutURL
    defaultThemeId
    homeReportId
    defaultLogo {
      id
      image
    }
    smallLogo {
      id
      image
    }
    themes {
      id
      name
      colors
    }
  }
`;
export const authenticateGqlFields = `
  success
  token
  user {
    ${userGqlFields}
  }
`;

const AUTHENTICATE_QUERY = `
  mutation ($name: String!, $password: String!, $tenant: String) {
    authenticate(name: $name, password: $password, tenant: $tenant) {
      ${authenticateGqlFields}
    }
  }
`;

export type AuthenticateResponseType = {
  authenticate: {
    success: boolean,
    token: string | null,
    user?: {
      id: number,
      username: string,
      homeReportId: number,
      permissions: Array<{
        id: number,
        name: string
      }>,
      tenant: {
        id: number,
        name: string,
        logoutURL: string,
        defaultThemeId: number,
        homeReportId: number,
        defaultLogo?: {
          id: number,
          image: string
        },
        smallLogo?: {
          id: number,
          image: string
        },
        themes: Array<{
          id: number
          name: string
          colors: string
        }>
      }
    }
  }
};

export { AUTHENTICATE_QUERY };
