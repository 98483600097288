export const REMOVE_TENANT_DATASOURCE_MUTATION = `
  mutation ($datasourceId: Int!, $tenantId: Int!) {
    removeTenantDatasource (datasourceId: $datasourceId, tenantId: $tenantId) {
      id
    }
  }
`;

export type RemoveTenantDatasourceResponseType = {
  removeTenantDatasource: { id: number }
}
