const EXPORT_REPORTS = `
  mutation ($ids: [Int!]!, $dependencies: Boolean) {
    exportReports(reportIds: $ids, includeDependencies: $dependencies)
  }
`;

export type ExportReportsResponseType = {
  exportReports: string
};

export { EXPORT_REPORTS };
