/* eslint-disable max-len */
import * as React from 'react';
import { styled } from 'linaria/react';
import { Dialog } from '@sevone/scratch';
import { VERTICAL_RHYTHM } from '../../utils/spacing';

const EulaContent = styled.div`
  height: 500px;
  width: 600px;
`;

const SectionWrapper = styled.div`
  margin-bottom: ${VERTICAL_RHYTHM}px;
`;

const CapitalizedWrapper = styled(SectionWrapper)`
  text-transform: uppercase;
`;

type Props = {
  hideModal: () => void
};

function EulaDialog(props: Props) {
  const { hideModal } = props;

  return (
    <Dialog
      title={'SevOne Data Insight End-User License Agreement'}
      onClose={hideModal}
    >
      <EulaContent>
        <CapitalizedWrapper>
          {`
            READ CAREFULLY THE TERMS AND CONDITIONS OF THIS END-USER LICENSE AGREEMENT
            (“AGREEMENT”) BEFORE YOU CLICK ON THE “ACCEPT” BUTTON. BY CLICKING ON THE “ACCEPT” BUTTON
            AND SELECTING AND UTILIZING THE LICENSED SOFTWARE AND/OR HARDWARE, INCLUDING ANY
            UNDERLYING SERVICES, OR ANY TECHNOLOGY, IDEA, DATA AND DATABASES, ALGORITHM OR
            INFORMATION CONTAINED THEREIN OR PROVIDED THEREWITH (COLLECTIVELY, THE “PRODUCTS”).
            CLICKING “I AGREE” OR OTHERWISE DOWNLOADING, INSTALLING AND/OR USING THE PRODUCTS
            ESTABLISHES A BINDING AGREEMENT BETWEEN YOU AS THE PERSON LICENSING THE PRODUCTS, EITHER
            ON BEHALF OF YOURSELF OR ANY THIRD PARTY ENTITY (“YOU” OR &quot;LICENSEE&quot;) AND SEVONE, INC.
            (“SEVONE”), YOU (WHETHER AN INDIVIDUAL OR FORMAL LEGAL ENTITY) (HEREINAFTER REFERRED TO
            AS “YOU”), AND YOU AGREE TO BE BOUND BY THE TERMS AND CONDITIONS OF THIS AGREEMENT WITH
            RESPECT TO YOUR USE OF THE PRODUCTS.
          `}
        </CapitalizedWrapper>
        <CapitalizedWrapper>
          {`
            BY INSTALLING AND/OR BY USING THE PRODUCTS, YOU REPRESENT AND WARRANT THAT YOU HAVE THE
            LEGAL CAPACITY AND AUTHORITY TO ENTER INTO A BINDING AGREEMENT TO ADHERE TO THE TERMS
            AND CONDITIONS SET FORTH HEREIN, AND THAT THE PRODUCTS WILL BE USED ONLY IN ACCORDANCE
            WITH THESE TERMS AND CONDITIONS AND WITH ALL APPLICABLE LAWS. IF AN INDIVIDUAL IS
            REGISTERING OR USING THE SOFTWARE ON BEHALF OF AN ENTITY OR ORGANIZATION, THAT INDIVIDUAL
            WARRANTS, REPRESENTS, AND COVENANTS TO SEVONE THAT SUCH INDIVIDUAL IS DULY AUTHORIZED
            TO AGREE TO THESE TERMS AND CONDITIONS ON BEHALF OF THE ORGANIZATION AND TO BIND THE
            ORGANIZATION TO THEM. WHERE APPLICABLE, THIS AGREEMENT APPLIES TO ALL PRODUCTS PROVIDED
            BY SEVONE, INCLUDING ANY EVALUATION LICENSE.
          `}
        </CapitalizedWrapper>
        <CapitalizedWrapper>
          {`
            IN THE EVENT CUSTOMER HAS EXECUTED A SEPARATE MASTER AGREEMENT WITH SEVONE, OR A
            SEVONE AUTHORIZED RESELLER, SUCH MASTER AGREEMENT SHALL INSTEAD GOVERN THE PARTIES’
            RESPECTIVE RIGHTS AND OBLIGATIONS WITH RESPECT TO THE SUBJECT MATTER SET FORTH HEREIN.
          `}
        </CapitalizedWrapper>
        <SectionWrapper>
          {'1. Grant of Rights'}
        </SectionWrapper>
        <SectionWrapper>
          {`
            1.1 Software License. Subject to the terms and conditions of this Agreement, SevOne hereby grants to Customer a non-
            exclusive, non-transferable, license (without the right to grant sublicenses) to execute and use the object code version of the
            Software identified in an order submitted by Customer and accepted by SevOne (each, an “Order”), solely for Customer’s own
            internal business operations (which includes internal data processing), in accordance with the related Documentation, and in
            accordance with the license term, scope and type of use set forth in such Order, at the installation site set forth in the Order (the
            “Site”). Customer may use the Documentation solely in connection with its authorized use of the Software, and shall not allow
            any unauthorized access to, copying of, or the creation of derivative works from, the Documentation. Customer’s use of
            application programming interfaces (“APIs”) associated with the Software shall be governed by the SevOne API End-User
            License Agreement set forth at https://www.sevone.com/sites/default/files/documents/sevone_api_eula_10_may_2018.pdf. The
            Software may be embedded in, pre-installed on, or otherwise provided specifically for use with Hardware (collectively,
            “Hardware-Specific Software”). Unless otherwise authorized by SevOne in writing, Hardware-Specific Software may only be
            used on the particular Hardware provided by SevOne, as set forth in an applicable Order and specified by serial number.
            Customer may not relocate the Hardware on which any Hardware-Specific Software resides from the Site without SevOne’s
            prior written consent. The Software may contain or otherwise make use of software, code or related materials from third
            parties, including “open source” or “freeware” software (“Third Party Components”). Certain Third Party Components may be
            subject to separate license terms that accompany such Third Party Components that, to the extent they conflict with the terms
            of this Agreement, supersede the terms of this Agreement. For Customer’s convenience, SevOne makes available a list of
            Third Party Components on its website at: https://www.sevone.com/sites/default/files/notice_and_attribution.pdf. If required by
            any license for a particular Third Party Component, SevOne makes the source code of such Third Party Component, and any of
            SevOne’s modifications to such Third Party Component, as required, available upon written request to SevOne at
            legal@sevone.com.
          `}
        </SectionWrapper>
        <SectionWrapper>
          {`
            1.2 Restrictions. Except as otherwise expressly provided in this Agreement (or where such restrictions are prohibited by law),
            Customer shall have no right, and Customer specifically agrees not to: (i) copy any feature, design, technology or intellectual property embedded in or otherwise related to any Product (collectively, “Product Technology”); (ii) transfer, assign or
            sublicense its license rights under this Agreement to any other person; (iii) access or use any Hardware-Specific Software on
            any hardware other than the Hardware in which it was embedded, on which it was pre-installed or for which it was otherwise
            provided; (iv) make error corrections or otherwise modify or adapt any Product or create derivative works based upon any
            Product or Product Technology; (v) distribute, resell, rent, lease, loan or use any Software, Documentation or Product
            Technology as a service bureau, as an application service provider, to perform consulting or training services for a third party
            or in any commercial time share arrangement; (vi) decompile, decrypt, reverse engineer, re-engineer or disassemble any
            Product or otherwise reduce the Software to human-readable form or gain access to trade secrets or confidential information in
            any Product; (vii) use any Product, Documentation or Product Technology in contravention to any applicable laws or
            government regulations; (viii) remove any product identification, trademark, copyright or other notices contained in or on the
            Software, Documentation and, if applicable, the Hardware; (ix) use or access any Software, Hardware, Documentation or
            Product Technology in order to build a competitive product or service; (x) use any Product, Documentation or Product
            Technology for performance, benchmarking or comparison testing or analysis; or (xi) permit any third party to do any of the
            foregoing. Additional restrictions regarding use of the Product may be set forth in an applicable Order.
          `}
        </SectionWrapper>
        <SectionWrapper>
          {`
            1.3 Ownership. SevOne and its suppliers reserve any and all rights, implied or otherwise, which are not expressly granted to
            Customer hereunder, and retain all rights, title and interest in and to the Software, Documentation and Product Technology.
            Customer acknowledges and agrees that this Agreement in no way shall be construed to provide to Customer, or any third
            party, any express or implied license to use, copy or otherwise exploit any Software, Documentation and Product Technology,
            or any portion thereof, other than as specifically set forth in this Agreement. Customer acknowledges that the use, copying,
            disclosure or dissemination of the Software, Product Technology or any confidential or proprietary information embodied
            therein, in a manner not authorized by this Agreement would cause irreparable harm to SevOne that could not be fully
            remedied by monetary damages. Customer therefore agrees that SevOne shall be entitled, in addition to any other remedies
            available to it at law or in equity, to such injunctive or other equitable relief as may be necessary or appropriate to prevent such
            unauthorized use, copying, disclosure or dissemination without the necessity of proving actual or irreparable damage.
          `}
        </SectionWrapper>
        <SectionWrapper>
          {`
            1.4 Audit Rights. In the event the use of the Software exceeds that licensed by Customer, as set forth in the Order, Customer
            agrees to immediately notify SevOne in writing and pay to SevOne the then-current fee associated with such additional usage,
            without limiting any other right or remedy available to SevOne. SevOne may, upon thirty (30) days’ advance notice and at its
            expense, conduct an audit, during Customer’s normal business hours and no more frequently than annually, of Customer’s use
            of the Products to verify compliance with this Agreement. If the audit reveals that Customer’s use has exceeded the authorized
            use of the Software by five percent (5%) or more, Customer shall reimburse SevOne for the expense of such audit and shall
            promptly pay to SevOne any and all fees owing as a result of such discrepancy, without limiting any other right or remedy
            available to SevOne.
          `}
        </SectionWrapper>
        <SectionWrapper>
          {`
            1.5 Aggregated Data. Customer understands and agrees that the Software may transmit to SevOne technical and related
            information about Customer’s use of the Software which may include, without limitation, system performance, capacity usage,
            hardware faults, internet protocol address, hardware identification, operating system, application software, peripheral hardware,
            and other non-attributable and de-identified Software usage statistics (“Aggregated Data”). SevOne owns Aggregated Data and
            uses it in its discretion to troubleshoot the Software and/or Hardware, facilitate the provisioning of updates, support, invoicing
            or online services, and to enhance, improve, and develop current and future SevOne products and services. Such transmission
            may be on a daily or other periodic basis, or upon a failure or crash of the Software.
          `}
        </SectionWrapper>
        <SectionWrapper>
          {`
            1.6 Maintenance and Support. Any maintenance or support must be purchased separately in accordance with SevOne’s
            maintenance and support policy, under the terms of a separate order form. If SevOne makes available to Customer any
            Software updates or upgrades for any Product as part of SevOne’s maintenance and support services, all such updates and
            upgrades will constitute “Software” under this Agreement except to the extent SevOne makes such updates or upgrades
            available pursuant to separate license terms. Please refer to https://www.sevone.com/datasheet/sevone-gold-and-platinum-
            maintenance for a general overview of our support offerings.
          `}
        </SectionWrapper>
        <SectionWrapper>
          {`
            2. LIMITED WARRANTY; DISCLAIMER. SevOne warrants that, at the time of initial delivery and for thirty (30) days
            thereafter (the “Warranty Period”), the Software will operate in substantial conformity with the then current Documentation
            when used in compliance with such Documentation. This warranty applies only to the standard version of the Software made generally available by SevOne. If the Software fails to perform as warranted and SevOne is notified in writing of such failure
            during the Warranty Period, SevOne shall, as Customer’s sole remedy, provide all reasonable programming services within a
            reasonable period of time to correct or, at SevOne’s sole option, replace the Software. NOTWITHSTANDING ANYTHING
            HEREIN TO THE CONTRARY, THE THIRD PARTY COMPONENTS ARE PROVIDED “AS IS,” “WHERE IS,” “AS
            AVAILABLE,” “WITH ALL FAULTS” AND, TO THE FULLEST EXTENT PERMITTED BY LAW, WITHOUT
            REPRESENTATION OR WARRANTY OF ANY KIND. Hardware may be covered by a separate warranty in accordance
            with the applicable warranty terms and conditions found at https://www.sevone.com/datasheet/harware-warranty. EXCEPT AS
            SPECIFIED IN THIS SECTION 2, ALL EXPRESS OR IMPLIED REPRESENTATIONS AND WARRANTIES RELATING
            TO THE PRODUCTS AND THIRD PARTY COMPONENTS, INCLUDING, WITHOUT LIMITATION, ANY IMPLIED
            WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, NONINFRINGEMENT,
            SATISFACTORY QUALITY OR ARISING FROM A COURSE OF DEALING, LAW, USAGE, OR TRADE PRACTICE,
            AND ANY WARRANTIES REGARDING THE SECURITY, QUIET ENJOYMENT, RELIABILITY, TIMELINESS, AND
            PERFORMANCE ARE HEREBY DISCLAIMED TO THE EXTENT ALLOWED BY APPLICABLE LAW.
          `}
        </SectionWrapper>
        <SectionWrapper>
          {`
            3. Limitation of Liability. THE TOTAL LIABILITY OF SEVONE AND ITS SUPPLIERS AND CONTRACTORS
            ARISING OUT OF OR RELATED TO THIS AGREEMENT OR CUSTOMER’S USE OF THE PRODUCTS SHALL NOT
            EXCEED THE AMOUNTS PAID BY CUSTOMER TO SEVONE FOR THE PRODUCTS GIVING RISE TO SUCH
            LIABILITY DURING THE TWELVE (12) MONTHS PERIOD PRECEDING THE EVENT OR CIRCUMSTANCE GIVING
            RISE TO SUCH LIABILITY. THIS LIMITATION OF LIABILITY IS CUMULATIVE AND NOT PER INCIDENT. IN NO
            EVENT SHALL SEVONE OR ITS SUPPLIERS OR CONTRACTORS BE LIABLE FOR ANY LOSS OF USE,
            INTERRUPTION OF BUSINESS, LOST PROFITS, OR LOST DATA, OR ANY INDIRECT, SPECIAL, INCIDENTAL, OR
            CONSEQUENTIAL DAMAGES ARISING OUT OF OR RELATED TO THIS AGREEMENT OR CUSTOMER’S USE OF
            ANY PRODUCT, HOWEVER CAUSED AND ON ANY THEORY OF LIABILITY, WHETHER IN CONTRACT, TORT
            (INCLUDING NEGLIGENCE) STRICT LIABILITY OR OTHERWISE, EVEN IF SEVONE HAS BEEN ADVISED OF
            THE POSSIBILITY OF SUCH DAMAGES. These limitations shall apply notwithstanding any failure of essential purpose of
            any limited remedy.
          `}
        </SectionWrapper>
        <SectionWrapper>
          {'4. Termination.'}
        </SectionWrapper>
        <SectionWrapper>
          {`
            4.1 By SevOne. SevOne shall have the right to terminate this Agreement, and/or any of Customer’s licenses to the Software
            granted herein, without liability, in the event Customer (i) breaches any provision of this Agreement, or (ii) becomes insolvent
            or takes any action to wind-up, liquidate or otherwise cease doing business. In addition, this Agreement and all licenses granted
            hereunder shall automatically terminate if Customer transfers the Hardware to a third party. Any such termination shall be
            without limitation of any other right or remedy available to SevOne.
          `}
        </SectionWrapper>
        <SectionWrapper>
          {`
            4.2 Effect of Termination. Upon any termination of this Agreement or any applicable Order or Statement of Work, (i) if the
            Order is for subscription licenses, all applicable licenses are also terminated, and Customer shall immediately cease use of the
            applicable Hardware, Software and Documentation and shall return to SevOne such Hardware, Software and Documentation
            (and all copies thereof), for which purpose SevOne will provide a pre-paid return label for the return of the Hardware; and (ii)
            if the Order is for perpetual licenses, Customer shall retain the Hardware and Software, however all maintenance and support
            services (including updates and upgrades) shall automatically terminate. To the extent that SevOne has the right to do so, it
            shall assign to Customer any remaining rights under all warranties for hardware delivered by SevOne that are manufactured by
            third parties and shall provide to Customer a copy of the third party warranty terms pertaining to such hardware. The terms
            and conditions of this Agreement will continue to govern any Order or Statement of Work that is not terminated. Unless
            termination is due to SevOne’s breach of this Agreement, no refunds will be provided by SevOne for any fees paid or prepaid
            by Customer prior to the termination of this Agreement or any applicable Order or Statement of Work, and all fees that have
            accrued or have become payable by Customer hereunder shall be due in full upon termination.
          `}
        </SectionWrapper>
        <SectionWrapper>
          {`
            4.3 Survival. The provisions of Sections 1.3, 1.4, 1.5, 3, 4.2, 4.3, 5, and 6 shall survive termination of this Agreement.
          `}
        </SectionWrapper>
        <SectionWrapper>
          {`
            5. Confidentiality. SevOne may, from time to time, deliver to Customer certain non-public information including formulas,
            flow charts, diagnostic routines, business information, forecasts, financial plans and data, balance sheet information, customer
            information, marketing plans, hardware, software and unannounced product information (collectively, “Confidential Information”). Such Confidential Information, if in writing, shall be marked prominently with the legend “confidential”,
            “proprietary”, or with a similar legend, or if disclosed orally shall be described as Confidential Information at the time of oral
            disclosure. However, regardless of any marking or subsequent summary, information disclosed shall be considered confidential
            if a reasonable person under the circumstances would understand such information to be of a confidential nature.
            Notwithstanding anything to the contrary herein, the Software, Documentation and non-public Product information shall be
            deemed Confidential Information. Customer shall not use or disclose SevOne’s Confidential Information, except as expressly
            authorized by this Agreement or in writing by SevOne, using the same degree of care which Customer uses with respect to its
            own proprietary information, but in no event with less than with reasonable care. The foregoing obligations of confidentiality
            shall not apply to any information that Customer can show is or was (i) already known to Customer at the time of disclosure
            without obligation of confidentiality; (ii) independently developed by Customer without use of or access to SevOne’s
            Confidential Information; (iii) approved for disclosure by SevOne beforehand and in writing; (iv) publicly known without
            breach of this Agreement; (v) lawfully received by Customer from a third party without obligation of confidentiality; or (vi)
            required to be disclosed by applicable law or order of a court, tribunal or other governmental agency; provided, however, that
            Customer shall promptly notify SevOne in writing of such requirement, and shall cooperate with SevOne to minimize the
            scope of any such disclosure, and in the obtaining of a confidentiality, protective or similar order.
          `}
        </SectionWrapper>
        <SectionWrapper>
          {`
            6. General Provisions. This Agreement shall be interpreted and enforced in accordance with the laws of the Commonwealth
            of Massachusetts, without regard to its conflict of laws principles. The UN Convention on Contracts for the International Sale
            of Goods shall not apply. This Agreement and all Orders agreed by the parties are the entire agreement of the parties, and
            supersedes all prior agreements and communications, written or oral, between the parties with respect to the subject matter of
            this Agreement. The terms and conditions of any purchase orders or other communications issued by Customer shall not be
            binding upon SevOne, even if accepted by SevOne. If any provision of this Agreement shall be held to be invalid, illegal or
            unenforceable, the remaining provisions shall not be affected or impaired. No modification or waiver of any provision of this
            Agreement shall be valid or binding unless made in writing and executed by an authorized officer of SevOne. No delay or
            omission to exercise any right or remedy accruing to SevOne hereunder shall impair that right or remedy, or be construed to be
            a waiver of any breach or default. Customer may assign this Agreement (including by operation of law or in connection with a
            change in control, merger, acquisition, reorganization, consolidation or sale of all or substantially all of its business, assets or
            stock), upon written notice to SevOne at least 30 days prior to any assignment, provided that the Agreement is not assigned to a
            competitor of SevOne. SevOne may assign this Agreement (including by operation of law or in connection with a change in
            control, merger, acquisition, reorganization, consolidation or sale of all or substantially all of its business, assets or stock),
            without consent or notice. Any other attempted assignment shall be void. Subject to the foregoing, this Agreement shall be
            binding upon and insure to the benefit of the parties hereto, their successors and permitted assigns. Customer hereby
            acknowledges that the Software and Hardware may be subject to export controls under the laws and regulations of the United
            States of America, as well as any applicable laws and regulations of the territories outside of the United States of America.
            Customer shall comply with such laws and regulations and agrees not to export, re-export or transfer any Software and
            Hardware without first obtaining all required governmental authorizations or licenses. The foregoing shall not be construed as
            to waive any other restrictions on transfer set forth herein. Customer agrees that SevOne may list Customer’s name in any
            customer lists and that it will cooperate with SevOne upon request in the preparation of a press release to be issued by SevOne
            announcing Customer’s having become a customer of SevOne.
          `}
        </SectionWrapper>
      </EulaContent>
    </Dialog>
  );
}

export { EulaDialog };
