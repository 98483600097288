import * as React from 'react';
import { styled } from 'linaria/react';
import throttle from 'lodash-es/throttle';
import { WidgetChainProvider } from '@sevone/insight-connect';
import { messages, FacetType } from '@sevone/insight-wdk';
import { VisualizationRuntime } from '@sevone/insight-widget-runtime';
import {
  useWidgets,
  useWidgetLinks
} from '../../../report-runtime';
import { useWidgetFetcher } from '../../../hooks/wdk-server';
import { debug } from '../../../utils/logger';
import { WidgetType } from '../../report/types';
import { generateWidgetReadyEvent } from '../events';

const Wrapper = styled.div<{ active: boolean }>`
  /* display: ${(p) => (p.active ? 'block' : 'none')}; */
  visibility: ${(p) => (p.active ? 'visible' : 'hidden')};
  /* opacity: ${(p) => (p.active ? 1 : 0.5)}; */
  /* float: ${(p) => (p.active ? 'none' : 'left')}; */
  /* max-height: ${(p) => (p.active ? 'none' : '100%')}; */
  position: ${(p) => (p.active ? 'static' : 'absolute')};
  top: ${(p) => (p.active ? 'auto' : '0')};
  bottom: ${(p) => (p.active ? 'auto' : '0')};
  width: 100%;
`;

type Props = {
  active: boolean,
  hasImportData: boolean,
  widget: WidgetType,
  // facetStack: FacetStack | null,
  facets: Array<FacetType>,
  readyWidgets: Record<string, boolean>,
  onReady: (widgetId: string) => void
};

function RawWidget(props: Props) {
  const {
    active,
    hasImportData,
    widget,
    // facetStack,
    facets,
    readyWidgets,
    onReady
  } = props;
  const {
    isFetching,
    widgetInterface,
    widgetMeta
  } = useWidgetFetcher(widget ? widget.type.name : null);
  const { broadcastFromWidget } = useWidgets();
  const { getWidgetLinksByChild, getWidgetLinkByParent } = useWidgetLinks();
  const linksAsParent = getWidgetLinkByParent(widget.id)?.children.map((c) => ({
    parentId: widget.id,
    childId: c.id
  })) || [];
  const linksAsChild = getWidgetLinksByChild(widget.id).map((link) => ({
    parentId: link.parentId,
    childId: widget.id
  }));
  // const parents = getWidgetLinksByChild(widget.id).map((link) => link.parentId);
  const parentsAreReady = linksAsChild.every((l) => readyWidgets[l.parentId]);
  const chains = React.useMemo(() => {
    return linksAsParent.concat(linksAsChild);
  }, [ JSON.stringify(linksAsParent), JSON.stringify(linksAsChild) ]);
  const isReady = !!readyWidgets[widget.id];
  const canRender = !isFetching &&
    // !!facetStack &&
    !!widget &&
    !!widgetInterface &&
    !!widgetMeta &&
    parentsAreReady;

  const throttledOnReady = React.useCallback(throttle(() => {
    debug('pdf', widget.id, 'onready');
    onReady(widget.id);
  }, 2000, { leading: false }), [ onReady ]);

  // const handleDataChange = () => {
  //   throttledOnReady();
  // };

  const handleMessage = (message: string, payload: any) => {
    debug('pdf', widget.id, 'handlemessage', message, payload);
    // If we have import data we don't want to overwrite it with facets the
    // widget is broadcasting now.
    if (message === messages.broadcastFacet && !hasImportData && !isReady) {
      debug('pdf', widget.id, 'broadcastfacet', payload);
      broadcastFromWidget(widget.id, payload);
      throttledOnReady();
    } else if (message === messages.setData) {
      debug('pdf', widget.id, 'datachange');
      throttledOnReady();
      // handleDataChange();
    }
  };

  React.useEffect(() => {
    // This is far from ideal, but for now we'll use a timeout to make sure
    // widgets have time to render their data before we fire off the event
    // immediately after them getting their data.
    // if (active) {
    //   setTimeout(() => {
    //     document.dispatchEvent(generateWidgetReadyEvent(widget.id));
    //   }, 2000);
    // }

    if (active) {
      document.dispatchEvent(generateWidgetReadyEvent(widget.id));
    }
  }, [ active ]);

  // React.useEffect(() => { console.log('pdf mountwidget', widget.id); }, []);

  if (!canRender) {
    return null;
  }

  // console.log('pdf', widget.id, facetStack?.list());

  return (
    <WidgetChainProvider
      widgetId={widget.id}
      chains={chains}
    >
      <Wrapper active={active}>
        <VisualizationRuntime
          // @ts-ignore: Not smart enough to know `isReady` check above is safe
          widget={widgetInterface}
          // @ts-ignore
          meta={widgetMeta}
          facets={facets.filter((facet) => {
            return facet.data !== null;
          })}
          configuration={widget.configuration}
          onMessage={handleMessage}
          onConfigurationChange={() => {}}
        />
      </Wrapper>
    </WidgetChainProvider>
  );
}

const Widget = RawWidget;
// const Widget = React.memo(RawWidget);

export { Widget };
