import * as React from 'react';
import { css } from 'linaria';
import { styled } from 'linaria/react';
import { useModal, navigation } from '@sevone/insight-connect';
import {
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Badge,
  CircleUserIcon,
  AngleUpIcon
} from '@sevone/scratch';
import { CoreStore } from '../../../store';
import { useGql } from '../../../hooks/use-gql';
import { oidcSignoutEnabled, obiEnabled, obiNewFeatures } from '../../../global';
import { PermissionGate } from '../../../components/permission-gate';
import { openidSignoutRedirect } from '../../../utils/openid-connect';
import { HORIZONTAL_RHYTHM, VERTICAL_RHYTHM } from '../../../utils/spacing';
import { baseUrl } from '../../../utils/base-location';
import { SwitchTenant } from './switch-tenant';
import { SwitchTheme } from './switch-theme';
import { WhatsNew } from './whats-new';
import {
  DEAUTHENTICATE_QUERY,
  DeauthenticateResponseType
} from './deauthenticate.mutation';

const DropdownWrapper = styled.div`
  cursor: pointer;
  padding: ${VERTICAL_RHYTHM}px ${HORIZONTAL_RHYTHM * 2}px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background: var(--sev1-primary-2-color);
    color: var(--sev1-primary-2-contrast);
  }
`;

const DropdownMenuWrapper = styled.div`
  padding-left: ${HORIZONTAL_RHYTHM}px;
`;

const UserIconWrapper = styled.div`
  line-height: 1.5;
`;

const UserInfoWrapper = styled.div`
  margin: 0 auto 0 ${HORIZONTAL_RHYTHM}px;
  padding-right: ${HORIZONTAL_RHYTHM}px;
`;

const UsernameWrapper = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 600;
  line-height: 1.5;
  font-size: .75em;
`;

const TenantWrapper = styled.div`
  font-size: .65em;
`;

const ExternalLink = styled.a`
  text-decoration: none;
`;

const WhatsNewItemWrapper = styled.div`
  display: flex;
`;

const WhatsNewItemIndicator = styled.div`
  align-self: center;
  background-color: var(--sev1-info-color);
  width: .5em;
  height: .5em;
  margin-left: ${HORIZONTAL_RHYTHM / 2}px;
  border-radius: 6px;
`;

const dropdownStyles = css`
  display: block !important;
`;

type Props = {
  collapsed: boolean
};

function UserMenu(props: Props) {
  const { collapsed } = props;
  const { state, logout } = CoreStore.useContainer();
  const { showModal } = useModal();
  const [ dropdownVisible, setDropdownVisibility ] = React.useState(false);
  const initialNewFeatures = localStorage.getItem('obiNewFeatures') !== obiNewFeatures;
  const [ newFeatures, setNewFeatures ] = React.useState(initialNewFeatures);
  const {
    runGql: deauthenticate
  } = useGql<DeauthenticateResponseType>(DEAUTHENTICATE_QUERY);

  let logoutUrl: string;
  if (state.activeTenant && state.activeTenant.logoutURL) {
    logoutUrl = state.activeTenant.logoutURL;
  }

  const handleShowTenantModal = () => {
    showModal(SwitchTenant);
  };

  const handleShowThemeModal = () => {
    showModal(SwitchTheme);
  };

  const handleToggleNewFeatures = () => {
    localStorage.setItem('obiNewFeatures', String(obiNewFeatures));
    setNewFeatures(false);
    showModal(WhatsNew);
  };

  // handle user-initiated logout
  const handleLogout = async () => {
    const { deauthenticate: res } = await deauthenticate();

    if (!res.success) {
      throw new Error('Error logging out.');
    }

    if (oidcSignoutEnabled) {
      // must not clear session-state, as we do not
      //  want to cause a redirect to DI login page
      logout({ clearSession: false });
      // redirect to OpenID logout endpoint,
      //  which in turn redirects to logoutUrl
      //  (only for user-initated logout)
      openidSignoutRedirect(logoutUrl);
    } else if (logoutUrl) {
      // must not clear session-state, as we do not
      //  want to cause a redirect to DI login page
      logout({ clearSession: false });
      // redirect to tenant logoutUrl
      //  (only for user-initated logout)
      window.location.assign(logoutUrl);
    } else {
      // redirect to DI login page by clearing session
      logout();
      // do not keep previous location state for user-initated logout
      navigation.navigateInPlace({
        pathname: navigation.location.pathname,
        hash: '',
        params: {},
        state: {}
      });
    }
  };

  if (!state.user || !state.activeTenant) {
    return null;
  }

  return (
    <Dropdown
      position="topLeft"
      trigger={[ 'click' ]}
      visible={dropdownVisible}
      renderDropdownClassname={dropdownStyles}
      menu={(
        <DropdownMenuWrapper>
          <DropdownMenu>
            <DropdownItem onClick={handleShowThemeModal}>
              {'Change Theme'}
            </DropdownItem>
            <PermissionGate requiredPermissions={[ 'switchTenants' ]}>
              <DropdownItem onClick={handleShowTenantModal}>
                {'Change Tenant'}
              </DropdownItem>
            </PermissionGate>
            {/* SHOW ON-BOARDING ITEMS */}
            {obiEnabled && (
              <>
                <DropdownItem onClick={handleToggleNewFeatures}>
                  <WhatsNewItemWrapper>
                    {'What\'s New?'}
                    {newFeatures && <WhatsNewItemIndicator />}
                  </WhatsNewItemWrapper>
                </DropdownItem>
                <DropdownItem>
                  <ExternalLink
                    href={`${baseUrl}/assets/user-guide.pdf`}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    {'User Guide'}
                  </ExternalLink>
                </DropdownItem>
              </>
            )}
            <DropdownItem onClick={handleLogout}>
              {'Logout'}
            </DropdownItem>
          </DropdownMenu>
        </DropdownMenuWrapper>
      )}
      onVisiblityChange={setDropdownVisibility}
    >
      <DropdownWrapper>
        <UserIconWrapper>
          {obiEnabled && newFeatures ?
            <Badge type="info"><CircleUserIcon /></Badge> :
            <CircleUserIcon />
          }
        </UserIconWrapper>
        {!collapsed &&
          <>
            <UserInfoWrapper>
              <UsernameWrapper>
                {state.user.username}
              </UsernameWrapper>
              <TenantWrapper>
                {state.activeTenant.name}
              </TenantWrapper>
            </UserInfoWrapper>
            <AngleUpIcon />
          </>
        }
      </DropdownWrapper>
    </Dropdown>
  );
}

export { UserMenu };
