import * as React from 'react';
import { styled } from 'linaria/react';
import uniqWith from 'lodash-es/uniqWith';
import isEqual from 'lodash-es/isEqual';
import castArray from 'lodash-es/castArray';
import {
  DeviceSelector,
  ObjectSelector,
  IndicatorSelector,
  DeviceType,
  ObjectType,
  IndicatorType
} from '@sevone/insight-connect';
import { HORIZONTAL_RHYTHM } from '../../../../../../utils/spacing';
import { RenderVariableProps } from '../../types';
import { MAX_RESOURCES_DISPLAYED } from '../utils';
import { ConfigurationType, OptionType, ValueType } from './types';

const Wrapper = styled.div`
  display: flex;
`;

const SelectWrapper = styled.div`
  flex: 1;
  margin-right: ${HORIZONTAL_RHYTHM}px;
  min-width: 150px;

  &:last-of-type {
    margin-right: 0;
  }
`;

type Props = RenderVariableProps<ConfigurationType, OptionType, ValueType>;

function Variable(props: Props) {
  const { value, options, config, onConfigChange, onValueChange } = props;
  const [
    devices,
    setDevices
  ] = React.useState<Array<DeviceType>>(
    // We need to dedup this list because it's based on `value`, which is full
    // of indicators. If multiple indicators share the same device, that device
    // will show up multiple times in this list.
    value ? uniqWith(value.resources.map((indicator) => ({
      value: indicator.deviceName
    })), isEqual) : config.partialDevices
  );
  const [
    objects,
    setObjects
  ] = React.useState<Array<ObjectType>>(
    // Deduping here for the same reason as above.
    value ? uniqWith(value.resources.map((indicator) => ({
      value: indicator.objectName,
      device: { value: indicator.deviceName },
      plugin: { value: indicator.indicatorType.objectType.plugin.value }
    })), isEqual) : config.partialObjects
  );

  const getDeviceGroups = () => {
    if (config.selectAll) {
      return undefined;
    }

    const deviceGroups = [
      ...config.hierarchicalData,
      config.value
    ].find((data) => {
      return data && data.type === 'DEVICE_GROUP';
    });

    // For some reason TS needs us to check again for `type` here.
    if (!deviceGroups || deviceGroups.type !== 'DEVICE_GROUP') {
      return [];
    }

    return deviceGroups.resources;
  };

  const getOptions = () => {
    if (config.selectAll) {
      return undefined;
    }

    return options.length > 0 ? options : undefined;
  };

  const handleDeviceChange = (selection: Array<DeviceType>) => {
    setDevices(selection);
    setObjects([]);
    onConfigChange({
      partialDevices: selection,
      partialObjects: []
    });
    onValueChange(null);
  };

  const handleObjectChange = (selection: Array<ObjectType>) => {
    setObjects(selection);
    onConfigChange({ partialObjects: selection });
    onValueChange(null);
  };

  const handleIndicatorChange = (selection: Array<IndicatorType>) => {
    const nextValue = selection.length ? {
      type: 'INDICATOR',
      resources: selection
    } as const : null;

    onValueChange(nextValue);
  };

  return (
    <Wrapper>
      <SelectWrapper>
        <DeviceSelector
          clearable={config.isClearable}
          isMulti={config.isMulti}
          maxDisplayed={MAX_RESOURCES_DISPLAYED}
          datasources={castArray(config.datasources)}
          value={devices}
          deviceGroups={getDeviceGroups()}
          deviceOptions={getOptions()}
          onChange={handleDeviceChange}
        />
      </SelectWrapper>
      <SelectWrapper>
        <ObjectSelector
          clearable={config.isClearable}
          disabled={devices.length === 0}
          isMulti={config.isMulti}
          maxDisplayed={MAX_RESOURCES_DISPLAYED}
          datasources={castArray(config.datasources)}
          value={objects}
          devices={devices}
          onChange={handleObjectChange}
        />
      </SelectWrapper>
      <SelectWrapper>
        <IndicatorSelector
          clearable={config.isClearable}
          disabled={objects.length === 0}
          isMulti={config.isMulti}
          maxDisplayed={MAX_RESOURCES_DISPLAYED}
          datasources={castArray(config.datasources)}
          value={value ? value.resources : []}
          objects={objects}
          onChange={handleIndicatorChange}
        />
      </SelectWrapper>
    </Wrapper>
  );
}

export { Variable };
