export const DATASOURCE_WITH_TENANTS_QUERY = `
  query($ids: [Int]) {
    datasources(ids: $ids) {
      id
      tenants {
        id
        name
      }
    }
  }
`;

export type TenantType = {
  id: number,
  name: string
}

export type DatasourceWithTenantsResponse = {
  datasources: Array<{
    id: string,
    tenants: Array<TenantType>
  }>
}
