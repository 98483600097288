import * as React from 'react';
import {
  ResourceTypesType,
  ObjectSelector,
  ObjectType
} from '@sevone/insight-connect';
import { RenderVariableProps } from '../../types';
import { MAX_RESOURCES_DISPLAYED, getFilterDataOfType } from '../utils';
import { useSingleSelection } from '../use-single-selection';
import { ConfigurationType, OptionType, ValueType } from './types';

type Props = RenderVariableProps<ConfigurationType, OptionType, ValueType>;

function Variable(props: Props) {
  const { value, options, config, onValueChange } = props;

  const getFilterValues = (type: ResourceTypesType) => {
    if (config.selectAll) {
      return undefined;
    }

    return getFilterDataOfType(
      type,
      config.hierarchicalData,
      config.value
    );
  };

  const getOptions = () => {
    if (config.selectAll) {
      return undefined;
    }

    return options.length > 0 ? options : undefined;
  };

  const handleChange = (values: Array<ObjectType>) => {
    const nextValue = values.length ? {
      type: 'OBJECT',
      resources: values
    } : null;

    onValueChange(nextValue);
  };

  const handleFetchValueError = () => {
    onValueChange(null);
  };

  const {
    isSingleOption,
    handleOptionsChange
  } = useSingleSelection(value?.resources, handleChange, [
    options,
    config.selectAll
  ]);

  return (
    <ObjectSelector
      clearable={config.isClearable}
      disabled={isSingleOption}
      isMulti={config.isMulti && !isSingleOption}
      maxDisplayed={MAX_RESOURCES_DISPLAYED}
      datasources={config.datasources}
      value={value ? value.resources : []}
      devices={getFilterValues('DEVICE')}
      deviceGroups={getFilterValues('DEVICE_GROUP')}
      objectTypes={getFilterValues('OBJECT_TYPE')}
      objectGroups={getFilterValues('OBJECT_GROUP')}
      objectOptions={getOptions()}
      onChange={handleChange}
      onOptionsChange={handleOptionsChange}
      onFetchValueError={handleFetchValueError}
    />
  );
}

export { Variable };
