import * as React from 'react';
import { ResourceSelection } from '@sevone/insight-connect';
import {
  ResourceType,
  ResourceValueType,
  ResourceSelectorOnChange,
  ResourceHierarchicalDataType
} from './types';

type Props = {
  value: ResourceValueType,
  onChange: ResourceSelectorOnChange
};

const allowedPaths = {
  DEVICE_TYPE: {}
};

export function DeviceTypeSelector(props: Props) {
  const handleChange = (
    datasources: number[],
    value: ResourceType,
    hierarchicalData: ResourceHierarchicalDataType
  ) => {
    props.onChange({
      datasources,
      value,
      hierarchicalData
    });
  };

  return (
    <ResourceSelection
      isMultiDatasource
      onChange={handleChange}
      value={props.value.value}
      allowedPaths={allowedPaths}
      datasource={props.value.datasources}
      hierarchicalData={props.value.hierarchicalData}
    />
  );
}
