import * as React from 'react';
import { useNotification } from '@sevone/insight-connect';
import {
  Dropdown,
  DropdownMenu,
  DropdownSubMenu,
  DropdownItem,
  CopyIcon
} from '@sevone/scratch';
import { useReportRuntime } from '../../../../../report-runtime';
import { WidgetType, SectionType } from '../../../types';

type Props = {
  widget: WidgetType,
  isCompact: boolean,
  className?: string
};

function CopyMenu(props: Props) {
  const { widget, isCompact, className } = props;
  const { getSections, copyWidget } = useReportRuntime();
  const { showNotification } = useNotification();
  const [ menuVisibility, setMenuVisibility ] = React.useState(false);
  const sections = getSections();
  const ItemTag = isCompact ? DropdownItem : 'div';
  const WrapperTag = isCompact ? DropdownSubMenu : 'div';

  const handleCopyWidget = (section: SectionType) => {
    try {
      copyWidget(section.id, widget.id);
      showNotification({
        type: 'success',
        message: `Widget successfully copied to ${section.title}`
      });
    } catch {
      showNotification({
        type: 'error',
        message: 'Unknown error copying widget.'
      });
    }
  };

  if (sections.length === 1) {
    return (
      <ItemTag
        title="Duplicate"
        className={className}
        onClick={() => { handleCopyWidget(sections[0]); }}
      >
        {isCompact ? 'Duplicate' : <CopyIcon />}
      </ItemTag>
    );
  }

  const items = [ (
    <DropdownItem
      key={'duplicateDropdownHeader'}
      disabled
      onClick={() => {}}
    >
      {'Duplicate To:'}
    </DropdownItem>
  ), ...sections.map((section) => (
    <DropdownItem
      key={section.id}
      onClick={() => { handleCopyWidget(section); }}
    >
      {section.title}
    </DropdownItem>
  )) ];

  return (
    <WrapperTag title="Duplicate" position="left" className={className}>
      {isCompact && items}
      {!isCompact &&
        <Dropdown
          trigger={[ 'click' ]}
          position="bottomLeft"
          visible={menuVisibility}
          menu={(
            <DropdownMenu>
              {items}
            </DropdownMenu>
          )}
          onVisiblityChange={setMenuVisibility}
        >
          <CopyIcon />
        </Dropdown>
      }
    </WrapperTag>
  );
}

export { CopyMenu };
