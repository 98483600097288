import * as React from 'react';
import { styled } from 'linaria/react';
import { useNotification, useModal } from '@sevone/insight-connect';
import {
  DropdownButton,
  DropdownMenu,
  DropdownSubMenu,
  DropdownItem,
  SaveIcon,
  FilePdfIcon
} from '@sevone/scratch';
import { CoreStore } from '../../../../../store';
import { useGql } from '../../../../../hooks/use-gql';
import { hasPermission } from '../../../../../components/permission-gate';
import { isTruthy } from '../../../../../utils/is-truthy';
import { HORIZONTAL_RHYTHM } from '../../../../../utils/spacing';
import { baseUrl } from '../../../../../utils/base-location';
import { useReportRuntime } from '../../../../../report-runtime';
import { SaveAsDialog } from './save-as-dialog';
import {
  PRINT_REPORT_QUERY,
  PrintReportResponseType
} from './print-report.mutation';

const IconWrapper = styled.span`
  margin-right: ${HORIZONTAL_RHYTHM / 2}px;
`;

type Props = {
  isWritable: boolean,
  isNewReport: boolean
};

function SaveMenu(props: Props) {
  const { isWritable, isNewReport } = props;
  const { state } = CoreStore.useContainer();
  const {
    report,
    saveReport,
    copyReport,
    exportData
  } = useReportRuntime();
  const { showNotification } = useNotification();
  const { showModal } = useModal();
  const {
    runGql: printReport
  } = useGql<PrintReportResponseType>(PRINT_REPORT_QUERY);
  const [ menuVisibililty, setMenuVisibility ] = React.useState(false);
  const permissions = state.permissions.map((p) => p.id);

  const handleSave = (opts: { isTemplate?: boolean } = {}) => {
    return saveReport(opts).catch((e) => {
      showNotification({ type: 'error', message: e.message });
      return Promise.reject();
    });
  };

  const handleSaveAs = () => {
    return new Promise((resolve, reject) => {
      showModal(({ hideModal }) => (
        <SaveAsDialog
          report={report}
          onSave={(name: string, isTemplate: boolean) => {
            return copyReport(name, isTemplate).then(() => {
              hideModal();
              resolve();
            }).catch((e) => {
              showNotification({ type: 'error', message: e.message });
              return Promise.reject();
            });
          }}
          onCancel={() => {
            hideModal();
            reject();
          }}
        />
      ));
    });
  };

  const handlePrint = (orientation: 'LANDSCAPE' | 'PORTRAIT') => {
    const { report: sessionReport, ...sessionPayload } = exportData();
    const pdfWindow = window.open(`${baseUrl}/pdf-loading`);

    return printReport({
      reportId: sessionReport.id,
      pageOrientation: orientation,
      sessionPayload: JSON.stringify(sessionPayload),
      report: {
        content: JSON.stringify(sessionReport.content)
      }
    }).then((res) => {
      if (res.printReport && res.printReport.assetUrl) {
        pdfWindow?.location.replace(res.printReport.assetUrl);
      }
    }).catch((e) => {
      showNotification({ type: 'error', message: e.message });
      return Promise.reject();
    });
  };

  // This is a weird way of adding options, but if we do the normal
  // {<condition> && <component>} approach, we get left with `null` values in
  // the tree, which the dropdown doesn't like. So we'll build it in the array
  // and then filter out null values.
  const options = [
    (isWritable ? (
      <DropdownItem key="save" onClick={() => { return handleSave(); }}>
        {'Save'}
      </DropdownItem>
    ) : null),
    (!isNewReport && hasPermission([ 'createReport' ], permissions) ? (
      <DropdownItem key="save-as" onClick={handleSaveAs}>
        {'Save as'}
      </DropdownItem>
    ) : null),
    (isNewReport && hasPermission([ 'createReport' ], permissions) ? (
      <DropdownItem
        key="save-template"
        onClick={() => {
          return handleSave({ isTemplate: true });
        }}
      >
        {'Save as template'}
      </DropdownItem>
    ) : null),
    (!isNewReport ? (
      <DropdownSubMenu
        key="pdf"
        position="left"
        title={<><IconWrapper><FilePdfIcon /></IconWrapper> {'PDF'}</>}
      >
        <DropdownItem onClick={() => handlePrint('LANDSCAPE')}>
          {'Landscape'}
        </DropdownItem>
        <DropdownItem onClick={() => handlePrint('PORTRAIT')}>
          {'Portrait'}
        </DropdownItem>
      </DropdownSubMenu>
    ) : null)
  ].filter(isTruthy);

  // If they can't do any sort of save/create, at least give the user the
  // ability to generate a pdf of a report they can view.
  if (!isWritable && !hasPermission([ 'createReport' ], permissions)) {
    return (
      <DropdownButton
        type="outlined"
        trigger={[ 'click' ]}
        position="bottomRight"
        prefixIcon={<FilePdfIcon />}
        visible={menuVisibililty}
        menu={(
          <DropdownMenu>
            <DropdownItem onClick={() => handlePrint('LANDSCAPE')}>
              {'Landscape'}
            </DropdownItem>
            <DropdownItem onClick={() => handlePrint('PORTRAIT')}>
              {'Portrait'}
            </DropdownItem>
          </DropdownMenu>
        )}
        onClick={() => handlePrint('LANDSCAPE')}
        onVisiblityChange={setMenuVisibility}
      >
        {'PDF'}
      </DropdownButton>
    );
  }

  return (
    <DropdownButton
      type="outlined"
      trigger={[ 'click' ]}
      position="bottomRight"
      prefixIcon={<SaveIcon />}
      visible={menuVisibililty}
      menu={(
        <DropdownMenu>
          {options}
        </DropdownMenu>
      )}
      onClick={isWritable ? handleSave : handleSaveAs}
      onVisiblityChange={setMenuVisibility}
    >
      {isWritable ? 'Save' : 'Save as'}
    </DropdownButton>
  );
}

export { SaveMenu };
