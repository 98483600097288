const UPDATE_REPORT_FOLDER = `
  mutation ($id: Int!, $report: ReportInput!) {
    updateReport(id: $id, report: $report) {
      id
    }
  }
`;

export type UpdateReportFolderResponseType = {
  updateReport: {
    id: number
  }
};

export { UPDATE_REPORT_FOLDER };
