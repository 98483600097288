const PRINT_REPORT_QUERY = `
  mutation(
    $pageOrientation: DocumentPageOrientation,
    $reportId: Int,
    $report: PrintReportOverrideArgs,
    $sessionPayload: String
  ) {
    printReport(
      pageOrientation: $pageOrientation,
      reportId: $reportId,
      report: $report,
      sessionPayload: $sessionPayload
    ) {
      assetUrl
    }
  }
`;

export type PrintReportResponseType = {
  printReport: {
    assetUrl: string
  } | null;
}

export { PRINT_REPORT_QUERY };
