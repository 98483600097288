const CONTAINER_TYPES = {
  widgetLink: 'widgetLink',
  widgetChain: 'widgetChain',
  reportLinks: 'reportLinks',
  reportVariables: 'reportVariables',
  reportTimespan: 'reportTimespan',
  widget: 'widget'
} as const;

const TYPE_PRIORITIES = {
  [CONTAINER_TYPES.widgetLink]: 1,
  [CONTAINER_TYPES.widgetChain]: 2,
  [CONTAINER_TYPES.reportLinks]: 3,
  [CONTAINER_TYPES.reportVariables]: 4,
  [CONTAINER_TYPES.reportTimespan]: 5,
  [CONTAINER_TYPES.widget]: 6
};

const DEPENDENCY_LIST: Record<keyof typeof CONTAINER_TYPES, Array<string>> = {
  [CONTAINER_TYPES.widgetLink]: [],
  [CONTAINER_TYPES.widgetChain]: [],
  [CONTAINER_TYPES.reportLinks]: [],
  [CONTAINER_TYPES.reportVariables]: [ CONTAINER_TYPES.reportLinks ],
  [CONTAINER_TYPES.reportTimespan]: [ CONTAINER_TYPES.reportLinks ],
  [CONTAINER_TYPES.widget]: [
    CONTAINER_TYPES.reportTimespan,
    CONTAINER_TYPES.reportVariables
  ]
};

export {
  CONTAINER_TYPES,
  TYPE_PRIORITIES,
  DEPENDENCY_LIST
};
