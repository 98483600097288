import * as React from 'react';
import { styled } from 'linaria/react';
import { navigation, useModal, useNotification } from '@sevone/insight-connect';
import {
  Button,
  IconButton,
  Tooltip,
  NewEntityIcon,
  TrashIcon,
  PlayIcon,
  SyncIcon
} from '@sevone/scratch';
import { CoreStore } from '../../../store';
import { PageHeader, PageTitle } from '../../../components/page';
import {
  PermissionGate,
  hasPermission
} from '../../../components/permission-gate';
import { HORIZONTAL_RHYTHM } from '../../../utils/spacing';
import { ReportType } from '../get-reports.query';
import { FolderType } from '../get-folders.query';
import { FilterType } from '../filter-reports';
import { DeleteReports } from './delete-reports';
import { CopyReports } from './copy-reports';
import { ImportReports } from './import-reports';
import { ExportReports } from './export-reports';
import { Search } from './search';

const RUN_REPORTS_LIMIT = 5;

const Wrapper = styled(PageHeader)`
  flex: none;
`;

const SearchWrapper = styled.div`
  margin: 0 ${HORIZONTAL_RHYTHM / 2}px 0 auto;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;

  & > * {
    margin-right: ${HORIZONTAL_RHYTHM / 2}px;

    &:last-child {
      margin-right: 0;
    }
  }
`;

const TooltipContentWrapper = styled.div`
  max-width: 200px;
`;

type ConditionalTooltipProps = {
  disabled: boolean
} & React.ComponentProps<typeof Tooltip>;

function ConditionalTooltip(props: ConditionalTooltipProps) {
  const { disabled, position, tooltip, children } = props;

  if (!disabled) {
    return <>{children}</>;
  }

  return <Tooltip position={position} tooltip={tooltip}>{children}</Tooltip>;
}

type Props = {
  selectedReports: Array<ReportType>,
  selectedFolder: FolderType | null,
  fetchReports: () => Promise<Array<ReportType>>,
  fetchFolders: () => Promise<Array<FolderType>>,
  onDeleteReports: (reports: Array<ReportType['id']>) => void,
  onSearchChange: (filters: Array<FilterType>) => void
};

function Header(props: Props) {
  const {
    selectedReports,
    selectedFolder,
    fetchReports,
    fetchFolders,
    onDeleteReports,
    onSearchChange
  } = props;
  const { state } = CoreStore.useContainer();
  const { showNotification } = useNotification();
  const { showModal } = useModal();
  // User can only delete selected reports if they either
  // A) can modify any tenant report
  // B) are the owner of the reports
  const hasDeleteReportAccess = hasPermission(
    [ 'modifyTenantReports' ],
    state.permissions.map((p) => p.id)
  ) || selectedReports.every((report) => {
    return report.owner.id === state.user?.id;
  });

  const refreshData = () => {
    return Promise.all([ fetchReports(), fetchFolders() ]);
  };

  const handleCreateReport = () => {
    const folderAddon = selectedFolder ? `?folder=${selectedFolder.id}` : '';
    navigation.navigateTo(`/reports/new-report${folderAddon}`);
  };

  const handleCopySuccess = () => {
    refreshData();
  };

  const handleCopyError = (error: { message: string }) => {
    // It's possible some reports were successfully copied, so fetch those
    refreshData();
    showNotification({ type: 'error', message: error.message });
  };

  const handleDeleteReports = () => {
    return new Promise((resolve, reject) => {
      showModal(({ hideModal }) => (
        <DeleteReports
          reports={selectedReports}
          onClose={() => {
            refreshData();
            hideModal();
            reject();
          }}
          onError={(e) => {
            showNotification({ type: 'error', message: e.message });
            return reject();
          }}
          onConfirm={(reports) => {
            onDeleteReports(reports);
            refreshData();
            return resolve();
          }}
        />
      ));
    });
  };

  const handleOpenReports = () => {
    selectedReports.forEach((report) => {
      window.open(`reports/${report.id}`, '_blank');
    });
  };

  return (
    <Wrapper>
      <PageTitle>{'Report Manager'}</PageTitle>
      <SearchWrapper>
        <Search onChange={onSearchChange} />
      </SearchWrapper>
      <ButtonsWrapper>
        <PermissionGate requiredPermissions={[ 'createReport' ]}>
          <Button
            prefixIcon={<NewEntityIcon />}
            onClick={handleCreateReport}
          >
            {'Create Report'}
          </Button>
        </PermissionGate>
        <PermissionGate requiredPermissions={[ 'createReport' ]}>
          <CopyReports
            reportIds={selectedReports.map((report) => report.id)}
            onError={handleCopyError}
            onSuccess={handleCopySuccess}
          />
        </PermissionGate>
        <PermissionGate
          requiredPermissions={[
            [ 'modifyPersonalReports', 'modifyTenantReports' ]
          ]}
        >
          <Button
            type="outlined"
            prefixIcon={<TrashIcon />}
            disabled={
              selectedReports.length === 0 ||
              !hasDeleteReportAccess
            }
            onClick={handleDeleteReports}
          >
            {'Delete'}
          </Button>
        </PermissionGate>
        <PermissionGate
          requiredPermissions={[
            [ 'viewPersonalReports', 'viewTenantReports' ]
          ]}
        >
          <ConditionalTooltip
            disabled={selectedReports.length > RUN_REPORTS_LIMIT}
            position={[ 'bottom', 'right' ]}
            tooltip={(
              <TooltipContentWrapper>
                {`Please select ${RUN_REPORTS_LIMIT} or fewer reports.`}
              </TooltipContentWrapper>
            )}
          >
            <Button
              type="outlined"
              prefixIcon={<PlayIcon />}
              disabled={
                selectedReports.length === 0 ||
                selectedReports.length > RUN_REPORTS_LIMIT
              }
              onClick={handleOpenReports}
            >
              {'Run'}
            </Button>
          </ConditionalTooltip>
        </PermissionGate>
        <PermissionGate requiredPermissions={[ 'createReport' ]}>
          {selectedReports.length === 0 ?
            <ImportReports onImportSuccess={refreshData} /> :
            <ExportReports
              selectedReports={selectedReports}
            />
          }
        </PermissionGate>
        <PermissionGate
          requiredPermissions={[
            [ 'viewPersonalReports', 'viewTenantReports' ]
          ]}
        >
          <IconButton onClick={refreshData}>
            <SyncIcon fixedWidth />
          </IconButton>
        </PermissionGate>
      </ButtonsWrapper>
    </Wrapper>
  );
}

export { Header };
