import * as React from 'react';
import { Select } from '@sevone/scratch';
import {
  NAMESPACE_QUERY,
  NamespaceResponseType
} from '../queries/get-namespaces.query';
import { useGql } from '../../../../../../../hooks/use-gql';

type Props = {
  datasourceIds: Array<number>,
  value: string | null,
  onChange: (value: string | null) => void
}
type OptionType = {
  value: string,
  label: string
}

const SIZE = 200;

function NamespaceSelector(props: Props) {
  const {
    runGql: requestNamespaces
  } = useGql<NamespaceResponseType>(NAMESPACE_QUERY);
  const { datasourceIds, onChange, value } = props;

  const fetchNamespaceOptions = async (name: string = '') => {
    const namespaceLists = await Promise.all(datasourceIds.map((datasourceId) => {
      const vars = {
        datasourceId,
        filter: {
          name
        },
        size: SIZE
      };
      return requestNamespaces(vars).then((res) => {
        return res.metadataNamespaces.map((ns) => {
          return ns.name;
        });
      });
    }));

    const namespaceSet: Set<string> = new Set();
    namespaceLists.forEach((namespaceList) => {
      namespaceList.forEach((namespace) => {
        namespaceSet.add(namespace);
      });
    });
    const mergedNamespaces = [ ...namespaceSet ];

    return mergedNamespaces
      .sort((a, b) => a.localeCompare(b, undefined, { numeric: true, ignorePunctuation: true }))
      .map((attr) => ({ label: attr, value: attr }));
  };

  const handleChange = (option: OptionType) => {
    onChange(option?.value || null);
  };

  return (
    <Select
      clearable
      key={datasourceIds.join('-')}
      label={'Namespace'}
      placeholder={'Namespace'}
      options={fetchNamespaceOptions}
      onChange={handleChange}
      value={value ? { value, label: value } : null}
    />
  );
}

export { NamespaceSelector };
