import { DatasourceType } from './get-datasource.query';

export const UPDATE_DATASOURCE_MUTATION = `
  mutation($datasource: DatasourceArgs!, $id: Int!) {
    updateDatasource(datasource: $datasource, id: $id) {
      id
      name
      dstype
      address
    }
  }
`;

export type UpdateDatasourceResponseType = {
  updateDatasource: DatasourceType
}
