const DELETE_FOLDER = `
  mutation ($id: Int!) {
    deleteFolder(id: $id) {
      id
    }
  }
`;

export type DeleteFolderResponseType = {
  deleteFolder: {
    id: number
  }
};

export { DELETE_FOLDER };
